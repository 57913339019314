.prev {
  background: linear-gradient(
    191.12deg,
    #00b3ec -91.84%,
    #cbec00 -91.82%,
    #6e9a10 25.42%,
    #d0a024 126.74%
  ) !important;
}
.current {
  color: #6e9a10 !important;
}
.next {
  background: linear-gradient(
    191.12deg,
    #00b3ec -91.84%,
    #cbec00 -91.82%,
    #6e9a10 25.42%,
    #d0a024 126.74%
  ) !important;
}
