.modal > * {
  border: 0 !important;
  border-radius: 0.25rem;
}
.header {
  padding: 0.8rem;
  border-top-left-radius: 0.25rem;
  border-top-right-radius: 0.25rem;
}
.header .title {
  color: #495057;
  font-weight: 600;
  font-size: 1.2rem !important;
}
.body .label {
  margin-bottom: 0.5rem;
  font-weight: 600;
  font-size: 0.75rem;
}
.body .input {
  border-radius: 0.25rem;
  font-size: 0.8rem;
  padding: 0.5rem 0.9rem;
  font-weight: 400;
  color: #212529;
}
.body .inputCheckbox {
  display: block;
  border-radius: 0.25rem;
  font-size: 2.32rem;
  padding: 0.5rem 0.5rem;
  font-weight: 400;
  color: #212529;
  margin-top: 0;
}
.body .invalid {
  font-size: 0.7em;
  display: block !important;
}
.body .checkboxGroup {
  max-height: 100px;
  overflow-y: auto;
  border: 1px solid #dee2e6;
  border-radius: 0.25rem;
  padding: 0.25rem;
}
.footer {
  border-top: 0;
}
.footer .close {
  border-radius: 0.25rem;
  font-size: 14px;
}
.footer .submit {
  border-radius: 0.25rem;
  border: 0 !important;
  background-color: #0ab39c;
  font-size: 14px;
}
.footer .submit:hover {
  color: #fff;
  background-color: #099885;
  border-color: #088f7d;
}
