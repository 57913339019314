.block {
  position: relative;
  margin-top: 100px;
  display: flex;
  --height-card: 280px;
  --width-card: 300px;
}
.block .header {
  display: flex;
  justify-content: flex-end;
  writing-mode: vertical-lr;
  transform: rotate(180deg);
  font-size: 29px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  background: linear-gradient(
    191.12deg,
    #00b3ec -91.84%,
    #cbec00 -91.82%,
    #6e9a10 25.42%,
    #d0a024 126.74%
  );
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  margin-right: 34px;
}
.registries .slide {
  height: var(--height-card);
  width: var(--width-card);
}
.registries .slide + .slide {
  border-left: 1px solid #cecece;
  padding-left: 25px;
  padding-right: 25px;
  width: calc(var(--width-card) + 25px);
}
.registries .slide .card {
  display: flex;
  flex-wrap: wrap;
  padding-left: 10px;
}
.registries .slide .card .link {
  position: relative;
  display: flex;
  align-items: center;
  margin-bottom: 30px;
  height: 75px;
  width: 100%;
  overflow: hidden;
}
.registries .slide .link .dot {
  position: absolute;
  width: 10px;
  height: 10px;
  border-radius: 20px;
  background: linear-gradient(
    191.12deg,
    #00b3ec -91.84%,
    #cbec00 -91.82%,
    #6e9a10 25.42%,
    #d0a024 126.74%
  );
  top: calc(50% - 5px);
}
.registries .slide .link .title {
  padding-left: 20px;
  color: #1194ba;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-decoration-line: underline;
  -webkit-line-clamp: 4;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
}
.registries .swiper {
  max-width: 1035px;
  padding: 1px 3px;
  height: calc(var(--height-card) + 33px) !important;
  --background-swiper-scrollbar-horizontal: #f8f8f8;
  --background-swiper-scrollbar-drag: linear-gradient(
    191.12deg,
    #00b3ec -91.84%,
    #cbec00 -91.82%,
    #6e9a10 25.42%,
    #d0a024 126.74%
  );
}
.block .hidden {
  position: absolute;
  top: 2px;
  right: -6px;
  box-shadow: #fff 0px 5px 12px 12px;
  width: 1px;
  height: var(--height-card);
  z-index: 1;
}
@media screen and (max-width: 1200px) {
  .block {
    display: block;
  }
  .block .header {
    display: none;
  }
  .block .hidden {
    display: none;
  }
  .registries .swiper {
    max-width: calc(100vw - 10px);
  }
}
