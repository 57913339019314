.block {
  position: relative;
  margin-top: 40px;
}
.tabs {
  display: flex;
  align-items: flex-end;
  border-bottom: none !important;
}
.tab {
  cursor: pointer;
  font-size: 26px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  color: #999;
  border: none !important;
  border-radius: 0px !important;
  background: #fff;
  box-shadow: -3px 2px 6px 0px rgba(0, 0, 0, 0.11) inset;
  margin-bottom: 5px !important;
  padding: 5px 27px;
}
.tab:hover {
  color: #999;
  background: linear-gradient(
    191.12deg,
    #00b3ec -91.84%,
    #cbec00 -91.82%,
    #6e9a10 25.42%,
    #d0a024 126.74%
  );
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.tab.start {
  border-radius: 10px 0px 0px 10px !important;
}
.tab.end {
  border-radius: 0px 10px 10px 0px !important;
}
.tab.active {
  font-size: 36px;
  background: linear-gradient(
    191.12deg,
    #00b3ec -91.84%,
    #cbec00 -91.82%,
    #6e9a10 25.42%,
    #d0a024 126.74%
  );
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  box-shadow: 1px 1px 8px 0px rgba(0, 0, 0, 0.15);
}
.tabContent {
  margin-top: 45px;
  display: flex !important;
  justify-content: flex-start;
  align-items: flex-start;
  align-content: flex-start;
  column-gap: 24px;
  row-gap: 18px;
  flex-wrap: wrap;
}
.card {
  width: 350px;
  height: auto;
  box-shadow: 1px 1px 8px 0px #00000026;
  padding: 15px 15px 8px 15px;
  border-radius: 10px;
}
.card .tags {
  display: flex;
  gap: 5px;
}
.card .tags .tag {
  font-size: 10px;
  font-weight: 400;
  background: linear-gradient(155.19deg, #6e9a10 15.81%, #6e9a10 100.43%);
  border-radius: 25px;
  padding: 2px 10px;
  color: #fff;
  text-transform: lowercase;
}
.card .type {
  margin-top: 6px;
  color: #696969;
  font-size: 12px;
  line-height: 14.5px;
  font-weight: 400;
  height: 30px;
  -webkit-line-clamp: 2;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
}
.card .header {
  color: #1a1a1a;
  font-size: 15px;
  font-weight: 500;
  line-height: 18px;
  height: 36px;
  -webkit-line-clamp: 2;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
}
.card .description {
  margin-top: 6px;
  background: #f6f6f6;
  border-radius: 10px;
  padding: 5px 10px;
  font-size: 12px;
  line-height: 16px;
  font-weight: 400;
  color: #1a1a1a;
  height: 58px;
  -webkit-line-clamp: 3;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
}
.card .footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 6px;
  font-size: 12px;
}
.card .footer .data {
  color: #1a1a1a;
  font-size: 11px;
  font-weight: 400;
}
.card .footer .addition {
  background: linear-gradient(155.19deg, #6e9a10 15.81%, #6e9a10 100.43%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-size: 11px;
  font-weight: 400;
}
.requestButton {
  position: absolute;
  padding: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  top: 10px;
  right: 0;
  color: #555;
  text-align: center;
  font-size: 11.5px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.requestButton:hover .fill,
.requestButton .border,
.requestButton .arrow {
  background-image: linear-gradient(
    191.12deg,
    #00b3ec -91.84%,
    #cbec00 -91.82%,
    #6e9a10 25.42%,
    #d0a024 126.74%
  ) !important;
}
.more {
  display: flex;
  justify-content: center;
  text-decoration: none;
}
.more .all {
  color: #000;
  font-size: 12px;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
  cursor: pointer;
  position: relative;
  margin-top: 48px;
  padding: 5px 20px;
  box-shadow: 1px 1px 8px 0px rgba(0, 0, 0, 0.15);
  border-radius: 20px;
}
.more .all::before {
  content: "";
  position: absolute;
  background: linear-gradient(
    191.12deg,
    #00b3ec -91.84%,
    #cbec00 -91.82%,
    #6e9a10 25.42%,
    #d0a024 126.74%
  );
  width: 6px;
  height: 16px;
  top: calc(50% - 8px);
  left: 100%;
  clip-path: polygon(0 12.5%, 100% 50%, 0 87.5%);
}
@media screen and (max-width: 1200px) {
  .block {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }
  .tab {
    font-size: 0.7rem;
  }
  .tab.active {
    font-size: 0.8rem;
  }
  .tab.start {
    border-radius: 5px 0px 0px 5px !important;
  }
  .tab.end {
    border-radius: 0px 5px 5px 0px !important;
  }
  .tabContent {
    justify-content: center;
  }
  .requestButton {
    top: 35px;
  }
}
