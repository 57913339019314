.block {
  display: flex;
  justify-content: space-between;
  position: relative;
  margin-top: 85px;
}
.card {
  width: 540px;
  height: auto;
}
.card .border {
  background-image: linear-gradient(
    191.12deg,
    #00b3ec -91.84%,
    #cbec00 -91.82%,
    #6e9a10 25.42%,
    #d0a024 126.74%
  );
}
.card .arrow {
  background-image: linear-gradient(
    201.12deg,
    #00b3ec -91.84%,
    #cbec00 -91.82%,
    #6e9a10 25.42%,
    #d0a024 126.74%
  );
}
.card .content {
  padding: 12px 25px;
  color: #555;
}
.card .content .header {
  margin-top: 40px;
  font-size: 36px;
  font-weight: 700;
  line-height: 44px;
  text-align: center;
  text-transform: uppercase;
  background: linear-gradient(
    180deg,
    #00b3ec -91.84%,
    #cbec00 -91.82%,
    #6e9a10 25.42%,
    #d0a024 126.74%
  );
  background-size: cover;
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.list {
  display: flex;
  flex-wrap: wrap;
  margin-top: 35px;
  padding-left: 10px;
}
.list .link {
  position: relative;
  margin-bottom: 30px;
  width: 100%;
}
.list .link .dot {
  position: absolute;
  width: 10px;
  height: 10px;
  border-radius: 20px;
  background: linear-gradient(
    191.12deg,
    #00b3ec -91.84%,
    #cbec00 -91.82%,
    #6e9a10 25.42%,
    #d0a024 126.74%
  );
  top: calc(50% - 5px);
}
.list .link .title {
  padding-left: 20px;
  color: #000000;
  font-size: 19px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  line-height: 22px;
  text-decoration-line: underline;
}
@media screen and (max-width: 1200px) {
  .block {
    display: flex;
    flex-wrap: wrap;
    row-gap: 40px;
    justify-content: center;
  }
  .card {
    margin: 0px 5px;
  }
  .card .content .header {
    font-size: 1.25rem;
  }
}
