.list {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  width: 240px;
  height: 50px;
  vertical-align: middle;
  overflow: auto;
  margin: 0;
  padding-left: 0;
}
.list li {
  width: 100%;
  white-space: nowrap;
}
.active td {
  background-color: #abb9e8;
}
.active .settings .main {
  border: 1px solid white !important;
}
.active .settings .main:hover {
  background-color: #405189 !important;
}
.active .settings .icon {
  stroke: white !important;
}
