.sidebarWrapper {
  position: absolute;
  width: auto;
  height: 100vh;
  overflow: visible;
}

@media (max-width: 900px) {
  .sidebarMenu {
    background-color: var(--sidebar-bg-color);
    width: var(--sidebar-active-width);
    height: 100vh;
    overflow-y: auto;
    display: block;
    position: absolute;
    margin: 0 0 0 calc(var(--sidebar-active-width) * -1);
    transition: 150ms;
    z-index: 999;
  }

  .sidebarMenu .background {
    position: absolute;
    background-color: var(--sidebar-bg-color);
    height: 100%;
    width: var(--sidebar-active-width);
    z-index: -1;
    transition: 150ms;
  }

  .sidebarMenu.active {
    margin: 0 0 0 0;
    transition: 150ms;
  }

  .sidebarLogo {
    display: none;
  }

  .overlay {
    z-index: 998;
    position: absolute;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
  }
}

@media (min-width: 900px) {
  .sidebarMenu {
    position: absolute;
    background-color: transparent;
    width: auto;
    height: 100vh;
    overflow-y: auto;
    overflow-x: hidden;
    display: block;
    float: left;
    transition: 150ms;
  }

  .sidebarMenu .background {
    position: fixed;
    background-color: var(--sidebar-bg-color);
    height: 100%;
    width: var(--sidebar-width);
    z-index: -1;
    transition: 150ms;
  }
  .sidebarMenu .sidebarText:hover {
    position: relative;
    background-color: var(--sidebar-bg-color);
    width: calc(var(--sidebar-active-width) + var(--sidebar-width) - 30px);
    z-index: 1000;
  }
  .sidebarMenu .sidebarText:hover a span {
    color: var(--sidebar-text-color-active);
    display: block;
    margin-left: 40px;
  }
  .sidebarMenu .sidebarText:hover a svg {
    fill: var(--sidebar-text-color-active);
  }
  .sidebarMenu .hasSubNav:hover {
    width: calc(var(--sidebar-active-width) + var(--sidebar-width) - 30px);
    z-index: 1000;
  }
  .sidebarMenu .hasSubNav:hover div {
    background-color: var(--sidebar-bg-color);
    width: 100%;
  }
  .sidebarMenu .hasSubNav:hover .title svg {
    fill: var(--sidebar-text-color-active);
  }
  .sidebarMenu .hasSubNav:hover .title span {
    margin-left: 40px;
    color: var(--sidebar-text-color-active);
    display: block;
  }
  .sidebarMenu .hasSubNav:hover svg:nth-child(2) {
    fill: var(--sidebar-text-color-active);
    display: block;
  }
  .sidebarMenu .hasSubNav:hover .subNav {
    background-color: var(--sidebar-bg-color);
    position: absolute;
    display: block;
    top: 40px;
    left: 45px;
    padding-left: 18px;
    width: var(--sidebar-active-width);
  }
  .sidebarMenu .hasSubNav:hover .subNav span {
    margin-left: 0px;
    display: block;
  }
  .sidebarMenu.active {
    width: var(--sidebar-active-width);
    transition: 150ms;
  }
  .sidebarMenu.active .background {
    width: var(--sidebar-active-width);
    transition: 150ms;
  }
  .sidebarMenu.active .sidebarText span {
    display: block;
  }
  .sidebarMenu.active .sidebarText svg:nth-child(2) {
    display: block;
  }
  .sidebarMenu.active .hasSubNav:hover {
    width: auto;
  }
  .sidebarMenu.active .hasSubNav:hover .subNav {
    background-color: transparent;
    position: relative;
    display: none;
    top: 0px;
    left: 0px;
    width: auto;
    padding-left: 0px;
  }
  .sidebarMenu.active .sidebarText:hover {
    background-color: transparent;
  }
  .sidebarMenu.active .sidebarText:hover a span {
    margin-left: 0px;
  }
  .sidebarMenu.active .hasSubNav:hover div {
    background-color: transparent;
  }
  .sidebarMenu.active .hasSubNav:hover div span {
    margin-left: 0px;
  }
  .sidebarText span {
    display: none;
  }
  .sidebarText svg:nth-child(2) {
    display: none;
  }
  .sidebarLogo {
    margin-top: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: 150ms;
    text-decoration: none;
  }
  .sidebarMenu .sidebarLogo {
    width: var(--sidebar-width);
  }
  .sidebarMenu.active .sidebarLogo {
    width: var(--sidebar-active-width);
  }
  .sidebarMenu .sidebarLogo .iconSmall {
    display: block;
  }
  .sidebarMenu.active .sidebarLogo .iconSmall {
    display: none;
  }
  .sidebarMenu .sidebarLogo .iconBig {
    display: none;
  }
  .sidebarMenu.active .sidebarLogo .iconBig {
    display: flex;
    flex-wrap: wrap;
    color: #fff;
    text-wrap: wrap;
    text-transform: uppercase;
    font-weight: 800;
    line-height: 22px;
    text-align: center;
  }
}
.sidebarText.active {
  color: var(--sidebar-text-color-active);
}
.sidebarText.active a {
  color: var(--sidebar-text-color-active);
}
.sidebarText.active svg {
  fill: var(--sidebar-text-color-active);
}
.sidebarSeparator {
  color: var(--sidebar-text-color);
  padding: 0.625rem 1.5rem;
  letter-spacing: 0.05em;
  cursor: default;
  font-size: 11px;
  text-transform: uppercase;
  font-weight: 600;
}
.sidebarSeparator .icon {
  color: var(--sidebar-text-color) !important;
}
.sidebarMenu .sidebarSeparator {
  display: flex;
  justify-content: center;
  width: var(--sidebar-width);
}
.sidebarMenu .sidebarSeparator .title {
  display: none;
}
.sidebarMenu .sidebarSeparator .icon {
  display: block;
  width: 20px;
  height: 20px;
}
.sidebarMenu.active .sidebarSeparator {
  display: block;
  width: var(--sidebar-active-width);
}
.sidebarMenu.active .sidebarSeparator .title {
  display: block;
}
.sidebarMenu.active .sidebarSeparator .icon {
  display: none;
}
.sidebarText {
  text-decoration: none;
  color: var(--sidebar-text-color);
  font-size: 0.9375rem;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  padding: 0.625rem 1.5rem;
  border-radius: 4px;
  cursor: pointer;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.sidebarText a {
  text-decoration: none;
  color: var(--sidebar-text-color);
  font-size: 0.9375rem;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  border-radius: 4px;
  cursor: pointer;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  min-height: 24px;
}
.sidebarText div {
  display: flex;
  align-items: center;
  margin-right: auto;
  min-height: 24px;
}
.sidebarText svg {
  fill: var(--sidebar-text-color);
  font-size: 18px;
  line-height: inherit;
  min-width: 1.75rem;
  text-align: start;
}
.sidebarMenuItems {
  width: 100%;
  padding: 0;
}
.hasSubNav {
  position: relative;
}
.hasSubNav .subNav {
  display: none;
}
.sidebarMenu.active .hasSubNav.active .subNav {
  display: block;
}
.sidebarMenu.active .hasSubNav.active svg:nth-child(2) {
  transform: rotate(180deg);
}
.sidebarMenu.active .hasSubNav.active .title span {
  color: var(--sidebar-text-color-active);
}
.sidebarMenu.active .hasSubNav.active .title svg {
  fill: var(--sidebar-text-color-active);
}
.sidebarMenu.active .hasSubNav.active svg:nth-child(2) {
  fill: var(--sidebar-text-color-active);
}
