.block {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}
.header {
  background: url("~@content/img/shared/home/png/background-space.png"),
    lightgray 0px -141.937px / 137.633% 239.84% no-repeat;
  background-position: center;
  background-size: cover;
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.header .first {
  font-size: 10vw;
  font-style: normal;
  font-weight: 900;
  line-height: 10vw;
  text-transform: uppercase;
  margin: 0;
}
.header .second {
  font-size: 9.4vw;
  font-style: normal;
  font-weight: 900;
  line-height: 9.4vw;
  text-transform: uppercase;
}
.bar {
  display: flex;
  width: 100%;
  text-align: center;
  background: url("~@content/img/shared/home/png/background-space.png"),
    lightgray 0px -141.937px / 137.633% 239.84% no-repeat;
  background-position: center;
  background-size: cover;
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
