.block {
  position: relative;
  margin-top: 60px;
}
.header {
  background: linear-gradient(
    180deg,
    #00b3ec -91.84%,
    #cbec00 -91.82%,
    #6e9a10 25.42%,
    #d0a024 126.74%
  );
  background-size: cover;
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-size: 36px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  text-transform: uppercase;
}
.background {
  position: absolute;
  top: -10px;
  right: 160px;
}
.all-news {
  cursor: pointer;
  position: relative;
  margin-top: 48px;
  margin-bottom: 17px;
  margin-left: -5px;
  padding-right: 8px;
  width: 100%;
  text-align: end;
  color: #555;
  font-size: 11.5px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.all-news::before {
  content: "";
  position: absolute;
  background: linear-gradient(
    191.12deg,
    #00b3ec -91.84%,
    #cbec00 -91.82%,
    #6e9a10 25.42%,
    #d0a024 126.74%
  );
  width: 6px;
  height: 12px;
  top: 1px;
  left: calc(100% - 4px);
  clip-path: polygon(0 12.5%, 100% 50%, 0 87.5%);
}
.news {
  --width-card: 264px;
  --height-card: 318px;
}
.news .slide {
  width: var(--width-card);
}
.news .card {
  cursor: default;
  position: relative;
  background-color: #fff;
  border-radius: 19.5px;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  width: var(--width-card);
  height: var(--height-card);
}
.news .card::before {
  content: "";
  background-image: linear-gradient(
    191.12deg,
    #00b3ec -91.84%,
    #cbec00 -91.82%,
    #6e9a10 25.42%,
    #d0a024 126.74%
  );
  top: -1px;
  left: -1px;
  bottom: -1px;
  right: -1px;
  position: absolute;
  z-index: -1;
  border-radius: 20px;
}
.news .card .image {
  height: calc(var(--height-card) / 2);
  max-width: 100%;
  border-radius: 20px;
}
.news .card .image img {
  width: 100%;
  height: calc(var(--height-card) / 2);
  border-radius: 20px 20px 0px 0px;
  object-fit: cover;
}
.news .card .info {
  padding: 15px;
  display: flex;
  flex-wrap: wrap;
  align-content: space-between;
  height: calc(var(--height-card) / 2);
}
.news .card .info .title {
  color: #333;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  width: 100%;
}
.news .card .info .description {
  color: #555;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  max-width: var(--width-card);
  max-height: calc(var(--height-card) / 2 - 80px);
  margin-top: 10px;
  -webkit-line-clamp: 5;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
}
.news .card .info .addition {
  display: flex;
  justify-content: space-between;
  color: #333;
  font-size: 11px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  width: 100%;
}
.news .card .info .addition .more {
  cursor: pointer;
  position: relative;
  padding-right: 8px;
  background-image: linear-gradient(
    191.12deg,
    #00b3ec -91.84%,
    #cbec00 -91.82%,
    #6e9a10 25.42%,
    #d0a024 126.74%
  );
  color: transparent;
  -webkit-background-clip: text;
  background-clip: text;
}
.news .card .info .addition .more::before {
  content: "";
  position: absolute;
  background: linear-gradient(
    191.12deg,
    #00b3ec -91.84%,
    #cbec00 -91.82%,
    #6e9a10 25.42%,
    #d0a024 126.74%
  );
  width: 6px;
  height: 12px;
  top: 1px;
  left: calc(100% - 4px);
  clip-path: polygon(0 12.5%, 100% 50%, 0 87.5%);
}
.news .swiper {
  max-width: 1100px;
  padding: 1px 3px;
  height: calc(var(--height-card) + 33px) !important;
  --background-swiper-scrollbar-horizontal: #f8f8f8;
  --background-swiper-scrollbar-drag: linear-gradient(
    191.12deg,
    #00b3ec -91.84%,
    #cbec00 -91.82%,
    #6e9a10 25.42%,
    #d0a024 126.74%
  );
}

@media screen and (max-width: 1200px) {
  .header{
    margin-left: 5px;
  }
  .news .swiper {
    max-width: calc(100vw - 10px);
  }
}
