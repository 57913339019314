.messages {
  overflow: auto;
}
.message .author {
  font-size: 0.9rem;
}
.message .author .date {
  margin-left: 5px;
}
.btnSend {
  border-radius: 0.25rem;
  border: 0 !important;
  background-color: #0ab39c;
  font-size: 14px;
}
.btnSend:hover {
  color: #fff;
  background-color: #099885;
  border-color: #088f7d;
}
.attachment {
  position: relative;
  cursor: pointer;
}
.attachment .badge {
  position: absolute;
  font-size: 10px;
  top: -5px;
  right: -5px;
  background-color: #0ab39c;
  color: #fff;
  border-radius: 50%;
  padding: 1px 5px;
}
.attachment svg {
  width: 25px;
  height: 25px;
}
