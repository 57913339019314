@media (min-width: 900px) {
  .box {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
}
.box {
  padding: 10px 1.5rem;
  background-color: var(--navbar-bg-color);
  box-shadow: 0 1px 2px rgba(56, 65, 74, 0.15);
  border-bottom: 1px solid none;
  border-top: 1px solid none;
}
.box h4 {
  font-weight: 700;
  font-size: 15px !important;
  text-transform: uppercase;
  color: var(--navbar-text-color);
}
.box .item a{
  color: var(--navbar-text-color);
  outline: none !important;
  text-decoration: none;
}
.box .item.active {
  color: #878a99;
}
.box .item + .item::before {
  float: left;
  padding: 0 0.5rem;
  color: #878a99;
  content: ">";
  font-size: 15px;
  line-height: 24px;
}
