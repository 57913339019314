.layout {
  --navbar-height: 60px;
  --navbar-bg-color: #fff;
  --navbar-text-color: #495057;
  --sidebar-width: 75px;
  --sidebar-active-width: 250px;
  --sidebar-bg-color: #405189;
  --sidebar-text-color: #abb9e8;
  --sidebar-text-color-active: #fff;
  --content-bg: #f3f3f9;
  --border-color: #e9ebec;
  --heading-color: #495057;
  --text-primary-rgb: 64, 81, 137;
  --text-opacity: 1;
}

@media (max-width: 900px) {
  .content {
    background-color: var(--content-bg);
    overflow-y: auto !important;
    min-height: calc(var(--vh, 1vh) * 100);
    max-height: calc(var(--vh, 1vh) * 100);
  }
}

@media (min-width: 900px) {
  .content {
    background-color: var(--content-bg);
    overflow-y: auto !important;
    min-height: calc(var(--vh, 1vh) * 100);
    margin-left: var(--sidebar-width);
    transition: 150ms;
  }

  .content.sidebarActive {
    margin-left: var(--sidebar-active-width);
    transition: 150ms;
  }
}

.badge {
  font-weight: 600;
  line-height: 1.4;
  padding: 3px 6px;
  font-size: 12px;
  font-weight: 600;
  transition: all 0.3s ease-out;
  -webkit-transition: all 0.3s ease-out;
}

.badge:hover {
  transition: all 0.3s ease-out;
  -webkit-transition: all 0.3s ease-out;
  -webkit-transform: translateY(-3px);
  transform: translateY(-3px);
}