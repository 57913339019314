.loader_block__2frd2 {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}
.loader_header__24dXM {
  background: url(/static/media/background-space.8699e94d.png),
    lightgray 0px -141.937px / 137.633% 239.84% no-repeat;
  background-position: center;
  background-size: cover;
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.loader_header__24dXM .loader_first__38aXk {
  font-size: 10vw;
  font-style: normal;
  font-weight: 900;
  line-height: 10vw;
  text-transform: uppercase;
  margin: 0;
}
.loader_header__24dXM .loader_second__2SuwV {
  font-size: 9.4vw;
  font-style: normal;
  font-weight: 900;
  line-height: 9.4vw;
  text-transform: uppercase;
}
.loader_bar__3Zu_D {
  display: flex;
  width: 100%;
  text-align: center;
  background: url(/static/media/background-space.8699e94d.png),
    lightgray 0px -141.937px / 137.633% 239.84% no-repeat;
  background-position: center;
  background-size: cover;
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

/* .swiper{
  overflow: unset !important;
} */
.swiper-scrollbar-horizontal {
  background: var(--background-swiper-scrollbar-horizontal);
}
.swiper-scrollbar-horizontal .swiper-scrollbar-drag {
  background: var(--background-swiper-scrollbar-drag);
}
.swiper-button-prev {
  left: 0 !important;
  top: 40px;
}
.swiper-button-prev::after {
  font-size: 24px;
  color: #00ecb3;
}
.swiper-button-next {
  right: 0 !important;
  top: 40px;
}
.swiper-button-next::after {
  font-size: 24px;
  color: #00ecb3;
}
.swiper-button-prev::after {
  font-size: 24px;
  color: #00ecb3;
}
.calendar-select__control {
  border-radius: 7px !important;
  background-color: rgba(239, 239, 239, 1) !important;
  border: none !important;
}
.calendar-select__control
  .calendar-select__value-container
  .calendar-select__single-value {
  font-size: 16px;
  font-weight: 600;
  line-height: 19px;
  letter-spacing: 0px;
  text-align: left;
  color: rgba(0, 0, 0, 1);
}
.calendar-select__control .calendar-select__indicator-separator {
  display: none;
}
.calendar-select__control .calendar-select__indicator svg {
  fill: rgba(0, 0, 0, 1);
}
.calendar-select__menu {
  background-color: rgba(239, 239, 239, 1) !important;
}
.calendar-select__menu .calendar-select__option--is-selected {
  background-color: rgba(49, 49, 49, 1) !important;
}
.calendar-select__menu .calendar-select__option--is-focused {
  background-color: rgba(200, 200, 200, 1) !important;
}

@keyframes animations_fadeIn__2x7-P {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes animations_fadeOut__35GwU {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

@keyframes animations_bounceIn__321-D {
  0% {
    opacity: 0;
    transform: scale(0.5);
  }
  100% {
    opacity: 1;
    transform: scale(1);
  }
}
@keyframes animations_bounceOut__206o9 {
  0% {
    opacity: 1;
    transform: scale(1);
  }
  100% {
    opacity: 0;
    transform: scale(0.5);
  }
}

@keyframes animations_swipeLeftIn__2kXAJ {
  0% {
    margin-left: -100%;
    margin-right: 100%;
    opacity: 0;
  }
  100% {
    margin-left: 0%;
    margin-right: 0%;
    opacity: 1;
  }
}
@keyframes animations_swipeLeftOut__ppjJG {
  0% {
    margin-left: 0%;
    margin-right: 0%;
    opacity: 1;
  }
  100% {
    margin-left: -100%;
    margin-right: 100%;
    opacity: 0;
  }
}

@keyframes animations_swipeRightIn__TwedC {
  0% {
    margin-left: 100%;
    margin-right: -100%;
    opacity: 0;
  }
  100% {
    margin-left: 0%;
    margin-right: 0%;
    opacity: 1;
  }
}
@keyframes animations_swipeRightOut__3m-dX {
  0% {
    margin-left: 0%;
    margin-right: 0%;
    opacity: 1;
  }
  100% {
    margin-left: 100%;
    margin-right: -100%;
    opacity: 0;
  }
}

@keyframes animations_swipeTopIn__fzKIT {
  0% {
    margin-top: -100%;
    opacity: 0;
  }
  100% {
    margin-top: 0%;
    opacity: 1;
  }
}
@keyframes animations_swipeTopOut__3xCPL {
  0% {
    margin-top: 0%;
    opacity: 1;
  }
  100% {
    margin-top: -100%;
    opacity: 0;
  }
}

.search_container__3d4Xe {
  position: absolute;
  top: 100%;
  background-color: #ffffff;
  border: 1px solid #dee2e6;
  padding: 5px;
}

.navbar_logo__2jCKw {
  cursor: pointer;
}
.navbar_search-container__1i8Kf {
  display: flex;
  align-items: center;
  background-color: #ffffff;
  border: 1px solid #e1e1e1;
  border-radius: 30px;
}
.navbar_search-container__1i8Kf .navbar_icon__UrvrG {
  height: 22px;
  width: 22px;
  margin-left: 22.75px;
}
.navbar_search-container__1i8Kf .navbar_input__3YL7W {
  background-color: #ffffff;
  border-radius: 30px;
  border: none;
  color: #646464;
  padding: 9px 10px 7px 12.75px;
  font-size: 15px;
  font-weight: 500;
  line-height: 18px;
  vertical-align: middle;
}

.navbar_search-container__1i8Kf .navbar_input__3YL7W::placeholder {
  color: #e1e1e1;
  line-height: 18px;
  vertical-align: middle;
}

.navbar_search-container__1i8Kf .navbar_input__3YL7W:focus-visible {
  outline: none;
}

.navbar_login-container__37ERv {
  display: flex;
  align-items: center;
  height: 100%;
  text-decoration: none;
}

.navbar_login-container__37ERv .navbar_icon__UrvrG {
  height: 30px;
  width: 30px;
  margin-right: 8px;
}

.navbar_login-container__37ERv .navbar_label__2iRlO {
  font-size: 15px;
  line-height: 18px;
  font-weight: 400;
  color: #444444;
}
@media screen and (max-width: 1200px) {
  .navbar_search-container__1i8Kf {
    display: none;
  }
}

.footer_block__2ML-1 {
  margin-top: 100px;
}
.footer_block__2ML-1 .footer_social__2TyNA .footer_link__3_j5B {
  color: #000;
  text-decoration: none;
  margin-right: 5px;
  font-size: 16px;
}
.footer_social__2TyNA + .footer_social__2TyNA {
  margin-top: 5px;
}
@media screen and (max-width: 1200px) {
  .footer_copyright__2QWCI {
    margin-left: 5px;
  }
  .footer_contacts__3UnN- {
    margin-right: 5px;
  }
}

.chooser_container__1DSOx {
  position: absolute;
  top: 50px;
  right: 0;
  border-radius: 10px;
}

.chooser_background-container__ducd7 {
  position: absolute;
  right: 0px;
  top: -92px;
  z-index: -1;
}

.chooser_button__F5Ijy {
  width: 55px;
  cursor: pointer;
  padding: 15px 15px 15px 15px;
  color: #ffffff;
  writing-mode: vertical-rl;
  text-align: center;
  justify-items: center;
  font-size: 15px;
  font-weight: 400;
}

.chooser_button__F5Ijy.chooser_top__Tpbar {
  margin-top: -40px;
  padding-top: 40px;
}

.chooser_button__F5Ijy.chooser_bottom__LD_wM {
  margin-bottom: -40px;
  padding-bottom: 40px;
}

.chooser_button__F5Ijy:hover {
  background-color: rgba(255, 255, 255, 0.4);
  color: #014170;
}

.chooser_line__2UQig {
  margin-left: 12.5px;
  width: 30px;
  height: 1px;
  background-color: #08fa99;
}

.buttonArrow_button__1OVWi {
  position: relative;
  cursor: pointer;
}
.buttonArrow_button__1OVWi.buttonArrow_rightTop__1_EE4 .buttonArrow_background__8njT5 {
  transform: scale(1, -1);
}
.buttonArrow_button__1OVWi.buttonArrow_leftTop__3YdOn .buttonArrow_background__8njT5 {
  transform: scale(-1, -1);
}
.buttonArrow_button__1OVWi.buttonArrow_rightBottom__3K3Xq .buttonArrow_background__8njT5 {
  transform: none;
}
.buttonArrow_button__1OVWi.buttonArrow_leftBottom__3U0dW .buttonArrow_background__8njT5 {
  transform: scale(-1, 1);
}
.buttonArrow_button__1OVWi:hover .buttonArrow_background__8njT5 .buttonArrow_fill__3vMuU {
  background-image: linear-gradient(
    191.12deg,
    #00b3ec -91.84%,
    #014170 25.42%,
    #8424d0 126.74%
  );
}
.buttonArrow_button__1OVWi:hover .buttonArrow_content__2opWl {
  color: #ffffff;
}
.buttonArrow_background__8njT5 {
  position: absolute;
  z-index: -1;
  width: 100%;
  height: 100%;
}
.buttonArrow_background__8njT5 .buttonArrow_fill__3vMuU {
  position: absolute;
  width: calc(100% - 2px);
  height: calc(100% - 2px);
  background: #ffffff;
  clip-path: polygon(0 0, 100% 0, 100% calc(70.3%), calc(92.5%) 100%, 0 100%);
  border: none;
  border-radius: 19.5px 19.5px 0px 19.5px;
}
.buttonArrow_background__8njT5 .buttonArrow_border__3xwb- {
  position: absolute;
  clip-path: polygon(0 0, 100% 0, 100% calc(71%), calc(92.3%) 100%, 0 100%);
  background-image: linear-gradient(
    191.12deg,
    #00b3ec -91.84%,
    #014170 25.42%,
    #8424d0 126.74%
  );
  top: -1px;
  left: -1px;
  bottom: -1px;
  right: -1px;
  z-index: -1;
  border-radius: 20px 20px 0px 20px;
  width: 100%;
  height: 100%;
}
.buttonArrow_background__8njT5 .buttonArrow_arrow__yUEMn {
  position: absolute;
  bottom: 1px;
  right: 1px;
  width: 11.5px;
  height: 11.5px;
  clip-path: polygon(100% 0, 0 100%, 100% 100%);
  background-image: linear-gradient(
    201.12deg,
    #00b3ec -91.84%,
    #014170 25.42%,
    #8424d0 186.74%
  );
}
.buttonArrow_content__2opWl {
  z-index: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
}

.button_button__3Zgm8 {
  cursor: pointer;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #fff;
  padding: 11px 30px;
  border-radius: 19.5px;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  cursor: pointer;
  --active-bg: linear-gradient(
    191deg,
    #00b3ec -91.84%,
    #014170 25.42%,
    #8424d0 126.74%
  );
  --active-color: #ffffff;
}
.button_button__3Zgm8::before {
  content: "";
  background-image: linear-gradient(170deg, #0072ff 15.81%, #29cd8f 100.43%);
  top: -1px;
  left: -1px;
  bottom: -1px;
  right: -1px;
  position: absolute;
  z-index: -1;
  border-radius: 20px;
}
.button_button__3Zgm8:hover,
.button_button__3Zgm8.button_active__3QcSV,
.button_button__3Zgm8.button_selected__lTxbW {
  background: var(--active-bg);
  color: var(--active-color);
}
.button_button__3Zgm8.button_disable__2ppBq {
  pointer-events: none;
}

.card_card__5paiX {
  position: relative;
  width: 100%;
  height: 100%;
}
.card_card__5paiX.card_rightTop__FJVw2 .card_background__1G5cV {
  transform: scale(1, -1);
}
.card_card__5paiX.card_leftTop__1iTfx .card_background__1G5cV {
  transform: scale(-1, -1);
}
.card_card__5paiX.card_rightBottom__oxY3B .card_background__1G5cV {
  transform: none;
}
.card_card__5paiX.card_leftBottom__2yPGc .card_background__1G5cV {
  transform: scale(-1, 1);
}
.card_background__1G5cV {
  position: absolute;
  z-index: -1;
  width: 100%;
  height: 100%;
}
.card_background__1G5cV .card_fill__npP9j {
  position: absolute;
  width: calc(100% - 2px);
  height: calc(100% - 2px);
  background: #ffffff;
  clip-path: polygon(
    0 0,
    100% 0,
    100% calc(100% - var(--arrow-height) - 1px),
    calc(100% - var(--arrow-width) - 1px) 100%,
    0 100%
  );
  border: none;
  border-radius: 19.5px 19.5px 0px 19.5px;
}
.card_background__1G5cV .card_border__1n-Oc {
  position: absolute;
  clip-path: polygon(
    0 0,
    100% 0,
    100% calc(100% - var(--arrow-height) - 1.5px),
    calc(100% - var(--arrow-width) - 1.5px) 100%,
    0 100%
  );
  background-image: linear-gradient(
    191.12deg,
    #00b3ec -91.84%,
    #014170 25.42%,
    #8424d0 126.74%
  );
  top: -1px;
  left: -1px;
  bottom: -1px;
  right: -1px;
  z-index: -1;
  border-radius: 20px 20px 0px 20px;
  width: 100%;
  height: 100%;
}
.card_background__1G5cV .card_arrow__1DiTT {
  position: absolute;
  bottom: 1px;
  right: 1px;
  width: var(--arrow-width);
  height: var(--arrow-height);
  clip-path: polygon(100% 0, 0 100%, 100% 100%);
  background-image: linear-gradient(
    201.12deg,
    #00b3ec -91.84%,
    #014170 25.42%,
    #8424d0 186.74%
  );
}
.card_content__3dZJR {
  z-index: 1;
  height: 100%;
  width: 100%;
}

.helper_helper__2h3lq {
  position: relative;
}
.helper_helper__2h3lq .helper_background__799qf {
  z-index: -1;
  position: absolute;
  width: auto;
  height: auto;
  top: 10px;
  left: 100px;
}
.helper_helper__2h3lq .helper_content__3PKAh {
  margin-top: 45px;
  margin-left: 90px;
}
.helper_helper__2h3lq .helper_content__3PKAh .helper_card__tvyTC {
  width: 460px;
  height: 500px;
  padding-top: 5px;
}
.helper_helper__2h3lq .helper_content__3PKAh .helper_card__tvyTC .helper_inner__36zn4 {
  overflow-y: auto;
  margin-top: 20px;
  padding: 20px 30px 30px 30px;
  max-height: calc(500px - 40px);
}
.helper_helper__2h3lq .helper_content__3PKAh .helper_card__tvyTC .helper_inner__36zn4 .helper_hello__1hPcx {
  display: flex;
  justify-content: space-between;
}
.helper_helper__2h3lq .helper_content__3PKAh .helper_card__tvyTC .helper_inner__36zn4 .helper_hello__1hPcx .helper_message__2Yo0j {
  position: relative;
  border-radius: 0px 20px 0px 20px;
  padding: 14px 12px;
  color: #555;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  background: #fff;
  position: relative;
  filter: drop-shadow(1px 1px 8px rgba(0, 0, 0, 0.15));
}
.helper_helper__2h3lq .helper_content__3PKAh .helper_hello__1hPcx .helper_message__2Yo0j::before {
  content: "";
  position: absolute;
  top: 40%;
  left: calc(100% - 2px);
  clip-path: polygon(100% 0, 0 100%, 0 25%);
  width: 30px;
  height: 30px;
  background: #fff;
}
.helper_helper__2h3lq .helper_content__3PKAh .helper_hello__1hPcx .helper_message__2Yo0j p {
  margin-bottom: 6px;
}
.helper_helper__2h3lq .helper_content__3PKAh .helper_hello__1hPcx .helper_icon__1m-u6 {
  height: 140px;
  width: auto;
  flex-shrink: 0;
  margin-left: 30px;
}
.helper_helper__2h3lq .helper_content__3PKAh .helper_card__tvyTC .helper_inner__36zn4 .helper_choose__3t-rF {
  color: #555;
}
.helper_helper__2h3lq .helper_content__3PKAh .helper_card__tvyTC .helper_inner__36zn4 .helper_choose__3t-rF .helper_title__2JA2M {
  margin-top: 20px;
  margin-bottom: 16px;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}
.helper_helper__2h3lq .helper_content__3PKAh .helper_card__tvyTC .helper_inner__36zn4 .helper_choose__3t-rF .helper_elements__13WpF {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  align-content: flex-start;
  grid-gap: 15px;
  gap: 15px;
  flex-wrap: wrap;
}
.helper_helper__2h3lq .helper_content__3PKAh .helper_choose__3t-rF .helper_elements__13WpF .helper_reload__30646 {
  padding: 10px;
}

@media screen and (max-width: 1200px) {
  .helper_helper__2h3lq .helper_background__799qf {
    display: none;
  }
  .helper_helper__2h3lq .helper_content__3PKAh{
    margin-left: 5px;
  }
  .helper_helper__2h3lq .helper_content__3PKAh .helper_card__tvyTC{
    width: calc(100% - 5px);
  }
}
.about_block__3-59j {
  position: relative;
  margin-top: 10px;
}

.about_content__1FikR{
  display: flex;
}

.about_background__Cnznt {
  position: absolute;
  z-index: -1;
  top: -10px;
  left: -360px;
}

.about_info__37-5T {
  margin-top: 95px;
  width: 575px;
}

.about_info__37-5T .about_header__3MJUc {
  background: url(/static/media/background-space.8699e94d.png),
    lightgray 0px -141.937px / 137.633% 239.84% no-repeat;
  background-position: center;
  background-size: cover;
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.about_info__37-5T .about_header__3MJUc .about_first__3ZZsF {
  font-size: 101px;
  font-style: normal;
  font-weight: 900;
  line-height: 100px;
  text-transform: uppercase;
  margin: 0;
}
.about_info__37-5T .about_header__3MJUc .about_second__1-fDi {
  font-size: 95px;
  font-style: normal;
  font-weight: 900;
  line-height: 100px;
  text-transform: uppercase;
}

.about_info__37-5T .about_subheader__3SIMj {
  margin-top: 17px;
  font-size: 23px;
  font-weight: 500;
  line-height: 28px;
  color: #333333;
}

.about_info__37-5T .about_subheader__3SIMj span {
  position: relative;
  cursor: default;
}

.about_info__37-5T .about_subheader__3SIMj span:hover div {
  visibility: visible;
}

.about_info__37-5T .about_description__ea_su {
  margin-top: 17px;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  color: #333333;
}

.about_info__37-5T .about_more__3HF2G {
  margin-top: 17px;
  cursor: pointer;
  width: 177px;
  height: 47px;
  font-weight: 400;
  font-size: 15px;
  line-height: 18px;
  color: #555555;
}
.about_hint__3oHeY {
  visibility: hidden;
  position: absolute;
  z-index: 999;
  top: 0;
  left: 85%;
  min-width: 280px;
  max-width: 280px;
}
.about_hint__3oHeY .about_description__ea_su {
  background: url(/static/media/about-background-hint.bfcbb6bd.svg);
  background-size: 100% auto;
  background-repeat: no-repeat;
  color: #555;
  text-align: center;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  padding: 22px 16px 20px 16px;
  word-wrap: break-word;
}

@media screen and (max-width: 1200px) {
  .about_content__1FikR{
    display: block;
  }
  .about_info__37-5T {
    width: 100%;
    margin-left: 5px;
    margin-top: 20px;
  }
  .about_info__37-5T .about_header__3MJUc .about_first__3ZZsF {
    font-size: 12vw;
    line-height: 14vw;
  }
  .about_info__37-5T .about_header__3MJUc .about_second__1-fDi {
    font-size: 11.3vw;
    line-height: 12vw;
  }
  .about_info__37-5T .about_subheader__3SIMj {
    font-size: 1rem;
    width: calc(100vw - 75px);
  }
  .about_info__37-5T .about_description__ea_su {
    width: calc(100vw - 75px);
  }
  .about_hint__3oHeY {
    display: none;
  }
  .about_helper__22Z64 {
    display: none;
  }
  .about_background__Cnznt {
    display: none;
  }
}

.actions_block__2g0lT {
  position: relative;
  margin-top: 160px;
}

.actions_cards__368ro {
  position: relative;
  display: flex;
  justify-content: space-between;
  min-height: 310px;
}

.actions_info__2hTg4,
.actions_card__Rd5C9 {
  position: relative;
  color: #555;
  width: 240px;
  min-height: 120px;
}

.actions_info__2hTg4 {
  margin-bottom: 10px;
}

.actions_info__2hTg4 .actions_header__2J8Vz {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin-bottom: 20px;
  cursor: pointer;
}

.actions_info__2hTg4 .actions_header__2J8Vz .actions_picture__2eVpB {
  position: relative;
  height: 110px;
  width: 100px;
}

.actions_info__2hTg4 .actions_header__2J8Vz .actions_picture__2eVpB .actions_background__1hr9W {
  position: absolute;
  top: 28px;
  z-index: 1;
}

.actions_info__2hTg4 .actions_header__2J8Vz .actions_picture__2eVpB .actions_icon__r3r6I {
  position: absolute;
  z-index: 2;
  top: 10px;
  left: -6px;
}

.actions_info__2hTg4 .actions_header__2J8Vz .actions_title__1Fq8G {
  cursor: default;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #fff;
  padding: 20px 73px 9px 73px;
  border-radius: 19.5px;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  width: 100%;
  cursor: pointer;
}
.actions_info__2hTg4 .actions_header__2J8Vz .actions_title__1Fq8G::before {
  content: "";
  background-image: linear-gradient(170deg, #0072ff 15.81%, #29cd8f 100.43%);
  top: -1px;
  left: -1px;
  bottom: -1px;
  right: -1px;
  position: absolute;
  z-index: -1;
  border-radius: 20px;
}
.actions_cards__368ro .actions_card__Rd5C9 .actions_actions__1jTTg {
  position: relative;
  padding: 10px 18px 0px 18px;
  max-height: 120px;
  z-index: 1;
  overflow: hidden;
}

.actions_cards__368ro .actions_card__Rd5C9 .actions_actions__1jTTg .actions_action__zahsU {
  display: block;
  cursor: pointer;
  width: 204px;
  border-bottom: 1px solid #cecece;
  padding-bottom: 8px;
  font-size: 12px;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
  text-decoration: none;
  color: #555;
}

.actions_cards__368ro .actions_card__Rd5C9 .actions_actions__1jTTg .actions_action__zahsU:not(:last-child) {
  margin-bottom: 8px;
}

.actions_cards__368ro .actions_lineContainer__x_Ouk {
  display: flex;
  align-items: flex-end;
}

.actions_cards__368ro .actions_lineContainer__x_Ouk .actions_line__3sxrv {
  height: 120px;
  border-left: 1px solid #cecece;
}

.actions_cards__368ro .actions_card__Rd5C9 .actions_actions-hidden__14NQe {
  position: absolute;
  height: 10px;
  width: 120%;
  box-shadow: #fff 0px 5px 20px 20px;
  z-index: 2;
  left: -20px;
  bottom: -20px;
}

.actions_cards__368ro .actions_card__Rd5C9 .actions_more__3-UpR {
  position: absolute;
  padding: 2px 18px 0px 18px;
  display: flex;
  justify-content: center;
  z-index: 3;
  width: 240px;
  bottom: -30px;
  left: calc(50% - 120px);
  visibility: visible;
}

.actions_cards__368ro .actions_card__Rd5C9 .actions_more__3-UpR .actions_button__2x7QC {
  cursor: pointer;
  position: relative;
  color: #000;
  font-size: 12px;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
  border-radius: 20px;
  background: #fff;
  box-shadow: 1px 1px 8px 0px rgba(0, 0, 0, 0.15);
  padding: 6px 20px;
  z-index: 3;
}

.actions_cards__368ro .actions_card__Rd5C9 .actions_more__3-UpR .actions_button__2x7QC::before {
  content: "";
  position: absolute;
  background: linear-gradient(
    191.12deg,
    #00b3ec -91.84%,
    #014170 25.42%,
    #8424d0 126.74%
  );
  height: 8px;
  width: 16px;
  top: calc(100% - 0.2px);
  left: calc(50% - 8px);
  clip-path: polygon(12.5% 0, 87.5% 0, 50% 100%);
  z-index: 3;
}
.actions_cards__368ro .actions_card__Rd5C9 .actions_more__3-UpR .actions_button__2x7QC:hover {
  color: #fff;
  background: linear-gradient(180deg, #8424d0 -90%, #014170 90%, #00b3ec 200%);
}
.actions_cards__368ro .actions_card__Rd5C9 .actions_more__3-UpR .actions_button__2x7QC:hover::before {
  background: linear-gradient(180deg, #00b3ec -90%, #014170 10%, #8424d0 125%);
}
.actions_cards__368ro .actions_card__Rd5C9 .actions_more__3-UpR {
  position: absolute;
  padding: 2px 18px 0px 18px;
  display: flex;
  justify-content: center;
  z-index: 3;
  max-width: 240px;
  bottom: -30px;
  left: calc(50% - 120px);
}

.actions_cards__368ro .actions_card__Rd5C9 .actions_actions__1jTTg .actions_hide__2JOS8 {
  padding: 2px 30px 0px 30px;
  z-index: 3;
  max-width: 240px;
  visibility: hidden;
}

.actions_cards__368ro .actions_card__Rd5C9 .actions_actions__1jTTg .actions_hide__2JOS8 .actions_button__2x7QC {
  cursor: pointer;
  position: relative;
  color: #000;
  font-size: 12px;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
  border-radius: 20px;
  background: #fff;
  box-shadow: 1px 1px 8px 0px rgba(0, 0, 0, 0.15);
  padding: 6px 20px;
  z-index: 3;
  text-align: center;
}

.actions_cards__368ro .actions_card__Rd5C9 .actions_actions__1jTTg .actions_hide__2JOS8 .actions_button__2x7QC::before {
  content: "";
  position: absolute;
  background: linear-gradient(
    191.12deg,
    #00b3ec -91.84%,
    #014170 25.42%,
    #8424d0 126.74%
  );
  height: 8px;
  width: 16px;
  top: -8px;
  left: calc(50% - 8px);
  clip-path: polygon(12.5% 100%, 87.5% 100%, 50% 0);
  z-index: 3;
}
.actions_cards__368ro .actions_card__Rd5C9 .actions_actions__1jTTg .actions_hide__2JOS8 .actions_button__2x7QC:hover {
  color: #fff;
  background: linear-gradient(180deg, #8424d0 -90%, #014170 90%, #00b3ec 200%);
}
.actions_cards__368ro .actions_card__Rd5C9 .actions_actions__1jTTg .actions_hide__2JOS8 .actions_button__2x7QC:hover::before {
  background: linear-gradient(180deg, #00b3ec -90%, #014170 10%, #8424d0 125%);
}
.actions_cards__368ro .actions_card__Rd5C9.actions_show__dvUbm .actions_actions__1jTTg {
  position: absolute;
  max-height: none;
  background-color: #fff;
  border-radius: 20px;
  padding-bottom: 10px;
  border: 1px solid #cecece;
}
.actions_cards__368ro .actions_card__Rd5C9.actions_show__dvUbm .actions_actions__1jTTg .actions_hide__2JOS8 {
  visibility: visible;
}
.actions_cards__368ro .actions_card__Rd5C9.actions_show__dvUbm .actions_actions-hidden__14NQe {
  visibility: hidden;
}
.actions_cards__368ro .actions_card__Rd5C9.actions_show__dvUbm .actions_more__3-UpR {
  visibility: hidden;
}


@media screen and (max-width: 1200px) {
  .actions_cards__368ro  {
    flex-wrap: wrap;
    justify-content: center;
    grid-gap: 20px;
    gap: 20px;
  }
  .actions_cards__368ro .actions_card__Rd5C9.actions_show__dvUbm{
    z-index: 10;
  }
  .actions_lineContainer__x_Ouk{
    display: none !important;
  }
  .actions_actions-hidden__14NQe{
    display: none !important;
  }
}

.filter_filter__3nLib .filter_searchFor__1DRUA {
  display: flex;
  grid-gap: 10px;
  gap: 10px;
}
.filter_filter__3nLib .filter_searchFor__1DRUA .filter_option__1mpzO {
  cursor: pointer;
  border-radius: 8px;
}
.filter_filter__3nLib .filter_searchFor__1DRUA .filter_option__1mpzO::before {
  border-radius: 8px;
}
.filter_filter__3nLib .filter_searchFor__1DRUA .filter_option__1mpzO.filter_active__xRT3e {
  background: linear-gradient(
    191deg,
    #00b3ec -91.84%,
    #014170 25.42%,
    #8424d0 126.74%
  );
  color: #fff;
}
.filter_filter__3nLib .filter_submit__1gWeH {
  margin-top: 10px;
  width: 190px;
}
.filter_filter__3nLib .filter_submit__1gWeH .filter_button__2GDvs {
  border-radius: 8px;
  cursor: pointer;
}
.filter_filter__3nLib .filter_submit__1gWeH .filter_button__2GDvs::before {
  border-radius: 8px;
}
.filter_filter__3nLib .filter_submit__1gWeH .filter_button__2GDvs:hover {
  background: linear-gradient(
    191deg,
    #00b3ec -91.84%,
    #014170 25.42%,
    #8424d0 126.74%
  );
  color: #fff;
}
.filter_filter__3nLib .filter_clear__36eFI {
  margin-top: 10px;
  width: 190px;
}
.filter_filter__3nLib .filter_clear__36eFI .filter_button__2GDvs {
  border-radius: 8px;
  cursor: pointer;
}
.filter_filter__3nLib .filter_clear__36eFI .filter_button__2GDvs::before {
  border-radius: 8px;
}
.filter_filter__3nLib .filter_clear__36eFI .filter_button__2GDvs:hover {
  background: linear-gradient(
    191deg,
    #00b3ec -91.84%,
    #014170 25.42%,
    #8424d0 126.74%
  );
  color: #fff;
}
.filter_filter__3nLib .filter_inner__3u6X4 {
  margin-top: 25px;
  width: 280px;
  height: 280px;
  overflow-y: auto;
}
.filter_filter__3nLib .filter_inner__3u6X4 .filter_checkboxGroup__3_56m .filter_checkboxGroupHeader__3mIO9 {
  position: relative;
  color: #1a1a1a;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  margin-top: 10px;
  margin-bottom: 10px;
  cursor: pointer;
  width: 270px;
}
.filter_filter__3nLib .filter_inner__3u6X4 .filter_checkboxGroup__3_56m .filter_checkboxGroupHeader__3mIO9::after {
  position: absolute;
  content: "˅";
  top: -2px;
  right: 0;
  font-size: 25px;
  font-weight: 250;
}
.filter_filter__3nLib .filter_inner__3u6X4 .filter_checkboxGroup__3_56m.filter_active__xRT3e .filter_checkboxGroupHeader__3mIO9::after {
  top: 0px;
  font-size: 28px;
  content: "^";
}
.filter_filter__3nLib .filter_inner__3u6X4 .filter_checkboxGroup__3_56m .filter_option__1mpzO {
  display: none;
  margin-right: 5px;
  margin-left: 5px;
  margin-bottom: 8px;
}
.filter_filter__3nLib .filter_inner__3u6X4 .filter_checkboxGroup__3_56m.filter_active__xRT3e .filter_option__1mpzO {
  display: flex;
  grid-gap: 5px;
  gap: 5px;
  align-items: center;
}
.filter_filter__3nLib .filter_inner__3u6X4 .filter_checkboxGroup__3_56m .filter_option__1mpzO .filter_checkboxLabel__2cf2W {
  padding-top: 2px;
  color: #1a1a1a;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-left: 5px;
  margin-bottom: 0px;
}
@media screen and (max-width: 1200px) {
  .filter_filter__3nLib .filter_inner__3u6X4 {
    height: 100px;
    width: calc(100vw - 60px);
    overflow-y: auto;
  }
  .filter_filter__3nLib .filter_inner__3u6X4 .filter_checkboxGroup__3_56m .filter_checkboxGroupHeader__3mIO9 {
    width: calc(100vw - 60px);
  }
}

.search_cardSearch__38mTe {
  width: 100%;
  height: 820px;
}
.search_cardSearch__38mTe .search_border__3-N11 {
  background: linear-gradient(
    191deg,
    #00b3ec -91.84%,
    #014170 25.42%,
    #8424d0 126.74%
  );
}
.search_cardSearch__38mTe .search_arrow__ROBku {
  background: linear-gradient(
    201deg,
    #00b3ec -91.84%,
    #014170 25.42%,
    #8424d0 126.74%
  );
}
.search_cardSearch__38mTe .search_inner__2tD_D {
  display: flex;
  grid-gap: 40px;
  gap: 40px;
  padding: 30px 29px 20px 29px;
}
.search_cardSearch__38mTe .search_content__LlBdJ{
  width: 100%;
}
.search_cardSearch__38mTe .search_content__LlBdJ .search_search__lc4x7 {
  display: flex;
  justify-content: flex-start;
  grid-gap: 10px;
  gap: 10px;
  width: 100%;
  filter: drop-shadow(1px 1px 8px rgba(0, 0, 0, 0.15));
  background-color: rgba(239, 239, 239, 1);
  border-radius: 20px;
  padding-left: 22px;
}
.search_cardSearch__38mTe .search_content__LlBdJ .search_search__lc4x7 .search_icon__13BqO {
  margin-top: 10px;
  margin-bottom: 10px;
  min-width: 22px;
}
.search_cardSearch__38mTe .search_content__LlBdJ .search_search__lc4x7 .search_input__2Sk8p {
  width: 100%;
  color: #646464;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  border: none;
  vertical-align: middle;
  background-color: rgba(239, 239, 239, 1);
}
.search_cardSearch__38mTe .search_content__LlBdJ .search_search__lc4x7 .search_input__2Sk8p::placeholder,
.search_cardSearch__38mTe .search_content__LlBdJ .search_search__lc4x7 .search_input__2Sk8p::-webkit-input-placeholder {
  color: #000;
  line-height: 18px;
  vertical-align: middle;
}

.search_cardSearch__38mTe .search_content__LlBdJ .search_search__lc4x7 .search_input__2Sk8p:focus-visible {
  outline: none;
}
.search_cardSearch__38mTe .search_content__LlBdJ .search_search__lc4x7 .search_button__2if0n {
  position: relative;
  min-width: 65px;
  border-radius: 0 20px 20px 20px;
  border: none;
  background: linear-gradient(
    191deg,
    #00b3ec -91.84%,
    #014170 25.42%,
    #8424d0 126.74%
  );
}
.search_cardSearch__38mTe .search_content__LlBdJ .search_search__lc4x7 .search_button__2if0n::after {
  position: absolute;
  content: "";
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  visibility: hidden;
  background-color: #fff;
  opacity: 0.3;
  border-radius: 0 20px 20px 20px;
}
.search_cardSearch__38mTe .search_content__LlBdJ .search_search__lc4x7 .search_button__2if0n:hover::after {
  visibility: visible;
}
.search_cardSearch__38mTe .search_content__LlBdJ .search_info__2Sl7b {
  margin-top: 5px;
  display: flex;
  flex-wrap: wrap;
  align-content: space-between;
  width: 100%;
}
.search_cardSearch__38mTe .search_content__LlBdJ .search_info__2Sl7b .search_totals__3fbLg {
  display: flex;
  flex-wrap: wrap;
  grid-gap: 5px;
  gap: 5px;
  margin-top: 10px;
  font-size: 15px;
  font-weight: 400;
  line-height: 18px;
  text-align: left;
  color: #696969;
}
.search_cardSearch__38mTe .search_content__LlBdJ .search_results__2jg4_ {
  margin-top: 10px;
  display: flex;
  flex-wrap: wrap;
  grid-gap: 20px;
  gap: 20px;
  width: 100%;
}
.search_cardSearch__38mTe .search_content__LlBdJ .search_results__2jg4_ .search_result__eU1I0 {
  position: relative;
  display: flex;
  color: #555;
  width: 100%;
}
.search_cardSearch__38mTe .search_content__LlBdJ .search_results__2jg4_ .search_result__eU1I0 .search_card__FE3Q8 {
  cursor: default;
  border-radius: 20px;
  background: #fff;
  filter: drop-shadow(1px 1px 4px rgba(0, 0, 0, 0.15));
  padding: 12px 20px;
  color: #555;
  width: 100%;
}
.search_cardSearch__38mTe .search_content__LlBdJ .search_results__2jg4_ .search_result__eU1I0 .search_card__FE3Q8 .search_city__FSwTJ {
  width: 100%;
  font-size: 13px;
  font-weight: 400;
  line-height: 16px;
  text-align: left;
}
.search_cardSearch__38mTe .search_content__LlBdJ .search_results__2jg4_ .search_result__eU1I0 .search_card__FE3Q8 .search_info__2Sl7b {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.search_cardSearch__38mTe .search_content__LlBdJ .search_results__2jg4_ .search_result__eU1I0 .search_card__FE3Q8 .search_info__2Sl7b .search_about__1wAbx {
  display: flex;
  align-items: center;
}
.search_cardSearch__38mTe .search_content__LlBdJ .search_results__2jg4_ .search_result__eU1I0 .search_card__FE3Q8 .search_info__2Sl7b .search_about__1wAbx img {
  width: 75px;
  height: 48px;
  object-fit: contain;
}
.search_cardSearch__38mTe .search_content__LlBdJ .search_results__2jg4_ .search_result__eU1I0 .search_card__FE3Q8 .search_info__2Sl7b .search_about__1wAbx .search_title__1-fKU {
  width: 400px;
  font-size: 16px;
  font-weight: 400;
  line-height: 20px;
  text-align: left;
  color: #1a1a1a;
  -webkit-line-clamp: 2;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
}
.search_cardSearch__38mTe .search_content__LlBdJ .search_results__2jg4_ .search_result__eU1I0 .search_card__FE3Q8 .search_info__2Sl7b .search_toCompany__BfXvs {
  text-decoration: none;
  color: #1a1a1a;
  font-size: 11px;
  font-weight: 400;
  line-height: 13.5px;
  text-align: left;
}
.search_cardSearch__38mTe .search_content__LlBdJ .search_results__2jg4_ .search_result__eU1I0 .search_card__FE3Q8 .search_info__2Sl7b .search_toCompany__BfXvs svg {
  margin-left: 10px;
  margin-bottom: 2px;
  fill: #1a1a1a;
}
.search_cardSearch__38mTe .search_content__LlBdJ .search_results__2jg4_ .search_result__eU1I0 .search_card__FE3Q8 .search_specialities__3gjK9 {
  margin-top: 10px;
}
.search_cardSearch__38mTe .search_content__LlBdJ .search_results__2jg4_ .search_result__eU1I0 .search_card__FE3Q8 .search_specialities__3gjK9 .search_title__1-fKU {
  display: flex;
  flex-wrap: wrap;
  grid-gap: 5px;
  gap: 5px;
  font-size: 13px;
  font-weight: 400;
  line-height: 16px;
  text-align: left;
  color: #1a1a1a;
  margin-bottom: 10px;
}
.search_cardSearch__38mTe .search_content__LlBdJ .search_results__2jg4_ .search_result__eU1I0 .search_card__FE3Q8 .search_specialities__3gjK9 .search_title__1-fKU .search_colored__32GNt {
  background: linear-gradient(
    191deg,
    #00b3ec -91.84%,
    #014170 25.42%,
    #8424d0 126.74%
  );
  background-size: cover;
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.search_speciality__bYFZd + .search_speciality__bYFZd {
  margin-top: 5px;
}
.search_cardSearch__38mTe .search_content__LlBdJ .search_results__2jg4_ .search_result__eU1I0 .search_card__FE3Q8 .search_specialities__3gjK9 .search_speciality__bYFZd {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: #f5f5f5;
  border-radius: 5px;
  padding: 5px 15px;
}
.search_cardSearch__38mTe .search_content__LlBdJ .search_results__2jg4_ .search_result__eU1I0 .search_card__FE3Q8 .search_specialities__3gjK9 .search_speciality__bYFZd .search_name__3JnDu {
  -webkit-line-clamp: 1;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
  font-size: 13px;
  font-weight: 400;
  line-height: 16px;
  text-align: left;
  color: #1a1a1a;
  max-width: 250px;
}
.search_cardSearch__38mTe .search_content__LlBdJ .search_results__2jg4_ .search_result__eU1I0 .search_card__FE3Q8 .search_specialities__3gjK9 .search_speciality__bYFZd .search_props__1kQR0 {
  display: flex;
  align-items: center;
}
.search_cardSearch__38mTe
  .search_content__LlBdJ
  .search_results__2jg4_
  .search_result__eU1I0
  .search_card__FE3Q8
  .search_specialities__3gjK9
  .search_speciality__bYFZd
  .search_props__1kQR0
  .search_prop__3LdP4 {
  position: relative;
  justify-content: space-between;
  background-color: #fff;
  padding: 4px 10px;
  display: flex;
  align-items: center;
  color: #1a1a1a;
  width: 150px;
}
.search_cardSearch__38mTe
  .search_content__LlBdJ
  .search_results__2jg4_
  .search_result__eU1I0
  .search_card__FE3Q8
  .search_specialities__3gjK9
  .search_speciality__bYFZd
  .search_props__1kQR0
  .search_prop__3LdP4
  .search_name__3JnDu {
  font-size: 10px;
  font-weight: 400;
  line-height: 10px;
  text-align: left;
}
.search_cardSearch__38mTe
  .search_content__LlBdJ
  .search_results__2jg4_
  .search_result__eU1I0
  .search_card__FE3Q8
  .search_specialities__3gjK9
  .search_speciality__bYFZd
  .search_props__1kQR0
  .search_prop__3LdP4
  .search_value__2_3HU {
  margin-left: 5px;
  font-size: 13px;
  font-weight: 400;
  line-height: 15.73px;
  text-align: left;
}
.search_cardSearch__38mTe
  .search_content__LlBdJ
  .search_results__2jg4_
  .search_result__eU1I0
  .search_card__FE3Q8
  .search_specialities__3gjK9
  .search_speciality__bYFZd
  .search_props__1kQR0
  .search_prop__3LdP4
  .search_separator__rIQvk {
  position: absolute;
  border: 1px solid #f5f5f5;
  height: 12px;
  right: -1px;
}
.search_cardSearch__38mTe
  .search_content__LlBdJ
  .search_results__2jg4_
  .search_result__eU1I0
  .search_card__FE3Q8
  .search_specialities__3gjK9
  .search_speciality__bYFZd
  .search_props__1kQR0
  .search_go__1akRp {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 20px;
  border-radius: 25px;
  background: linear-gradient(
    191deg,
    #00b3ec -91.84%,
    #014170 25.42%,
    #8424d0 126.74%
  );
  padding: 7px 10px;
}
.search_cardSearch__38mTe
  .search_content__LlBdJ
  .search_results__2jg4_
  .search_result__eU1I0
  .search_card__FE3Q8
  .search_specialities__3gjK9
  .search_speciality__bYFZd
  .search_props__1kQR0
  .search_go__1akRp
  svg {
  fill: #fff;
  width: 20px;
  height: 10px;
}

.search_cardSearch__38mTe .search_content__LlBdJ .search_results__2jg4_ .search_result__eU1I0 .search_title__1-fKU {
  font-size: 13.5px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  -webkit-line-clamp: 3;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
}
.search_more__1xtOG {
  display: flex;
  justify-content: center;
  text-decoration: none;
}
.search_more__1xtOG .search_all__33xGR {
  color: #000;
  font-size: 10px;
  font-weight: 300;
  line-height: 12px;
  text-align: left;
  cursor: pointer;
  position: relative;
  margin-top: 10px;
  padding: 5px 20px;
  box-shadow: 1px 1px 8px 0px rgba(0, 0, 0, 0.15);
  border-radius: 20px;
  text-decoration: none;
}
.search_more__1xtOG .search_all__33xGR::before {
  content: "";
  position: absolute;
  background: linear-gradient(
    191deg,
    #00b3ec -91.84%,
    #014170 25.42%,
    #8424d0 126.74%
  );
  width: 6px;
  height: 16px;
  top: calc(50% - 8px);
  left: 100%;
  clip-path: polygon(0 12.5%, 100% 50%, 0 87.5%);
}
.search_full__36Tel {
  width: 100%;
  display: flex;
  justify-content: center;
}
.search_full__36Tel .search_more__1xtOG .search_all__33xGR {
  font-size: 12px;
  line-height: 14.5px;
  margin-top: 20px;
}
.search_full__36Tel .search_more__1xtOG .search_all__33xGR::before {
  width: 8px;
  height: 18px;
  top: calc(50% - 8px);
}
@media screen and (max-width: 1200px) {
  .search_cardSearch__38mTe {
    height: auto;
    margin: 0px 5px;
  }
  .search_cardSearch__38mTe .search_inner__2tD_D {
    flex-wrap: wrap;
  }
  .search_cardSearch__38mTe .search_content__LlBdJ {
    width: 100%;
  }
  .search_cardSearch__38mTe .search_content__LlBdJ .search_results__2jg4_ .search_result__eU1I0 .search_card__FE3Q8 .search_info__2Sl7b {
    display: block;
  }
  .search_cardSearch__38mTe .search_content__LlBdJ .search_results__2jg4_ .search_result__eU1I0 .search_card__FE3Q8 .search_info__2Sl7b .search_about__1wAbx .search_title__1-fKU {
    width: 100%;
  }
  .search_cardSearch__38mTe .search_content__LlBdJ .search_results__2jg4_ .search_result__eU1I0 .search_card__FE3Q8 .search_specialities__3gjK9 .search_speciality__bYFZd {
    display: block;
    padding: 10px;
  }
  .search_cardSearch__38mTe .search_content__LlBdJ .search_results__2jg4_ .search_result__eU1I0 .search_card__FE3Q8 .search_specialities__3gjK9 .search_speciality__bYFZd .search_name__3JnDu {
    margin-bottom: 10px;
    max-width: auto;
  }
  .search_cardSearch__38mTe .search_content__LlBdJ .search_results__2jg4_ .search_result__eU1I0 .search_card__FE3Q8 .search_specialities__3gjK9 .search_speciality__bYFZd .search_props__1kQR0 {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
  }
  .search_cardSearch__38mTe
    .search_content__LlBdJ
    .search_results__2jg4_
    .search_result__eU1I0
    .search_card__FE3Q8
    .search_specialities__3gjK9
    .search_speciality__bYFZd
    .search_props__1kQR0
    .search_prop__3LdP4 {
    width: 100%;
  }
  .search_cardSearch__38mTe
    .search_content__LlBdJ
    .search_results__2jg4_
    .search_result__eU1I0
    .search_card__FE3Q8
    .search_specialities__3gjK9
    .search_speciality__bYFZd
    .search_props__1kQR0
    .search_prop__3LdP4
    .search_name__3JnDu {
    margin-bottom: 0px;
  }
  .search_cardSearch__38mTe
    .search_content__LlBdJ
    .search_results__2jg4_
    .search_result__eU1I0
    .search_card__FE3Q8
    .search_specialities__3gjK9
    .search_speciality__bYFZd
    .search_props__1kQR0
    .search_prop__3LdP4
    .search_separator__rIQvk {
    display: none;
  }
  .search_cardSearch__38mTe
    .search_content__LlBdJ
    .search_results__2jg4_
    .search_result__eU1I0
    .search_card__FE3Q8
    .search_specialities__3gjK9
    .search_speciality__bYFZd
    .search_props__1kQR0
    .search_go__1akRp {
    margin-left: 0px;
    width: 50px;
    margin-top: 10px;
  }
}

.map_block__1mhth {
  display: flex;
  flex-wrap: wrap;
  position: relative;
  margin-top: 140px;
  justify-content: center;
}
.map_header__2jeEk {
  background: url(/static/media/background-space.8699e94d.png),
    lightgray 0px -141.937px / 137.633% 239.84% no-repeat;
  background-position: center;
  background-size: cover;
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-size: 36px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  text-transform: uppercase;
  text-align: left;
  margin-bottom: 40px;
  width: 100%;
}
.map_secondHeader__1GGmJ {
  background: url(/static/media/background-space.8699e94d.png),
    lightgray 0px -141.937px / 137.633% 239.84% no-repeat;
  background-position: center;
  background-size: cover;
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-size: 32px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  text-align: left;
  margin-top: 40px;
  width: 100%;
}
.map_legend__21gO- {
  display: flex;
  grid-row-gap: 5px;
  row-gap: 5px;
  flex-direction: column;
  width: 100%;
  margin-top: 10px;
}
.map_legend__21gO- .map_legendPoint__3bxfm {
  display: flex;
}
.map_legend__21gO- .map_legendPoint__3bxfm .map_icon__V7yu8.map_college__3LoeZ {
  cursor: pointer;
  fill: #fff;
}
.map_legend__21gO- .map_legendPoint__3bxfm .map_icon__V7yu8.map_university__1pnVS {
  cursor: pointer;
  fill: #0072ff;
}
.map_map__1Ze9S {
  position: relative;
  margin-top: 20px;
  height: 960px;
  width: 800px;
}
.map_map__1Ze9S .map_point__3IEIe {
  position: absolute;
}
.map_map__1Ze9S .map_point__3IEIe .map_icon__V7yu8.map_college__3LoeZ {
  cursor: pointer;
  fill: #fff;
}
.map_map__1Ze9S .map_point__3IEIe .map_icon__V7yu8.map_university__1pnVS {
  cursor: pointer;
  fill: #0072ff;
}
.map_map__1Ze9S .map_point__3IEIe .map_icon__V7yu8:hover {
  fill: #646464;
}
.map_map__1Ze9S .map_point__3IEIe .map_card__aLcD0 {
  height: 289px;
  width: 434px;
  position: absolute;
  display: none;
  top: 12px;
  right: 20px;
}
.map_card__aLcD0 .map_border__2RhXY {
  background: linear-gradient(
    191deg,
    #00b3ec -91.84%,
    #014170 25.42%,
    #8424d0 126.74%
  );
}
.map_card__aLcD0 .map_arrow__FHYLp {
  background: linear-gradient(
    191deg,
    #00b3ec -91.84%,
    #014170 25.42%,
    #8424d0 126.74%
  );
}
.map_map__1Ze9S .map_point__3IEIe:hover .map_card__aLcD0 {
  display: block;
  z-index: 1;
}
.map_card__aLcD0.map_mobile__2i2Vo {
  display: none;
}
.map_card__aLcD0 .map_content__1CpcI {
  margin-top: 5px;
  padding: 10px 20px 27px 22px;
  cursor: default;
  height: 280px;
  overflow-y: auto;
}
.map_card__aLcD0 .map_content__1CpcI .map_item__1PyBu {
  border-bottom: 1px solid #e1e1e1;
  text-decoration: none;
}
.map_item__1PyBu + .map_item__1PyBu {
  margin-top: 12px;
}
.map_card__aLcD0 .map_content__1CpcI .map_item__1PyBu .map_header__2jeEk {
  display: flex;
  color: #555;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  writing-mode: horizontal-tb;
  transform: none;
  background: none;
  background-clip: none;
  -webkit-background-clip: none;
  -webkit-text-fill-color: #555;
  text-transform: none;
  margin-right: 0;
  margin-bottom: 8px;
  align-items: center;
}
.map_card__aLcD0 .map_content__1CpcI .map_item__1PyBu .map_header__2jeEk .map_icon__V7yu8 {
  margin-right: 15px;
  margin-left: -4px;
  height: 48px;
  object-fit: contain;
}
.map_card__aLcD0 .map_content__1CpcI .map_item__1PyBu .map_contacts__3cqfy {
  display: flex;
  grid-gap: 40px;
  gap: 40px;
}
.map_card__aLcD0 .map_content__1CpcI .map_item__1PyBu .map_contacts__3cqfy .map_address__14W7X {
  margin-bottom: 12px;
}
.map_card__aLcD0 .map_content__1CpcI .map_item__1PyBu .map_contacts__3cqfy .map_address__14W7X .map_title__1dSZq {
  color: #555;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-bottom: 8px;
}
.map_card__aLcD0 .map_content__1CpcI .map_item__1PyBu .map_contacts__3cqfy .map_address__14W7X .map_description__SUWeW {
  color: #555;
  font-size: 12px;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
}
@media screen and (max-width: 1200px) {
  .map_block__1mhth {
    flex-wrap: wrap;
    justify-content: center;
  }
  .map_header__2jeEk {
    margin-left: 5px;
  }
  .map_secondHeader__1GGmJ {
    margin-left: 5px;
  }
  .map_cardSearch__3cLtZ {
    width: calc(100vw - 10px);
    height: 660px;
  }
  .map_cardSearch__3cLtZ .map_content__1CpcI .map_results__1IBip .map_result__3Nbgw {
    width: 100%;
  }
  .map_map__1Ze9S {
    margin-top: 20px;
    height: auto;
    display: block;
  }
  .map_map__1Ze9S .map_point__3IEIe:hover .map_card__aLcD0 {
    display: none;
    z-index: 10;
  }
  .map_card__aLcD0.map_mobile__2i2Vo {
    margin-top: 20px;
    display: block;
    width: calc(100vw - 10px);
  }
}

.categories_block__3x9RY {
  position: relative;
  margin-top: 40px;
}
.categories_tabs__2AKQ2 {
  display: flex;
  align-items: flex-end;
  border-bottom: none !important;
}
.categories_tab__e_5X8 {
  cursor: pointer;
  font-size: 26px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  color: #999;
  border: none !important;
  border-radius: 0px !important;
  background: #fff;
  box-shadow: -3px 2px 6px 0px rgba(0, 0, 0, 0.11) inset;
  margin-bottom: 5px !important;
  padding: 5px 27px;
}
.categories_tab__e_5X8:hover {
  color: #999;
  background: linear-gradient(
    191deg,
    #00b3ec -91.84%,
    #014170 25.42%,
    #8424d0 126.74%
  );
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.categories_tab__e_5X8.categories_start__3iUcL {
  border-radius: 10px 0px 0px 10px !important;
}
.categories_tab__e_5X8.categories_end__1vzFv {
  border-radius: 0px 10px 10px 0px !important;
}
.categories_tab__e_5X8.categories_active__2SS7j {
  font-size: 36px;
  background: linear-gradient(
    191deg,
    #00b3ec -91.84%,
    #014170 25.42%,
    #8424d0 126.74%
  );
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  box-shadow: 1px 1px 8px 0px rgba(0, 0, 0, 0.15);
}
.categories_tabContent__1FS_O {
  margin-top: 45px;
  display: flex !important;
  justify-content: flex-start;
  align-items: flex-start;
  align-content: flex-start;
  grid-column-gap: 24px;
  column-gap: 24px;
  grid-row-gap: 18px;
  row-gap: 18px;
  flex-wrap: wrap;
}
.categories_card__3XTtg {
  width: 350px;
  height: auto;
  box-shadow: 1px 1px 8px 0px #00000026;
  padding: 15px 15px 8px 15px;
  border-radius: 10px;
}
.categories_card__3XTtg .categories_tags__BhKME {
  display: flex;
  grid-gap: 5px;
  gap: 5px;
}
.categories_card__3XTtg .categories_tags__BhKME .categories_tag__2wEDM {
  font-size: 10px;
  font-weight: 400;
  background: linear-gradient(155.19deg, #014170 15.81%, #8424d0 100.43%);
  border-radius: 25px;
  padding: 2px 10px;
  color: #fff;
  text-transform: lowercase;
}
.categories_card__3XTtg .categories_type__zlNEm {
  margin-top: 6px;
  color: #696969;
  font-size: 12px;
  line-height: 14.5px;
  font-weight: 400;
  height: 30px;
  -webkit-line-clamp: 2;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
}
.categories_card__3XTtg .categories_header__1ayYi {
  color: #1a1a1a;
  font-size: 15px;
  font-weight: 500;
  line-height: 18px;
  height: 36px;
  -webkit-line-clamp: 2;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
}
.categories_card__3XTtg .categories_description__15z9F {
  margin-top: 6px;
  background: #f6f6f6;
  border-radius: 10px;
  padding: 5px 10px;
  font-size: 12px;
  line-height: 16px;
  font-weight: 400;
  color: #1a1a1a;
  height: 58px;
  -webkit-line-clamp: 3;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
}
.categories_card__3XTtg .categories_footer__2OZIu {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 6px;
  font-size: 12px;
}
.categories_card__3XTtg .categories_footer__2OZIu .categories_data__NkxM8 {
  color: #1a1a1a;
  font-size: 11px;
  font-weight: 400;
}
.categories_card__3XTtg .categories_footer__2OZIu .categories_addition__1eiTS {
  background: linear-gradient(155.19deg, #014170 15.81%, #8424d0 100.43%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-size: 11px;
  font-weight: 400;
}
.categories_requestButton__35yoG {
  position: absolute;
  padding: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  top: 10px;
  right: 0;
  color: #555;
  text-align: center;
  font-size: 11.5px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.categories_requestButton__35yoG:hover .categories_fill__32gYe,
.categories_requestButton__35yoG .categories_border__atR1b,
.categories_requestButton__35yoG .categories_arrow__Kv-bf {
  background-image: linear-gradient(
    191deg,
    #00b3ec -91.84%,
    #014170 25.42%,
    #8424d0 126.74%
  ) !important;
}
.categories_more__1ARB3 {
  display: flex;
  justify-content: center;
  text-decoration: none;
}
.categories_more__1ARB3 .categories_all__1ZFnv {
  color: #000;
  font-size: 12px;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
  cursor: pointer;
  position: relative;
  margin-top: 48px;
  padding: 5px 20px;
  box-shadow: 1px 1px 8px 0px rgba(0, 0, 0, 0.15);
  border-radius: 20px;
}
.categories_more__1ARB3 .categories_all__1ZFnv::before {
  content: "";
  position: absolute;
  background: linear-gradient(
    191deg,
    #00b3ec -91.84%,
    #014170 25.42%,
    #8424d0 126.74%
  );
  width: 6px;
  height: 16px;
  top: calc(50% - 8px);
  left: 100%;
  clip-path: polygon(0 12.5%, 100% 50%, 0 87.5%);
}
@media screen and (max-width: 1200px) {
  .categories_block__3x9RY {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }
  .categories_tab__e_5X8 {
    font-size: 0.7rem;
  }
  .categories_tab__e_5X8.categories_active__2SS7j {
    font-size: 0.8rem;
  }
  .categories_tab__e_5X8.categories_start__3iUcL {
    border-radius: 5px 0px 0px 5px !important;
  }
  .categories_tab__e_5X8.categories_end__1vzFv {
    border-radius: 0px 5px 5px 0px !important;
  }
  .categories_tabContent__1FS_O {
    justify-content: center;
  }
  .categories_requestButton__35yoG {
    top: 35px;
  }
}

.news_block__3kDEl {
  position: relative;
  margin-top: 160px;
}
.news_header__1X6uC {
  background: url(/static/media/background-space.8699e94d.png),
    lightgray -477.999px -192.305px / 357.419% 786.932% no-repeat;
  background-position: center;
  background-size: cover;
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-size: 36px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  text-transform: uppercase;
}
.news_background__4ylSV {
  position: absolute;
  top: -10px;
  right: 160px;
}
.news_all-news__3Zo17 {
  cursor: pointer;
  position: relative;
  margin-top: 48px;
  margin-bottom: 17px;
  margin-left: -5px;
  padding-right: 8px;
  width: 100%;
  text-align: end;
  color: #555;
  font-size: 11.5px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.news_all-news__3Zo17::before {
  content: "";
  position: absolute;
  background: linear-gradient(
    191deg,
    #00b3ec -91.84%,
    #014170 25.42%,
    #8424d0 126.74%
  );
  width: 6px;
  height: 12px;
  top: 1px;
  left: calc(100% - 4px);
  clip-path: polygon(0 12.5%, 100% 50%, 0 87.5%);
}
.news_news__2TsjI {
  --width-card: 264px;
  --height-card: 318px;
}
.news_news__2TsjI .news_slide__3bTpg {
  width: var(--width-card);
}
.news_news__2TsjI .news_card__1dSXq {
  cursor: default;
  position: relative;
  background-color: #fff;
  border-radius: 19.5px;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  width: var(--width-card);
  height: var(--height-card);
}
.news_news__2TsjI .news_card__1dSXq::before {
  content: "";
  background-image: linear-gradient(
    191.12deg,
    #00b3ec -91.84%,
    #014170 25.42%,
    #8424d0 126.74%
  );
  top: -1px;
  left: -1px;
  bottom: -1px;
  right: -1px;
  position: absolute;
  z-index: -1;
  border-radius: 20px;
}
.news_news__2TsjI .news_card__1dSXq .news_image__3xqAh {
  height: calc(var(--height-card) / 2);
  max-width: 100%;
  border-radius: 20px;
}
.news_news__2TsjI .news_card__1dSXq .news_image__3xqAh img {
  width: 100%;
  height: calc(var(--height-card) / 2);
  border-radius: 20px 20px 0px 0px;
  object-fit: cover;
}
.news_news__2TsjI .news_card__1dSXq .news_info__1dmO0 {
  padding: 15px;
  display: flex;
  flex-wrap: wrap;
  align-content: space-between;
  height: calc(var(--height-card) / 2);
}
.news_news__2TsjI .news_card__1dSXq .news_info__1dmO0 .news_title__2d7Wv {
  color: #333;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  width: 100%;
}
.news_news__2TsjI .news_card__1dSXq .news_info__1dmO0 .news_description__3ostx {
  color: #555;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  max-width: var(--width-card);
  max-height: calc(var(--height-card) / 2 - 80px);
  margin-top: 10px;
  -webkit-line-clamp: 5;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
}
.news_news__2TsjI .news_card__1dSXq .news_info__1dmO0 .news_addition__yYeZS {
  display: flex;
  justify-content: space-between;
  color: #333;
  font-size: 11px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  width: 100%;
}
.news_news__2TsjI .news_card__1dSXq .news_info__1dmO0 .news_addition__yYeZS .news_more__2u4EY {
  cursor: pointer;
  position: relative;
  padding-right: 8px;
  background-image: linear-gradient(
    191deg,
    #00b3ec -91.84%,
    #014170 25.42%,
    #8424d0 126.74%
  );
  color: transparent;
  -webkit-background-clip: text;
  background-clip: text;
}
.news_news__2TsjI .news_card__1dSXq .news_info__1dmO0 .news_addition__yYeZS .news_more__2u4EY::before {
  content: "";
  position: absolute;
  background: linear-gradient(
    191deg,
    #00b3ec -91.84%,
    #014170 25.42%,
    #8424d0 126.74%
  );
  width: 6px;
  height: 12px;
  top: 1px;
  left: calc(100% - 4px);
  clip-path: polygon(0 12.5%, 100% 50%, 0 87.5%);
}
.news_news__2TsjI .news_swiper__21KOV {
  max-width: 1100px;
  padding: 1px 3px;
  height: calc(var(--height-card) + 33px) !important;
  --background-swiper-scrollbar-horizontal: #f8f8f8;
  --background-swiper-scrollbar-drag: linear-gradient(
    155deg,
    #0072ff 15.81%,
    #29cd8f 100.43%
  );
}

@media screen and (max-width: 1200px) {
  .news_news__2TsjI .news_swiper__21KOV{
    max-width: calc(100vw - 10px);
  }
}

.partners_block__3pizi {
  position: relative;
  margin-top: 160px;
}
.partners_header__3WU20 {
  background: url(/static/media/background-space.8699e94d.png),
    lightgray -477.999px -192.305px / 357.419% 786.932% no-repeat;
  background-position: center;
  background-size: cover;
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-size: 36px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  text-transform: uppercase;
}
.partners_partners__96DFV {
  margin-top: 77px;
  display: flex;
  flex-wrap: wrap;
  grid-gap: 25px;
  gap: 25px;
}
.partners_partners__96DFV .partners_partner__2SF22 {
  cursor: default;
  position: relative;
  background-color: #fff;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  width: 350px;
}
.partners_partners__96DFV .partners_partner__2SF22 .partners_photo__KvMsc {
  height: 120px;
}
.partners_partners__96DFV .partners_partner__2SF22 .partners_photo__KvMsc img {
  height: 120px;
  width: 100%;
  object-fit: contain;
}
.partners_partners__96DFV .partners_partner__2SF22 .partners_info__g7Zln .partners_title__1MxhI {
  padding: 8px 13px;
}
.partners_partners__96DFV .partners_partner__2SF22 .partners_info__g7Zln .partners_title__1MxhI .partners_name__AKvEV {
  color: #333;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}
@media screen and (max-width: 1200px) {
  .partners_partners__96DFV {
    justify-content: center;
  }
  .partners_partners__96DFV .partners_partner__2SF22 {
    width: calc(100vw - 10px);
  }
}

.news_block__zK5xh {
  position: relative;
  margin-top: 160px;
}
.news_header__Sh22h {
  background: url(/static/media/background-space.8699e94d.png),
    lightgray -477.999px -192.305px / 357.419% 786.932% no-repeat;
  background-position: center;
  background-size: cover;
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-size: 36px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  text-transform: uppercase;
}
.news_background__15U-e {
  position: absolute;
  top: -10px;
  right: 160px;
}
.news_all-news__3y6pO {
  cursor: pointer;
  position: relative;
  margin-top: 48px;
  margin-bottom: 17px;
  margin-left: -5px;
  padding-right: 8px;
  width: 100%;
  text-align: end;
  color: #555;
  font-size: 11.5px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.news_all-news__3y6pO::before {
  content: "";
  position: absolute;
  background: linear-gradient(
    191deg,
    #00b3ec -91.84%,
    #014170 25.42%,
    #8424d0 126.74%
  );
  width: 6px;
  height: 12px;
  top: 1px;
  left: calc(100% - 4px);
  clip-path: polygon(0 12.5%, 100% 50%, 0 87.5%);
}
.news_news__7OI8e {
  --width-card: 264px;
  --height-card: 318px;
  display: flex;
  flex-wrap: wrap;
}
.news_news__7OI8e .news_slide__1Hyvy {
  width: var(--width-card);
}
.news_news__7OI8e .news_card__1mQ2f {
  cursor: default;
  position: relative;
  background-color: #fff;
  border-radius: 19.5px;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  width: var(--width-card);
  height: var(--height-card);
  margin: 15px;
}
.news_news__7OI8e .news_card__1mQ2f::before {
  content: "";
  background-image: linear-gradient(
    191.12deg,
    #00b3ec -91.84%,
    #014170 25.42%,
    #8424d0 126.74%
  );
  top: -1px;
  left: -1px;
  bottom: -1px;
  right: -1px;
  position: absolute;
  z-index: -1;
  border-radius: 20px;
}
.news_news__7OI8e .news_card__1mQ2f .news_image__12-rk {
  height: calc(var(--height-card) / 2);
  max-width: 100%;
  border-radius: 20px;
}
.news_news__7OI8e .news_card__1mQ2f .news_image__12-rk img {
  width: 100%;
  height: calc(var(--height-card) / 2);
  border-radius: 20px 20px 0px 0px;
  object-fit: cover;
}
.news_news__7OI8e .news_card__1mQ2f .news_info__1FlXf {
  padding: 15px;
  display: flex;
  flex-wrap: wrap;
  align-content: space-between;
  height: calc(var(--height-card) / 2);
}
.news_news__7OI8e .news_card__1mQ2f .news_info__1FlXf .news_title__2_1Ib {
  color: #333;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  width: 100%;
}
.news_news__7OI8e .news_card__1mQ2f .news_info__1FlXf .news_description__219_7 {
  color: #555;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  max-width: var(--width-card);
  max-height: calc(var(--height-card) / 2 - 80px);
  margin-top: 10px;
  -webkit-line-clamp: 5;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
}
.news_news__7OI8e .news_card__1mQ2f .news_info__1FlXf .news_addition__1FA6- {
  display: flex;
  justify-content: space-between;
  color: #333;
  font-size: 11px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  width: 100%;
}
.news_news__7OI8e .news_card__1mQ2f .news_info__1FlXf .news_addition__1FA6- .news_more__2oAOl {
  cursor: pointer;
  position: relative;
  padding-right: 8px;
  background-image: linear-gradient(
    191deg,
    #00b3ec -91.84%,
    #014170 25.42%,
    #8424d0 126.74%
  );
  color: transparent;
  -webkit-background-clip: text;
  background-clip: text;
}
.news_news__7OI8e .news_card__1mQ2f .news_info__1FlXf .news_addition__1FA6- .news_more__2oAOl::before {
  content: "";
  position: absolute;
  background: linear-gradient(
    191deg,
    #00b3ec -91.84%,
    #014170 25.42%,
    #8424d0 126.74%
  );
  width: 6px;
  height: 12px;
  top: 1px;
  left: calc(100% - 4px);
  clip-path: polygon(0 12.5%, 100% 50%, 0 87.5%);
}
.news_news__7OI8e .news_swiper__2u0fv {
  max-width: 1100px;
  padding: 1px 3px;
  height: calc(var(--height-card) + 33px) !important;
  --background-swiper-scrollbar-horizontal: #f8f8f8;
  --background-swiper-scrollbar-drag: linear-gradient(
    155deg,
    #0072ff 15.81%,
    #29cd8f 100.43%
  );
}

@media screen and (max-width: 1200px) {
  .news_news__7OI8e .news_swiper__2u0fv{
    max-width: calc(100vw - 10px);
  }
}

.filter_cardFilter__20KcY {
  width: 100%;
  height: 600px;
}
.filter_cardFilter__20KcY .filter_border__y_Xhs {
  background: linear-gradient(
    191deg,
    #00b3ec -91.84%,
    #014170 25.42%,
    #8424d0 126.74%
  );
}
.filter_cardFilter__20KcY .filter_arrow__2y8rk {
  background: linear-gradient(
    201deg,
    #00b3ec -91.84%,
    #014170 25.42%,
    #8424d0 126.74%
  );
}
.filter_cardFilter__20KcY .filter_content__1GQOk {
  padding: 30px 29px 20px 29px;
}
.filter_cardFilter__20KcY .filter_content__1GQOk .filter_searchFor__kfTAz {
  display: flex;
  grid-gap: 10px;
  gap: 10px;
}
.filter_cardFilter__20KcY .filter_content__1GQOk .filter_searchFor__kfTAz .filter_option__2z_tp {
  cursor: pointer;
  border-radius: 8px;
}
.filter_cardFilter__20KcY .filter_content__1GQOk .filter_searchFor__kfTAz .filter_option__2z_tp::before {
  border-radius: 8px;
}
.filter_cardFilter__20KcY .filter_content__1GQOk .filter_searchFor__kfTAz .filter_option__2z_tp.filter_active__P3ZR2 {
  background: linear-gradient(
    191deg,
    #00b3ec -91.84%,
    #014170 25.42%,
    #8424d0 126.74%
  );
  color: #fff;
}
.filter_cardFilter__20KcY .filter_content__1GQOk .filter_submit__BUeIQ {
  margin-top: 10px;
  width: 190px;
}
.filter_cardFilter__20KcY .filter_content__1GQOk .filter_submit__BUeIQ .filter_button__2W5Up {
  border-radius: 8px;
  cursor: pointer;
}
.filter_cardFilter__20KcY .filter_content__1GQOk .filter_submit__BUeIQ .filter_button__2W5Up::before {
  border-radius: 8px;
}
.filter_cardFilter__20KcY .filter_content__1GQOk .filter_submit__BUeIQ .filter_button__2W5Up:hover {
  background: linear-gradient(
    191deg,
    #00b3ec -91.84%,
    #014170 25.42%,
    #8424d0 126.74%
  );
  color: #fff;
}
.filter_cardFilter__20KcY .filter_content__1GQOk .filter_clear__2mfzY {
  margin-top: 10px;
  width: 190px;
}
.filter_cardFilter__20KcY .filter_content__1GQOk .filter_clear__2mfzY .filter_button__2W5Up {
  border-radius: 8px;
  cursor: pointer;
}
.filter_cardFilter__20KcY .filter_content__1GQOk .filter_clear__2mfzY .filter_button__2W5Up::before {
  border-radius: 8px;
}
.filter_cardFilter__20KcY .filter_content__1GQOk .filter_clear__2mfzY .filter_button__2W5Up:hover {
  background: linear-gradient(
    191deg,
    #00b3ec -91.84%,
    #014170 25.42%,
    #8424d0 126.74%
  );
  color: #fff;
}
.filter_cardFilter__20KcY .filter_content__1GQOk .filter_inner__16J_x {
  margin-top: 25px;
  width: 200px;
  height: 280px;
  overflow-y: auto;
}
.filter_cardFilter__20KcY .filter_content__1GQOk .filter_inner__16J_x .filter_checkboxGroup__3TERZ .filter_checkboxGroupHeader__2O_c8 {
  position: relative;
  color: #1a1a1a;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  margin-top: 10px;
  margin-bottom: 10px;
  cursor: pointer;
  width: 100%;
}
.filter_cardFilter__20KcY .filter_content__1GQOk .filter_inner__16J_x .filter_checkboxGroup__3TERZ .filter_checkboxGroupHeader__2O_c8::after {
  position: absolute;
  content: "˅";
  top: -2px;
  right: 0;
  font-size: 25px;
  font-weight: 250;
}
.filter_cardFilter__20KcY .filter_content__1GQOk .filter_inner__16J_x .filter_checkboxGroup__3TERZ.filter_active__P3ZR2 .filter_checkboxGroupHeader__2O_c8::after {
  top: 0px;
  font-size: 28px;
  content: "^";
}
.filter_cardFilter__20KcY .filter_content__1GQOk .filter_inner__16J_x .filter_checkboxGroup__3TERZ .filter_option__2z_tp {
  display: none;
  margin-right: 5px;
  margin-left: 5px;
  margin-bottom: 8px;
}
.filter_cardFilter__20KcY .filter_content__1GQOk .filter_inner__16J_x .filter_checkboxGroup__3TERZ.filter_active__P3ZR2 .filter_option__2z_tp {
  display: flex;
  grid-gap: 5px;
  gap: 5px;
  align-items: center;
}
.filter_cardFilter__20KcY .filter_content__1GQOk .filter_inner__16J_x .filter_checkboxGroup__3TERZ .filter_option__2z_tp .filter_checkboxLabel__dTWgO {
  padding-top: 2px;
  color: #1a1a1a;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-left: 5px;
  margin-bottom: 0px;
  overflow: hidden;
}
.filter_cardFilter__20KcY .filter_content__1GQOk .filter_inner__16J_x .filter_radioGroup__18DrL .filter_radioGroupHeader__7swRG {
  position: relative;
  color: #555;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  margin: 5px 0px;
}
.filter_cardFilter__20KcY .filter_content__1GQOk .filter_inner__16J_x .filter_radioGroup__18DrL .filter_radioGroupHeader__7swRG::after {
  position: absolute;
  content: "˅";
  top: -5px;
  right: 0;
  font-size: 30px;
  font-weight: 200;
}
.filter_cardFilter__20KcY .filter_content__1GQOk .filter_inner__16J_x .filter_radioGroup__18DrL.filter_active__P3ZR2 .filter_radioGroupHeader__7swRG::after {
  content: "^";
}
.filter_cardFilter__20KcY .filter_content__1GQOk .filter_inner__16J_x .filter_radioGroup__18DrL .filter_radio__2-oOE {
  display: none;
  margin-right: 5px;
}
.filter_cardFilter__20KcY .filter_content__1GQOk .filter_inner__16J_x .filter_radioGroup__18DrL.filter_active__P3ZR2 .filter_radio__2-oOE {
  display: inline-block;
}
.filter_cardFilter__20KcY .filter_content__1GQOk .filter_inner__16J_x .filter_radioGroup__18DrL .filter_radioLabel__2V340 {
  display: none;
  color: #555;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  vertical-align: end;
}
.filter_cardFilter__20KcY .filter_content__1GQOk .filter_inner__16J_x .filter_radioGroup__18DrL.filter_active__P3ZR2 .filter_radioLabel__2V340 {
  display: inline-block;
}
.filter_cardFilter__20KcY .filter_content__1GQOk .filter_inner__16J_x .filter_inputGroup__3bSP7 .filter_inputGroupHeader__2xUoj {
  position: relative;
  color: #555;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  margin: 5px 0px;
}
.filter_cardFilter__20KcY .filter_content__1GQOk .filter_inner__16J_x .filter_inputGroup__3bSP7 .filter_inputGroupHeader__2xUoj::after {
  position: absolute;
  content: "˅";
  top: -5px;
  right: 0;
  font-size: 30px;
  font-weight: 200;
}
.filter_cardFilter__20KcY .filter_content__1GQOk .filter_inner__16J_x .filter_inputGroup__3bSP7.filter_active__P3ZR2 .filter_inputGroupHeader__2xUoj::after {
  content: "^";
}
.filter_cardFilter__20KcY .filter_content__1GQOk .filter_inner__16J_x .filter_inputGroup__3bSP7 .filter_input__2gxdc {
  display: none;
  max-height: 26px;
  border-radius: 4px;
  border: 1px solid #555;
  font-size: 14px;
}
.filter_cardFilter__20KcY .filter_content__1GQOk .filter_inner__16J_x .filter_inputGroup__3bSP7 .filter_input__2gxdc::placeholder {
  color: #b1b1b1;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.filter_cardFilter__20KcY .filter_content__1GQOk .filter_inner__16J_x .filter_inputGroup__3bSP7.filter_active__P3ZR2 .filter_input__2gxdc {
  display: block;
}
.filter_cardFilter__20KcY .filter_content__1GQOk .filter_inner__16J_x .filter_betweenGroup__20Dm5 .filter_betweenGroupHeader__2F2Oy {
  position: relative;
  color: #555;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  margin: 5px 0px;
}
.filter_cardFilter__20KcY .filter_content__1GQOk .filter_inner__16J_x .filter_betweenGroup__20Dm5 .filter_betweenGroupHeader__2F2Oy::after {
  position: absolute;
  content: "˅";
  top: -5px;
  right: 0;
  font-size: 30px;
  font-weight: 200;
}
.filter_cardFilter__20KcY .filter_content__1GQOk .filter_inner__16J_x .filter_betweenGroup__20Dm5.filter_active__P3ZR2 .filter_betweenGroupHeader__2F2Oy::after {
  content: "^";
}
.filter_cardFilter__20KcY .filter_content__1GQOk .filter_inner__16J_x .filter_betweenGroup__20Dm5 .filter_betweenInputs__Bx-NX {
  display: none;
  grid-gap: 10px;
  gap: 10px;
}
.filter_cardFilter__20KcY .filter_content__1GQOk .filter_inner__16J_x .filter_betweenGroup__20Dm5 .filter_betweenInputs__Bx-NX .filter_input__2gxdc {
  max-height: 26px;
  border-radius: 4px;
  border: 1px solid #555;
  font-size: 14px;
}
.filter_cardFilter__20KcY .filter_content__1GQOk .filter_inner__16J_x .filter_betweenGroup__20Dm5 .filter_betweenInputs__Bx-NX .filter_input__2gxdc::placeholder {
  color: #b1b1b1;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.filter_cardFilter__20KcY .filter_content__1GQOk .filter_inner__16J_x .filter_betweenGroup__20Dm5.filter_active__P3ZR2 .filter_betweenInputs__Bx-NX {
  display: flex;
}

@media screen and (max-width: 1200px) {
  .filter_cardFilter__20KcY {
    height: 310px;
    margin-bottom: 20px;
  }
  .filter_cardFilter__20KcY .filter_content__1GQOk .filter_inner__16J_x {
    height: 100px;
    width: calc(100vw - 60px);
    overflow-y: auto;
  }
  .filter_cardFilter__20KcY .filter_content__1GQOk .filter_inner__16J_x .filter_checkboxGroup__3TERZ .filter_checkboxGroupHeader__2O_c8 {
    width: calc(100vw - 60px);
  }
}

.search_search__3gp0S {
  display: flex;
  justify-content: flex-start;
  grid-gap: 10px;
  gap: 10px;
  width: 100%;
  filter: drop-shadow(1px 1px 8px rgba(0, 0, 0, 0.15));
  background-color: #fff;
  border-radius: 20px;
  padding-left: 22px;
}
.search_search__3gp0S .search_icon__KT4B8 {
  margin-top: 10px;
  margin-bottom: 10px;
  min-width: 22px;
}
.search_search__3gp0S .search_input__kKIKi {
  width: 100%;
  color: #646464;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  border: none;
  vertical-align: middle;
}
.search_search__3gp0S .search_input__kKIKi::placeholder {
  color: #e1e1e1;
  line-height: 18px;
  vertical-align: middle;
}

.search_search__3gp0S .search_input__kKIKi:focus-visible {
  outline: none;
}
.search_search__3gp0S .search_button__34dar {
  position: relative;
  min-width: 65px;
  border-radius: 0 20px 20px 20px;
  border: none;
  background: linear-gradient(
    191deg,
    #00b3ec -91.84%,
    #014170 25.42%,
    #8424d0 126.74%
  );
}
.search_search__3gp0S .search_button__34dar::after {
  position: absolute;
  content: "";
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  visibility: hidden;
  background-color: #fff;
  opacity: 0.3;
  border-radius: 0 20px 20px 20px;
}
.search_search__3gp0S .search_button__34dar:hover::after {
  visibility: visible;
}

.pagination_pages__1oydQ {
  display: flex;
  justify-content: center;
  align-items: center;
  grid-gap: 8px;
  gap: 8px;
  color: #555;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-top: 4px;
  width: 100%;
}
.pagination_pages__1oydQ .pagination_prev__2qF2f {
  cursor: pointer;
  background: linear-gradient(
    191deg,
    #00b3ec -91.84%,
    #014170 25.42%,
    #8424d0 126.74%
  );
  width: 6px;
  height: 10px;
  top: 1px;
  left: calc(100% - 4px);
  clip-path: polygon(100% 100%, 0 50%, 100% 0);
}
.pagination_pages__1oydQ .pagination_numbers__14yZ_ {
  display: flex;
  grid-gap: 6px;
  gap: 6px;
}
.pagination_pages__1oydQ .pagination_numbers__14yZ_ .pagination_number__1YigO {
  cursor: pointer;
}
.pagination_pages__1oydQ .pagination_numbers__14yZ_ .pagination_current__1ZUEJ {
  cursor: default;
  font-weight: 600;
  color: #6610f2;
}
.pagination_pages__1oydQ .pagination_next__3ZEXv {
  cursor: pointer;
  background: linear-gradient(
    191deg,
    #00b3ec -91.84%,
    #014170 25.42%,
    #8424d0 126.74%
  );
  width: 6px;
  height: 10px;
  top: 1px;
  left: calc(100% - 4px);
  clip-path: polygon(0 0, 100% 50%, 0 100%);
}

.results_info__YeOR6 .results_totals__qAirQ {
  display: flex;
  flex-wrap: wrap;
  grid-gap: 5px;
  gap: 5px;
  margin-top: 10px;
  font-size: 15px;
  font-weight: 400;
  line-height: 18px;
  text-align: left;
  color: #696969;
}
.results_results__VNW00 {
  margin-top: 10px;
  display: flex;
  flex-wrap: wrap;
  grid-gap: 20px;
  gap: 20px;
  width: 100%;
}
.results_results__VNW00 .results_result__2XcYH {
  position: relative;
  display: flex;
  color: #555;
  width: 100%;
}
.results_results__VNW00 .results_result__2XcYH .results_card__3nLfi {
  cursor: default;
  border-radius: 20px;
  background: #fff;
  filter: drop-shadow(1px 1px 4px rgba(0, 0, 0, 0.15));
  padding: 12px 20px;
  color: #555;
  width: 100%;
}
.results_results__VNW00 .results_result__2XcYH .results_card__3nLfi .results_city__2xOJm {
  width: 100%;
  font-size: 13px;
  font-weight: 400;
  line-height: 16px;
  text-align: left;
}
.results_results__VNW00 .results_result__2XcYH .results_card__3nLfi .results_info__YeOR6 {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.results_results__VNW00 .results_result__2XcYH .results_card__3nLfi .results_info__YeOR6 .results_about__37tQB {
  display: flex;
  align-items: center;
}
.results_results__VNW00 .results_result__2XcYH .results_card__3nLfi .results_info__YeOR6 .results_about__37tQB img {
  width: 75px;
  height: 48px;
  object-fit: contain;
}
.results_results__VNW00 .results_result__2XcYH .results_card__3nLfi .results_info__YeOR6 .results_about__37tQB .results_title__2tLHl {
  width: 400px;
  font-size: 16px;
  font-weight: 400;
  line-height: 20px;
  text-align: left;
  color: #1a1a1a;
  -webkit-line-clamp: 2;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
}
.results_results__VNW00 .results_result__2XcYH .results_card__3nLfi .results_info__YeOR6 .results_toCompany__30O8_ {
  font-size: 11px;
  font-weight: 400;
  line-height: 13.5px;
  text-align: left;
  text-decoration: none;
  color: #1a1a1a;
}
.results_results__VNW00 .results_result__2XcYH .results_card__3nLfi .results_info__YeOR6 .results_toCompany__30O8_ svg {
  margin-left: 10px;
  margin-bottom: 2px;
  fill: #1a1a1a;
}
.results_results__VNW00 .results_result__2XcYH .results_card__3nLfi .results_specialities__2WCRL {
  margin-top: 10px;
}
.results_results__VNW00 .results_result__2XcYH .results_card__3nLfi .results_specialities__2WCRL .results_title__2tLHl {
  display: flex;
  flex-wrap: wrap;
  grid-gap: 5px;
  gap: 5px;
  font-size: 13px;
  font-weight: 400;
  line-height: 16px;
  text-align: left;
  color: #1a1a1a;
  margin-bottom: 10px;
}
.results_results__VNW00 .results_result__2XcYH .results_card__3nLfi .results_specialities__2WCRL .results_title__2tLHl .results_colored__2te-M {
  background: linear-gradient(
    191deg,
    #00b3ec -91.84%,
    #014170 25.42%,
    #8424d0 126.74%
  );
  background-size: cover;
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.results_speciality__3PL1A + .results_speciality__3PL1A {
  margin-top: 5px;
}
.results_results__VNW00 .results_result__2XcYH .results_card__3nLfi .results_specialities__2WCRL .results_speciality__3PL1A {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: #f5f5f5;
  border-radius: 5px;
  padding: 5px 15px;
}
.results_results__VNW00 .results_result__2XcYH .results_card__3nLfi .results_specialities__2WCRL .results_speciality__3PL1A .results_name__2yKUC {
  -webkit-line-clamp: 1;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
  font-size: 13px;
  font-weight: 400;
  line-height: 16px;
  text-align: left;
  color: #1a1a1a;
  max-width: 250px;
}
.results_results__VNW00 .results_result__2XcYH .results_card__3nLfi .results_specialities__2WCRL .results_speciality__3PL1A .results_props__3xVHM {
  display: flex;
  align-items: center;
}
.results_results__VNW00 .results_result__2XcYH .results_card__3nLfi .results_specialities__2WCRL .results_speciality__3PL1A .results_props__3xVHM .results_prop__hTBF7 {
  position: relative;
  justify-content: space-between;
  background-color: #fff;
  padding: 4px 10px;
  display: flex;
  align-items: center;
  color: #1a1a1a;
  width: 150px;
}
.results_results__VNW00 .results_result__2XcYH .results_card__3nLfi .results_specialities__2WCRL .results_speciality__3PL1A .results_props__3xVHM .results_prop__hTBF7 .results_name__2yKUC {
  font-size: 10px;
  font-weight: 400;
  line-height: 10px;
  text-align: left;
}
.results_results__VNW00 .results_result__2XcYH .results_card__3nLfi .results_specialities__2WCRL .results_speciality__3PL1A .results_props__3xVHM .results_prop__hTBF7 .results_value__1l5ab {
  margin-left: 5px;
  font-size: 13px;
  font-weight: 400;
  line-height: 15.73px;
  text-align: left;
}
.results_results__VNW00 .results_result__2XcYH .results_card__3nLfi .results_specialities__2WCRL .results_speciality__3PL1A .results_props__3xVHM .results_prop__hTBF7 .results_separator__1U1NO {
  position: absolute;
  border: 1px solid #f5f5f5;
  height: 12px;
  right: -1px;
}
.results_results__VNW00 .results_result__2XcYH .results_card__3nLfi .results_specialities__2WCRL .results_speciality__3PL1A .results_props__3xVHM .results_go__Z1I-I {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 20px;
  border-radius: 25px;
  background: linear-gradient(
    191deg,
    #00b3ec -91.84%,
    #014170 25.42%,
    #8424d0 126.74%
  );
  padding: 7px 10px;
}
.results_results__VNW00 .results_result__2XcYH .results_card__3nLfi .results_specialities__2WCRL .results_speciality__3PL1A .results_props__3xVHM .results_go__Z1I-I svg {
  fill: #fff;
  width: 20px;
  height: 10px;
}

.results_results__VNW00 .results_result__2XcYH .results_title__2tLHl {
  font-size: 13.5px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  -webkit-line-clamp: 3;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
}
.results_more__2SYyV {
  display: flex;
  justify-content: center;
  text-decoration: none;
}
.results_more__2SYyV .results_all__r7j0s {
  color: #000;
  font-size: 10px;
  font-weight: 300;
  line-height: 12px;
  text-align: left;
  cursor: pointer;
  position: relative;
  margin-top: 10px;
  padding: 5px 20px;
  box-shadow: 1px 1px 8px 0px rgba(0, 0, 0, 0.15);
  border-radius: 20px;
  text-decoration: none;
}
.results_more__2SYyV .results_all__r7j0s::before {
  content: "";
  position: absolute;
  background: linear-gradient(
    191deg,
    #00b3ec -91.84%,
    #014170 25.42%,
    #8424d0 126.74%
  );
  width: 6px;
  height: 16px;
  top: calc(50% - 8px);
  left: 100%;
  clip-path: polygon(0 12.5%, 100% 50%, 0 87.5%);
}
.results_full__xbyH2 {
  width: 100%;
  display: flex;
  justify-content: center;
}
@media screen and (max-width: 1200px) {
  .results_results__VNW00 .results_result__2XcYH .results_card__3nLfi .results_info__YeOR6 {
    display: block;
  }
  .results_results__VNW00 .results_result__2XcYH .results_card__3nLfi .results_info__YeOR6 .results_about__37tQB .results_title__2tLHl {
    width: 100%;
  }
  .results_results__VNW00 .results_result__2XcYH .results_card__3nLfi .results_specialities__2WCRL .results_speciality__3PL1A {
    display: block;
    padding: 10px;
  }
  .results_results__VNW00 .results_result__2XcYH .results_card__3nLfi .results_specialities__2WCRL .results_speciality__3PL1A .results_name__2yKUC {
    margin-bottom: 10px;
  }
  .results_results__VNW00 .results_result__2XcYH .results_card__3nLfi .results_specialities__2WCRL .results_speciality__3PL1A .results_props__3xVHM {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
  }
  .results_results__VNW00 .results_result__2XcYH .results_card__3nLfi .results_specialities__2WCRL .results_speciality__3PL1A .results_props__3xVHM .results_prop__hTBF7 {
    width: 100%;
  }
  .results_results__VNW00 .results_result__2XcYH .results_card__3nLfi .results_specialities__2WCRL .results_speciality__3PL1A .results_props__3xVHM .results_prop__hTBF7 .results_name__2yKUC {
    margin-bottom: 0px;
  }
  .results_results__VNW00 .results_result__2XcYH .results_card__3nLfi .results_specialities__2WCRL .results_speciality__3PL1A .results_props__3xVHM .results_prop__hTBF7 .results_separator__1U1NO {
    display: none;
  }
  .results_results__VNW00 .results_result__2XcYH .results_card__3nLfi .results_specialities__2WCRL .results_speciality__3PL1A .results_props__3xVHM .results_go__Z1I-I {
    margin-left: 0px;
    width: 50px;
    margin-top: 10px;
  }
}

.page_block__1ecN0 {
  padding: 12px;
}
@media screen and (max-width: 1200px) {
  .page_block__1ecN0 {
    padding: 0px;
  }
}

.sidebar_sidebarWrapper__1leb_ {
  position: absolute;
  width: auto;
  height: 100vh;
  overflow: visible;
}

@media (max-width: 900px) {
  .sidebar_sidebarMenu__3DJPi {
    background-color: var(--sidebar-bg-color);
    width: var(--sidebar-active-width);
    height: 100vh;
    overflow-y: auto;
    display: block;
    position: absolute;
    margin: 0 0 0 calc(var(--sidebar-active-width) * -1);
    transition: 150ms;
    z-index: 999;
  }

  .sidebar_sidebarMenu__3DJPi .sidebar_background__3Sr_F {
    position: absolute;
    background-color: var(--sidebar-bg-color);
    height: 100%;
    width: var(--sidebar-active-width);
    z-index: -1;
    transition: 150ms;
  }

  .sidebar_sidebarMenu__3DJPi.sidebar_active__3updx {
    margin: 0 0 0 0;
    transition: 150ms;
  }

  .sidebar_sidebarLogo__27ppH {
    display: none;
  }

  .sidebar_overlay__25rqA {
    z-index: 998;
    position: absolute;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
  }
}

@media (min-width: 900px) {
  .sidebar_sidebarMenu__3DJPi {
    position: absolute;
    background-color: transparent;
    width: auto;
    height: 100vh;
    overflow-y: auto;
    overflow-x: hidden;
    display: block;
    float: left;
    transition: 150ms;
  }

  .sidebar_sidebarMenu__3DJPi .sidebar_background__3Sr_F {
    position: fixed;
    background-color: var(--sidebar-bg-color);
    height: 100%;
    width: var(--sidebar-width);
    z-index: -1;
    transition: 150ms;
  }
  .sidebar_sidebarMenu__3DJPi .sidebar_sidebarText__mMxaS:hover {
    position: relative;
    background-color: var(--sidebar-bg-color);
    width: calc(var(--sidebar-active-width) + var(--sidebar-width) - 30px);
    z-index: 1000;
  }
  .sidebar_sidebarMenu__3DJPi .sidebar_sidebarText__mMxaS:hover a span {
    color: var(--sidebar-text-color-active);
    display: block;
    margin-left: 40px;
  }
  .sidebar_sidebarMenu__3DJPi .sidebar_sidebarText__mMxaS:hover a svg {
    fill: var(--sidebar-text-color-active);
  }
  .sidebar_sidebarMenu__3DJPi .sidebar_hasSubNav__3inHN:hover {
    width: calc(var(--sidebar-active-width) + var(--sidebar-width) - 30px);
    z-index: 1000;
  }
  .sidebar_sidebarMenu__3DJPi .sidebar_hasSubNav__3inHN:hover div {
    background-color: var(--sidebar-bg-color);
    width: 100%;
  }
  .sidebar_sidebarMenu__3DJPi .sidebar_hasSubNav__3inHN:hover .sidebar_title__2Mp7v svg {
    fill: var(--sidebar-text-color-active);
  }
  .sidebar_sidebarMenu__3DJPi .sidebar_hasSubNav__3inHN:hover .sidebar_title__2Mp7v span {
    margin-left: 40px;
    color: var(--sidebar-text-color-active);
    display: block;
  }
  .sidebar_sidebarMenu__3DJPi .sidebar_hasSubNav__3inHN:hover svg:nth-child(2) {
    fill: var(--sidebar-text-color-active);
    display: block;
  }
  .sidebar_sidebarMenu__3DJPi .sidebar_hasSubNav__3inHN:hover .sidebar_subNav__35569 {
    background-color: var(--sidebar-bg-color);
    position: absolute;
    display: block;
    top: 40px;
    left: 45px;
    padding-left: 18px;
    width: var(--sidebar-active-width);
  }
  .sidebar_sidebarMenu__3DJPi .sidebar_hasSubNav__3inHN:hover .sidebar_subNav__35569 span {
    margin-left: 0px;
    display: block;
  }
  .sidebar_sidebarMenu__3DJPi.sidebar_active__3updx {
    width: var(--sidebar-active-width);
    transition: 150ms;
  }
  .sidebar_sidebarMenu__3DJPi.sidebar_active__3updx .sidebar_background__3Sr_F {
    width: var(--sidebar-active-width);
    transition: 150ms;
  }
  .sidebar_sidebarMenu__3DJPi.sidebar_active__3updx .sidebar_sidebarText__mMxaS span {
    display: block;
  }
  .sidebar_sidebarMenu__3DJPi.sidebar_active__3updx .sidebar_sidebarText__mMxaS svg:nth-child(2) {
    display: block;
  }
  .sidebar_sidebarMenu__3DJPi.sidebar_active__3updx .sidebar_hasSubNav__3inHN:hover {
    width: auto;
  }
  .sidebar_sidebarMenu__3DJPi.sidebar_active__3updx .sidebar_hasSubNav__3inHN:hover .sidebar_subNav__35569 {
    background-color: transparent;
    position: relative;
    display: none;
    top: 0px;
    left: 0px;
    width: auto;
    padding-left: 0px;
  }
  .sidebar_sidebarMenu__3DJPi.sidebar_active__3updx .sidebar_sidebarText__mMxaS:hover {
    background-color: transparent;
  }
  .sidebar_sidebarMenu__3DJPi.sidebar_active__3updx .sidebar_sidebarText__mMxaS:hover a span {
    margin-left: 0px;
  }
  .sidebar_sidebarMenu__3DJPi.sidebar_active__3updx .sidebar_hasSubNav__3inHN:hover div {
    background-color: transparent;
  }
  .sidebar_sidebarMenu__3DJPi.sidebar_active__3updx .sidebar_hasSubNav__3inHN:hover div span {
    margin-left: 0px;
  }
  .sidebar_sidebarText__mMxaS span {
    display: none;
  }
  .sidebar_sidebarText__mMxaS svg:nth-child(2) {
    display: none;
  }
  .sidebar_sidebarLogo__27ppH {
    margin-top: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: 150ms;
    text-decoration: none;
  }
  .sidebar_sidebarMenu__3DJPi .sidebar_sidebarLogo__27ppH {
    width: var(--sidebar-width);
  }
  .sidebar_sidebarMenu__3DJPi.sidebar_active__3updx .sidebar_sidebarLogo__27ppH {
    width: var(--sidebar-active-width);
  }
  .sidebar_sidebarMenu__3DJPi .sidebar_sidebarLogo__27ppH .sidebar_iconSmall__1SQwP {
    display: block;
  }
  .sidebar_sidebarMenu__3DJPi.sidebar_active__3updx .sidebar_sidebarLogo__27ppH .sidebar_iconSmall__1SQwP {
    display: none;
  }
  .sidebar_sidebarMenu__3DJPi .sidebar_sidebarLogo__27ppH .sidebar_iconBig__2QWVy {
    display: none;
  }
  .sidebar_sidebarMenu__3DJPi.sidebar_active__3updx .sidebar_sidebarLogo__27ppH .sidebar_iconBig__2QWVy {
    display: flex;
    flex-wrap: wrap;
    color: #fff;
    text-wrap: wrap;
    text-transform: uppercase;
    font-weight: 800;
    line-height: 22px;
    text-align: center;
  }
}
.sidebar_sidebarText__mMxaS.sidebar_active__3updx {
  color: var(--sidebar-text-color-active);
}
.sidebar_sidebarText__mMxaS.sidebar_active__3updx a {
  color: var(--sidebar-text-color-active);
}
.sidebar_sidebarText__mMxaS.sidebar_active__3updx svg {
  fill: var(--sidebar-text-color-active);
}
.sidebar_sidebarSeparator__1Cwcx {
  color: var(--sidebar-text-color);
  padding: 0.625rem 1.5rem;
  letter-spacing: 0.05em;
  cursor: default;
  font-size: 11px;
  text-transform: uppercase;
  font-weight: 600;
}
.sidebar_sidebarSeparator__1Cwcx .sidebar_icon__2Vw2j {
  color: var(--sidebar-text-color) !important;
}
.sidebar_sidebarMenu__3DJPi .sidebar_sidebarSeparator__1Cwcx {
  display: flex;
  justify-content: center;
  width: var(--sidebar-width);
}
.sidebar_sidebarMenu__3DJPi .sidebar_sidebarSeparator__1Cwcx .sidebar_title__2Mp7v {
  display: none;
}
.sidebar_sidebarMenu__3DJPi .sidebar_sidebarSeparator__1Cwcx .sidebar_icon__2Vw2j {
  display: block;
  width: 20px;
  height: 20px;
}
.sidebar_sidebarMenu__3DJPi.sidebar_active__3updx .sidebar_sidebarSeparator__1Cwcx {
  display: block;
  width: var(--sidebar-active-width);
}
.sidebar_sidebarMenu__3DJPi.sidebar_active__3updx .sidebar_sidebarSeparator__1Cwcx .sidebar_title__2Mp7v {
  display: block;
}
.sidebar_sidebarMenu__3DJPi.sidebar_active__3updx .sidebar_sidebarSeparator__1Cwcx .sidebar_icon__2Vw2j {
  display: none;
}
.sidebar_sidebarText__mMxaS {
  text-decoration: none;
  color: var(--sidebar-text-color);
  font-size: 0.9375rem;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  padding: 0.625rem 1.5rem;
  border-radius: 4px;
  cursor: pointer;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  user-select: none;
}
.sidebar_sidebarText__mMxaS a {
  text-decoration: none;
  color: var(--sidebar-text-color);
  font-size: 0.9375rem;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  border-radius: 4px;
  cursor: pointer;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  user-select: none;
  min-height: 24px;
}
.sidebar_sidebarText__mMxaS div {
  display: flex;
  align-items: center;
  margin-right: auto;
  min-height: 24px;
}
.sidebar_sidebarText__mMxaS svg {
  fill: var(--sidebar-text-color);
  font-size: 18px;
  line-height: inherit;
  min-width: 1.75rem;
  text-align: start;
}
.sidebar_sidebarMenuItems__2Hgn0 {
  width: 100%;
  padding: 0;
}
.sidebar_hasSubNav__3inHN {
  position: relative;
}
.sidebar_hasSubNav__3inHN .sidebar_subNav__35569 {
  display: none;
}
.sidebar_sidebarMenu__3DJPi.sidebar_active__3updx .sidebar_hasSubNav__3inHN.sidebar_active__3updx .sidebar_subNav__35569 {
  display: block;
}
.sidebar_sidebarMenu__3DJPi.sidebar_active__3updx .sidebar_hasSubNav__3inHN.sidebar_active__3updx svg:nth-child(2) {
  transform: rotate(180deg);
}
.sidebar_sidebarMenu__3DJPi.sidebar_active__3updx .sidebar_hasSubNav__3inHN.sidebar_active__3updx .sidebar_title__2Mp7v span {
  color: var(--sidebar-text-color-active);
}
.sidebar_sidebarMenu__3DJPi.sidebar_active__3updx .sidebar_hasSubNav__3inHN.sidebar_active__3updx .sidebar_title__2Mp7v svg {
  fill: var(--sidebar-text-color-active);
}
.sidebar_sidebarMenu__3DJPi.sidebar_active__3updx .sidebar_hasSubNav__3inHN.sidebar_active__3updx svg:nth-child(2) {
  fill: var(--sidebar-text-color-active);
}

.hamburger_icon__2YByy {
  width: 20px;
  height: 14px;
  position: relative;
  cursor: pointer;
  display: inline-block;
}
.hamburger_icon__2YByy span {
  background-color: #878a99;
  position: absolute;
  border-radius: 2px;
  transition: 0.3s cubic-bezier(0.8, 0.5, 0.2, 1.4);
  width: 100%;
  height: 2px;
  display: block;
  left: 0;
}
.hamburger_icon__2YByy span:nth-child(1) {
  top: 0;
  width: 80%;
}
.hamburger_icon__2YByy span:nth-child(2) {
  top: 6px;
}
.hamburger_icon__2YByy span:nth-child(3) {
  bottom: 0;
  width: 60%;
}
.hamburger_icon__2YByy.hamburger_open__39F2I {
  transform: rotate(-90deg);
}
.hamburger_icon__2YByy.hamburger_open__39F2I span:nth-child(1) {
  left: 1px;
  top: 5px;
  width: 20px;
  transform: rotate(90deg);
  transition-delay: 150ms;
}
.hamburger_icon__2YByy.hamburger_open__39F2I span:nth-child(2) {
  left: 3px;
  top: 13px;
  width: 10px;
  transform: rotate(45deg);
  transition-delay: 50ms;
}
.hamburger_icon__2YByy.hamburger_open__39F2I span:nth-child(3) {
  left: 9px;
  top: 13px;
  width: 10px;
  transform: rotate(-45deg);
  transition-delay: 0.1s;
}

.navbar_navbar__1Dcto {
  display: flex;
  justify-content: space-between;
  align-items: center;
  list-style: none;
  background-color: var(--navbar-bg-color);
  height: var(--navbar-height);
  color: aliceblue;
  border-bottom: 1px solid #e1e1e1;
}
.navbar_navbar__1Dcto .navbar_burger__1DvQO {
  margin-left: 1.5rem;
  font-size: 1.5rem;
  background: none;
}

.navbar_navItem__1EQrs {
  display: flex;
  justify-content: center;
  align-items: center;
  color: var(--navbar-text-color);
  text-align: center;
  background-color: #f3f3f9;
  list-style-type: none;
  padding: 0.5rem 1rem;
  height: 100%;
}
.navbar_navItem__1EQrs svg {
  color: var(--navbar-text-color) !important;
  margin-right: 5px;
}

.navbar_dropdown-menu__1mSbP[data-bs-popper] {
  margin-top: 0 !important;
}

.navbar_dropdown-menu__1mSbP.navbar_show__1QBw- {
  right: 0 !important;
  left: auto !important;
  z-index: 9999;
}
.navbar_dropdownToggle__3WY7j {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  text-align: start;
}
.navbar_dropdownToggle__3WY7j .navbar_roles__MB-8i {
  font-size: 14px;
  font-weight: 400;
  opacity: 0.8;
  max-height: 20px;
  overflow: hidden;
}
.navbar_dropdownToggle__3WY7j .navbar_roles__MB-8i .navbar_role__O_6DJ {
  transform: translateY(calc(-100% + 20px));
}
.navbar_dropdownToggle__3WY7j .navbar_roles__MB-8i .navbar_role__O_6DJ.navbar_active__1QvIb {
  transform: translateY(0);
}
@keyframes navbar_scrollDown__q7lWs {
  from {
    transform: translateY(0);
  }
  to {
    transform: translateY(calc(-100% + 20px));
  }
}
@keyframes navbar_scrollTop__1Djd_ {
  from {
    transform: translateY(calc(-100% + 20px));
  }
  to {
    transform: translateY(0);
  }
}
.navbar_dropdownToggle__3WY7j::after {
  display: none !important;
  border: 0 !important;
}
@media (max-width: 900px) {
  .navbar_desktop__nniqZ {
    display: none;
  }
  .navbar_mobile__34O4T {
    display: block;
  }
}
@media (min-width: 900px) {
  .navbar_desktop__nniqZ {
    display: block;
  }
  .navbar_mobile__34O4T {
    display: none;
  }
}
.navbar_select__1SPjP {
  width: 30vh;
}

@media (min-width: 900px) {
  .breadCrumb_box__3gv5k {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
}
.breadCrumb_box__3gv5k {
  padding: 10px 1.5rem;
  background-color: var(--navbar-bg-color);
  box-shadow: 0 1px 2px rgba(56, 65, 74, 0.15);
  border-bottom: 1px solid none;
  border-top: 1px solid none;
}
.breadCrumb_box__3gv5k h4 {
  font-weight: 700;
  font-size: 15px !important;
  text-transform: uppercase;
  color: var(--navbar-text-color);
}
.breadCrumb_box__3gv5k .breadCrumb_item__3ngfv a{
  color: var(--navbar-text-color);
  outline: none !important;
  text-decoration: none;
}
.breadCrumb_box__3gv5k .breadCrumb_item__3ngfv.breadCrumb_active__3D8vk {
  color: #878a99;
}
.breadCrumb_box__3gv5k .breadCrumb_item__3ngfv + .breadCrumb_item__3ngfv::before {
  float: left;
  padding: 0 0.5rem;
  color: #878a99;
  content: ">";
  font-size: 15px;
  line-height: 24px;
}

.layout_layout__1q3XX {
  --navbar-height: 60px;
  --navbar-bg-color: #fff;
  --navbar-text-color: #495057;
  --sidebar-width: 75px;
  --sidebar-active-width: 250px;
  --sidebar-bg-color: #405189;
  --sidebar-text-color: #abb9e8;
  --sidebar-text-color-active: #fff;
  --content-bg: #f3f3f9;
  --border-color: #e9ebec;
  --heading-color: #495057;
  --text-primary-rgb: 64, 81, 137;
  --text-opacity: 1;
}

@media (max-width: 900px) {
  .layout_content__2l_Qp {
    background-color: var(--content-bg);
    overflow-y: auto !important;
    min-height: calc(var(--vh, 1vh) * 100);
    max-height: calc(var(--vh, 1vh) * 100);
  }
}

@media (min-width: 900px) {
  .layout_content__2l_Qp {
    background-color: var(--content-bg);
    overflow-y: auto !important;
    min-height: calc(var(--vh, 1vh) * 100);
    margin-left: var(--sidebar-width);
    transition: 150ms;
  }

  .layout_content__2l_Qp.layout_sidebarActive__2kzTj {
    margin-left: var(--sidebar-active-width);
    transition: 150ms;
  }
}

.layout_badge__2YZ_f {
  font-weight: 600;
  line-height: 1.4;
  padding: 3px 6px;
  font-size: 12px;
  font-weight: 600;
  transition: all 0.3s ease-out;
  -webkit-transition: all 0.3s ease-out;
}

.layout_badge__2YZ_f:hover {
  transition: all 0.3s ease-out;
  -webkit-transition: all 0.3s ease-out;
  transform: translateY(-3px);
}
.personal h1,
h2,
h3,
h4,
h5,
h6 {
  color: var(--heading-color) !important;
}
.personal h6 {
  font-size: 0.875rem;
}
.personal .dropdown-menu {
  border: 0 solid #fff;
  color: #212529;
  font-weight: 400;
  font-size: 0.8125rem;
  border-radius: 0.3rem;
  box-shadow: 0 5px 10px rgba(30, 32, 37, 0.12);
}
.personal .dropdown-divider {
  border-top: 1px solid #e9ebec;
}
.personal .card {
  margin-bottom: 1.5rem;
  box-shadow: 0 1px 2px rgba(56, 65, 74, 0.15);
  border: 0 solid #fff;
}
.personal .card-header {
  background-color: #fff;
  padding: 16px;
  border-bottom: 1px solid #e9ebec;
}
.personal .card-title {
  font-size: 16px;
  margin: 0 0 7px 0;
}
.personal .card-body {
  border-radius: 0.2rem;
}
.personal table th,
td {
  font-size: 14px;
}
.personal table th {
  font-weight: 600;
}
.personal .text-primary {
  color: rgba(var(--text-primary-rgb), var(--text-opacity)) !important;
}
.personal p {
  font-size: 0.875rem;
  font-weight: 500;
  color: #878a99;
}
.personal .profile-nav.nav-pills .nav-link {
  color: rgba(255, 255, 255, 0.8);
  font-weight: 500;
}
.personal .profile-nav.nav-pills .nav-link::before {
  background-color: rgba(255, 255, 255, 0.1);
}
.personal .animation-nav li a.active,
.animation-nav li a:hover {
  color: #fff;
  background-color: transparent !important;
}
.personal .animation-nav li a {
  color: var(--vz-body-color);
  border-radius: 4px;
  position: relative;
  overflow: hidden;
  transition: all 0.4s;
  z-index: 1;
}
.personal .animation-nav li a::before {
  content: "";
  position: absolute;
  top: 0;
  width: 0;
  right: 0;
  height: 100%;
  transition: width 0.4s cubic-bezier(0.51, 0.18, 0, 0.88) 0.1s;
  background-color: #405189;
  z-index: -1;
}
.personal .animation-nav li a.active::before,
.animation-nav li a:hover::before {
  width: 100%;
  left: 0;
}
.rdt_TableBody {
  min-height: 200px;
}

.personal .ck-editor__main .ck-editor__editable {
  min-height: calc(100vh - 400px);
}
.company-select-mobile__control {
  border: none !important;
  border-radius: 0 !important;
  height: 35px !important;
}
.company-select__control {
  border: none !important;
  border-radius: 0 !important;
  height: 44px !important;
}

.login_block__ZNKLL {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  min-height: calc(100vh - 320px);
}
.login_block__ZNKLL .login_card__14abU {
  width: 300px;
  height: 300px;
}
.login_block__ZNKLL .login_card__14abU .login_inner__MxpsL {
  height: 100%;
  padding: 20px;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-content: space-between;
  grid-gap: 15px;
  gap: 15px;
}
.login_block__ZNKLL .login_card__14abU .login_inner__MxpsL .login_title__WA6Mb {
  color: #555;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}
.login_block__ZNKLL .login_card__14abU .login_inner__MxpsL .login_formGroup__3dwfm {
  position: relative;
}
.login_block__ZNKLL .login_card__14abU .login_inner__MxpsL .login_formGroup__3dwfm .login_input__1OuCS {
  width: 100%;
  border-radius: 20px;
  border: 1px solid #e1e1e1;
  padding: 10px 20px;
}
.login_block__ZNKLL .login_card__14abU .login_inner__MxpsL .login_formGroup__3dwfm .login_input__1OuCS:focus-visible {
  outline: none;
}
.login_block__ZNKLL .login_card__14abU .login_inner__MxpsL .login_formGroup__3dwfm .login_showPassword__2ovyH {
  position: absolute;
  right: 15px;
  top: calc(50% - 2px);
  transform: translateY(-50%);
  cursor: pointer;
}
.login_block__ZNKLL .login_card__14abU .login_inner__MxpsL .login_formGroup__3dwfm .login_showPassword__2ovyH svg {
  width: 20px;
  height: 20px;
}
.login_block__ZNKLL .login_card__14abU .login_inner__MxpsL .login_formGroup__3dwfm .login_inputError__N12q- {
  width: 100%;
  border-radius: 20px;
  border: 1px solid #ec4141;
  padding: 10px 20px;
}
.login_block__ZNKLL .login_card__14abU .login_inner__MxpsL .login_formGroup__3dwfm .login_inputError__N12q-:focus-visible {
  outline: none;
}
.login_block__ZNKLL .login_card__14abU .login_inner__MxpsL .login_formGroup__3dwfm .login_errorMessage__ao4s1 {
  position: absolute;
  font-size: 12px;
  color: #ec4141;
}
.login_block__ZNKLL .login_card__14abU .login_inner__MxpsL .login_submit__2XYsm {
  position: relative;
  display: flex;
  width: 100%;
  height: 40px;
  justify-content: center;
}
.login_block__ZNKLL .login_card__14abU .login_inner__MxpsL .login_submit__2XYsm .login_btn__2WxoR {
  cursor: pointer;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #fff;
  padding: 11px 30px;
  border-radius: 19.5px;
  border: none;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  max-width: 125px;
}
.login_block__ZNKLL .login_card__14abU .login_inner__MxpsL .login_submit__2XYsm .login_btn__2WxoR::before {
  content: "";
  background-image: linear-gradient(170deg, #0072ff 15.81%, #29cd8f 100.43%);
  top: -1px;
  left: -1px;
  bottom: -1px;
  right: -1px;
  position: absolute;
  z-index: -1;
  border-radius: 20px;
}
.login_block__ZNKLL .login_card__14abU .login_inner__MxpsL .login_submit__2XYsm .login_btn__2WxoR:hover {
  background: linear-gradient(
    191deg,
    #00b3ec -91.84%,
    #014170 25.42%,
    #8424d0 126.74%
  );
  color: #ffffff;
}
.login_block__ZNKLL .login_card__14abU .login_inner__MxpsL .login_submit__2XYsm .login_submitError__2cUEL {
  font-size: 12px;
  line-height: 16px;
  color: #ec4141;
  position: absolute;
  bottom: -30px;
  overflow: hidden;
  height: 30px;
  text-align: center;
}
.login_block__ZNKLL .login_card__14abU .login_inner__MxpsL .login_addition__1_CYW {
  margin-top: 15px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  color: #555;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.login_block__ZNKLL .login_card__14abU .login_inner__MxpsL .login_link__20y9l {
  color: #555;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.register_block__3cnY0 {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  min-height: calc(100vh - 320px);
}
.register_block__3cnY0 .register_card__1HVs_ {
  width: 300px;
  height: auto;
}
.register_block__3cnY0 .register_card__1HVs_ .register_inner__2g0Ij {
  height: 100%;
  padding: 20px;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-content: space-between;
  grid-gap: 15px;
  gap: 15px;
}
.register_block__3cnY0 .register_card__1HVs_ .register_inner__2g0Ij .register_title__1zWms {
  color: #555;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}
.register_block__3cnY0 .register_card__1HVs_ .register_inner__2g0Ij .register_formGroup__2SttC {
  position: relative;
  width: 100%;
  padding: 0 10px;
}
.register_block__3cnY0 .register_card__1HVs_ .register_inner__2g0Ij .register_formGroup__2SttC .register_input__NWm1A {
  width: 100%;
  border-radius: 20px;
  border: 1px solid #e1e1e1;
  padding: 10px 20px;
}
.register_block__3cnY0 .register_card__1HVs_ .register_inner__2g0Ij .register_formGroup__2SttC .register_input__NWm1A:focus-visible {
  outline: none;
}
.register_block__3cnY0 .register_card__1HVs_ .register_inner__2g0Ij .register_formGroup__2SttC .register_inputError__3R_mD {
  width: 100%;
  border-radius: 20px;
  border: 1px solid #ec4141;
  padding: 10px 20px;
}
.register_block__3cnY0 .register_card__1HVs_ .register_inner__2g0Ij .register_formGroup__2SttC .register_inputError__3R_mD:focus-visible {
  outline: none;
}
.register_block__3cnY0 .register_card__1HVs_ .register_inner__2g0Ij .register_formGroup__2SttC .register_select__1Hty7 > div:nth-child(3) {
  border: 1px solid #e1e1e1;
  box-shadow: none;
}
.register_block__3cnY0 .register_card__1HVs_ .register_inner__2g0Ij .register_formGroup__2SttC .register_selectError__1Ctyu > div:nth-child(3) {
  border: 1px solid #ec4141;
  box-shadow: none;
}
.register_block__3cnY0 .register_card__1HVs_ .register_inner__2g0Ij .register_formGroup__2SttC .register_errorMessage__h6bq5 {
  position: absolute;
  font-size: 12px;
  color: #ec4141;
}
.register_block__3cnY0 .register_card__1HVs_ .register_inner__2g0Ij .register_submit__3mlFj {
  position: relative;
  display: flex;
  width: 100%;
  height: 40px;
  justify-content: center;
}
.register_block__3cnY0 .register_card__1HVs_ .register_inner__2g0Ij .register_submit__3mlFj .register_btn__z1-Ve {
  cursor: pointer;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #fff;
  padding: 11px 30px;
  border-radius: 19.5px;
  border: none;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  max-width: 200px;
}
.register_block__3cnY0 .register_card__1HVs_ .register_inner__2g0Ij .register_submit__3mlFj .register_btn__z1-Ve::before {
  content: "";
  background-image: linear-gradient(170deg, #0072ff 15.81%, #29cd8f 100.43%);
  top: -1px;
  left: -1px;
  bottom: -1px;
  right: -1px;
  position: absolute;
  z-index: -1;
  border-radius: 20px;
}
.register_block__3cnY0 .register_card__1HVs_ .register_inner__2g0Ij .register_submit__3mlFj .register_btn__z1-Ve:hover {
  background: linear-gradient(
    191deg,
    #00b3ec -91.84%,
    #014170 25.42%,
    #8424d0 126.74%
  );
  color: #ffffff;
}
.register_block__3cnY0 .register_card__1HVs_ .register_inner__2g0Ij .register_submit__3mlFj .register_submitError__3Y44i {
  font-size: 12px;
  line-height: 16px;
  color: #ec4141;
  position: absolute;
  bottom: -30px;
  overflow: hidden;
  height: 30px;
  text-align: center;
}
.register_block__3cnY0 .register_card__1HVs_ .register_inner__2g0Ij .register_addition__3beRt {
  margin-top: 15px;
  width: 100%;
  display: flex;
  justify-content: center;
  color: #555;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.register_block__3cnY0 .register_card__1HVs_ .register_inner__2g0Ij .register_link__rFd1S {
  color: #555;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.forgot_block__3Y4p1 {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  min-height: calc(100vh - 320px);
}
.forgot_block__3Y4p1 .forgot_card__1Zb9p {
  width: 300px;
  height: 230px;
}
.forgot_block__3Y4p1 .forgot_card__1Zb9p .forgot_inner__38EwK {
  height: 100%;
  padding: 20px;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-content: space-between;
  grid-gap: 15px;
  gap: 15px;
}
.forgot_block__3Y4p1 .forgot_card__1Zb9p .forgot_inner__38EwK .forgot_title__2UyOu {
  color: #555;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}
.forgot_block__3Y4p1 .forgot_card__1Zb9p .forgot_inner__38EwK .forgot_formGroup__xqtS2 {
  position: relative;
}
.forgot_block__3Y4p1 .forgot_card__1Zb9p .forgot_inner__38EwK .forgot_formGroup__xqtS2 .forgot_input__yjAoK {
  width: 100%;
  border-radius: 20px;
  border: 1px solid #e1e1e1;
  padding: 10px 20px;
}
.forgot_block__3Y4p1 .forgot_card__1Zb9p .forgot_inner__38EwK .forgot_formGroup__xqtS2 .forgot_input__yjAoK:focus-visible {
  outline: none;
}
.forgot_block__3Y4p1 .forgot_card__1Zb9p .forgot_inner__38EwK .forgot_formGroup__xqtS2 .forgot_inputError__XvZiD {
  width: 100%;
  border-radius: 20px;
  border: 1px solid #ec4141;
  padding: 10px 20px;
}
.forgot_block__3Y4p1 .forgot_card__1Zb9p .forgot_inner__38EwK .forgot_formGroup__xqtS2 .forgot_inputError__XvZiD:focus-visible {
  outline: none;
}
.forgot_block__3Y4p1 .forgot_card__1Zb9p .forgot_inner__38EwK .forgot_formGroup__xqtS2 .forgot_errorMessage__2UQCF {
  position: absolute;
  font-size: 12px;
  color: #ec4141;
}
.forgot_block__3Y4p1 .forgot_card__1Zb9p .forgot_inner__38EwK .forgot_submit__2s_G3 {
  position: relative;
  display: flex;
  width: 100%;
  height: 40px;
  justify-content: center;
}
.forgot_block__3Y4p1 .forgot_card__1Zb9p .forgot_inner__38EwK .forgot_submit__2s_G3 .forgot_btn__DFhrq {
  cursor: pointer;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #fff;
  padding: 11px 30px;
  border-radius: 19.5px;
  border: none;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  max-width: 125px;
}
.forgot_block__3Y4p1 .forgot_card__1Zb9p .forgot_inner__38EwK .forgot_submit__2s_G3 .forgot_btn__DFhrq::before {
  content: "";
  background-image: linear-gradient(170deg, #0072ff 15.81%, #29cd8f 100.43%);
  top: -1px;
  left: -1px;
  bottom: -1px;
  right: -1px;
  position: absolute;
  z-index: -1;
  border-radius: 20px;
}
.forgot_block__3Y4p1 .forgot_card__1Zb9p .forgot_inner__38EwK .forgot_submit__2s_G3 .forgot_btn__DFhrq:hover {
  background: linear-gradient(
    191deg,
    #00b3ec -91.84%,
    #014170 25.42%,
    #8424d0 126.74%
  );
  color: #ffffff;
}
.forgot_block__3Y4p1 .forgot_card__1Zb9p .forgot_inner__38EwK .forgot_submit__2s_G3 .forgot_submitError__1N3ij {
  font-size: 12px;
  line-height: 16px;
  color: #ec4141;
  position: absolute;
  bottom: -20px;
  overflow: hidden;
  height: 16px;
  text-align: center;
}
.forgot_block__3Y4p1 .forgot_card__1Zb9p .forgot_inner__38EwK .forgot_submit__2s_G3 .forgot_submitSuccess__us9bg {
  font-size: 12px;
  line-height: 16px;
  color: #41ec58;
  position: absolute;
  bottom: -20px;
  overflow: hidden;
  height: 16px;
  text-align: center;
}
.forgot_block__3Y4p1 .forgot_card__1Zb9p .forgot_inner__38EwK .forgot_addition__2GOeq {
  width: 100%;
  display: flex;
  justify-content: center;
  color: #555;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.forgot_block__3Y4p1 .forgot_card__1Zb9p .forgot_inner__38EwK .forgot_link__1rSad {
  color: #555;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.newPassword_block__2H8um {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  min-height: calc(100vh - 320px);
}
.newPassword_block__2H8um .newPassword_card__2ayRf {
  width: 300px;
  height: 300px;
}
.newPassword_block__2H8um .newPassword_card__2ayRf .newPassword_inner__3gL1G {
  height: 100%;
  padding: 20px;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-content: space-between;
  grid-gap: 15px;
  gap: 15px;
}
.newPassword_block__2H8um .newPassword_card__2ayRf .newPassword_inner__3gL1G .newPassword_title__pjiEg {
  color: #555;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}
.newPassword_block__2H8um .newPassword_card__2ayRf .newPassword_inner__3gL1G .newPassword_formGroup__3WowJ {
  position: relative;
}
.newPassword_block__2H8um .newPassword_card__2ayRf .newPassword_inner__3gL1G .newPassword_formGroup__3WowJ .newPassword_input__1o0DQ {
  width: 100%;
  border-radius: 20px;
  border: 1px solid #e1e1e1;
  padding: 10px 20px;
}
.newPassword_block__2H8um .newPassword_card__2ayRf .newPassword_inner__3gL1G .newPassword_formGroup__3WowJ .newPassword_input__1o0DQ:focus-visible {
  outline: none;
}
.newPassword_block__2H8um .newPassword_card__2ayRf .newPassword_inner__3gL1G .newPassword_formGroup__3WowJ .newPassword_inputError__1rdmH {
  width: 100%;
  border-radius: 20px;
  border: 1px solid #ec4141;
  padding: 10px 20px;
}
.newPassword_block__2H8um .newPassword_card__2ayRf .newPassword_inner__3gL1G .newPassword_formGroup__3WowJ .newPassword_inputError__1rdmH:focus-visible {
  outline: none;
}
.newPassword_block__2H8um .newPassword_card__2ayRf .newPassword_inner__3gL1G .newPassword_formGroup__3WowJ .newPassword_errorMessage__1bPHh {
  position: absolute;
  font-size: 12px;
  color: #ec4141;
}
.newPassword_block__2H8um .newPassword_card__2ayRf .newPassword_inner__3gL1G .newPassword_submit__dDOeB {
  position: relative;
  display: flex;
  width: 100%;
  height: 40px;
  justify-content: center;
}
.newPassword_block__2H8um .newPassword_card__2ayRf .newPassword_inner__3gL1G .newPassword_submit__dDOeB .newPassword_btn__30D-F {
  cursor: pointer;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #fff;
  padding: 11px 30px;
  border-radius: 19.5px;
  border: none;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  max-width: 125px;
}
.newPassword_block__2H8um .newPassword_card__2ayRf .newPassword_inner__3gL1G .newPassword_submit__dDOeB .newPassword_btn__30D-F::before {
  content: "";
  background-image: linear-gradient(170deg, #0072ff 15.81%, #29cd8f 100.43%);
  top: -1px;
  left: -1px;
  bottom: -1px;
  right: -1px;
  position: absolute;
  z-index: -1;
  border-radius: 20px;
}
.newPassword_block__2H8um .newPassword_card__2ayRf .newPassword_inner__3gL1G .newPassword_submit__dDOeB .newPassword_btn__30D-F:hover {
  background: linear-gradient(
    191deg,
    #00b3ec -91.84%,
    #014170 25.42%,
    #8424d0 126.74%
  );
  color: #ffffff;
}
.newPassword_block__2H8um .newPassword_card__2ayRf .newPassword_inner__3gL1G .newPassword_submit__dDOeB .newPassword_submitError__2Cjaa {
  font-size: 12px;
  line-height: 16px;
  color: #ec4141;
  position: absolute;
  bottom: -20px;
  overflow: hidden;
  height: 16px;
  text-align: center;
}
.newPassword_block__2H8um .newPassword_card__2ayRf .newPassword_inner__3gL1G .newPassword_submit__dDOeB .newPassword_submitSuccess__126Fo {
  font-size: 12px;
  line-height: 16px;
  color: #41ec58;
  position: absolute;
  bottom: -20px;
  overflow: hidden;
  height: 16px;
  text-align: center;
}
.newPassword_block__2H8um .newPassword_card__2ayRf .newPassword_inner__3gL1G .newPassword_addition__1JNXg {
  width: 100%;
  display: flex;
  justify-content: center;
  color: #555;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.newPassword_block__2H8um .newPassword_card__2ayRf .newPassword_inner__3gL1G .newPassword_link__1wrSC {
  color: #555;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.header_block__3Vj68 {
  margin-bottom: 50px;
}
.header_block__3Vj68 .header_foreground__2Q3wD {
  position: relative;
}
.header_block__3Vj68 .header_foreground__2Q3wD .header_background__3txqV {
  margin: -1.5rem;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  height: 320px;
}
.header_block__3Vj68 .header_foreground__2Q3wD .header_background__3txqV::before {
  content: "";
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  opacity: 0.9;
  background: #405189;
  background: linear-gradient(to top, #171e32, #405189);
}
.header_block__3Vj68 .header_info__231jK {
  position: relative;
  display: flex;
  grid-gap: 1.5rem;
  gap: 1.5rem;
}
.header_block__3Vj68 .header_info__231jK .header_icon__1XOEH {
  height: 6rem;
  width: 6rem;
}
.header_block__3Vj68 .header_info__231jK .header_icon__1XOEH svg {
  background-color: #f3f3f9;
  border-radius: 50%;
  width: 100%;
  height: auto;
}
.header_block__3Vj68 .header_info__231jK .header_description__1qepe .header_fio__2_Rej {
  color: #fff !important;
}
.header_block__3Vj68 .header_info__231jK .header_profession__2Pmtg {
  color: rgba(255, 255, 255, 0.75) !important;
}

.buttonDanger_custom__dZS7s {
  vertical-align: middle;
  background-color: #f06548;
  border-color: #f06548;
  font-size: 0.8rem;
  padding: 0.5rem 0.9rem;
  border-radius: 0.25rem;
}
.buttonDanger_custom__dZS7s svg {
  margin-right: 5px;
}
.buttonDanger_custom__dZS7s:hover {
  background-color: #cc563d;
  border-color: #c0513a;
}
.buttonDanger_custom__dZS7s:active {
  background-color: #c0513a !important;
  border-color: #b44c36;
  box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
}

.buttonSuccess_custom__1g1uc {
  vertical-align: middle;
  background-color: #0ab39c;
  border-color: #0ab39c;
  font-size: 0.8rem;
  padding: 0.5rem 0.9rem;
  border-radius: 0.25rem;
}
.buttonSuccess_custom__1g1uc svg {
  margin-right: 5px;
}
.buttonSuccess_custom__1g1uc:hover {
  background-color: #099885;
  border-color: #088f7d;
}
.buttonSuccess_custom__1g1uc:active {
  background-color: #088f7d !important;
  border-color: #088675;
  box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
}

.content_block__1P9aB {
  position: relative;
  z-index: 2;
}
.content_block__1P9aB .content_tabItem__3aE1C {
  color: rgba(255, 255, 255, 0.8);
}

.customModal_modal__3IioE > * {
  border: 0 !important;
  border-radius: 0.25rem;
}
.customModal_header__3wQki {
  background-color: rgba(41, 156, 219, 0.18) !important;
  padding: 0.8rem;
  border-top-left-radius: 0.25rem;
  border-top-right-radius: 0.25rem;
}
.customModal_header__3wQki .customModal_title__yBYUL {
  color: #495057;
  font-weight: 600;
  font-size: 1.2rem !important;
}
.customModal_body__1cu0s .customModal_label__2kHha {
  margin-bottom: 0.5rem;
  font-weight: 600;
  font-size: 0.75rem;
}
.customModal_body__1cu0s .customModal_input__3YEfX {
  border-radius: 0.25rem;
  font-size: 0.8rem;
  padding: 0.5rem 0.9rem;
  font-weight: 400;
  color: #212529;
}
.customModal_body__1cu0s .customModal_inputCheckbox__315dh {
  display: block;
  border-radius: 0.25rem;
  font-size: 2.32rem;
  padding: 0.5rem 0.5rem;
  font-weight: 400;
  color: #212529;
  margin-top: 0;
}
.customModal_body__1cu0s .customModal_invalid__uNBe7 {
  font-size: 0.7em;
  display: block !important;
}
.customModal_body__1cu0s .customModal_checkboxGroup__AVbOX {
  max-height: 100px;
  overflow-y: auto;
  border: 1px solid #dee2e6;
  border-radius: 0.25rem;
  padding: 0.25rem;
}
.customModal_footer__TeKQ6 {
  border-top: 0;
}
.customModal_footer__TeKQ6 .customModal_close__KZ_xe {
  border-radius: 0.25rem;
  font-size: 14px;
}
.customModal_footer__TeKQ6 .customModal_submit__XCR_3 {
  border-radius: 0.25rem;
  border: 0 !important;
  background-color: #0ab39c;
  font-size: 14px;
}
.customModal_footer__TeKQ6 .customModal_submit__XCR_3:hover {
  color: #fff;
  background-color: #099885;
  border-color: #088f7d;
}

.loadBlock_block__362EM {
  position: absolute;
  top: 0;
  left: 0;
  background: #405189;
  opacity: 0.8;
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 10;
  border-radius: 0.25rem;
  animation: loadBlock_fadeIn__3TksR 0.3s ease-in-out;
}

@keyframes loadBlock_fadeIn__3TksR {
  from {
    opacity: 0;
  }
  to {
    opacity: 0.8;
  }
}

.contactPersonTable_list__HXmLv {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  width: 240px;
  height: 50px;
  vertical-align: middle;
  overflow: auto;
  margin: 0;
  padding-left: 0;
}
.contactPersonTable_list__HXmLv li {
  width: 100%;
  white-space: nowrap;
}
.contactPersonTable_active__1U6vE td {
  background-color: #abb9e8;
}
.contactPersonTable_active__1U6vE .contactPersonTable_settings__2sOSA .contactPersonTable_main___dbxw {
  border: 1px solid white !important;
}
.contactPersonTable_active__1U6vE .contactPersonTable_settings__2sOSA .contactPersonTable_main___dbxw:hover {
  background-color: #405189 !important;
}
.contactPersonTable_active__1U6vE .contactPersonTable_settings__2sOSA .contactPersonTable_icon__3Sib0 {
  stroke: white !important;
}

.table_status__39ibf {
  font-size: 0.7rem;
}

.table_status__z4hg_ {
  font-size: 0.7rem;
}

.description_messages__1TT7r {
  overflow: auto;
}
.description_message__1VJ5W .description_author__3pWLO {
  font-size: 0.9rem;
}
.description_message__1VJ5W .description_author__3pWLO .description_date__3AnZu {
  margin-left: 5px;
}
.description_btnSend__Gsp-1 {
  border-radius: 0.25rem;
  border: 0 !important;
  background-color: #0ab39c;
  font-size: 14px;
}
.description_btnSend__Gsp-1:hover {
  color: #fff;
  background-color: #099885;
  border-color: #088f7d;
}
.description_attachment__3yLmo {
  position: relative;
  cursor: pointer;
}
.description_attachment__3yLmo .description_badge__AbLRF {
  position: absolute;
  font-size: 10px;
  top: -5px;
  right: -5px;
  background-color: #0ab39c;
  color: #fff;
  border-radius: 50%;
  padding: 1px 5px;
}
.description_attachment__3yLmo svg {
  width: 25px;
  height: 25px;
}

.detail_link__312ez {
  color: #405189;
  text-decoration: none;
}
.detail_btnAddFiles__21dRn {
  display: flex;
  padding: 0.3rem;
}
.detail_btnAddFiles__21dRn svg {
  margin-right: 0;
}

.allTable_search__1RCUY {
  display: flex;
  grid-column-gap: 5px;
  column-gap: 5px;
  padding: 0px 15px;
}
.allTable_search__1RCUY input {
  font-size: 14px;
  width: calc(100% - 50px);
  border-radius: 5px;
  border: 1px solid #e1e1e1;
  padding: 0px 15px;
}
.allTable_search__1RCUY .allTable_btn__2P1gC {
  width: 50px;
  padding: 5px 10px;
}
.allTable_table__3Hyy- {
  width: 100%;
  font-size: 14px;
}
.allTable_table__3Hyy- .allTable_header__i9qB9 .allTable_main__1Fr2O {
  padding-left: 15px;
  width: 85%;
}
.allTable_table__3Hyy- .allTable_header__i9qB9 .allTable_info__27ip1 {
  text-align: center;
  width: 10%;
}
.allTable_table__3Hyy- .allTable_header__i9qB9 .allTable_opened__Y8Rbs {
  width: 5%;
  padding-right: 15px;
}
.allTable_table__3Hyy- tr {
  border-bottom: 1px solid #e1e1e1;
}
.allTable_table__3Hyy- .allTable_row__1NPA1 .allTable_main__1Fr2O {
  display: flex !important;
  align-items: center;
  width: auto;
  min-height: 45px;
  padding-left: 15px;
}
.allTable_table__3Hyy- .allTable_row__1NPA1 .allTable_info__27ip1 {
  position: relative;
  width: 10%;
  text-align: center;
}
.allTable_table__3Hyy- .allTable_row__1NPA1 .allTable_info__27ip1::before {
  position: absolute;
  top: calc(50% - 10px);
  left: 0;
  width: 1px;
  height: 20px;
  content: "";
  background-color: #e1e1e1;
}
.allTable_table__3Hyy- .allTable_row__1NPA1 .allTable_opened__Y8Rbs {
  position: relative;
  width: 5%;
  font-size: 20px;
  font-weight: 200;
  text-align: center;
  padding-left: 10px;
  padding-right: 15px;
}
.allTable_table__3Hyy- .allTable_row__1NPA1 .allTable_opened__Y8Rbs::before {
  position: absolute;
  top: calc(50% - 10px);
  left: 0;
  width: 1px;
  height: 20px;
  content: "";
  background-color: #e1e1e1;
}
.allTable_table__3Hyy- .allTable_row__1NPA1.allTable_choosed__2rzGE {
  color: #fff;
}
.allTable_table__3Hyy- .allTable_row__1NPA1.allTable_choosed__2rzGE td {
  background-color: #405189;
}
.allTable_table__3Hyy- .allTable_row__1NPA1.allTable_choosed__2rzGE .allTable_info__27ip1::before {
  background-color: #fff;
}
.allTable_table__3Hyy- .allTable_row__1NPA1.allTable_choosed__2rzGE .allTable_opened__Y8Rbs::before {
  background-color: #fff;
}

.allTable_search__oOEYZ {
  display: flex;
  grid-column-gap: 5px;
  column-gap: 5px;
  padding: 0px 15px;
}
.allTable_search__oOEYZ input {
  font-size: 14px;
  width: calc(100% - 50px);
  border-radius: 5px;
  border: 1px solid #e1e1e1;
  padding: 0px 15px;
}
.allTable_search__oOEYZ .allTable_btn__1IP5P {
  width: 50px;
  padding: 5px 10px;
}
.allTable_table__2MUZL {
  width: 100%;
  font-size: 14px;
}
.allTable_table__2MUZL .allTable_header__3af8t .allTable_main__zDHoV {
  padding-left: 15px;
  width: 85%;
}
.allTable_table__2MUZL .allTable_header__3af8t .allTable_info__30cP1 {
  text-align: center;
  width: 10%;
}
.allTable_table__2MUZL .allTable_header__3af8t .allTable_opened__2Zugd {
  width: 5%;
  padding-right: 15px;
}
.allTable_table__2MUZL tr {
  border-bottom: 1px solid #e1e1e1;
}
.allTable_table__2MUZL .allTable_row__12OiX .allTable_main__zDHoV {
  display: flex !important;
  align-items: center;
  width: auto;
  min-height: 45px;
  padding-left: 15px;
}
.allTable_table__2MUZL .allTable_row__12OiX .allTable_info__30cP1 {
  position: relative;
  width: 10%;
  text-align: center;
}
.allTable_table__2MUZL .allTable_row__12OiX .allTable_info__30cP1::before {
  position: absolute;
  top: calc(50% - 10px);
  left: 0;
  width: 1px;
  height: 20px;
  content: "";
  background-color: #e1e1e1;
}
.allTable_table__2MUZL .allTable_row__12OiX .allTable_opened__2Zugd {
  position: relative;
  width: 5%;
  font-size: 20px;
  font-weight: 200;
  text-align: center;
  padding-left: 10px;
  padding-right: 15px;
}
.allTable_table__2MUZL .allTable_row__12OiX .allTable_opened__2Zugd::before {
  position: absolute;
  top: calc(50% - 10px);
  left: 0;
  width: 1px;
  height: 20px;
  content: "";
  background-color: #e1e1e1;
}
.allTable_table__2MUZL .allTable_row__12OiX.allTable_choosed__1vvRz {
  color: #fff;
}
.allTable_table__2MUZL .allTable_row__12OiX.allTable_choosed__1vvRz td {
  background-color: #405189;
}
.allTable_table__2MUZL .allTable_row__12OiX.allTable_choosed__1vvRz .allTable_info__30cP1::before {
  background-color: #fff;
}
.allTable_table__2MUZL .allTable_row__12OiX.allTable_choosed__1vvRz .allTable_opened__2Zugd::before {
  background-color: #fff;
}

.allTable_search__1Les_ {
  display: flex;
  grid-column-gap: 5px;
  column-gap: 5px;
  padding: 0px 15px;
  margin-bottom: 14px;
}
.allTable_search__1Les_ input {
  font-size: 14px;
  width: calc(100% - 50px);
  border-radius: 5px;
  border: 1px solid #e1e1e1;
  padding: 0px 15px;
}
.allTable_search__1Les_ .allTable_btn__1bz88 {
  width: 50px;
  padding: 5px 10px;
}
.allTable_table__XnXX1 {
  width: 100%;
  font-size: 14px;
}
.allTable_table__XnXX1 .allTable_header__1J23t .allTable_main__2b6AX {
  padding-left: 15px;
  width: 85%;
}
.allTable_table__XnXX1 .allTable_header__1J23t .allTable_info__1NAjQ {
  text-align: center;
  width: 10%;
}
.allTable_table__XnXX1 .allTable_header__1J23t .allTable_opened__2Vf1W {
  width: 5%;
  padding-right: 15px;
}
.allTable_table__XnXX1 tr {
  border-bottom: 1px solid #e1e1e1;
}
.allTable_table__XnXX1 .allTable_row__12DJD .allTable_main__2b6AX {
  display: flex !important;
  align-items: center;
  width: auto;
  min-height: 45px;
  padding-left: 15px;
}
.allTable_table__XnXX1 .allTable_row__12DJD .allTable_info__1NAjQ {
  position: relative;
  width: 10%;
  text-align: center;
}
.allTable_table__XnXX1 .allTable_row__12DJD .allTable_info__1NAjQ::before {
  position: absolute;
  top: calc(50% - 10px);
  left: 0;
  width: 1px;
  height: 20px;
  content: "";
  background-color: #e1e1e1;
}
.allTable_table__XnXX1 .allTable_row__12DJD .allTable_opened__2Vf1W {
  position: relative;
  width: 5%;
  font-size: 20px;
  font-weight: 200;
  text-align: center;
  padding-left: 10px;
  padding-right: 15px;
}
.allTable_table__XnXX1 .allTable_row__12DJD .allTable_opened__2Vf1W::before {
  position: absolute;
  top: calc(50% - 10px);
  left: 0;
  width: 1px;
  height: 20px;
  content: "";
  background-color: #e1e1e1;
}
.allTable_table__XnXX1 .allTable_row__12DJD.allTable_choosed__2DoB- {
  color: #fff;
}
.allTable_table__XnXX1 .allTable_row__12DJD.allTable_choosed__2DoB- td {
  background-color: #405189;
}
.allTable_table__XnXX1 .allTable_row__12DJD.allTable_choosed__2DoB- .allTable_info__1NAjQ::before {
  background-color: #fff;
}
.allTable_table__XnXX1 .allTable_row__12DJD.allTable_choosed__2DoB- .allTable_opened__2Vf1W::before {
  background-color: #fff;
}

.search_container__17ghV {
  position: absolute;
  top: 100%;
  background-color: #ffffff;
  border: 1px solid #dee2e6;
  padding: 5px;
}

.navbar_logo__FeueO {
  cursor: pointer;
}
.navbar_search-container__3F40T {
  display: flex;
  align-items: center;
  background-color: #ffffff;
  border: 1px solid #e1e1e1;
  border-radius: 30px;
}
.navbar_search-container__3F40T .navbar_icon__2_O4D {
  height: 22px;
  width: 22px;
  margin-left: 22.75px;
}
.navbar_search-container__3F40T .navbar_input__1ptE_ {
  background-color: #ffffff;
  border-radius: 30px;
  border: none;
  color: #646464;
  padding: 9px 10px 7px 12.75px;
  font-size: 15px;
  font-weight: 500;
  line-height: 18px;
  vertical-align: middle;
}

.navbar_search-container__3F40T .navbar_input__1ptE_::placeholder {
  color: #e1e1e1;
  line-height: 18px;
  vertical-align: middle;
}

.navbar_search-container__3F40T .navbar_input__1ptE_:focus-visible {
  outline: none;
}

.navbar_login-container__2dHC7 {
  display: flex;
  align-items: center;
  height: 100%;
  text-decoration: none;
}

.navbar_login-container__2dHC7 .navbar_icon__2_O4D {
  height: 30px;
  width: 30px;
  margin-right: 8px;
}

.navbar_login-container__2dHC7 .navbar_label__2Xbfh {
  font-size: 15px;
  line-height: 18px;
  font-weight: 400;
  color: #444444;
}
@media screen and (max-width: 1200px) {
  .navbar_search-container__3F40T {
    display: none;
  }
}

.footer_block__eekH5 {
  margin-top: 100px;
}
.footer_block__eekH5 .footer_social__30Ek- .footer_link__1g_mS {
  color: #000;
  text-decoration: none;
  margin-right: 5px;
  font-size: 16px;
}
.footer_social__30Ek- + .footer_social__30Ek- {
  margin-top: 5px;
}
@media screen and (max-width: 1200px) {
  .footer_copyright__2-1Mi {
    margin-left: 5px;
  }
  .footer_contacts__oXq_y {
    margin-right: 5px;
  }
}

.textFilter_container__1V_Ri {
  position: relative;
}
.textFilter_container__1V_Ri .textFilter_text__26wCg,
.textFilter_container__1V_Ri .textFilter_filter__3TsEu {
  background-position: center !important;
  background-size: cover !important;
  background-clip: text !important;
  -webkit-background-clip: text !important;
  -webkit-text-fill-color: transparent !important;
}
.textFilter_container__1V_Ri .textFilter_filter__3TsEu {
  position: absolute;
  left: 0;
  top: 0;
}

.chooser_container__27DU- {
  position: absolute;
  top: 50px;
  right: 0;
  border-radius: 10px;
}

.chooser_background-container__1auLn {
  position: absolute;
  right: 0px;
  top: -104px;
  z-index: -1;
}

.chooser_button__32Rkj {
  width: 55px;
  cursor: pointer;
  padding: 15px 15px 15px 15px;
  color: #ffffff;
  writing-mode: vertical-rl;
  text-align: center;
  justify-items: center;
  font-size: 15px;
  font-weight: 400;
}

.chooser_button__32Rkj.chooser_top__6rxvZ {
  margin-top: -40px;
  padding-top: 40px;
}

.chooser_button__32Rkj.chooser_bottom__3n4Gg {
  margin-bottom: -40px;
  padding-bottom: 40px;
}

.chooser_button__32Rkj:hover {
  background-color: rgba(255, 255, 255, 0.4);
  color: #014170;
}

.chooser_line__1meW9 {
  margin-left: 12.5px;
  width: 30px;
  height: 1px;
  background-color: #08FA99;
}

.button_button__19a0E {
  cursor: default;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #fff;
  padding: 11px 30px;
  border-radius: 19.5px;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;

  --active-bg: linear-gradient(
    191deg,
    #00ecb3 -91.84%,
    #1cd1bd 25.42%,
    #24d07d 126.74%
  );
  --active-color: #ffffff;
}
.button_button__19a0E::before {
  content: "";
  background-image: linear-gradient(170deg, #00ecb3 15.81%, #1cd1bd 100.43%);
  top: -1px;
  left: -1px;
  bottom: -1px;
  right: -1px;
  position: absolute;
  z-index: -1;
  border-radius: 20px;
}

.about_block__2PpeQ {
  position: relative;
  margin-top: 10px;
}

.about_content__1fgnn {
  display: flex;
}

.about_background__1OqyR {
  position: absolute;
  z-index: -1;
  top: -10px;
  left: -360px;
}

.about_info__wgSEn {
  margin-top: 95px;
  width: 575px;
}

.about_info__wgSEn .about_header__3zVMg {
  font-size: 101px;
  font-style: normal;
  font-weight: 900;
  line-height: 100px;
  text-transform: uppercase;
  margin: 0;
}

.about_info__wgSEn .about_header__3zVMg .about_text__2BZhm {
  background: url(/static/media/background-space.8699e94d.png),
    lightgray 0px -61.53px / 137.633% 239.84% no-repeat;
}

.about_info__wgSEn .about_header__3zVMg .about_filter__2itog {
  background: rgba(0, 236, 179, 0.4);
}

.about_info__wgSEn .about_subheader__32kQm {
  margin-top: 17px;
  font-size: 18px;
  font-weight: 400;
  line-height: 22px;
  color: #333333;
}

.about_info__wgSEn .about_subheader__32kQm span {
  position: relative;
  cursor: default;
}

.about_info__wgSEn .about_subheader__32kQm span:hover div {
  visibility: visible;
}

.about_info__wgSEn .about_description__2fjas {
  margin-top: 17px;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  color: #333333;
}

.about_info__wgSEn .about_more__1zrLT {
  margin-top: 17px;
  cursor: pointer;
  width: 177px;
  height: 47px;
  font-weight: 400;
  font-size: 15px;
  line-height: 18px;
  color: #555555;
}
.about_info__wgSEn .about_more__1zrLT:hover .about_fill__3hL7z,
.about_info__wgSEn .about_more__1zrLT .about_border__3XoYF,
.about_info__wgSEn .about_more__1zrLT .about_arrow__10Vl1 {
  background-image: linear-gradient(
    191.12deg,
    #00ecb3 -91.84%,
    #24c9bf 25.42%,
    #23c8b0 126.74%
  );
}
.about_card__3amPz {
  width: 460px;
  height: 450px;
}
.about_card__3amPz .about_border__3XoYF {
  background-image: linear-gradient(
    191.12deg,
    #00ecb3 -91.84%,
    #24c9bf 25.42%,
    #23c8b0 126.74%
  );
}
.about_card__3amPz .about_arrow__10Vl1 {
  background-image: linear-gradient(
    201.12deg,
    #00ecb3 -91.84%,
    #24c9bf 25.42%,
    #23c8b0 186.74%
  );
}
.about_hint__3QFN7 {
  visibility: hidden;
  position: absolute;
  z-index: 999;
  top: 0;
  left: 85%;
  min-width: 280px;
  max-width: 280px;
}
.about_hint__3QFN7 .about_description__2fjas {
  background: url(/static/media/about-background-hint.bfcbb6bd.svg);
  background-size: 100% auto;
  background-repeat: no-repeat;
  color: #555;
  text-align: center;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  padding: 22px 16px 20px 16px;
  word-wrap: break-word;
}
@media screen and (max-width: 1200px) {
  .about_content__1fgnn {
    display: block;
  }
  .about_info__wgSEn {
    width: 100%;
    margin-left: 5px;
    margin-top: 20px;
  }
  .about_info__wgSEn .about_header__3zVMg {
    font-size: 12vw;
    line-height: 14vw;
  }
  .about_info__wgSEn .about_subheader__32kQm {
    font-size: 1rem;
    width: calc(100vw - 75px);
  }
  .about_info__wgSEn .about_description__2fjas {
    width: calc(100vw - 75px);
  }
  .about_hint__3QFN7 {
    display: none;
  }
  .about_helper__3gtj3 {
    display: none;
  }
  .about_background__1OqyR {
    display: none;
  }
}
.filter_filter__3pbzb .filter_searchFor__U0YBY {
  display: flex;
  grid-gap: 10px;
  gap: 10px;
}
.filter_filter__3pbzb .filter_searchFor__U0YBY .filter_option__1Wl0B {
  cursor: pointer;
  border-radius: 8px;
}
.filter_filter__3pbzb .filter_searchFor__U0YBY .filter_option__1Wl0B::before {
  border-radius: 8px;
}
.filter_filter__3pbzb .filter_searchFor__U0YBY .filter_option__1Wl0B.filter_active__2gMXu {
  background: linear-gradient(
    191.12deg,
    #00ecb3 -91.84%,
    #24c9bf 25.42%,
    #23c8b0 126.74%
  );
  color: #fff;
}
.filter_filter__3pbzb .filter_submit__379FY {
  margin-top: 10px;
  width: 190px;
}
.filter_filter__3pbzb .filter_submit__379FY .filter_button__3vcth {
  border-radius: 8px;
  cursor: pointer;
}
.filter_filter__3pbzb .filter_submit__379FY .filter_button__3vcth::before {
  border-radius: 8px;
}
.filter_filter__3pbzb .filter_submit__379FY .filter_button__3vcth:hover {
  background: linear-gradient(
    191.12deg,
    #00ecb3 -91.84%,
    #24c9bf 25.42%,
    #23c8b0 126.74%
  );
  color: #fff;
}
.filter_filter__3pbzb .filter_clear__kFkj2 {
  margin-top: 10px;
  width: 190px;
}
.filter_filter__3pbzb .filter_clear__kFkj2 .filter_button__3vcth {
  border-radius: 8px;
  cursor: pointer;
}
.filter_filter__3pbzb .filter_clear__kFkj2 .filter_button__3vcth::before {
  border-radius: 8px;
}
.filter_filter__3pbzb .filter_clear__kFkj2 .filter_button__3vcth:hover {
  background: linear-gradient(
    191.12deg,
    #00ecb3 -91.84%,
    #24c9bf 25.42%,
    #23c8b0 126.74%
  );
  color: #fff;
}
.filter_filter__3pbzb .filter_inner__3vsTs {
  margin-top: 25px;
  width: 280px;
  height: 280px;
  overflow-y: auto;
}
.filter_filter__3pbzb .filter_inner__3vsTs .filter_checkboxGroup__3lETl .filter_checkboxGroupHeader__24Y-5 {
  position: relative;
  color: #1a1a1a;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  margin-top: 10px;
  margin-bottom: 10px;
  cursor: pointer;
  width: 270px;
}
.filter_filter__3pbzb .filter_inner__3vsTs .filter_checkboxGroup__3lETl .filter_checkboxGroupHeader__24Y-5::after {
  position: absolute;
  content: "˅";
  top: -2px;
  right: 0;
  font-size: 25px;
  font-weight: 250;
}
.filter_filter__3pbzb .filter_inner__3vsTs .filter_checkboxGroup__3lETl.filter_active__2gMXu .filter_checkboxGroupHeader__24Y-5::after {
  top: 0px;
  font-size: 28px;
  content: "^";
}
.filter_filter__3pbzb .filter_inner__3vsTs .filter_checkboxGroup__3lETl .filter_option__1Wl0B {
  display: none;
  margin-right: 5px;
  margin-left: 5px;
  margin-bottom: 8px;
}
.filter_filter__3pbzb .filter_inner__3vsTs .filter_checkboxGroup__3lETl.filter_active__2gMXu .filter_option__1Wl0B {
  display: flex;
  grid-gap: 5px;
  gap: 5px;
  align-items: center;
}
.filter_filter__3pbzb .filter_inner__3vsTs .filter_checkboxGroup__3lETl .filter_option__1Wl0B .filter_checkboxLabel__3GdfP {
  padding-top: 2px;
  color: #1a1a1a;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-left: 5px;
  margin-bottom: 0px;
}
@media screen and (max-width: 1200px) {
  .filter_filter__3pbzb .filter_inner__3vsTs {
    height: 100px;
    width: calc(100vw - 60px);
    overflow-y: auto;
  }
  .filter_filter__3pbzb .filter_inner__3vsTs .filter_checkboxGroup__3lETl .filter_checkboxGroupHeader__24Y-5 {
    width: calc(100vw - 60px);
  }
}

.search_cardSearch__1IlXc {
  width: 100%;
  height: auto;
}
.search_cardSearch__1IlXc .search_border__2SXH_ {
  background: linear-gradient(
    191.12deg,
    #00ecb3 -91.84%,
    #24c9bf 25.42%,
    #23c8b0 126.74%
  );
}
.search_cardSearch__1IlXc .search_arrow__3VxGr {
  background: linear-gradient(
    201deg,
    #00ecb3 -91.84%,
    #24c9bf 25.42%,
    #23c8b0 126.74%
  );
}
.search_cardSearch__1IlXc .search_inner__1S2yH {
  display: flex;
  grid-gap: 40px;
  gap: 40px;
  padding: 30px 29px 20px 29px;
}
.search_cardSearch__1IlXc .search_content__2-CY2 {
  width: 100%;
}
.search_cardSearch__1IlXc .search_content__2-CY2 .search_search__3P1PH {
  display: flex;
  justify-content: flex-start;
  grid-gap: 10px;
  gap: 10px;
  width: 100%;
  filter: drop-shadow(1px 1px 8px rgba(0, 0, 0, 0.15));
  background-color: rgba(239, 239, 239, 1);
  border-radius: 20px;
  padding-left: 22px;
}
.search_cardSearch__1IlXc .search_content__2-CY2 .search_search__3P1PH .search_icon__22ukH {
  margin-top: 10px;
  margin-bottom: 10px;
  min-width: 22px;
}
.search_cardSearch__1IlXc .search_content__2-CY2 .search_search__3P1PH .search_input__20Tb6 {
  width: 100%;
  color: #646464;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  border: none;
  vertical-align: middle;
  background-color: rgba(239, 239, 239, 1);
}
.search_cardSearch__1IlXc .search_content__2-CY2 .search_search__3P1PH .search_input__20Tb6::placeholder,
.search_cardSearch__1IlXc .search_content__2-CY2 .search_search__3P1PH .search_input__20Tb6::-webkit-input-placeholder {
  color: #000;
  line-height: 18px;
  vertical-align: middle;
}

.search_cardSearch__1IlXc .search_content__2-CY2 .search_search__3P1PH .search_input__20Tb6:focus-visible {
  outline: none;
}
.search_cardSearch__1IlXc .search_content__2-CY2 .search_search__3P1PH .search_button__27rW2 {
  position: relative;
  min-width: 65px;
  border-radius: 0 20px 20px 20px;
  border: none;
  background: linear-gradient(
    191.12deg,
    #00ecb3 -91.84%,
    #24c9bf 25.42%,
    #23c8b0 126.74%
  );
}
.search_cardSearch__1IlXc .search_content__2-CY2 .search_search__3P1PH .search_button__27rW2::after {
  position: absolute;
  content: "";
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  visibility: hidden;
  background-color: #fff;
  opacity: 0.3;
  border-radius: 0 20px 20px 20px;
}
.search_cardSearch__1IlXc .search_content__2-CY2 .search_search__3P1PH .search_button__27rW2:hover::after {
  visibility: visible;
}
.search_cardSearch__1IlXc .search_content__2-CY2 .search_info__1o63G {
  margin-top: 5px;
  display: flex;
  flex-wrap: wrap;
  align-content: space-between;
  width: 100%;
}
.search_cardSearch__1IlXc .search_content__2-CY2 .search_info__1o63G .search_totals__2cTF- {
  display: flex;
  flex-wrap: wrap;
  grid-gap: 5px;
  gap: 5px;
  margin-top: 10px;
  font-size: 15px;
  font-weight: 400;
  line-height: 18px;
  text-align: left;
  color: #696969;
}
.search_cardSearch__1IlXc .search_content__2-CY2 .search_results__AnICf {
  margin-top: 10px;
  display: flex;
  flex-wrap: wrap;
  grid-gap: 20px;
  gap: 20px;
  width: 100%;
}
.search_cardSearch__1IlXc .search_content__2-CY2 .search_results__AnICf .search_result__3i9sY {
  position: relative;
  display: flex;
  color: #555;
  width: 100%;
}
.search_cardSearch__1IlXc .search_content__2-CY2 .search_results__AnICf .search_result__3i9sY .search_card__3Z2jx {
  cursor: default;
  border-radius: 20px;
  background: #fff;
  filter: drop-shadow(1px 1px 4px rgba(0, 0, 0, 0.15));
  padding: 12px 20px;
  color: #555;
  width: 100%;
}
.search_cardSearch__1IlXc .search_content__2-CY2 .search_results__AnICf .search_result__3i9sY .search_card__3Z2jx .search_city__3N2xb {
  width: 100%;
  font-size: 13px;
  font-weight: 400;
  line-height: 16px;
  text-align: left;
}
.search_cardSearch__1IlXc .search_content__2-CY2 .search_results__AnICf .search_result__3i9sY .search_card__3Z2jx .search_info__1o63G {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.search_cardSearch__1IlXc .search_content__2-CY2 .search_results__AnICf .search_result__3i9sY .search_card__3Z2jx .search_info__1o63G .search_about__8jJe- {
  display: flex;
  align-items: center;
}
.search_cardSearch__1IlXc .search_content__2-CY2 .search_results__AnICf .search_result__3i9sY .search_card__3Z2jx .search_info__1o63G .search_about__8jJe- img {
  width: 75px;
  height: 48px;
  object-fit: contain;
}
.search_cardSearch__1IlXc .search_content__2-CY2 .search_results__AnICf .search_result__3i9sY .search_card__3Z2jx .search_info__1o63G .search_about__8jJe- .search_title__1t4AO {
  width: 400px;
  font-size: 16px;
  font-weight: 400;
  line-height: 20px;
  text-align: left;
  color: #1a1a1a;
  -webkit-line-clamp: 2;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
}
.search_cardSearch__1IlXc .search_content__2-CY2 .search_results__AnICf .search_result__3i9sY .search_card__3Z2jx .search_info__1o63G .search_toCompany__G1ghC {
  text-decoration: none;
  color: #1a1a1a;
  font-size: 11px;
  font-weight: 400;
  line-height: 13.5px;
  text-align: left;
}
.search_cardSearch__1IlXc .search_content__2-CY2 .search_results__AnICf .search_result__3i9sY .search_card__3Z2jx .search_info__1o63G .search_toCompany__G1ghC svg {
  margin-left: 10px;
  margin-bottom: 2px;
  fill: #1a1a1a;
}
.search_cardSearch__1IlXc .search_content__2-CY2 .search_results__AnICf .search_result__3i9sY .search_card__3Z2jx .search_actions__2AqmU{
  width: 100%;
}
.search_cardSearch__1IlXc .search_content__2-CY2 .search_results__AnICf .search_result__3i9sY .search_card__3Z2jx .search_actions__2AqmU .search_practices__2OyHZ {
  margin-top: 10px;
}
.search_cardSearch__1IlXc .search_content__2-CY2 .search_results__AnICf .search_result__3i9sY .search_card__3Z2jx .search_actions__2AqmU .search_practices__2OyHZ .search_title__1t4AO {
  display: flex;
  flex-wrap: wrap;
  grid-gap: 5px;
  gap: 5px;
  font-size: 13px;
  font-weight: 400;
  line-height: 16px;
  text-align: left;
  color: #1a1a1a;
  margin-bottom: 10px;
}
.search_cardSearch__1IlXc
  .search_content__2-CY2
  .search_results__AnICf
  .search_result__3i9sY
  .search_card__3Z2jx
  .search_actions__2AqmU
  .search_practices__2OyHZ
  .search_title__1t4AO
  .search_colored__3aZoh {
  background: linear-gradient(
    191.12deg,
    #00ecb3 -91.84%,
    #24c9bf 25.42%,
    #23c8b0 126.74%
  );
  background-size: cover;
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.search_practice__IpNDU + .search_practice__IpNDU {
  margin-top: 5px;
}
.search_cardSearch__1IlXc .search_content__2-CY2 .search_results__AnICf .search_result__3i9sY .search_card__3Z2jx .search_actions__2AqmU .search_practices__2OyHZ .search_practice__IpNDU {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: #f5f5f5;
  border-radius: 5px;
  padding: 5px 15px;
}
.search_cardSearch__1IlXc
  .search_content__2-CY2
  .search_results__AnICf
  .search_result__3i9sY
  .search_card__3Z2jx
  .search_actions__2AqmU
  .search_practices__2OyHZ
  .search_practice__IpNDU
  .search_name__3AaBI {
  -webkit-line-clamp: 1;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
  font-size: 13px;
  font-weight: 400;
  line-height: 16px;
  text-align: left;
  color: #1a1a1a;
  max-width: 250px;
}
.search_cardSearch__1IlXc
  .search_content__2-CY2
  .search_results__AnICf
  .search_result__3i9sY
  .search_card__3Z2jx
  .search_actions__2AqmU
  .search_practices__2OyHZ
  .search_practice__IpNDU
  .search_props__2MG-v {
  display: flex;
  align-items: center;
}
.search_cardSearch__1IlXc
  .search_content__2-CY2
  .search_results__AnICf
  .search_result__3i9sY
  .search_card__3Z2jx
  .search_actions__2AqmU
  .search_practices__2OyHZ
  .search_practice__IpNDU
  .search_props__2MG-v
  .search_go__1Q9rU {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 20px;
  border-radius: 25px;
  background: linear-gradient(
    191.12deg,
    #00ecb3 -91.84%,
    #24c9bf 25.42%,
    #23c8b0 126.74%
  );
  padding: 7px 10px;
}
.search_cardSearch__1IlXc
  .search_content__2-CY2
  .search_results__AnICf
  .search_result__3i9sY
  .search_card__3Z2jx
  .search_actions__2AqmU
  .search_practices__2OyHZ
  .search_practice__IpNDU
  .search_props__2MG-v
  .search_go__1Q9rU
  svg {
  fill: #fff;
  width: 20px;
  height: 10px;
}

.search_cardSearch__1IlXc .search_content__2-CY2 .search_results__AnICf .search_result__3i9sY .search_card__3Z2jx .search_actions__2AqmU .search_mentorships__3M028 {
  margin-top: 10px;
}
.search_cardSearch__1IlXc .search_content__2-CY2 .search_results__AnICf .search_result__3i9sY .search_card__3Z2jx .search_actions__2AqmU .search_mentorships__3M028 .search_title__1t4AO {
  display: flex;
  flex-wrap: wrap;
  grid-gap: 5px;
  gap: 5px;
  font-size: 13px;
  font-weight: 400;
  line-height: 16px;
  text-align: left;
  color: #1a1a1a;
  margin-bottom: 10px;
}
.search_cardSearch__1IlXc
  .search_content__2-CY2
  .search_results__AnICf
  .search_result__3i9sY
  .search_card__3Z2jx
  .search_actions__2AqmU
  .search_mentorships__3M028
  .search_title__1t4AO
  .search_colored__3aZoh {
  background: linear-gradient(
    191.12deg,
    #00ecb3 -91.84%,
    #24c9bf 25.42%,
    #23c8b0 126.74%
  );
  background-size: cover;
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.search_mentorship__18JHf + .search_mentorship__18JHf {
  margin-top: 5px;
}
.search_cardSearch__1IlXc .search_content__2-CY2 .search_results__AnICf .search_result__3i9sY .search_card__3Z2jx .search_actions__2AqmU .search_mentorships__3M028 .search_mentorship__18JHf {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: #f5f5f5;
  border-radius: 5px;
  padding: 5px 15px;
  width: 100%;
}
.search_cardSearch__1IlXc
  .search_content__2-CY2
  .search_results__AnICf
  .search_result__3i9sY
  .search_card__3Z2jx
  .search_actions__2AqmU
  .search_mentorships__3M028
  .search_mentorship__18JHf
  .search_name__3AaBI {
  -webkit-line-clamp: 1;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
  font-size: 13px;
  font-weight: 400;
  line-height: 16px;
  text-align: left;
  color: #1a1a1a;
  max-width: 250px;
}
.search_cardSearch__1IlXc
  .search_content__2-CY2
  .search_results__AnICf
  .search_result__3i9sY
  .search_card__3Z2jx
  .search_actions__2AqmU
  .search_mentorships__3M028
  .search_mentorship__18JHf
  .search_description__2IjL2 {
  -webkit-line-clamp: 1;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
  font-size: 13px;
  font-weight: 400;
  line-height: 16px;
  text-align: left;
  color: #1a1a1a;
  min-width: calc(100% - 250px);
  max-width: calc(100% - 250px);
}
.search_cardSearch__1IlXc
  .search_content__2-CY2
  .search_results__AnICf
  .search_result__3i9sY
  .search_card__3Z2jx
  .search_actions__2AqmU
  .search_mentorships__3M028
  .search_mentorship__18JHf
  .search_props__2MG-v {
  display: flex;
  align-items: center;
}
.search_cardSearch__1IlXc
  .search_content__2-CY2
  .search_results__AnICf
  .search_result__3i9sY
  .search_card__3Z2jx
  .search_actions__2AqmU
  .search_mentorships__3M028
  .search_mentorship__18JHf
  .search_props__2MG-v
  .search_go__1Q9rU {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 20px;
  border-radius: 25px;
  background: linear-gradient(
    191.12deg,
    #00ecb3 -91.84%,
    #24c9bf 25.42%,
    #23c8b0 126.74%
  );
  padding: 7px 10px;
}
.search_cardSearch__1IlXc
  .search_content__2-CY2
  .search_results__AnICf
  .search_result__3i9sY
  .search_card__3Z2jx
  .search_actions__2AqmU
  .search_mentorships__3M028
  .search_mentorship__18JHf
  .search_props__2MG-v
  .search_go__1Q9rU
  svg {
  fill: #fff;
  width: 20px;
  height: 10px;
}
.search_cardSearch__1IlXc .search_content__2-CY2 .search_results__AnICf .search_result__3i9sY .search_card__3Z2jx .search_actions__2AqmU .search_vacancies__1aSm7 {
  margin-top: 10px;
}
.search_cardSearch__1IlXc .search_content__2-CY2 .search_results__AnICf .search_result__3i9sY .search_card__3Z2jx .search_actions__2AqmU .search_vacancies__1aSm7 .search_title__1t4AO {
  display: flex;
  flex-wrap: wrap;
  grid-gap: 5px;
  gap: 5px;
  font-size: 13px;
  font-weight: 400;
  line-height: 16px;
  text-align: left;
  color: #1a1a1a;
  margin-bottom: 10px;
}
.search_cardSearch__1IlXc
  .search_content__2-CY2
  .search_results__AnICf
  .search_result__3i9sY
  .search_card__3Z2jx
  .search_actions__2AqmU
  .search_vacancies__1aSm7
  .search_title__1t4AO
  .search_colored__3aZoh {
  background: linear-gradient(
    191.12deg,
    #00ecb3 -91.84%,
    #24c9bf 25.42%,
    #23c8b0 126.74%
  );
  background-size: cover;
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.search_vacancy__1q-jT + .search_vacancy__1q-jT {
  margin-top: 5px;
}
.search_cardSearch__1IlXc .search_content__2-CY2 .search_results__AnICf .search_result__3i9sY .search_card__3Z2jx .search_actions__2AqmU .search_vacancies__1aSm7 .search_vacancy__1q-jT {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: #f5f5f5;
  border-radius: 5px;
  padding: 5px 15px;
  width: 100%;
}
.search_cardSearch__1IlXc .search_content__2-CY2 .search_results__AnICf .search_result__3i9sY .search_card__3Z2jx .search_actions__2AqmU .search_vacancies__1aSm7 .search_vacancy__1q-jT .search_name__3AaBI {
  -webkit-line-clamp: 1;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
  font-size: 13px;
  font-weight: 400;
  line-height: 16px;
  text-align: left;
  color: #1a1a1a;
  width: 100%;
}
.search_cardSearch__1IlXc
  .search_content__2-CY2
  .search_results__AnICf
  .search_result__3i9sY
  .search_card__3Z2jx
  .search_actions__2AqmU
  .search_vacancies__1aSm7
  .search_vacancy__1q-jT
  .search_props__2MG-v {
  display: flex;
  align-items: center;
}
.search_cardSearch__1IlXc
  .search_content__2-CY2
  .search_results__AnICf
  .search_result__3i9sY
  .search_card__3Z2jx
  .search_actions__2AqmU
  .search_vacancies__1aSm7
  .search_vacancy__1q-jT
  .search_props__2MG-v
  .search_go__1Q9rU {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 20px;
  border-radius: 25px;
  background: linear-gradient(
    191.12deg,
    #00ecb3 -91.84%,
    #24c9bf 25.42%,
    #23c8b0 126.74%
  );
  padding: 7px 10px;
}
.search_cardSearch__1IlXc
  .search_content__2-CY2
  .search_results__AnICf
  .search_result__3i9sY
  .search_card__3Z2jx
  .search_actions__2AqmU
  .search_vacancies__1aSm7
  .search_vacancy__1q-jT
  .search_props__2MG-v
  .search_go__1Q9rU
  svg {
  fill: #fff;
  width: 20px;
  height: 10px;
}
.search_cardSearch__1IlXc .search_content__2-CY2 .search_results__AnICf .search_result__3i9sY .search_title__1t4AO {
  font-size: 13.5px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  -webkit-line-clamp: 3;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
}
.search_more__32oBd {
  display: flex;
  justify-content: center;
  text-decoration: none;
}
.search_more__32oBd .search_all__1WfSw {
  color: #000;
  font-size: 10px;
  font-weight: 300;
  line-height: 12px;
  text-align: left;
  cursor: pointer;
  position: relative;
  margin-top: 10px;
  padding: 5px 20px;
  box-shadow: 1px 1px 8px 0px rgba(0, 0, 0, 0.15);
  border-radius: 20px;
  text-decoration: none;
}
.search_more__32oBd .search_all__1WfSw::before {
  content: "";
  position: absolute;
  background: linear-gradient(
    191.12deg,
    #00ecb3 -91.84%,
    #24c9bf 25.42%,
    #23c8b0 126.74%
  );
  width: 6px;
  height: 16px;
  top: calc(50% - 8px);
  left: 100%;
  clip-path: polygon(0 12.5%, 100% 50%, 0 87.5%);
}
.search_full__-Dunh {
  width: 100%;
  display: flex;
  justify-content: center;
}
.search_full__-Dunh .search_more__32oBd .search_all__1WfSw {
  font-size: 12px;
  line-height: 14.5px;
  margin-top: 20px;
}
.search_full__-Dunh .search_more__32oBd .search_all__1WfSw::before {
  width: 8px;
  height: 18px;
  top: calc(50% - 8px);
}
@media screen and (max-width: 1200px) {
  .search_cardSearch__1IlXc {
    height: auto;
    margin: 0px 5px;
  }
  .search_cardSearch__1IlXc .search_inner__1S2yH {
    flex-wrap: wrap;
  }
  .search_cardSearch__1IlXc .search_content__2-CY2 {
    width: 100%;
  }
  .search_cardSearch__1IlXc .search_content__2-CY2 .search_results__AnICf .search_result__3i9sY .search_card__3Z2jx .search_info__1o63G {
    display: block;
  }
  .search_cardSearch__1IlXc .search_content__2-CY2 .search_results__AnICf .search_result__3i9sY .search_card__3Z2jx .search_info__1o63G .search_about__8jJe- .search_title__1t4AO {
    width: 100%;
  }
  .search_results__AnICf .search_result__3i9sY .search_card__3Z2jx .search_actions__2AqmU {
    min-width: 100%;
  }
  .search_cardSearch__1IlXc .search_content__2-CY2 .search_results__AnICf .search_result__3i9sY .search_card__3Z2jx .search_actions__2AqmU .search_practices__2OyHZ .search_practice__IpNDU {
    display: block;
    padding: 10px;
  }
  .search_cardSearch__1IlXc
    .search_content__2-CY2
    .search_results__AnICf
    .search_result__3i9sY
    .search_card__3Z2jx
    .search_actions__2AqmU
    .search_practices__2OyHZ
    .search_practice__IpNDU
    .search_name__3AaBI {
    margin-bottom: 10px;
    max-width: auto;
  }
  .search_cardSearch__1IlXc
    .search_content__2-CY2
    .search_results__AnICf
    .search_result__3i9sY
    .search_card__3Z2jx
    .search_actions__2AqmU
    .search_practices__2OyHZ
    .search_practice__IpNDU
    .search_props__2MG-v {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
  }
  .search_cardSearch__1IlXc
    .search_content__2-CY2
    .search_results__AnICf
    .search_result__3i9sY
    .search_card__3Z2jx
    .search_actions__2AqmU
    .search_practices__2OyHZ
    .search_practice__IpNDU
    .search_props__2MG-v
    .search_go__1Q9rU {
    margin-left: 0px;
    width: 50px;
    margin-top: 10px;
  }
  .search_cardSearch__1IlXc
    .search_content__2-CY2
    .search_results__AnICf
    .search_result__3i9sY
    .search_card__3Z2jx
    .search_actions__2AqmU
    .search_mentorships__3M028
    .search_mentorship__18JHf {
    display: block;
    padding: 10px;
  }
  .search_cardSearch__1IlXc
    .search_content__2-CY2
    .search_results__AnICf
    .search_result__3i9sY
    .search_card__3Z2jx
    .search_actions__2AqmU
    .search_mentorships__3M028
    .search_mentorship__18JHf
    .search_name__3AaBI {
    margin-bottom: 10px;
    max-width: auto;
  }
  .search_cardSearch__1IlXc
    .search_content__2-CY2
    .search_results__AnICf
    .search_result__3i9sY
    .search_card__3Z2jx
    .search_actions__2AqmU
    .search_mentorships__3M028
    .search_mentorship__18JHf
    .search_description__2IjL2 {
    margin-bottom: 10px;
    max-width: none;
  }
  .search_cardSearch__1IlXc
    .search_content__2-CY2
    .search_results__AnICf
    .search_result__3i9sY
    .search_card__3Z2jx
    .search_actions__2AqmU
    .search_mentorships__3M028
    .search_mentorship__18JHf
    .search_props__2MG-v {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
  }
  .search_cardSearch__1IlXc
    .search_content__2-CY2
    .search_results__AnICf
    .search_result__3i9sY
    .search_card__3Z2jx
    .search_actions__2AqmU
    .search_mentorships__3M028
    .search_mentorship__18JHf
    .search_props__2MG-v
    .search_go__1Q9rU {
    margin-left: 0px;
    width: 50px;
    margin-top: 10px;
  }
  .search_cardSearch__1IlXc .search_content__2-CY2 .search_results__AnICf .search_result__3i9sY .search_card__3Z2jx .search_actions__2AqmU .search_vacancies__1aSm7 .search_vacancy__1q-jT {
    display: block;
    padding: 10px;
  }
  .search_cardSearch__1IlXc
    .search_content__2-CY2
    .search_results__AnICf
    .search_result__3i9sY
    .search_card__3Z2jx
    .search_actions__2AqmU
    .search_vacancies__1aSm7
    .search_vacancy__1q-jT
    .search_name__3AaBI {
    margin-bottom: 10px;
    max-width: auto;
  }
  .search_cardSearch__1IlXc
    .search_content__2-CY2
    .search_results__AnICf
    .search_result__3i9sY
    .search_card__3Z2jx
    .search_actions__2AqmU
    .search_vacancies__1aSm7
    .search_vacancy__1q-jT
    .search_props__2MG-v {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
  }
  .search_cardSearch__1IlXc
    .search_content__2-CY2
    .search_results__AnICf
    .search_result__3i9sY
    .search_card__3Z2jx
    .search_actions__2AqmU
    .search_vacancies__1aSm7
    .search_vacancy__1q-jT
    .search_props__2MG-v
    .search_go__1Q9rU {
    margin-left: 0px;
    width: 50px;
    margin-top: 10px;
  }
}

.map_block__39QeZ {
  display: flex;
  flex-wrap: wrap;
  position: relative;
  margin-top: 140px;
  justify-content: center;
}
.map_header__2hCQR {
  background-image: linear-gradient(
    180.12deg,
    #00ecb3 -91.84%,
    #24c9bf 25.42%,
    #23c8b0 126.74%
  );
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-size: 36px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  text-transform: uppercase;
  text-align: left;
  margin-bottom: 40px;
  width: 100%;
}
.map_secondHeader__203d7 {
  background: linear-gradient(
    180.12deg,
    #00ecb3 -91.84%,
    #24c9bf 25.42%,
    #23c8b0 126.74%
  );
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-size: 32px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  text-align: left;
  margin-top: 40px;
  width: 100%;
}
.map_legend__7Fnyw {
  display: flex;
  grid-row-gap: 5px;
  row-gap: 5px;
  flex-direction: column;
  width: 100%;
  margin-top: 10px;
}
.map_legend__7Fnyw .map_legendPoint__62lRW {
  display: flex;
}
.map_legend__7Fnyw .map_legendPoint__62lRW .map_icon__3XFF4.map_college__2lOlY {
  cursor: pointer;
  fill: #fff;
}
.map_legend__7Fnyw .map_legendPoint__62lRW .map_icon__3XFF4.map_university__eehFC {
  cursor: pointer;
  fill: #0072ff;
}
.map_map__1g-v8 {
  position: relative;
  margin-top: 20px;
  height: 960px;
  width: 800px;
}
.map_map__1g-v8 .map_point__1Fu-o {
  position: absolute;
}
.map_map__1g-v8 .map_point__1Fu-o .map_icon__3XFF4.map_college__2lOlY {
  cursor: pointer;
  fill: #fff;
}
.map_map__1g-v8 .map_point__1Fu-o .map_icon__3XFF4.map_university__eehFC {
  cursor: pointer;
  fill: #0072ff;
}
.map_map__1g-v8 .map_point__1Fu-o .map_icon__3XFF4:hover {
  fill: #646464;
}
.map_map__1g-v8 .map_point__1Fu-o .map_card__2eRuO {
  height: 289px;
  width: 434px;
  position: absolute;
  display: none;
  top: 12px;
  right: 20px;
}
.map_card__2eRuO .map_border__21N1l {
  background: linear-gradient(
    191.12deg,
    #00ecb3 -91.84%,
    #24c9bf 25.42%,
    #23c8b0 126.74%
  );
}
.map_card__2eRuO .map_arrow__381Pj {
  background: linear-gradient(
    191.12deg,
    #00ecb3 -91.84%,
    #24c9bf 25.42%,
    #23c8b0 126.74%
  );
}
.map_map__1g-v8 .map_point__1Fu-o:hover .map_card__2eRuO {
  display: block;
  z-index: 1;
}
.map_card__2eRuO.map_mobile__2xUG7 {
  display: none;
}
.map_card__2eRuO .map_content__2wwui {
  margin-top: 5px;
  padding: 10px 20px 27px 22px;
  cursor: default;
  height: 280px;
  overflow-y: auto;
}
.map_card__2eRuO .map_content__2wwui .map_item__32Y_U {
  border-bottom: 1px solid #e1e1e1;
  text-decoration: none;
}
.map_item__32Y_U + .map_item__32Y_U {
  margin-top: 12px;
}
.map_card__2eRuO .map_content__2wwui .map_item__32Y_U .map_header__2hCQR {
  display: flex;
  color: #555;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  writing-mode: horizontal-tb;
  transform: none;
  background: none;
  background-clip: none;
  -webkit-background-clip: none;
  -webkit-text-fill-color: #555;
  text-transform: none;
  margin-right: 0;
  margin-bottom: 8px;
  align-items: center;
}
.map_card__2eRuO .map_content__2wwui .map_item__32Y_U .map_header__2hCQR .map_icon__3XFF4 {
  margin-right: 15px;
  margin-left: -4px;
  height: 48px;
  object-fit: contain;
}
.map_card__2eRuO .map_content__2wwui .map_item__32Y_U .map_contacts__2t7jc {
  display: flex;
  grid-gap: 40px;
  gap: 40px;
}
.map_card__2eRuO .map_content__2wwui .map_item__32Y_U .map_contacts__2t7jc .map_address__1SSmi {
  margin-bottom: 12px;
}
.map_card__2eRuO .map_content__2wwui .map_item__32Y_U .map_contacts__2t7jc .map_address__1SSmi .map_title__2mBk7 {
  color: #555;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-bottom: 8px;
}
.map_card__2eRuO .map_content__2wwui .map_item__32Y_U .map_contacts__2t7jc .map_address__1SSmi .map_description__1oLmF {
  color: #555;
  font-size: 12px;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
}
@media screen and (max-width: 1200px) {
  .map_block__39QeZ {
    flex-wrap: wrap;
    justify-content: center;
  }
  .map_header__2hCQR {
    margin-left: 5px;
  }
  .map_secondHeader__203d7 {
    margin-left: 5px;
  }
  .map_cardSearch__ykI7w {
    width: calc(100vw - 10px);
    height: 660px;
  }
  .map_cardSearch__ykI7w .map_content__2wwui .map_results__6C4wI .map_result__2UCK0 {
    width: 100%;
  }
  .map_map__1g-v8 {
    margin-top: 20px;
    height: auto;
    display: block;
  }
  .map_map__1g-v8 .map_point__1Fu-o:hover .map_card__2eRuO {
    display: none;
    z-index: 10;
  }
  .map_card__2eRuO.map_mobile__2xUG7 {
    margin-top: 20px;
    display: block;
    width: calc(100vw - 10px);
  }
}

.cell_cell__1Ssvo {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  width: 95px;
  height: 100px;
  padding: 5px;
  --border-color: rgba(239, 239, 239, 1);
}
.cell_first__g_Uv_ {
  border-bottom: 1px solid var(--border-color);
  border-right: 1px solid var(--border-color);
}
.cell_last__3uzYJ {
  border-right: none !important;
  border-bottom: 1px solid var(--border-color);
}
.cell_top__3t2oW {
  border-right: 1px solid var(--border-color);
  border-bottom: 1px solid var(--border-color);
}
.cell_bottom__rsKrZ {
  border-bottom: none !important;
  border-right: 1px solid var(--border-color);
}
.cell_center__2Bf_3 {
  border-right: 1px solid var(--border-color);
  border-bottom: 1px solid var(--border-color);
}
.cell_active__1cTDd {
  background: linear-gradient(
    191.12deg,
    #00b3ec -91.84%,
    #cbec00 -91.82%,
    #6e9a10 25.42%,
    #d0a024 126.74%
  );
}
.cell_focus__1Dcr8 {
  border: 1px solid #d0a024 !important;
  color: #d0a024;
}
.cell_active__1cTDd.cell_focus__1Dcr8 {
  background: none;
}
@media screen and (max-width: 1200px) {
  .cell_cell__1Ssvo {
    width: calc(100vw / 7);
    height: calc(100vw / 7 - 2px);
  }
}

.calendar_element__3ewJQ {
  display: block;
}
.calendar_inputs__vDkbh {
  display: flex;
  justify-content: flex-start;
  width: 100%;
  margin-bottom: 40px;
  grid-gap: 15px;
  gap: 15px;
}
.calendar_inputs__vDkbh .calendar_months__16Wnb {
  width: 130px;
}
.calendar_inputs__vDkbh .calendar_years__3v3nf {
  width: 100px;
}
.calendar_calendar__2H3uY {
  display: grid;
  grid-template-columns: repeat(7, 95px);
}
.calendar_calendar__2H3uY .calendar_weekDayTitle__253T6 {
  font-size: 16px;
  font-weight: 600;
  line-height: 19px;
  letter-spacing: 0px;
  text-align: left;
  padding-bottom: 20px;
}
@media screen and (max-width: 1200px) {
  .calendar_calendar__2H3uY {
    grid-template-columns: repeat(7, calc((100vw - 10px) / 7));
  }
}

.calendar_block__2ib-L {
  margin-top: 40px;
}
.calendar_block__2ib-L .calendar_header__2wjN7 {
  background-image: linear-gradient(
    180.12deg,
    #00ecb3 -91.84%,
    #24c9bf 25.42%,
    #23c8b0 126.74%
  );
  background-size: cover;
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-size: 36px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}
.calendar_block__2ib-L .calendar_inner__3o0uT {
  display: flex;
  flex-wrap: wrap;
  grid-gap: 40px;
  gap: 40px;
  margin-top: 40px;
}
.calendar_toDay__3W25S {
  background-image: linear-gradient(
    191.12deg,
    #00ecb3 -91.84%,
    #24c9bf 25.42%,
    #23c8b0 126.74%
  );
}
.calendar_focusDay__3U0IV {
  border: 1px solid #23c8b0 !important;
  color: #23c8b0;
}
.calendar_cardSearch__3cUYu {
  width: 392px;
  height: 640px;
}
.calendar_cardSearch__3cUYu .calendar_border__2tU7B {
  background-image: linear-gradient(
    191.12deg,
    #00ecb3 -91.84%,
    #24c9bf 25.42%,
    #23c8b0 126.74%
  );
}
.calendar_cardSearch__3cUYu .calendar_arrow__3U2AW {
  background-image: linear-gradient(
    201.12deg,
    #00ecb3 -91.84%,
    #24c9bf 25.42%,
    #23c8b0 126.74%
  );
}
.calendar_cardSearch__3cUYu .calendar_content__2Wy6r {
  padding: 30px 29px 20px 29px;
}
.calendar_cardSearch__3cUYu .calendar_content__2Wy6r .calendar_search__2ixIy {
  display: flex;
  justify-content: flex-start;
  grid-gap: 10px;
  gap: 10px;
  width: 100%;
  filter: drop-shadow(1px 1px 8px rgba(0, 0, 0, 0.15));
  background-color: rgba(239, 239, 239, 1);
  border-radius: 20px;
  padding-left: 22px;
}
.calendar_cardSearch__3cUYu .calendar_content__2Wy6r .calendar_search__2ixIy .calendar_icon__2QD_i {
  margin-top: 10px;
  margin-bottom: 10px;
  min-width: 22px;
}
.calendar_cardSearch__3cUYu .calendar_content__2Wy6r .calendar_search__2ixIy .calendar_input__1gRbA {
  width: 100%;
  color: rgba(0, 0, 0, 1);
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  border: none;
  vertical-align: middle;
  background-color: rgba(239, 239, 239, 1);
}
.calendar_cardSearch__3cUYu .calendar_content__2Wy6r .calendar_search__2ixIy .calendar_input__1gRbA::placeholder,
.calendar_cardSearch__3cUYu .calendar_content__2Wy6r .calendar_search__2ixIy .calendar_input__1gRbA::-webkit-input-placeholder {
  color: #000;
  line-height: 18px;
  vertical-align: middle;
}

.calendar_cardSearch__3cUYu .calendar_content__2Wy6r .calendar_search__2ixIy .calendar_input__1gRbA:focus-visible {
  outline: none;
}
.calendar_cardSearch__3cUYu .calendar_content__2Wy6r .calendar_search__2ixIy .calendar_button__3UWrT {
  position: relative;
  min-width: 65px;
  border-radius: 0 20px 20px 20px;
  border: none;
  background-image: linear-gradient(
    191.12deg,
    #00ecb3 -91.84%,
    #24c9bf 25.42%,
    #23c8b0 126.74%
  );
}
.calendar_cardSearch__3cUYu .calendar_content__2Wy6r .calendar_search__2ixIy .calendar_button__3UWrT::after {
  position: absolute;
  content: "";
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  visibility: hidden;
  background-color: #fff;
  opacity: 0.3;
  border-radius: 0 20px 20px 20px;
}
.calendar_cardSearch__3cUYu .calendar_content__2Wy6r .calendar_search__2ixIy .calendar_button__3UWrT:hover::after {
  visibility: visible;
}
.calendar_cardSearch__3cUYu .calendar_content__2Wy6r .calendar_searchAdvanced__2v4AR {
  display: flex;
  justify-content: center;
}
.calendar_cardSearch__3cUYu .calendar_content__2Wy6r .calendar_searchAdvanced__2v4AR a {
  margin-top: 6px;
  font-size: 0.85rem;
  text-decoration: none;
  color: #24c9bf;
}
.calendar_cardSearch__3cUYu .calendar_content__2Wy6r .calendar_info__1SIJz {
  margin-top: 5px;
  display: flex;
  flex-wrap: wrap;
  align-content: space-between;
  justify-content: center;
  height: 540px;
}
.calendar_cardSearch__3cUYu .calendar_content__2Wy6r .calendar_results__2VqGm {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
}
.calendar_cardSearch__3cUYu .calendar_content__2Wy6r .calendar_results__2VqGm .calendar_result__3QAgn {
  position: relative;
  color: #555;
  width: 100%;
}
.calendar_cardSearch__3cUYu .calendar_content__2Wy6r .calendar_results__2VqGm .calendar_result__3QAgn .calendar_card__iIrPI {
  cursor: default;
  padding: 12px 0px;
  grid-gap: 15px;
  gap: 15px;
  color: #000;
  width: 100%;
  border-bottom: 1px solid #e1e1e1;
}
.calendar_cardSearch__3cUYu .calendar_content__2Wy6r .calendar_results__2VqGm .calendar_result__3QAgn .calendar_title__1L92m {
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  -webkit-line-clamp: 3;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-align: start;
  width: 100%;
}
.calendar_cardSearch__3cUYu .calendar_content__2Wy6r .calendar_results__2VqGm .calendar_result__3QAgn .calendar_date__12g0P {
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  -webkit-line-clamp: 3;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-align: end;
  width: 100%;
}
@media screen and (max-width: 1200px) {
  .calendar_block__2ib-L .calendar_inner__3o0uT {
    justify-content: center;
  }
  .calendar_cardSearch__3cUYu {
    width: calc(100vw - 10px);
  }
}

.news_block__3L2XU {
  position: relative;
  margin-top: 120px;
}
.news_header__131Wl {
  font-size: 32px;
  font-weight: 700;
  line-height: 40px;
  text-align: left;
  text-transform: uppercase;
  background: linear-gradient(
    191.12deg,
    #00ecb3 -91.84%,
    #24c9bf 25.42%,
    #23c8b0 126.74%
  );
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.news_background__2wkeT {
  position: absolute;
  top: 40px;
  right: 160px;
}
.news_all-news__rRhDq {
  cursor: pointer;
  position: relative;
  margin-top: 48px;
  margin-bottom: 17px;
  margin-left: -5px;
  padding-right: 8px;
  width: 100%;
  text-align: end;
  color: #555;
  font-size: 11.5px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.news_all-news__rRhDq::before {
  content: "";
  position: absolute;
  background: linear-gradient(
    191.12deg,
    #00ecb3 -91.84%,
    #24c9bf 25.42%,
    #23c8b0 126.74%
  );
  width: 6px;
  height: 12px;
  top: 1px;
  left: calc(100% - 4px);
  clip-path: polygon(0 12.5%, 100% 50%, 0 87.5%);
}
.news_news__2QrZQ {
  --width-card: 264px;
  --height-card: 318px;
}
.news_news__2QrZQ .news_slide__a-HLs {
  width: var(--width-card);
}
.news_news__2QrZQ .news_card__1ieLn {
  cursor: default;
  position: relative;
  background-color: #fff;
  border-radius: 19.5px;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  width: var(--width-card);
  height: var(--height-card);
}
.news_news__2QrZQ .news_card__1ieLn::before {
  content: "";
  background-image: linear-gradient(
    191.12deg,
    #00ecb3 -91.84%,
    #24c9bf 25.42%,
    #23c8b0 126.74%
  );
  top: -1px;
  left: -1px;
  bottom: -1px;
  right: -1px;
  position: absolute;
  z-index: -1;
  border-radius: 20px;
}
.news_news__2QrZQ .news_card__1ieLn .news_image__3HsEM {
  height: calc(var(--height-card) / 2);
  max-width: 100%;
  border-radius: 20px;
}
.news_news__2QrZQ .news_card__1ieLn .news_image__3HsEM img {
  width: 100%;
  height: calc(var(--height-card) / 2);
  border-radius: 20px 20px 0px 0px;
  object-fit: cover;
}
.news_news__2QrZQ .news_card__1ieLn .news_info__VTG98 {
  padding: 15px;
  display: flex;
  flex-wrap: wrap;
  align-content: space-between;
  height: calc(var(--height-card) / 2);
}
.news_news__2QrZQ .news_card__1ieLn .news_info__VTG98 .news_title__1tAJ5 {
  color: #333;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  width: 100%;
}
.news_news__2QrZQ .news_card__1ieLn .news_info__VTG98 .news_description__3LbKL {
  color: #555;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  max-width: var(--width-card);
  max-height: calc(var(--height-card) / 2 - 80px);
  margin-top: 10px;
  -webkit-line-clamp: 5;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
}
.news_news__2QrZQ .news_card__1ieLn .news_info__VTG98 .news_addition__2Ssq3 {
  display: flex;
  justify-content: space-between;
  color: #333;
  font-size: 11px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  width: 100%;
}
.news_news__2QrZQ .news_card__1ieLn .news_info__VTG98 .news_addition__2Ssq3 .news_more__2CAF2 {
  cursor: pointer;
  position: relative;
  padding-right: 8px;
  background-image: linear-gradient(
    191.12deg,
    #00ecb3 -91.84%,
    #24c9bf 25.42%,
    #23c8b0 126.74%
  );
  color: transparent;
  -webkit-background-clip: text;
  background-clip: text;
}
.news_news__2QrZQ .news_card__1ieLn .news_info__VTG98 .news_addition__2Ssq3 .news_more__2CAF2::before {
  content: "";
  position: absolute;
  background: linear-gradient(
    191.12deg,
    #00ecb3 -91.84%,
    #24c9bf 25.42%,
    #23c8b0 126.74%
  );
  width: 6px;
  height: 12px;
  top: 1px;
  left: calc(100% - 4px);
  clip-path: polygon(0 12.5%, 100% 50%, 0 87.5%);
}
.news_news__2QrZQ .news_swiper__27G-W {
  max-width: 1100px;
  padding: 1px 3px;
  height: calc(var(--height-card) + 33px) !important;
  --background-swiper-scrollbar-horizontal: #f8f8f8;
  --background-swiper-scrollbar-drag: linear-gradient(
    155deg,
    #00ecb3 15.81%,
    #23c8b0 100.43%
  );
}

@media screen and (max-width: 1200px) {
  .news_block__3L2XU {
    padding-left: 5px !important;
    padding-right: 5px !important;
  }
  .news_news__2QrZQ .news_swiper__27G-W {
    max-width: 100vw;
  }
}

.partners_block__28P8P {
  position: relative;
  margin-top: 160px;
}
.partners_header__2znx5 {
  font-size: 32px;
  font-weight: 700;
  line-height: 40px;
  text-align: left;
  text-transform: uppercase;
  background: linear-gradient(
    191.12deg,
    #00ecb3 -91.84%,
    #24c9bf 25.42%,
    #23c8b0 126.74%
  );
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.partners_partners__O-uAw {
  margin-top: 77px;
  display: flex;
  flex-wrap: wrap;
  grid-gap: 25px;
  gap: 25px;
}
.partners_partners__O-uAw .partners_partner__fFb02 {
  cursor: default;
  position: relative;
  background-color: #fff;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  width: 350px;
}
.partners_partners__O-uAw .partners_partner__fFb02 .partners_photo__29lqM {
  height: 120px;
}
.partners_partners__O-uAw .partners_partner__fFb02 .partners_photo__29lqM img {
  height: 120px;
  width: 100%;
  object-fit: contain;
}
.partners_partners__O-uAw .partners_partner__fFb02 .partners_info__3NL8j .partners_title__ygtaN {
  padding: 8px 13px;
}
.partners_partners__O-uAw .partners_partner__fFb02 .partners_info__3NL8j .partners_title__ygtaN .partners_name__3b8RW {
  color: #333;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}
@media screen and (max-width: 1200px) {
  .partners_block__28P8P {
    padding-left: 5px !important;
    padding-right: 5px !important;
  }
  .partners_partners__O-uAw {
    justify-content: center;
  }
  .partners_partners__O-uAw .partners_partner__fFb02 {
    width: 100vw;
  }
}

.about_block__fq_s2 {
  margin-top: 30px;
}
.about_block__fq_s2 .about_first__3R--x {
  display: flex;
  justify-content: space-between;
}
.about_block__fq_s2 .about_second__2ESto {
  display: flex;
  justify-content: space-between;
}
.about_block__fq_s2 .about_mainPhoto__1E9HY {
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
}
.about_block__fq_s2 .about_mainPhoto__1E9HY img {
  object-fit: contain;
  width: 480px;
  height: 350px;
}
.about_block__fq_s2 .about_info__X5SGH {
  margin-top: 20px;
  width: 575px;
}
.about_block__fq_s2 .about_info__X5SGH .about_header__mLjwc {
  display: flex;
  grid-gap: 20px;
  gap: 20px;
  align-items: center;
}
.about_block__fq_s2 .about_info__X5SGH .about_header__mLjwc h1 {
  color: #555;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  margin-bottom: 0px;
  min-width: 77.5%;
}
.about_block__fq_s2 .about_info__X5SGH .about_header__mLjwc .about_logo__3dIju {
  object-fit: contain;
  max-width: 100px;
  max-height: 40px;
}
.about_block__fq_s2 .about_info__X5SGH .about_header__mLjwc .about_title__1QZN6 {
  color: #555;
  font-size: 16px;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
}
.about_block__fq_s2 .about_info__X5SGH .about_header__mLjwc .about_rating__2Qzo0 .about_stars__2eyX0 {
  flex-wrap: nowrap;
  display: flex;
}
.about_block__fq_s2 .about_info__X5SGH .about_header__mLjwc .about_rating__2Qzo0 .about_stars__2eyX0 svg {
  margin-left: 3px;
  fill: #d9d9d9;
}
.about_block__fq_s2 .about_info__X5SGH .about_header__mLjwc .about_rating__2Qzo0 .about_stars__2eyX0 svg.about_active__1g1JM {
  fill: #ddc544;
}
.about_block__fq_s2 .about_info__X5SGH .about_description__39FKY {
  margin-top: 15px;
  color: #555;
  font-size: 16px;
  font-style: normal;
  font-weight: 300;
  line-height: 20px;
  overflow: auto;
  height: 60px;
}
.about_block__fq_s2 .about_info__X5SGH .about_addresses__1Ryvr {
  margin-top: 15px;
}
.about_block__fq_s2 .about_info__X5SGH .about_addresses__1Ryvr .about_title__1QZN6 {
  color: #555;
  font-size: 17px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-bottom: 8px;
}
.about_block__fq_s2 .about_info__X5SGH .about_addresses__1Ryvr .about_address__1SH94 {
  color: #555;
  font-size: 16px;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
}
.about_block__fq_s2 .about_info__X5SGH .about_contacts__1fR1g {
  display: flex;
  flex-wrap: wrap;
  grid-column-gap: 48px;
  column-gap: 48px;
  grid-row-gap: 5px;
  row-gap: 5px;
  margin-top: 10px;
}
.about_block__fq_s2 .about_info__X5SGH .about_contacts__1fR1g .about_contact__3_3Hg .about_title__1QZN6 {
  color: #555;
  font-size: 17px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-bottom: 8px;
}
.about_block__fq_s2 .about_info__X5SGH .about_contacts__1fR1g .about_contact__3_3Hg .about_description__39FKY {
  margin-top: 0px;
  text-decoration: none;
  height: auto;
}
.about_block__fq_s2 .about_info__X5SGH .about_contacts__1fR1g .about_contact__3_3Hg .about_link__1NHrT {
  color: #1194ba;
  -webkit-text-decoration-line: underline;
          text-decoration-line: underline;
}
.about_block__fq_s2 .about_tags__3c0HR {
  margin-top: 12px;
}
.about_block__fq_s2 .about_tags__3c0HR .about_title__1QZN6 {
  color: #555;
  font-size: 17px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.about_block__fq_s2 .about_tags__3c0HR .about_options__3amkI {
  margin-top: 16px;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  align-content: flex-start;
  grid-row-gap: 12px;
  row-gap: 12px;
  grid-column-gap: 24px;
  column-gap: 24px;
  align-self: stretch;
  flex-wrap: wrap;
  max-width: 480px;
}
.about_block__fq_s2 .about_tags__3c0HR .about_option___ks27 {
  color: #fff;
  font-size: 14px;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
  border-radius: 20px;
  border: 1px solid;
  background: #d8d8d8;
  padding: 8px 16px;
}
.about_block__fq_s2 .about_tags__3c0HR .about_option___ks27.about_active__1g1JM {
  border: 1px solid #20d694;
  background: linear-gradient(
    191deg,
    #00ecb3 -91.84%,
    #24c9bf 97.14%,
    #23c8b0 126.74%
  );
}
.about_block__fq_s2 .about_contact__3_3Hg {
  margin-top: 20px;
}
.about_block__fq_s2 .about_contact__3_3Hg .about_card__3Dmwk {
  width: 576px;
  height: auto;
  min-height: 160px;
}
.about_block__fq_s2 .about_contact__3_3Hg .about_card__3Dmwk .about_border__ioNRJ {
  background-image: linear-gradient(
    191.12deg,
    #00ecb3 -91.84%,
    #24c9bf 25.42%,
    #23c8b0 126.74%
  );
}
.about_block__fq_s2 .about_contact__3_3Hg .about_card__3Dmwk .about_arrow__2yhDf {
  background-image: linear-gradient(
    201.12deg,
    #00ecb3 -91.84%,
    #24c9bf 25.42%,
    #23c8b0 186.74%
  );
}
.about_block__fq_s2 .about_contact__3_3Hg .about_card__3Dmwk .about_inner__1iCHA {
  padding: 20px;
}
.about_block__fq_s2 .about_contact__3_3Hg .about_card__3Dmwk .about_inner__1iCHA .about_title__1QZN6 {
  color: #555;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.about_block__fq_s2 .about_contact__3_3Hg .about_card__3Dmwk .about_inner__1iCHA .about_about__1ge6o {
  margin-top: 14px;
  display: flex;
  align-items: center;
  grid-gap: 16px;
  gap: 16px;
}
.about_block__fq_s2 .about_contact__3_3Hg .about_card__3Dmwk .about_inner__1iCHA .about_about__1ge6o .about_photo__1ZfWm {
  object-fit: cover;
  width: 72px;
  height: 72px;
  border-radius: 100px;
}
.about_block__fq_s2 .about_contact__3_3Hg .about_card__3Dmwk .about_inner__1iCHA .about_about__1ge6o .about_contacts__1fR1g {
  margin-top: 5px;
}
.about_block__fq_s2 .about_contact__3_3Hg .about_card__3Dmwk .about_inner__1iCHA .about_about__1ge6o .about_contacts__1fR1g .about_name__3orIn {
  color: #555;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.about_block__fq_s2 .about_contact__3_3Hg .about_card__3Dmwk .about_inner__1iCHA .about_about__1ge6o .about_contacts__1fR1g .about_links__1-3G4 {
  margin-top: 5px;
  display: flex;
  grid-gap: 30px;
  gap: 30px;
}
.about_block__fq_s2 .about_contact__3_3Hg .about_card__3Dmwk .about_inner__1iCHA .about_about__1ge6o .about_contacts__1fR1g .about_positions__227A8 {
  display: flex;
  grid-gap: 4px;
  gap: 4px;
}
.about_block__fq_s2 .about_contact__3_3Hg .about_card__3Dmwk .about_inner__1iCHA .about_about__1ge6o .about_contacts__1fR1g .about_positions__227A8 .about_title__1QZN6 {
  color: #555;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.about_block__fq_s2 .about_contact__3_3Hg .about_card__3Dmwk .about_inner__1iCHA .about_about__1ge6o .about_contacts__1fR1g .about_positions__227A8 .about_position__mnRuh {
  color: #555;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.about_block__fq_s2 .about_contact__3_3Hg .about_card__3Dmwk .about_inner__1iCHA .about_about__1ge6o .about_contacts__1fR1g .about_phones__2-Y3E .about_title__1QZN6 {
  color: #555;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.about_block__fq_s2 .about_contact__3_3Hg .about_card__3Dmwk .about_inner__1iCHA .about_about__1ge6o .about_contacts__1fR1g .about_phones__2-Y3E .about_phone__2Vyvs {
  display: inline;
  color: #555;
  font-size: 12px;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
}
.about_block__fq_s2 .about_contact__3_3Hg .about_card__3Dmwk .about_inner__1iCHA .about_about__1ge6o .about_contacts__1fR1g .about_phones__2-Y3E .about_phone__2Vyvs + .about_phone__2Vyvs {
  margin-left: 18px;
}
.about_block__fq_s2 .about_contact__3_3Hg .about_card__3Dmwk .about_inner__1iCHA .about_about__1ge6o .about_contacts__1fR1g .about_emails__61V-x .about_title__1QZN6 {
  color: #555;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.about_block__fq_s2 .about_contact__3_3Hg .about_card__3Dmwk .about_inner__1iCHA .about_about__1ge6o .about_contacts__1fR1g .about_emails__61V-x .about_email__2D6Rm {
  display: inline;
  color: #1194ba;
  font-size: 12px;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
  -webkit-text-decoration-line: underline;
          text-decoration-line: underline;
}
.about_block__fq_s2 .about_contact__3_3Hg .about_card__3Dmwk .about_inner__1iCHA .about_about__1ge6o .about_contacts__1fR1g .about_groupsVK__3dkFM .about_title__1QZN6 {
  color: #555;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.about_block__fq_s2 .about_contact__3_3Hg .about_card__3Dmwk .about_inner__1iCHA .about_about__1ge6o .about_contacts__1fR1g .about_groupsVK__3dkFM .about_groupVK__1asJs {
  display: inline;
  color: #1194ba;
  font-size: 12px;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
  -webkit-text-decoration-line: underline;
          text-decoration-line: underline;
}
@media screen and (max-width: 1200px) {
  .about_block__fq_s2 .about_second__2ESto {
    flex-wrap: wrap;
  }
  .about_block__fq_s2 .about_mainPhoto__1E9HY {
    display: none;
  }
  .about_block__fq_s2 .about_info__X5SGH .about_header__mLjwc .about_logo__3dIju {
    display: none;
  }
  .about_block__fq_s2 .about_contact__3_3Hg .about_card__3Dmwk {
    width: 100%;
    height: auto;
  }
  .about_block__fq_s2 .about_contact__3_3Hg .about_card__3Dmwk .about_inner__1iCHA .about_about__1ge6o .about_contacts__1fR1g .about_links__1-3G4 {
    flex-wrap: wrap;
    grid-gap: 5px;
    gap: 5px;
  }
}

.addition_block__2mppW {
  position: relative;
  margin-top: 40px;
}
.addition_tabs__115ts {
  display: flex;
  align-items: flex-end;
  border-bottom: none !important;
}
.addition_tab__3x2kw {
  cursor: pointer;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  text-transform: uppercase;
  color: #999;
  border: none !important;
  border-radius: 0px !important;
  background: #fff;
  margin-bottom: 5px !important;
}
.addition_tab__3x2kw:hover {
  color: #999;
  background: linear-gradient(
    191deg,
    #00ecb3 -91.84%,
    #24c9bf 97.14%,
    #23c8b0 126.74%
  );
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.addition_tab__3x2kw.addition_active__ilawV {
  font-size: 25px;
  background: linear-gradient(
    191deg,
    #00ecb3 -91.84%,
    #24c9bf 97.14%,
    #23c8b0 126.74%
  );
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.addition_tabContent__1o2SX {
  position: relative;
  margin-top: 30px;
  display: flex !important;
  justify-content: space-between;
  align-items: flex-start;
  align-content: flex-start;
  grid-row-gap: 18px;
  row-gap: 18px;
  flex-wrap: wrap;
}
.addition_tabContent__1o2SX .addition_directions__1ggK_ {
  position: relative;
  --height-card-direction: 140px;
  --width-card-direction: 260px;
}
.addition_tabContent__1o2SX .addition_directions__1ggK_ .addition_swiper__32ItL {
  width: 1045px;
  max-width: 1045px;
  padding: 1px 3px;
  height: calc(var(--height-card-direction) + 33px) !important;
  --background-swiper-scrollbar-horizontal: #f8f8f8;
  --background-swiper-scrollbar-drag: linear-gradient(
    191deg,
    #00ecb3 -91.84%,
    #1cd1bd 24.28%,
    #24d07d 126.74%
  );
}
.addition_tabContent__1o2SX .addition_directions__1ggK_ .addition_slide__2QhBB {
  height: var(--height-card-direction);
  width: var(--width-card-direction);
}
.addition_tabContent__1o2SX .addition_directions__1ggK_ .addition_slide__2QhBB .addition_card__3fpEN {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  padding-left: 10px;
}
.addition_tabContent__1o2SX .addition_directions__1ggK_ .addition_slide__2QhBB .addition_card__3fpEN .addition_tabDirectionLogo__3tX3h {
  position: absolute;
  z-index: 1;
  top: 0;
  left: calc(50% - 40px);
}
.addition_tabContent__1o2SX .addition_directions__1ggK_ .addition_slide__2QhBB .addition_card__3fpEN .addition_tabDirection__I2hLt {
  margin-top: 85px;
  padding: 5px;
  min-height: 50px;
  min-width: 240px;
  text-align: center;
  max-width: none !important;
  font-size: 15px;
  font-weight: 400;
  color: #555;
}
.addition_tabContent__1o2SX .addition_directions__1ggK_ .addition_slide__2QhBB .addition_card__3fpEN .addition_tabDirection__I2hLt::before {
  background-image: linear-gradient(
    191deg,
    #00ecb3 -91.84%,
    #24c9bf 97.14%,
    #23c8b0 126.74%
  );
}
.addition_tabContent__1o2SX .addition_directions__1ggK_ .addition_slide__2QhBB .addition_card__3fpEN .addition_tabDirection__I2hLt:hover {
  color: #fff;
  background-image: linear-gradient(
    191deg,
    #00ecb3 -91.84%,
    #24c9bf 97.14%,
    #23c8b0 126.74%
  );
}
.addition_tabContent__1o2SX .addition_directions__1ggK_ .addition_slide__2QhBB .addition_card__3fpEN .addition_tabDirection__I2hLt.addition_active__ilawV {
  color: #fff;
  background-image: linear-gradient(
    191deg,
    #00ecb3 -91.84%,
    #24c9bf 97.14%,
    #23c8b0 126.74%
  );
}
.addition_tabContent__1o2SX .addition_directions__1ggK_ .addition_hidden__WYn74 {
  position: absolute;
  top: 2px;
  right: -6px;
  box-shadow: #fff 0px 5px 25px 25px;
  width: 5px;
  height: var(--height-card-direction);
  z-index: 1;
}
.addition_tabContent__1o2SX .addition_institutes__1_FCA {
  position: relative;
  --height-card-direction: 140px;
  --width-card-direction: 260px;
}
.addition_tabContent__1o2SX .addition_institutes__1_FCA .addition_header__hZ1X0 {
  margin-left: 20px;
  margin-bottom: 2 0px;
  font-weight: 700;
  font-size: 25px;
  background: linear-gradient(
    191deg,
    #00ecb3 -91.84%,
    #24c9bf 97.14%,
    #23c8b0 126.74%
  );
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  text-transform: uppercase;
}
.addition_tabContent__1o2SX .addition_institutes__1_FCA .addition_swiper__32ItL {
  width: 1045px;
  max-width: 1045px;
  padding: 1px 3px;
  height: calc(var(--height-card-direction) + 33px) !important;
  --background-swiper-scrollbar-horizontal: #f8f8f8;
  --background-swiper-scrollbar-drag: linear-gradient(
    191deg,
    #00ecb3 -91.84%,
    #1cd1bd 24.28%,
    #24d07d 126.74%
  );
}
.addition_tabContent__1o2SX .addition_institutes__1_FCA .addition_slide__2QhBB {
  height: var(--height-card-direction);
  width: var(--width-card-direction);
}
.addition_tabContent__1o2SX .addition_institutes__1_FCA .addition_slide__2QhBB .addition_card__3fpEN {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  padding-left: 10px;
}
.addition_tabContent__1o2SX .addition_institutes__1_FCA .addition_slide__2QhBB .addition_card__3fpEN .addition_tabDirectionLogo__3tX3h {
  position: absolute;
  z-index: 1;
  top: 0;
  left: calc(50% - 40px);
}
.addition_tabContent__1o2SX .addition_institutes__1_FCA .addition_slide__2QhBB .addition_card__3fpEN .addition_tabDirection__I2hLt {
  margin-top: 85px;
  padding: 5px;
  min-height: 50px;
  min-width: 240px;
  text-align: center;
  max-width: none !important;
  font-size: 15px;
  font-weight: 400;
  color: #555;
}
.addition_tabContent__1o2SX .addition_institutes__1_FCA .addition_slide__2QhBB .addition_card__3fpEN .addition_tabDirection__I2hLt::before {
  background-image: linear-gradient(
    191deg,
    #00ecb3 -91.84%,
    #24c9bf 97.14%,
    #23c8b0 126.74%
  );
}
.addition_tabContent__1o2SX .addition_institutes__1_FCA .addition_slide__2QhBB .addition_card__3fpEN .addition_tabDirection__I2hLt:hover {
  color: #fff;
  background-image: linear-gradient(
    191deg,
    #00ecb3 -91.84%,
    #24c9bf 97.14%,
    #23c8b0 126.74%
  );
}
.addition_tabContent__1o2SX .addition_institutes__1_FCA .addition_slide__2QhBB .addition_card__3fpEN .addition_tabDirection__I2hLt.addition_active__ilawV {
  color: #fff;
  background-image: linear-gradient(
    191deg,
    #00ecb3 -91.84%,
    #24c9bf 97.14%,
    #23c8b0 126.74%
  );
}
.addition_tabContent__1o2SX .addition_institutes__1_FCA .addition_hidden__WYn74 {
  position: absolute;
  top: 2px;
  right: -6px;
  box-shadow: #fff 0px 5px 25px 25px;
  width: 5px;
  height: var(--height-card-direction);
  z-index: 1;
}
.addition_tabContent__1o2SX .addition_specialties__PFQBN {
  display: flex;
  grid-gap: 30px;
  gap: 30px;
  margin-top: 30px;
}
.addition_tabContent__1o2SX .addition_specialties__PFQBN .addition_header__hZ1X0 {
  display: flex;
  justify-content: flex-end;
  writing-mode: vertical-lr;
  transform: rotate(180deg);
  font-size: 25px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  background: linear-gradient(
    191deg,
    #00ecb3 -91.84%,
    #24c9bf 97.14%,
    #23c8b0 126.74%
  );
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.addition_tabContent__1o2SX .addition_specialties__PFQBN .addition_results__31mlp {
  width: 1000px;
}
.addition_tabContent__1o2SX .addition_specialties__PFQBN .addition_results__31mlp.addition_filter__fA-L5 {
  width: 750px;
}
.addition_tabContent__1o2SX .addition_specialties__PFQBN .addition_results__31mlp .addition_card__3fpEN {
  margin-bottom: 30px;
  min-height: 120px;
  width: 100%;
  height: min-content;
}
.addition_tabContent__1o2SX .addition_specialties__PFQBN .addition_results__31mlp .addition_card__3fpEN .addition_border__uoU8L {
  background-image: linear-gradient(
    191.12deg,
    #00ecb3 -91.84%,
    #24c9bf 25.42%,
    #23c8b0 126.74%
  );
}
.addition_tabContent__1o2SX .addition_specialties__PFQBN .addition_results__31mlp .addition_card__3fpEN .addition_arrow__3yOhk {
  background-image: linear-gradient(
    201.12deg,
    #00ecb3 -91.84%,
    #24c9bf 25.42%,
    #23c8b0 186.74%
  );
}
.addition_tabContent__1o2SX .addition_specialties__PFQBN .addition_results__31mlp .addition_card__3fpEN .addition_inner__2QZ1h {
  padding: 20px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  grid-gap: 24px;
  gap: 24px;
}
.addition_tabContent__1o2SX .addition_specialties__PFQBN .addition_results__31mlp .addition_card__3fpEN .addition_inner__2QZ1h .addition_title__3CLhi {
  color: #555;
  font-size: 19px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-bottom: 10px;
  text-decoration: none;
}
.addition_tabContent__1o2SX .addition_specialties__PFQBN .addition_results__31mlp .addition_card__3fpEN .addition_inner__2QZ1h .addition_options__2lnCR {
  margin-top: 5px;
  display: flex;
  grid-gap: 12px;
  gap: 12px;
}
.addition_tabContent__1o2SX .addition_specialties__PFQBN .addition_results__31mlp .addition_card__3fpEN .addition_inner__2QZ1h .addition_options__2lnCR .addition_option__19PJd {
  color: #fff;
  font-size: 14px;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
  border-radius: 20px;
  border: 1px solid;
  background: #d8d8d8;
  padding: 8px 16px;
}
.addition_tabContent__1o2SX .addition_specialties__PFQBN .addition_results__31mlp .addition_card__3fpEN .addition_inner__2QZ1h .addition_options__2lnCR .addition_option__19PJd.addition_active__ilawV {
  border: 1px solid #20d694;
  background: linear-gradient(
    191deg,
    #00ecb3 -91.84%,
    #24c9bf 97.14%,
    #23c8b0 126.74%
  );
}
.addition_tabContent__1o2SX .addition_practices__22Owy {
  display: flex;
  flex-wrap: wrap;
  grid-column-gap: 30px;
  column-gap: 30px;
  grid-row-gap: 25px;
  row-gap: 25px;
}
.addition_tabContent__1o2SX .addition_practices__22Owy .addition_card__3fpEN {
  width: 535px;
  min-height: 120px;
  height: min-content;
}
.addition_tabContent__1o2SX .addition_practices__22Owy .addition_card__3fpEN .addition_border__uoU8L {
  background-image: linear-gradient(
    191.12deg,
    #00ecb3 -91.84%,
    #24c9bf 25.42%,
    #23c8b0 126.74%
  );
}
.addition_tabContent__1o2SX .addition_practices__22Owy .addition_card__3fpEN .addition_arrow__3yOhk {
  background-image: linear-gradient(
    201.12deg,
    #00ecb3 -91.84%,
    #24c9bf 25.42%,
    #23c8b0 186.74%
  );
}
.addition_tabContent__1o2SX .addition_practices__22Owy .addition_card__3fpEN .addition_inner__2QZ1h {
  padding: 20px;
}
.addition_tabContent__1o2SX .addition_practices__22Owy .addition_card__3fpEN .addition_inner__2QZ1h .addition_dates__2ZkYP {
  color: #555;
  font-size: 13px;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0em;
  text-align: left;
  text-align: end;
  width: 100%;
  margin-bottom: 8px;
}
.addition_tabContent__1o2SX .addition_practices__22Owy .addition_card__3fpEN .addition_inner__2QZ1h .addition_title__3CLhi {
  color: #555;
  font-size: 16px;
  font-weight: 400;
  line-height: 19px;
  text-align: left;
  text-decoration: none;
  width: 100%;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}
.addition_tabContent__1o2SX .addition_practices__22Owy .addition_card__3fpEN .addition_inner__2QZ1h .addition_program__23ecW {
  margin-top: 14px;
}
.addition_tabContent__1o2SX .addition_practices__22Owy .addition_card__3fpEN .addition_inner__2QZ1h .addition_program__23ecW .addition_title__3CLhi {
  color: #555;
  font-size: 14px;
  font-weight: 600;
  line-height: 19px;
  text-align: left;
  text-decoration: none;
  margin-bottom: 8px;
}
.addition_tabContent__1o2SX .addition_practices__22Owy .addition_card__3fpEN .addition_inner__2QZ1h .addition_program__23ecW .addition_description__14Bjk {
  color: #555;
  font-size: 14px;
  font-weight: 400;
  line-height: 19px;
  text-align: left;
  text-decoration: none;
}
.addition_tabContent__1o2SX .addition_events__3VXZ8 {
  display: flex;
  flex-wrap: wrap;
  grid-column-gap: 30px;
  column-gap: 30px;
  grid-row-gap: 25px;
  row-gap: 25px;
}
.addition_tabContent__1o2SX .addition_events__3VXZ8 .addition_card__3fpEN {
  width: 535px;
  min-height: 120px;
  height: min-content;
}
.addition_tabContent__1o2SX .addition_events__3VXZ8 .addition_card__3fpEN .addition_border__uoU8L {
  background-image: linear-gradient(
    191.12deg,
    #00ecb3 -91.84%,
    #24c9bf 25.42%,
    #23c8b0 126.74%
  );
}
.addition_tabContent__1o2SX .addition_events__3VXZ8 .addition_card__3fpEN .addition_arrow__3yOhk {
  background-image: linear-gradient(
    201.12deg,
    #00ecb3 -91.84%,
    #24c9bf 25.42%,
    #23c8b0 186.74%
  );
}
.addition_tabContent__1o2SX .addition_events__3VXZ8 .addition_card__3fpEN .addition_inner__2QZ1h {
  padding: 20px;
}
.addition_tabContent__1o2SX .addition_events__3VXZ8 .addition_card__3fpEN .addition_inner__2QZ1h .addition_dates__2ZkYP {
  color: #555;
  font-size: 13px;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0em;
  text-align: left;
  text-align: end;
  width: 100%;
  margin-bottom: 8px;
}
.addition_tabContent__1o2SX .addition_events__3VXZ8 .addition_card__3fpEN .addition_inner__2QZ1h .addition_title__3CLhi {
  color: #555;
  font-size: 16px;
  font-weight: 400;
  line-height: 19px;
  text-align: left;
  text-decoration: none;
  width: 100%;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}
.addition_tabContent__1o2SX .addition_events__3VXZ8 .addition_card__3fpEN .addition_inner__2QZ1h .addition_description__14Bjk {
  margin-top: 14px;
  color: #555;
  font-size: 14px;
  font-weight: 400;
  line-height: 19px;
  text-align: left;
  text-decoration: none;
}
.addition_tabContent__1o2SX .addition_events__3VXZ8 .addition_card__3fpEN .addition_inner__2QZ1h .addition_description__14Bjk .addition_title__3CLhi {
  color: #555;
  font-size: 14px;
  font-weight: 600;
  line-height: 19px;
  text-align: left;
  text-decoration: none;
  margin-bottom: 8px;
}
@media screen and (max-width: 1200px) {
  .addition_tabContent__1o2SX .addition_specialties__PFQBN {
    flex-wrap: wrap;
  }
  .addition_tabContent__1o2SX .addition_specialties__PFQBN .addition_header__hZ1X0 {
    display: none;
  }
  .addition_tabContent__1o2SX .addition_specialties__PFQBN .addition_results__31mlp {
    width: 100vw;
  }
  .addition_tabContent__1o2SX .addition_specialties__PFQBN .addition_results__31mlp.addition_filter__fA-L5 {
    width: 100vw;
  }
  .addition_tabContent__1o2SX .addition_specialties__PFQBN .addition_results__31mlp .addition_card__3fpEN {
    width: 100vw;
  }
  .addition_tabContent__1o2SX .addition_directions__1ggK_ .addition_swiper__32ItL {
    max-width: calc(100vw - 5px);
  }
  .addition_tabContent__1o2SX .addition_institutes__1_FCA .addition_swiper__32ItL {
    max-width: calc(100vw - 5px);
  }
  .addition_tabContent__1o2SX .addition_practices__22Owy .addition_card__3fpEN {
    width: 100vw;
  }
  .addition_tabContent__1o2SX .addition_events__3VXZ8 .addition_card__3fpEN {
    width: 100vw;
  }
}

.pagination_prev__3VJce {
  background: linear-gradient(
    191deg,
    #00ecb3 -91.84%,
    #24c9bf 25.42%,
    #23c8b0 126.74%
  ) !important;
}
.pagination_current__3Gvky {
  color: #24c9bf !important;
}
.pagination_next__2hDZU {
  background: linear-gradient(
    191deg,
    #00ecb3 -91.84%,
    #24c9bf 25.42%,
    #23c8b0 126.74%
  ) !important;
}

.filter_cardFilter__IO_R_ {
  width: 250px;
  height: 600px;
}
.filter_cardFilter__IO_R_ .filter_arrow__3883u {
  background-image: linear-gradient(
    201.12deg,
    #00ecb3 -91.84%,
    #24c9bf 25.42%,
    #23c8b0 186.74%
  );
}
.filter_cardFilter__IO_R_ .filter_border__3GnDN {
  background-image: linear-gradient(
    191.12deg,
    #00ecb3 -91.84%,
    #24c9bf 25.42%,
    #23c8b0 126.74%
  );
}
.filter_cardFilter__IO_R_ .filter_content__nnae2 {
  padding: 30px 29px 20px 29px;
}
.filter_cardFilter__IO_R_ .filter_content__nnae2 .filter_header__2TkY5 {
  color: #555;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  width: 100%;
  text-align: center;
}
.filter_cardFilter__IO_R_ .filter_content__nnae2 .filter_radioGroup__x1Yig .filter_radioGroupHeader__1D7tk {
  position: relative;
  color: #555;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  margin: 5px 0px;
}
.filter_cardFilter__IO_R_ .filter_content__nnae2 .filter_radioGroup__x1Yig .filter_radioGroupHeader__1D7tk::after {
  position: absolute;
  content: "˅";
  top: -5px;
  right: 0;
  font-size: 30px;
  font-weight: 200;
}
.filter_cardFilter__IO_R_ .filter_content__nnae2 .filter_radioGroup__x1Yig.filter_active__1x2wS .filter_radioGroupHeader__1D7tk::after {
  content: "^";
}
.filter_cardFilter__IO_R_ .filter_content__nnae2 .filter_radioGroup__x1Yig .filter_radio__1kmXJ {
  display: none;
  margin-right: 5px;
}
.filter_cardFilter__IO_R_ .filter_content__nnae2 .filter_radioGroup__x1Yig.filter_active__1x2wS .filter_radio__1kmXJ {
  display: inline-block;
}
.filter_cardFilter__IO_R_ .filter_content__nnae2 .filter_radioGroup__x1Yig .filter_radioLabel__1VRLn {
  display: none;
  color: #555;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  vertical-align: end;
}
.filter_cardFilter__IO_R_ .filter_content__nnae2 .filter_radioGroup__x1Yig.filter_active__1x2wS .filter_radioLabel__1VRLn {
  display: inline-block;
}
.filter_cardFilter__IO_R_ .filter_content__nnae2 .filter_inputGroup__VT3rQ .filter_inputGroupHeader__2bbmr {
  position: relative;
  color: #555;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  margin: 5px 0px;
}
.filter_cardFilter__IO_R_ .filter_content__nnae2 .filter_inputGroup__VT3rQ .filter_inputGroupHeader__2bbmr::after {
  position: absolute;
  content: "˅";
  top: -5px;
  right: 0;
  font-size: 30px;
  font-weight: 200;
}
.filter_cardFilter__IO_R_ .filter_content__nnae2 .filter_inputGroup__VT3rQ.filter_active__1x2wS .filter_inputGroupHeader__2bbmr::after {
  content: "^";
}
.filter_cardFilter__IO_R_ .filter_content__nnae2 .filter_inputGroup__VT3rQ .filter_input__lHukw {
  display: none;
  max-height: 26px;
  border-radius: 4px;
  border: 1px solid #555;
  font-size: 14px;
}
.filter_cardFilter__IO_R_ .filter_content__nnae2 .filter_inputGroup__VT3rQ .filter_input__lHukw::placeholder {
  color: #b1b1b1;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.filter_cardFilter__IO_R_ .filter_content__nnae2 .filter_inputGroup__VT3rQ.filter_active__1x2wS .filter_input__lHukw {
  display: block;
}
.filter_cardFilter__IO_R_ .filter_content__nnae2 .filter_betweenGroup__ii__J .filter_betweenGroupHeader__2XH6G {
  position: relative;
  color: #555;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  margin: 5px 0px;
}
.filter_cardFilter__IO_R_ .filter_content__nnae2 .filter_betweenGroup__ii__J .filter_betweenGroupHeader__2XH6G::after {
  position: absolute;
  content: "˅";
  top: -5px;
  right: 0;
  font-size: 30px;
  font-weight: 200;
}
.filter_cardFilter__IO_R_ .filter_content__nnae2 .filter_betweenGroup__ii__J.filter_active__1x2wS .filter_betweenGroupHeader__2XH6G::after {
  content: "^";
}
.filter_cardFilter__IO_R_ .filter_content__nnae2 .filter_betweenGroup__ii__J .filter_betweenInputs__16FEp {
  display: none;
  grid-gap: 10px;
  gap: 10px;
}
.filter_cardFilter__IO_R_ .filter_content__nnae2 .filter_betweenGroup__ii__J .filter_betweenInputs__16FEp .filter_input__lHukw {
  max-height: 26px;
  border-radius: 4px;
  border: 1px solid #555;
  font-size: 14px;
}
.filter_cardFilter__IO_R_ .filter_content__nnae2 .filter_betweenGroup__ii__J .filter_betweenInputs__16FEp .filter_input__lHukw::placeholder {
  color: #b1b1b1;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.filter_cardFilter__IO_R_ .filter_content__nnae2 .filter_betweenGroup__ii__J.filter_active__1x2wS .filter_betweenInputs__16FEp {
  display: flex;
}
@media screen and (max-width: 1200px) {
  .filter_cardFilter__IO_R_ {
    width: 100vw;
    height: 200px;
    margin-bottom: 20px;
  }
  .filter_cardFilter__IO_R_ .filter_content__nnae2 .filter_filters__2VLDL {
    height: 140px;
    overflow-y: auto;
  }
}

.search_search__2NF1- {
  display: flex;
  justify-content: flex-start;
  grid-gap: 10px;
  gap: 10px;
  width: 100%;
  filter: drop-shadow(1px 1px 8px rgba(0, 0, 0, 0.15));
  background-color: #fff;
  border-radius: 20px;
  padding-left: 22px;
}
.search_search__2NF1- .search_icon__1nNQ0 {
  margin-top: 10px;
  margin-bottom: 10px;
  min-width: 22px;
}
.search_search__2NF1- .search_input__1ikq0 {
  width: 100%;
  color: #646464;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  border: none;
  vertical-align: middle;
}
.search_search__2NF1- .search_input__1ikq0::placeholder {
  color: #e1e1e1;
  line-height: 18px;
  vertical-align: middle;
}

.search_search__2NF1- .search_input__1ikq0:focus-visible {
  outline: none;
}
.search_search__2NF1- .search_button__2R07a {
  position: relative;
  min-width: 65px;
  border-radius: 0 20px 20px 20px;
  border: none;
  background: linear-gradient(
    191deg,
    #00ecb3 -91.84%,
    #24c9bf 25.42%,
    #23c8b0 126.74%
  );
}
.search_search__2NF1- .search_button__2R07a::after {
  position: absolute;
  content: "";
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  visibility: hidden;
  background-color: #fff;
  opacity: 0.3;
  border-radius: 0 20px 20px 20px;
}
.search_search__2NF1- .search_button__2R07a:hover::after {
  visibility: visible;
}

.return_block__2aFAj {
  margin-top: 20px;
}
.return_block__2aFAj .return_second__1ap9L {
  display: flex;
  justify-content: flex-start;
}
.return_block__2aFAj .return_second__1ap9L .return_link__BP-Xx {
  display: flex;
  align-items: center;
  text-decoration: none;
  color: #555;
}

.about_block__3PshK {
  margin-top: 30px;
}
.about_block__3PshK .about_first__2AlSy {
  display: flex;
  justify-content: space-between;
}
.about_block__3PshK .about_second__1IVUa {
  display: flex;
  justify-content: space-between;
}
.about_block__3PshK .about_mainPhoto__3gR9q {
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
}
.about_block__3PshK .about_mainPhoto__3gR9q {
  object-fit: contain;
  height: 350px;
  width: 480px;
  border-radius: 10px;
  box-shadow: 1px 1px 4px 0px #00000040;
}
.about_block__3PshK .about_info__1KHHQ {
  margin-top: 20px;
  width: 575px;
}
.about_block__3PshK .about_info__1KHHQ .about_header__1AG8Y {
  display: flex;
  grid-gap: 20px;
  gap: 20px;
  align-items: center;
}
.about_block__3PshK .about_info__1KHHQ .about_header__1AG8Y h1 {
  color: #555;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  margin-bottom: 0px;
  min-width: 77.5%;
}
.about_block__3PshK .about_info__1KHHQ .about_header__1AG8Y .about_logo__3BhAb {
  object-fit: contain;
  max-width: 100px;
  max-height: 40px;
  border-radius: 10px;
  box-shadow: 1px 1px 4px 0px #00000040;
}
.about_block__3PshK .about_info__1KHHQ .about_header__1AG8Y .about_title__l2Iqr {
  color: #555;
  font-size: 16px;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
}
.about_block__3PshK .about_info__1KHHQ .about_header__1AG8Y .about_rating__2wyJ2 .about_stars__1s87l {
  flex-wrap: nowrap;
  display: flex;
}
.about_block__3PshK .about_info__1KHHQ .about_header__1AG8Y .about_rating__2wyJ2 .about_stars__1s87l svg {
  margin-left: 3px;
  fill: #d9d9d9;
}
.about_block__3PshK .about_info__1KHHQ .about_header__1AG8Y .about_rating__2wyJ2 .about_stars__1s87l svg.about_active__H8Tcv {
  fill: #ddc544;
}
.about_block__3PshK .about_info__1KHHQ .about_description__1E-vy {
  margin-top: 15px;
  color: #555;
  font-size: 16px;
  font-style: normal;
  font-weight: 300;
  line-height: 20px;
}
.about_block__3PshK .about_info__1KHHQ .about_addresses__HBEUg {
  margin-top: 15px;
}
.about_block__3PshK .about_info__1KHHQ .about_addresses__HBEUg .about_title__l2Iqr {
  color: #555;
  font-size: 17px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-bottom: 8px;
}
.about_block__3PshK .about_info__1KHHQ .about_addresses__HBEUg .about_address__3M192 {
  color: #555;
  font-size: 16px;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
}
.about_block__3PshK .about_info__1KHHQ .about_contacts__3abkK {
  display: flex;
  flex-wrap: wrap;
  grid-column-gap: 48px;
  column-gap: 48px;
  grid-row-gap: 5px;
  row-gap: 5px;
  margin-top: 10px;
}
.about_block__3PshK .about_info__1KHHQ .about_contacts__3abkK .about_contact__24Dlz .about_title__l2Iqr {
  color: #555;
  font-size: 17px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-bottom: 8px;
}
.about_block__3PshK .about_info__1KHHQ .about_contacts__3abkK .about_contact__24Dlz .about_description__1E-vy {
  margin-top: 0px;
  text-decoration: none;
}
.about_block__3PshK .about_info__1KHHQ .about_contacts__3abkK .about_contact__24Dlz .about_link__141JJ {
  color: #1194ba;
  -webkit-text-decoration-line: underline;
          text-decoration-line: underline;
}
.about_block__3PshK .about_tags__2jtbA {
  margin-top: 12px;
}
.about_block__3PshK .about_tags__2jtbA .about_title__l2Iqr {
  color: #555;
  font-size: 17px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.about_block__3PshK .about_tags__2jtbA .about_options__1MzXL {
  margin-top: 16px;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  align-content: flex-start;
  grid-row-gap: 12px;
  row-gap: 12px;
  grid-column-gap: 24px;
  column-gap: 24px;
  align-self: stretch;
  flex-wrap: wrap;
  max-width: 480px;
}
.about_block__3PshK .about_tags__2jtbA .about_option__1j66v {
  color: #191919;
  font-size: 14px;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
  border-radius: 20px;
  background: #d8d8d8;
  padding: 8px 16px;
}
.about_block__3PshK .about_tags__2jtbA .about_option__1j66v.about_active__H8Tcv {
  background: linear-gradient(
    191deg,
    #00ecb3 -91.84%,
    #24c9bf 97.14%,
    #23c8b0 126.74%
  );
}
.about_block__3PshK .about_contact__24Dlz {
  margin-top: 20px;
}
.about_block__3PshK .about_contact__24Dlz .about_card__14K7e {
  width: 575px;
  height: auto;
  min-height: 160px;
}
.about_block__3PshK .about_contact__24Dlz .about_card__14K7e .about_border__18S4- {
  background-image: linear-gradient(
    191.12deg,
    #00ecb3 -91.84%,
    #24c9bf 25.42%,
    #23c8b0 126.74%
  );
}
.about_block__3PshK .about_contact__24Dlz .about_card__14K7e .about_arrow__HgwOQ {
  background-image: linear-gradient(
    201.12deg,
    #00ecb3 -91.84%,
    #24c9bf 25.42%,
    #23c8b0 186.74%
  );
}
.about_block__3PshK .about_contact__24Dlz .about_card__14K7e .about_inner___WdsV {
  padding: 20px;
}
.about_block__3PshK .about_contact__24Dlz .about_card__14K7e .about_inner___WdsV .about_title__l2Iqr {
  color: #555;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.about_block__3PshK .about_contact__24Dlz .about_card__14K7e .about_inner___WdsV .about_about__3zHvq {
  margin-top: 14px;
  display: flex;
  align-items: center;
  grid-gap: 16px;
  gap: 16px;
}
.about_block__3PshK .about_contact__24Dlz .about_card__14K7e .about_inner___WdsV .about_about__3zHvq .about_photo__2QncE {
  object-fit: cover;
  width: 72px;
  height: 72px;
  border-radius: 100px;
}
.about_block__3PshK .about_contact__24Dlz .about_card__14K7e .about_inner___WdsV .about_about__3zHvq .about_contacts__3abkK {
  margin-top: 5px;
}
.about_block__3PshK .about_contact__24Dlz .about_card__14K7e .about_inner___WdsV .about_about__3zHvq .about_contacts__3abkK .about_name__2v3Ot {
  color: #555;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.about_block__3PshK .about_contact__24Dlz .about_card__14K7e .about_inner___WdsV .about_about__3zHvq .about_contacts__3abkK .about_links__1O1mk {
  margin-top: 5px;
  display: flex;
  grid-gap: 30px;
  gap: 30px;
}
.about_block__3PshK .about_contact__24Dlz .about_card__14K7e .about_inner___WdsV .about_about__3zHvq .about_contacts__3abkK .about_positions__PZ8cZ{
  display: flex;
  grid-gap: 4px;
  gap: 4px;
}
.about_block__3PshK .about_contact__24Dlz .about_card__14K7e .about_inner___WdsV .about_about__3zHvq .about_contacts__3abkK .about_positions__PZ8cZ .about_title__l2Iqr {
  color: #555;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.about_block__3PshK .about_contact__24Dlz .about_card__14K7e .about_inner___WdsV .about_about__3zHvq .about_contacts__3abkK .about_positions__PZ8cZ .about_position__1lsSQ {
  color: #555;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.about_block__3PshK .about_contact__24Dlz .about_card__14K7e .about_inner___WdsV .about_about__3zHvq .about_contacts__3abkK .about_phones__5vEel .about_title__l2Iqr {
  color: #555;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.about_block__3PshK .about_contact__24Dlz .about_card__14K7e .about_inner___WdsV .about_about__3zHvq .about_contacts__3abkK .about_phones__5vEel .about_phone__32WmL {
  display: inline;
  color: #555;
  font-size: 12px;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
}
.about_block__3PshK .about_contact__24Dlz .about_card__14K7e .about_inner___WdsV .about_about__3zHvq .about_contacts__3abkK .about_phones__5vEel .about_phone__32WmL + .about_phone__32WmL {
  margin-left: 18px;
}
.about_block__3PshK .about_contact__24Dlz .about_card__14K7e .about_inner___WdsV .about_about__3zHvq .about_contacts__3abkK .about_emails__145m5 .about_title__l2Iqr {
  color: #555;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.about_block__3PshK .about_contact__24Dlz .about_card__14K7e .about_inner___WdsV .about_about__3zHvq .about_contacts__3abkK .about_emails__145m5 .about_email__3THdJ {
  display: inline;
  color: #1194ba;
  font-size: 12px;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
  -webkit-text-decoration-line: underline;
          text-decoration-line: underline;
}
.about_block__3PshK .about_contact__24Dlz .about_card__14K7e .about_inner___WdsV .about_about__3zHvq .about_contacts__3abkK .about_groupsVK__3sUp4 .about_title__l2Iqr {
  color: #555;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.about_block__3PshK .about_contact__24Dlz .about_card__14K7e .about_inner___WdsV .about_about__3zHvq .about_contacts__3abkK .about_groupsVK__3sUp4 .about_groupVK__1nL7R {
  display: inline;
  color: #1194ba;
  font-size: 12px;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
  -webkit-text-decoration-line: underline;
          text-decoration-line: underline;
}
@media screen and (max-width: 1200px) {
  .about_block__3PshK .about_second__1IVUa {
    flex-wrap: wrap;
    justify-content: center;
    padding: 5px;
  }
  .about_block__3PshK .about_info__1KHHQ {
    width: calc(100vw - 10px);
  }
  .about_block__3PshK .about_info__1KHHQ .about_contacts__3abkK {
    flex-wrap: wrap;
    grid-row-gap: 4px;
    row-gap: 4px;
  }
  .about_block__3PshK .about_tags__2jtbA {
    width: calc(100vw - 10px);
  }
  .about_block__3PshK .about_mainPhoto__3gR9q {
    display: none;
  }
  .about_block__3PshK .about_info__1KHHQ .about_header__1AG8Y .about_logo__3BhAb {
    display: none;
  }
  .about_block__3PshK .about_contact__24Dlz .about_card__14K7e {
    width: calc(100vw - 10px);
    height: auto;
  }
  .about_block__3PshK .about_contact__24Dlz .about_card__14K7e .about_inner___WdsV .about_about__3zHvq .about_contacts__3abkK .about_links__1O1mk {
    flex-wrap: wrap;
    grid-gap: 5px;
    gap: 5px;
  }
}

.addition_block__1J0pC {
  display: block;
  margin-top: 20px;
}
.addition_block__1J0pC .addition_program__2Tspv {
  color: #1a1a1a;
}
.addition_block__1J0pC .addition_program__2Tspv .addition_title__Q2eba {
  font-size: 17px;
  font-weight: 400;
  line-height: 20px;
  text-align: left;
  margin-bottom: 10px;
}
.addition_block__1J0pC .addition_program__2Tspv .addition_description__1oj0f {
  font-size: 16px;
  font-weight: 300;
  line-height: 20px;
  text-align: left;
}
.addition_block__1J0pC .addition_columns__1X6g- {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
}
.addition_block__1J0pC .addition_card__3eK2L {
  height: auto;
}
.addition_block__1J0pC .addition_left__3c9yR .addition_card__3eK2L {
  width: 480px;
}
.addition_block__1J0pC .addition_columns__1X6g- .addition_left__3c9yR .addition_years__tHHTL {
  display: flex;
  justify-content: space-between;
  width: 480px;
  box-shadow: 1px 1px 8px 0px #00000026;
  border-radius: 10px;
  padding: 13px 16px;
  margin-bottom: 20px;
}
.addition_block__1J0pC .addition_columns__1X6g- .addition_left__3c9yR .addition_years__tHHTL .addition_description__1oj0f {
  display: flex;
  grid-gap: 10px;
  gap: 10px;
}
.addition_block__1J0pC .addition_right__4vVIV .addition_card__3eK2L {
  width: 575px;
}
.addition_block__1J0pC .addition_card__3eK2L {
  padding: 20px;
  background: #f5f5f5;
  border-radius: 10px;
  color: #1a1a1a;
}
.addition_block__1J0pC .addition_card__3eK2L .addition_title__Q2eba {
  display: flex;
  align-items: center;
  grid-gap: 10px;
  gap: 10px;
  font-size: 17px;
  font-weight: 400;
  line-height: 20px;
  text-align: left;
  margin-bottom: 10px;
}
.addition_block__1J0pC .addition_card__3eK2L .addition_description__1oj0f {
  display: flex;
  flex-wrap: wrap;
  grid-gap: 12px;
  gap: 12px;
  color: rgba(26, 26, 26, 1);
  font-size: 16px;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
}
.addition_block__1J0pC .addition_card__3eK2L .addition_description__1oj0f.addition_spaces__3qQNK {
  justify-content: space-between;
}
.addition_block__1J0pC .addition_card__3eK2L .addition_description__1oj0f .addition_item__2Oda9 {
  background-color: #fff;
  border-radius: 7px;
  box-shadow: 1px 1px 8px 0px #00000026;
  padding: 11px 9px;
}
.addition_block__1J0pC .addition_card__3eK2L .addition_description__1oj0f .addition_item__2Oda9 .addition_value__2urVi {
  background: linear-gradient(
    191.12deg,
    #00ecb3 -91.84%,
    #24c9bf 97.14%,
    #23c8b0 126.74%
  );
  font-size: 18px;
  font-weight: 400;
  line-height: 22px;
  text-align: left;
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.addition_block__1J0pC .addition_card__3eK2L .addition_description__1oj0f .addition_item__2Oda9.addition_fix__2uVJb {
  min-width: 170px;
}
.addition_card__3eK2L + .addition_card__3eK2L {
  margin-top: 20px;
}
@media screen and (max-width: 1200px) {
  .addition_block__1J0pC {
    padding: 5px;
  }
  .addition_block__1J0pC .addition_columns__1X6g- {
    flex-wrap: wrap;
    justify-content: center;
  }
  .addition_block__1J0pC .addition_columns__1X6g- .addition_left__3c9yR .addition_years__tHHTL {
    width: calc(100vw - 10px);
    flex-wrap: wrap;
  }
  .addition_block__1J0pC .addition_columns__1X6g- .addition_left__3c9yR .addition_card__3eK2L {
    width: calc(100vw - 10px);
  }
  .addition_block__1J0pC .addition_columns__1X6g- .addition_right__4vVIV {
    margin-top: 20px;
  }
  .addition_block__1J0pC .addition_columns__1X6g- .addition_right__4vVIV .addition_card__3eK2L {
    width: calc(100vw - 10px);
  }
}

.return_block__4MOS7 {
  margin-top: 20px;
}
.return_block__4MOS7 .return_second__2YV4T {
  display: flex;
  justify-content: flex-start;
}
.return_block__4MOS7 .return_second__2YV4T .return_link__1SMGb {
  display: flex;
  align-items: center;
  text-decoration: none;
  color: #555;
}

.about_block__25a8q {
  margin-top: 30px;
}
.about_block__25a8q .about_first__3S6GJ {
  display: flex;
  justify-content: space-between;
}
.about_block__25a8q .about_second__XcpH8 {
  display: flex;
  justify-content: space-between;
}
.about_block__25a8q .about_mainPhoto__1Xbbg {
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
}
.about_block__25a8q .about_mainPhoto__1Xbbg{
  object-fit: contain;
  height: 350px;
  width: 480px;
}
.about_block__25a8q .about_info__1jpXG {
  margin-top: 20px;
  width: 575px;
}
.about_block__25a8q .about_info__1jpXG .about_header__eMUF1 {
  display: flex;
  grid-gap: 20px;
  gap: 20px;
  align-items: center;
}
.about_block__25a8q .about_info__1jpXG .about_header__eMUF1 h1 {
  color: #555;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  margin-bottom: 0px;
  min-width: 77.5%;
}
.about_block__25a8q .about_info__1jpXG .about_header__eMUF1 .about_logo__2mYFT {
  object-fit: contain;
  max-width: 100px;
  max-height: 40px;
}
.about_block__25a8q .about_info__1jpXG .about_header__eMUF1 .about_title__1Vger {
  color: #555;
  font-size: 16px;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
}
.about_block__25a8q .about_info__1jpXG .about_header__eMUF1 .about_rating__2lp5h .about_stars__2cfMj {
  flex-wrap: nowrap;
  display: flex;
}
.about_block__25a8q .about_info__1jpXG .about_header__eMUF1 .about_rating__2lp5h .about_stars__2cfMj svg {
  margin-left: 3px;
  fill: #d9d9d9;
}
.about_block__25a8q .about_info__1jpXG .about_header__eMUF1 .about_rating__2lp5h .about_stars__2cfMj svg.about_active__3z2Xg {
  fill: #ddc544;
}
.about_block__25a8q .about_info__1jpXG .about_description__3jcV6 {
  margin-top: 15px;
  color: #555;
  font-size: 16px;
  font-style: normal;
  font-weight: 300;
  line-height: 20px;
}
.about_block__25a8q .about_info__1jpXG .about_addresses__7MTSF {
  margin-top: 15px;
}
.about_block__25a8q .about_info__1jpXG .about_addresses__7MTSF .about_title__1Vger {
  color: #555;
  font-size: 17px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-bottom: 8px;
}
.about_block__25a8q .about_info__1jpXG .about_addresses__7MTSF .about_address__1AHLm {
  color: #555;
  font-size: 16px;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
}
.about_block__25a8q .about_info__1jpXG .about_contacts__3j8Zo {
  display: flex;
  grid-gap: 48px;
  gap: 48px;
  margin-top: 10px;
}
.about_block__25a8q .about_info__1jpXG .about_contacts__3j8Zo .about_contact__3PT_I .about_title__1Vger {
  color: #555;
  font-size: 17px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-bottom: 8px;
}
.about_block__25a8q .about_info__1jpXG .about_contacts__3j8Zo .about_contact__3PT_I .about_description__3jcV6 {
  margin-top: 0px;
  text-decoration: none;
}
.about_block__25a8q .about_info__1jpXG .about_contacts__3j8Zo .about_contact__3PT_I .about_link__XFgez {
  color: #1194ba;
  -webkit-text-decoration-line: underline;
          text-decoration-line: underline;
}
.about_block__25a8q .about_tags__2ekB4 {
  margin-top: 12px;
}
.about_block__25a8q .about_tags__2ekB4 .about_title__1Vger {
  color: #555;
  font-size: 17px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.about_block__25a8q .about_tags__2ekB4 .about_options__1wivo {
  margin-top: 16px;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  align-content: flex-start;
  grid-row-gap: 12px;
  row-gap: 12px;
  grid-column-gap: 24px;
  column-gap: 24px;
  align-self: stretch;
  flex-wrap: wrap;
  max-width: 480px;
}
.about_block__25a8q .about_tags__2ekB4 .about_option__3gh7e {
  color: #fff;
  font-size: 14px;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
  border-radius: 20px;
  border: 1px solid;
  background: #d8d8d8;
  padding: 8px 16px;
}
.about_block__25a8q .about_tags__2ekB4 .about_option__3gh7e.about_active__3z2Xg {
  border: 1px solid #20d694;
  background: linear-gradient(
    191deg,
    #00ecb3 -91.84%,
    #24c9bf 97.14%,
    #23c8b0 126.74%
  );
}
.about_block__25a8q .about_contact__3PT_I {
  margin-top: 20px;
}
.about_block__25a8q .about_contact__3PT_I .about_card__NYlLS {
  width: 575px;
  height: auto;
  min-height: 160px;
}
.about_block__25a8q .about_contact__3PT_I .about_card__NYlLS .about_border__1aS-D {
  background-image: linear-gradient(
    191.12deg,
    #00ecb3 -91.84%,
    #24c9bf 25.42%,
    #23c8b0 126.74%
  );
}
.about_block__25a8q .about_contact__3PT_I .about_card__NYlLS .about_arrow__2kFlH {
  background-image: linear-gradient(
    201.12deg,
    #00ecb3 -91.84%,
    #24c9bf 25.42%,
    #23c8b0 186.74%
  );
}
.about_block__25a8q .about_contact__3PT_I .about_card__NYlLS .about_inner__2SaDK {
  padding: 20px;
}
.about_block__25a8q .about_contact__3PT_I .about_card__NYlLS .about_inner__2SaDK .about_title__1Vger {
  color: #555;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.about_block__25a8q .about_contact__3PT_I .about_card__NYlLS .about_inner__2SaDK .about_about__1xoab {
  margin-top: 14px;
  display: flex;
  align-items: center;
  grid-gap: 16px;
  gap: 16px;
}
.about_block__25a8q .about_contact__3PT_I .about_card__NYlLS .about_inner__2SaDK .about_about__1xoab .about_photo__3C2Xm {
  object-fit: cover;
  width: 72px;
  height: 72px;
  border-radius: 100px;
}
.about_block__25a8q .about_contact__3PT_I .about_card__NYlLS .about_inner__2SaDK .about_about__1xoab .about_contacts__3j8Zo {
  margin-top: 5px;
}
.about_block__25a8q .about_contact__3PT_I .about_card__NYlLS .about_inner__2SaDK .about_about__1xoab .about_contacts__3j8Zo .about_name__2LyUz {
  color: #555;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.about_block__25a8q .about_contact__3PT_I .about_card__NYlLS .about_inner__2SaDK .about_about__1xoab .about_contacts__3j8Zo .about_positions__3fmWy{
  display: flex;
  grid-gap: 4px;
  gap: 4px;
}
.about_block__25a8q .about_contact__3PT_I .about_card__NYlLS .about_inner__2SaDK .about_about__1xoab .about_contacts__3j8Zo .about_positions__3fmWy .about_title__1Vger {
  color: #555;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.about_block__25a8q .about_contact__3PT_I .about_card__NYlLS .about_inner__2SaDK .about_about__1xoab .about_contacts__3j8Zo .about_positions__3fmWy .about_position__1C4Ty {
  color: #555;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.about_block__25a8q .about_contact__3PT_I .about_card__NYlLS .about_inner__2SaDK .about_about__1xoab .about_contacts__3j8Zo .about_links__1L8X2 {
  margin-top: 5px;
  display: flex;
  grid-gap: 30px;
  gap: 30px;
}
.about_block__25a8q .about_contact__3PT_I .about_card__NYlLS .about_inner__2SaDK .about_about__1xoab .about_contacts__3j8Zo .about_phones__1_od_ .about_title__1Vger {
  color: #555;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.about_block__25a8q .about_contact__3PT_I .about_card__NYlLS .about_inner__2SaDK .about_about__1xoab .about_contacts__3j8Zo .about_phones__1_od_ .about_phone__3E_JN {
  display: inline;
  color: #555;
  font-size: 12px;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
}
.about_block__25a8q .about_contact__3PT_I .about_card__NYlLS .about_inner__2SaDK .about_about__1xoab .about_contacts__3j8Zo .about_phones__1_od_ .about_phone__3E_JN + .about_phone__3E_JN {
  margin-left: 18px;
}
.about_block__25a8q .about_contact__3PT_I .about_card__NYlLS .about_inner__2SaDK .about_about__1xoab .about_contacts__3j8Zo .about_emails__2C18f .about_title__1Vger {
  color: #555;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.about_block__25a8q .about_contact__3PT_I .about_card__NYlLS .about_inner__2SaDK .about_about__1xoab .about_contacts__3j8Zo .about_emails__2C18f .about_email__3WTir {
  display: inline;
  color: #1194ba;
  font-size: 12px;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
  -webkit-text-decoration-line: underline;
          text-decoration-line: underline;
}
@media screen and (max-width: 1200px) {
  .about_block__25a8q .about_second__XcpH8 {
    flex-wrap: wrap;
    justify-content: center;
    padding: 5px;
  }
  .about_block__25a8q .about_info__1jpXG {
    width: calc(100vw - 10px);
  }
  .about_block__25a8q .about_info__1jpXG .about_contacts__3j8Zo{
    flex-wrap: wrap;
    grid-row-gap: 4px;
    row-gap: 4px
  }
  .about_block__25a8q .about_tags__2ekB4 {
    width: calc(100vw - 10px);
  }
  .about_block__25a8q .about_mainPhoto__1Xbbg {
    display: none;
  }
  .about_block__25a8q .about_info__1jpXG .about_header__eMUF1 .about_logo__2mYFT {
    display: none;
  }
  .about_block__25a8q .about_contact__3PT_I .about_card__NYlLS {
    width: calc(100vw - 10px);
    height: auto;
  }
  .about_block__25a8q .about_contact__3PT_I .about_card__NYlLS .about_inner__2SaDK .about_about__1xoab .about_contacts__3j8Zo .about_links__1L8X2 {
    flex-wrap: wrap;
    grid-gap: 5px;
    gap: 5px;
  }
}

.filter_cardFilter__2KPA0 {
  width: 100%;
  height: 600px;
}
.filter_cardFilter__2KPA0 .filter_border__LA9Ve {
  background: linear-gradient(
    191.12deg,
    #00ecb3 -91.84%,
    #24c9bf 25.42%,
    #23c8b0 126.74%
  );
}
.filter_cardFilter__2KPA0 .filter_arrow__35bG6 {
  background: linear-gradient(
    201deg,
    #00ecb3 -91.84%,
    #24c9bf 25.42%,
    #23c8b0 126.74%
  );
}
.filter_cardFilter__2KPA0 .filter_content__3u7uR {
  padding: 30px 29px 20px 29px;
}
.filter_cardFilter__2KPA0 .filter_content__3u7uR .filter_searchFor__3rOmd {
  display: flex;
  grid-gap: 10px;
  gap: 10px;
}
.filter_cardFilter__2KPA0 .filter_content__3u7uR .filter_searchFor__3rOmd .filter_option__sAnHr {
  cursor: pointer;
  border-radius: 8px;
}
.filter_cardFilter__2KPA0 .filter_content__3u7uR .filter_searchFor__3rOmd .filter_option__sAnHr::before {
  border-radius: 8px;
}
.filter_cardFilter__2KPA0 .filter_content__3u7uR .filter_searchFor__3rOmd .filter_option__sAnHr.filter_active__Mqp3e {
  background: linear-gradient(
    191.12deg,
    #00ecb3 -91.84%,
    #24c9bf 25.42%,
    #23c8b0 126.74%
  );
  color: #fff;
}
.filter_cardFilter__2KPA0 .filter_content__3u7uR .filter_submit__1hIpR {
  margin-top: 10px;
  width: 190px;
}
.filter_cardFilter__2KPA0 .filter_content__3u7uR .filter_submit__1hIpR .filter_button__1OhR- {
  border-radius: 8px;
  cursor: pointer;
}
.filter_cardFilter__2KPA0 .filter_content__3u7uR .filter_submit__1hIpR .filter_button__1OhR-::before {
  border-radius: 8px;
}
.filter_cardFilter__2KPA0 .filter_content__3u7uR .filter_submit__1hIpR .filter_button__1OhR-:hover {
  background: linear-gradient(
    191.12deg,
    #00ecb3 -91.84%,
    #24c9bf 25.42%,
    #23c8b0 126.74%
  );
  color: #fff;
}
.filter_cardFilter__2KPA0 .filter_content__3u7uR .filter_clear__3KmwY {
  margin-top: 10px;
  width: 190px;
}
.filter_cardFilter__2KPA0 .filter_content__3u7uR .filter_clear__3KmwY .filter_button__1OhR- {
  border-radius: 8px;
  cursor: pointer;
}
.filter_cardFilter__2KPA0 .filter_content__3u7uR .filter_clear__3KmwY .filter_button__1OhR-::before {
  border-radius: 8px;
}
.filter_cardFilter__2KPA0 .filter_content__3u7uR .filter_clear__3KmwY .filter_button__1OhR-:hover {
  background: linear-gradient(
    191.12deg,
    #00ecb3 -91.84%,
    #24c9bf 25.42%,
    #23c8b0 126.74%
  );
  color: #fff;
}
.filter_cardFilter__2KPA0 .filter_content__3u7uR .filter_inner__2g7QI {
  margin-top: 25px;
  width: 200px;
  height: 280px;
  overflow-y: auto;
}
.filter_cardFilter__2KPA0 .filter_content__3u7uR .filter_inner__2g7QI .filter_checkboxGroup__2hQjL .filter_checkboxGroupHeader__2fSrb {
  position: relative;
  color: #1a1a1a;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  margin-top: 10px;
  margin-bottom: 10px;
  cursor: pointer;
  width: 100%;
}
.filter_cardFilter__2KPA0 .filter_content__3u7uR .filter_inner__2g7QI .filter_checkboxGroup__2hQjL .filter_checkboxGroupHeader__2fSrb::after {
  position: absolute;
  content: "˅";
  top: -2px;
  right: 0;
  font-size: 25px;
  font-weight: 250;
}
.filter_cardFilter__2KPA0 .filter_content__3u7uR .filter_inner__2g7QI .filter_checkboxGroup__2hQjL.filter_active__Mqp3e .filter_checkboxGroupHeader__2fSrb::after {
  top: 0px;
  font-size: 28px;
  content: "^";
}
.filter_cardFilter__2KPA0 .filter_content__3u7uR .filter_inner__2g7QI .filter_checkboxGroup__2hQjL .filter_option__sAnHr {
  display: none;
  margin-right: 5px;
  margin-left: 5px;
  margin-bottom: 8px;
}
.filter_cardFilter__2KPA0 .filter_content__3u7uR .filter_inner__2g7QI .filter_checkboxGroup__2hQjL.filter_active__Mqp3e .filter_option__sAnHr {
  display: flex;
  grid-gap: 5px;
  gap: 5px;
  align-items: center;
}
.filter_cardFilter__2KPA0 .filter_content__3u7uR .filter_inner__2g7QI .filter_checkboxGroup__2hQjL .filter_option__sAnHr .filter_checkboxLabel__3wKxw {
  padding-top: 2px;
  color: #1a1a1a;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-left: 5px;
  margin-bottom: 0px;
  overflow: hidden;
}
.filter_cardFilter__2KPA0 .filter_content__3u7uR .filter_inner__2g7QI .filter_radioGroup__2yHFG .filter_radioGroupHeader__3u9wO {
  position: relative;
  color: #555;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  margin: 5px 0px;
}
.filter_cardFilter__2KPA0 .filter_content__3u7uR .filter_inner__2g7QI .filter_radioGroup__2yHFG .filter_radioGroupHeader__3u9wO::after {
  position: absolute;
  content: "˅";
  top: -5px;
  right: 0;
  font-size: 30px;
  font-weight: 200;
}
.filter_cardFilter__2KPA0 .filter_content__3u7uR .filter_inner__2g7QI .filter_radioGroup__2yHFG.filter_active__Mqp3e .filter_radioGroupHeader__3u9wO::after {
  content: "^";
}
.filter_cardFilter__2KPA0 .filter_content__3u7uR .filter_inner__2g7QI .filter_radioGroup__2yHFG .filter_radio__3yuxq {
  display: none;
  margin-right: 5px;
}
.filter_cardFilter__2KPA0 .filter_content__3u7uR .filter_inner__2g7QI .filter_radioGroup__2yHFG.filter_active__Mqp3e .filter_radio__3yuxq {
  display: inline-block;
}
.filter_cardFilter__2KPA0 .filter_content__3u7uR .filter_inner__2g7QI .filter_radioGroup__2yHFG .filter_radioLabel__1izBX {
  display: none;
  color: #555;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  vertical-align: end;
}
.filter_cardFilter__2KPA0 .filter_content__3u7uR .filter_inner__2g7QI .filter_radioGroup__2yHFG.filter_active__Mqp3e .filter_radioLabel__1izBX {
  display: inline-block;
}
.filter_cardFilter__2KPA0 .filter_content__3u7uR .filter_inner__2g7QI .filter_inputGroup__2tzDk .filter_inputGroupHeader__3pJnJ {
  position: relative;
  color: #555;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  margin: 5px 0px;
}
.filter_cardFilter__2KPA0 .filter_content__3u7uR .filter_inner__2g7QI .filter_inputGroup__2tzDk .filter_inputGroupHeader__3pJnJ::after {
  position: absolute;
  content: "˅";
  top: -5px;
  right: 0;
  font-size: 30px;
  font-weight: 200;
}
.filter_cardFilter__2KPA0 .filter_content__3u7uR .filter_inner__2g7QI .filter_inputGroup__2tzDk.filter_active__Mqp3e .filter_inputGroupHeader__3pJnJ::after {
  content: "^";
}
.filter_cardFilter__2KPA0 .filter_content__3u7uR .filter_inner__2g7QI .filter_inputGroup__2tzDk .filter_input__HeEqM {
  display: none;
  max-height: 26px;
  border-radius: 4px;
  border: 1px solid #555;
  font-size: 14px;
}
.filter_cardFilter__2KPA0 .filter_content__3u7uR .filter_inner__2g7QI .filter_inputGroup__2tzDk .filter_input__HeEqM::placeholder {
  color: #b1b1b1;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.filter_cardFilter__2KPA0 .filter_content__3u7uR .filter_inner__2g7QI .filter_inputGroup__2tzDk.filter_active__Mqp3e .filter_input__HeEqM {
  display: block;
}
.filter_cardFilter__2KPA0 .filter_content__3u7uR .filter_inner__2g7QI .filter_betweenGroup__w6MOc .filter_betweenGroupHeader__tAz1v {
  position: relative;
  color: #555;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  margin: 5px 0px;
}
.filter_cardFilter__2KPA0 .filter_content__3u7uR .filter_inner__2g7QI .filter_betweenGroup__w6MOc .filter_betweenGroupHeader__tAz1v::after {
  position: absolute;
  content: "˅";
  top: -5px;
  right: 0;
  font-size: 30px;
  font-weight: 200;
}
.filter_cardFilter__2KPA0 .filter_content__3u7uR .filter_inner__2g7QI .filter_betweenGroup__w6MOc.filter_active__Mqp3e .filter_betweenGroupHeader__tAz1v::after {
  content: "^";
}
.filter_cardFilter__2KPA0 .filter_content__3u7uR .filter_inner__2g7QI .filter_betweenGroup__w6MOc .filter_betweenInputs__3I62q {
  display: none;
  grid-gap: 10px;
  gap: 10px;
}
.filter_cardFilter__2KPA0 .filter_content__3u7uR .filter_inner__2g7QI .filter_betweenGroup__w6MOc .filter_betweenInputs__3I62q .filter_input__HeEqM {
  max-height: 26px;
  border-radius: 4px;
  border: 1px solid #555;
  font-size: 14px;
}
.filter_cardFilter__2KPA0 .filter_content__3u7uR .filter_inner__2g7QI .filter_betweenGroup__w6MOc .filter_betweenInputs__3I62q .filter_input__HeEqM::placeholder {
  color: #b1b1b1;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.filter_cardFilter__2KPA0 .filter_content__3u7uR .filter_inner__2g7QI .filter_betweenGroup__w6MOc.filter_active__Mqp3e .filter_betweenInputs__3I62q {
  display: flex;
}

@media screen and (max-width: 1200px) {
  .filter_cardFilter__2KPA0 {
    height: 310px;
    margin-bottom: 20px;
  }
  .filter_cardFilter__2KPA0 .filter_content__3u7uR .filter_inner__2g7QI {
    height: 100px;
    width: calc(100vw - 60px);
    overflow-y: auto;
  }
  .filter_cardFilter__2KPA0 .filter_content__3u7uR .filter_inner__2g7QI .filter_checkboxGroup__2hQjL .filter_checkboxGroupHeader__2fSrb {
    width: calc(100vw - 60px);
  }
}

.search_search__ooSU7 {
  display: flex;
  justify-content: flex-start;
  grid-gap: 10px;
  gap: 10px;
  width: 100%;
  filter: drop-shadow(1px 1px 8px rgba(0, 0, 0, 0.15));
  background-color: #fff;
  border-radius: 20px;
  padding-left: 22px;
}
.search_search__ooSU7 .search_icon__12TGY {
  margin-top: 10px;
  margin-bottom: 10px;
  min-width: 22px;
}
.search_search__ooSU7 .search_input__WgDpQ {
  width: 100%;
  color: #646464;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  border: none;
  vertical-align: middle;
}
.search_search__ooSU7 .search_input__WgDpQ::placeholder {
  color: #e1e1e1;
  line-height: 18px;
  vertical-align: middle;
}

.search_search__ooSU7 .search_input__WgDpQ:focus-visible {
  outline: none;
}
.search_search__ooSU7 .search_button__2IOSO {
  position: relative;
  min-width: 65px;
  border-radius: 0 20px 20px 20px;
  border: none;
  background: linear-gradient(
    191.12deg,
    #00ecb3 -91.84%,
    #24c9bf 25.42%,
    #23c8b0 126.74%
  );
}
.search_search__ooSU7 .search_button__2IOSO::after {
  position: absolute;
  content: "";
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  visibility: hidden;
  background-color: #fff;
  opacity: 0.3;
  border-radius: 0 20px 20px 20px;
}
.search_search__ooSU7 .search_button__2IOSO:hover::after {
  visibility: visible;
}

.results_info__2bA34 .results_totals__3P7WU {
  display: flex;
  flex-wrap: wrap;
  grid-gap: 5px;
  gap: 5px;
  margin-top: 10px;
  font-size: 15px;
  font-weight: 400;
  line-height: 18px;
  text-align: left;
  color: #696969;
}
.results_results__SzGFK {
  margin-top: 10px;
  display: flex;
  flex-wrap: wrap;
  grid-gap: 20px;
  gap: 20px;
  width: 100%;
}
.results_results__SzGFK .results_result__1g0Vf {
  position: relative;
  display: flex;
  color: #555;
  width: 100%;
}
.results_results__SzGFK .results_result__1g0Vf .results_card__2xneY {
  cursor: default;
  border-radius: 20px;
  background: #fff;
  filter: drop-shadow(1px 1px 4px rgba(0, 0, 0, 0.15));
  padding: 12px 20px;
  color: #555;
  width: 100%;
}
.results_results__SzGFK .results_result__1g0Vf .results_card__2xneY .results_city__2dus1 {
  width: 100%;
  font-size: 13px;
  font-weight: 400;
  line-height: 16px;
  text-align: left;
}
.results_results__SzGFK .results_result__1g0Vf .results_card__2xneY .results_info__2bA34 {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
}
.results_results__SzGFK .results_result__1g0Vf .results_card__2xneY .results_info__2bA34 .results_about__2lube {
  display: flex;
  align-items: center;
}
.results_results__SzGFK .results_result__1g0Vf .results_card__2xneY .results_info__2bA34 .results_about__2lube img {
  width: 75px;
  height: 48px;
  object-fit: contain;
}
.results_results__SzGFK .results_result__1g0Vf .results_card__2xneY .results_info__2bA34 .results_about__2lube .results_title__2r0Tc {
  width: 400px;
  font-size: 16px;
  font-weight: 400;
  line-height: 20px;
  text-align: left;
  color: #1a1a1a;
  -webkit-line-clamp: 2;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
}
.results_results__SzGFK .results_result__1g0Vf .results_card__2xneY .results_info__2bA34 .results_toCompany__Pypp1 {
  font-size: 11px;
  font-weight: 400;
  line-height: 13.5px;
  text-align: left;
  text-decoration: none;
  color: #1a1a1a;
}
.results_results__SzGFK .results_result__1g0Vf .results_card__2xneY .results_info__2bA34 .results_toCompany__Pypp1 svg {
  margin-left: 10px;
  margin-bottom: 2px;
  fill: #1a1a1a;
}
.results_results__SzGFK .results_result__1g0Vf .results_card__2xneY .results_actions__2Xpeb {
  min-width: 100%;
}
.results_results__SzGFK .results_result__1g0Vf .results_card__2xneY .results_actions__2Xpeb .results_practices__3vGpE {
  margin-top: 10px;
}
.results_results__SzGFK .results_result__1g0Vf .results_card__2xneY .results_actions__2Xpeb .results_practices__3vGpE .results_title__2r0Tc {
  display: flex;
  flex-wrap: wrap;
  grid-gap: 5px;
  gap: 5px;
  font-size: 13px;
  font-weight: 400;
  line-height: 16px;
  text-align: left;
  color: #1a1a1a;
  margin-bottom: 10px;
}
.results_results__SzGFK .results_result__1g0Vf .results_card__2xneY .results_actions__2Xpeb .results_practices__3vGpE .results_title__2r0Tc .results_colored__3KDZS {
  background: linear-gradient(
    191.12deg,
    #00ecb3 -91.84%,
    #24c9bf 25.42%,
    #23c8b0 126.74%
  );
  background-size: cover;
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.results_practice__EV_0H + .results_practice__EV_0H {
  margin-top: 5px;
}
.results_results__SzGFK .results_result__1g0Vf .results_card__2xneY .results_actions__2Xpeb .results_practices__3vGpE .results_practice__EV_0H {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: #f5f5f5;
  border-radius: 5px;
  padding: 5px 15px;
}
.results_results__SzGFK .results_result__1g0Vf .results_card__2xneY .results_actions__2Xpeb .results_practices__3vGpE .results_practice__EV_0H .results_name__hHCYf {
  -webkit-line-clamp: 1;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
  font-size: 13px;
  font-weight: 400;
  line-height: 16px;
  text-align: left;
  color: #1a1a1a;
  max-width: 250px;
}
.results_results__SzGFK .results_result__1g0Vf .results_card__2xneY .results_actions__2Xpeb .results_practices__3vGpE .results_practice__EV_0H .results_props__1xvzA {
  display: flex;
  align-items: center;
}
.results_results__SzGFK .results_result__1g0Vf .results_card__2xneY .results_actions__2Xpeb .results_practices__3vGpE .results_practice__EV_0H .results_props__1xvzA .results_go__THFq0 {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 20px;
  border-radius: 25px;
  background: linear-gradient(
    191.12deg,
    #00ecb3 -91.84%,
    #24c9bf 25.42%,
    #23c8b0 126.74%
  );
  padding: 7px 10px;
}
.results_results__SzGFK .results_result__1g0Vf .results_card__2xneY .results_actions__2Xpeb .results_practices__3vGpE .results_practice__EV_0H .results_props__1xvzA .results_go__THFq0 svg {
  fill: #fff;
  width: 20px;
  height: 10px;
}
.results_results__SzGFK .results_result__1g0Vf .results_card__2xneY .results_actions__2Xpeb .results_mentorships__1z0Oa {
  margin-top: 10px;
}
.results_results__SzGFK .results_result__1g0Vf .results_card__2xneY .results_actions__2Xpeb .results_mentorships__1z0Oa .results_title__2r0Tc {
  display: flex;
  flex-wrap: wrap;
  grid-gap: 5px;
  gap: 5px;
  font-size: 13px;
  font-weight: 400;
  line-height: 16px;
  text-align: left;
  color: #1a1a1a;
  margin-bottom: 10px;
}
.results_results__SzGFK .results_result__1g0Vf .results_card__2xneY .results_actions__2Xpeb .results_mentorships__1z0Oa .results_title__2r0Tc .results_colored__3KDZS {
  background: linear-gradient(
    191.12deg,
    #00ecb3 -91.84%,
    #24c9bf 25.42%,
    #23c8b0 126.74%
  );
  background-size: cover;
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.results_mentorship__Rgl21 + .results_mentorship__Rgl21 {
  margin-top: 5px;
}
.results_results__SzGFK .results_result__1g0Vf .results_card__2xneY .results_actions__2Xpeb .results_mentorships__1z0Oa .results_mentorship__Rgl21 {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: #f5f5f5;
  border-radius: 5px;
  padding: 5px 15px;
  width: 100%;
}
.results_results__SzGFK .results_result__1g0Vf .results_card__2xneY .results_actions__2Xpeb .results_mentorships__1z0Oa .results_mentorship__Rgl21 .results_name__hHCYf {
  -webkit-line-clamp: 1;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
  font-size: 13px;
  font-weight: 400;
  line-height: 16px;
  text-align: left;
  color: #1a1a1a;
  max-width: 250px;
}
.results_results__SzGFK .results_result__1g0Vf .results_card__2xneY .results_actions__2Xpeb .results_mentorships__1z0Oa .results_mentorship__Rgl21 .results_description__3zSnu {
  -webkit-line-clamp: 1;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
  font-size: 13px;
  font-weight: 400;
  line-height: 16px;
  text-align: left;
  color: #1a1a1a;
  min-width: calc(100% - 250px);
  min-width: calc(100% - 250px);
}
.results_results__SzGFK .results_result__1g0Vf .results_card__2xneY .results_actions__2Xpeb .results_mentorships__1z0Oa .results_mentorship__Rgl21 .results_props__1xvzA {
  display: flex;
  align-items: center;
}
.results_results__SzGFK .results_result__1g0Vf .results_card__2xneY .results_actions__2Xpeb .results_mentorships__1z0Oa .results_mentorship__Rgl21 .results_props__1xvzA .results_go__THFq0 {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 20px;
  border-radius: 25px;
  background: linear-gradient(
    191.12deg,
    #00ecb3 -91.84%,
    #24c9bf 25.42%,
    #23c8b0 126.74%
  );
  padding: 7px 10px;
}
.results_results__SzGFK .results_result__1g0Vf .results_card__2xneY .results_actions__2Xpeb .results_mentorships__1z0Oa .results_mentorship__Rgl21 .results_props__1xvzA .results_go__THFq0 svg {
  fill: #fff;
  width: 20px;
  height: 10px;
}
.results_results__SzGFK .results_result__1g0Vf .results_card__2xneY .results_actions__2Xpeb .results_vacancies__7b9TY {
  margin-top: 10px;
}
.results_results__SzGFK .results_result__1g0Vf .results_card__2xneY .results_actions__2Xpeb .results_vacancies__7b9TY .results_title__2r0Tc {
  display: flex;
  flex-wrap: wrap;
  grid-gap: 5px;
  gap: 5px;
  font-size: 13px;
  font-weight: 400;
  line-height: 16px;
  text-align: left;
  color: #1a1a1a;
  margin-bottom: 10px;
}
.results_results__SzGFK .results_result__1g0Vf .results_card__2xneY .results_actions__2Xpeb .results_vacancies__7b9TY .results_title__2r0Tc .results_colored__3KDZS {
  background: linear-gradient(
    191.12deg,
    #00ecb3 -91.84%,
    #24c9bf 25.42%,
    #23c8b0 126.74%
  );
  background-size: cover;
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.results_vacancy__R53lq + .results_vacancy__R53lq {
  margin-top: 5px;
}
.results_results__SzGFK .results_result__1g0Vf .results_card__2xneY .results_actions__2Xpeb .results_vacancies__7b9TY .results_vacancy__R53lq {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: #f5f5f5;
  border-radius: 5px;
  padding: 5px 15px;
}
.results_results__SzGFK .results_result__1g0Vf .results_card__2xneY .results_actions__2Xpeb .results_vacancies__7b9TY .results_vacancy__R53lq .results_name__hHCYf {
  -webkit-line-clamp: 1;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
  font-size: 13px;
  font-weight: 400;
  line-height: 16px;
  text-align: left;
  color: #1a1a1a;
  width: 100%;
}
.results_results__SzGFK .results_result__1g0Vf .results_card__2xneY .results_actions__2Xpeb .results_vacancies__7b9TY .results_vacancy__R53lq .results_props__1xvzA {
  display: flex;
  align-items: center;
}
.results_results__SzGFK .results_result__1g0Vf .results_card__2xneY .results_actions__2Xpeb .results_vacancies__7b9TY .results_vacancy__R53lq .results_props__1xvzA .results_go__THFq0 {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 20px;
  border-radius: 25px;
  background: linear-gradient(
    191.12deg,
    #00ecb3 -91.84%,
    #24c9bf 25.42%,
    #23c8b0 126.74%
  );
  padding: 7px 10px;
}
.results_results__SzGFK .results_result__1g0Vf .results_card__2xneY .results_actions__2Xpeb .results_vacancies__7b9TY .results_vacancy__R53lq .results_props__1xvzA .results_go__THFq0 svg {
  fill: #fff;
  width: 20px;
  height: 10px;
}

.results_results__SzGFK .results_result__1g0Vf .results_title__2r0Tc {
  font-size: 13.5px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  -webkit-line-clamp: 3;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
}
.results_more__101rS {
  display: flex;
  justify-content: center;
  text-decoration: none;
}
.results_more__101rS .results_all__1eTjN {
  color: #000;
  font-size: 10px;
  font-weight: 300;
  line-height: 12px;
  text-align: left;
  cursor: pointer;
  position: relative;
  margin-top: 10px;
  padding: 5px 20px;
  box-shadow: 1px 1px 8px 0px rgba(0, 0, 0, 0.15);
  border-radius: 20px;
  text-decoration: none;
}
.results_more__101rS .results_all__1eTjN::before {
  content: "";
  position: absolute;
  background: linear-gradient(
    191.12deg,
    #00ecb3 -91.84%,
    #24c9bf 25.42%,
    #23c8b0 126.74%
  );
  width: 6px;
  height: 16px;
  top: calc(50% - 8px);
  left: 100%;
  clip-path: polygon(0 12.5%, 100% 50%, 0 87.5%);
}
.results_full__1lMzs {
  width: 100%;
  display: flex;
  justify-content: center;
}
@media screen and (max-width: 1200px) {
  .results_results__SzGFK .results_result__1g0Vf .results_card__2xneY .results_info__2bA34 {
    display: block;
  }
  .results_results__SzGFK .results_result__1g0Vf .results_card__2xneY .results_info__2bA34 .results_about__2lube .results_title__2r0Tc {
    width: 100%;
  }
  .results_results__SzGFK .results_result__1g0Vf .results_card__2xneY .results_actions__2Xpeb .results_practices__3vGpE .results_practice__EV_0H {
    display: block;
    padding: 10px;
  }
  .results_results__SzGFK .results_result__1g0Vf .results_card__2xneY .results_actions__2Xpeb .results_practices__3vGpE .results_practice__EV_0H .results_name__hHCYf {
    margin-bottom: 10px;
  }
  .results_results__SzGFK .results_result__1g0Vf .results_card__2xneY .results_actions__2Xpeb .results_practices__3vGpE .results_practice__EV_0H .results_props__1xvzA {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
  }
  .results_results__SzGFK .results_result__1g0Vf .results_card__2xneY .results_actions__2Xpeb .results_practices__3vGpE .results_practice__EV_0H .results_props__1xvzA .results_go__THFq0 {
    margin-left: 0px;
    width: 50px;
    margin-top: 10px;
  }
  .results_results__SzGFK .results_result__1g0Vf .results_card__2xneY .results_actions__2Xpeb .results_mentorships__1z0Oa .results_mentorship__Rgl21 {
    display: block;
    padding: 10px;
  }
  .results_results__SzGFK .results_result__1g0Vf .results_card__2xneY .results_actions__2Xpeb .results_mentorships__1z0Oa .results_mentorship__Rgl21 .results_name__hHCYf {
    margin-bottom: 10px;
  }
  .results_results__SzGFK .results_result__1g0Vf .results_card__2xneY .results_actions__2Xpeb .results_mentorships__1z0Oa .results_mentorship__Rgl21 .results_description__3zSnu {
    margin-bottom: 10px;
    min-width: none;
    max-width: none;
  }
  .results_results__SzGFK .results_result__1g0Vf .results_card__2xneY .results_actions__2Xpeb .results_mentorships__1z0Oa .results_mentorship__Rgl21 .results_props__1xvzA {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
  }
  .results_results__SzGFK .results_result__1g0Vf .results_card__2xneY .results_actions__2Xpeb .results_mentorships__1z0Oa .results_mentorship__Rgl21 .results_props__1xvzA .results_go__THFq0 {
    margin-left: 0px;
    width: 50px;
    margin-top: 10px;
  }
  .results_results__SzGFK .results_result__1g0Vf .results_card__2xneY .results_actions__2Xpeb .results_vacancies__7b9TY .results_vacancy__R53lq {
    display: block;
    padding: 10px;
  }
  .results_results__SzGFK .results_result__1g0Vf .results_card__2xneY .results_actions__2Xpeb .results_vacancies__7b9TY .results_vacancy__R53lq .results_name__hHCYf {
    margin-bottom: 10px;
  }
  .results_results__SzGFK .results_result__1g0Vf .results_card__2xneY .results_actions__2Xpeb .results_vacancies__7b9TY .results_vacancy__R53lq .results_props__1xvzA {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
  }
  .results_results__SzGFK .results_result__1g0Vf .results_card__2xneY .results_actions__2Xpeb .results_vacancies__7b9TY .results_vacancy__R53lq .results_props__1xvzA .results_go__THFq0 {
    margin-left: 0px;
    width: 50px;
    margin-top: 10px;
  }
}

.page_block__ZCYfu {
  padding: 12px;
}
@media screen and (max-width: 1200px) {
  .page_block__ZCYfu {
    padding: 0px;
  }
}

.search_container__3wbzN {
  position: absolute;
  top: 100%;
  background-color: #ffffff;
  border: 1px solid #dee2e6;
  padding: 5px;
}

.navbar_logo__KL1lT {
  cursor: pointer;
}
.navbar_search-container__9bCbm {
  display: flex;
  align-items: center;
  background-color: #ffffff;
  border: 1px solid #e1e1e1;
  border-radius: 30px;
}
.navbar_search-container__9bCbm .navbar_icon__3VKOi {
  height: 22px;
  width: 22px;
  margin-left: 22.75px;
}
.navbar_search-container__9bCbm .navbar_input__3ff6F {
  background-color: #ffffff;
  border-radius: 30px;
  border: none;
  color: #646464;
  padding: 9px 10px 7px 12.75px;
  font-size: 15px;
  font-weight: 500;
  line-height: 18px;
  vertical-align: middle;
}

.navbar_search-container__9bCbm .navbar_input__3ff6F::placeholder {
  color: #e1e1e1;
  line-height: 18px;
  vertical-align: middle;
}

.navbar_search-container__9bCbm .navbar_input__3ff6F:focus-visible {
  outline: none;
}

.navbar_login-container__1BwRu {
  display: flex;
  align-items: center;
  height: 100%;
  text-decoration: none;
}

.navbar_login-container__1BwRu .navbar_icon__3VKOi {
  height: 30px;
  width: 30px;
  margin-right: 8px;
}

.navbar_login-container__1BwRu .navbar_label__3o9CY {
  font-size: 15px;
  line-height: 18px;
  font-weight: 400;
  color: #444444;
}
@media screen and (max-width: 1200px) {
  .navbar_logo__KL1lT {
    margin-left: 5px;
  }
  .navbar_search-container__9bCbm {
    display: none;
  }
  .navbar_login-container__1BwRu{
    margin-right: 5px;
  }
}
.footer_block__10G3M {
  margin-top: 100px;
}
.footer_block__10G3M .footer_social__Rykw6 .footer_link__1qlN1 {
  color: #000;
  text-decoration: none;
  margin-right: 5px;
  font-size: 16px;
}
.footer_social__Rykw6 + .footer_social__Rykw6 {
  margin-top: 5px;
}
@media screen and (max-width: 1200px) {
  .footer_copyright__BTgpp {
    margin-left: 5px;
  }
  .footer_contacts__l_Bao {
    margin-right: 5px;
  }
}

.customModal_modal__2buuC > * {
  border: 0 !important;
  border-radius: 0.25rem;
}
.customModal_header__n6H1_ {
  padding: 0.8rem;
  border-top-left-radius: 0.25rem;
  border-top-right-radius: 0.25rem;
}
.customModal_header__n6H1_ .customModal_title__2V4VY {
  color: #495057;
  font-weight: 600;
  font-size: 1.2rem !important;
}
.customModal_body__2FjsA .customModal_label__2pMI7 {
  margin-bottom: 0.5rem;
  font-weight: 600;
  font-size: 0.75rem;
}
.customModal_body__2FjsA .customModal_input__3RDni {
  border-radius: 0.25rem;
  font-size: 0.8rem;
  padding: 0.5rem 0.9rem;
  font-weight: 400;
  color: #212529;
}
.customModal_body__2FjsA .customModal_inputCheckbox__1dxEx {
  display: block;
  border-radius: 0.25rem;
  font-size: 2.32rem;
  padding: 0.5rem 0.5rem;
  font-weight: 400;
  color: #212529;
  margin-top: 0;
}
.customModal_body__2FjsA .customModal_invalid__2_irp {
  font-size: 0.7em;
  display: block !important;
}
.customModal_body__2FjsA .customModal_checkboxGroup__3YwE6 {
  max-height: 100px;
  overflow-y: auto;
  border: 1px solid #dee2e6;
  border-radius: 0.25rem;
  padding: 0.25rem;
}
.customModal_footer__3BYV_ {
  border-top: 0;
}
.customModal_footer__3BYV_ .customModal_close__1DnNp {
  border-radius: 0.25rem;
  font-size: 14px;
}
.customModal_footer__3BYV_ .customModal_submit__pxFrU {
  border-radius: 0.25rem;
  border: 0 !important;
  background-color: #0ab39c;
  font-size: 14px;
}
.customModal_footer__3BYV_ .customModal_submit__pxFrU:hover {
  color: #fff;
  background-color: #099885;
  border-color: #088f7d;
}

.loadBlock_block__20UwI {
  position: absolute;
  top: 0;
  left: 0;
  background: #405189;
  opacity: 0.8;
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 10;
  border-radius: 0.25rem;
  animation: loadBlock_fadeIn__2PhOy 0.3s ease-in-out;
}

@keyframes loadBlock_fadeIn__2PhOy {
  from {
    opacity: 0;
  }
  to {
    opacity: 0.8;
  }
}

.button_button__34TxJ {
  cursor: default;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #fff;
  padding: 11px 30px;
  border-radius: 19.5px;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;

  --active-bg: linear-gradient(
    191deg,
    #cbec00 -91.84%,
    #700101 25.42%,
    #d0a024 126.74%
  );
  --active-color: #ffffff;
}
.button_button__34TxJ::before {
  content: "";
  background-image: linear-gradient(170deg, #c12e2e 15.81%, #cd9f29 100.43%);
  top: -1px;
  left: -1px;
  bottom: -1px;
  right: -1px;
  position: absolute;
  z-index: -1;
  border-radius: 20px;
}
.filter_cardFilter__17je9 {
  width: 100%;
  height: auto;
  z-index: 1000;
}
.filter_cardFilter__17je9 .filter_border__2Am-F {
  background: linear-gradient(
    191deg,
    #00b3ec -91.84%,
    #cbec00 -91.82%,
    #700101 25.42%,
    #d0a024 126.74%
  );
}
.filter_cardFilter__17je9 .filter_arrow__2b9Tl {
  background: linear-gradient(
    201deg,
    #00b3ec -91.84%,
    #cbec00 -91.82%,
    #700101 25.42%,
    #d0a024 126.74%
  );
}
.filter_cardFilter__17je9 .filter_content__11JAq {
  padding: 30px 29px 20px 29px;
}
.filter_cardFilter__17je9 .filter_content__11JAq .filter_searchFor__u5qPN {
  display: flex;
  grid-gap: 10px;
  gap: 10px;
}
.filter_cardFilter__17je9 .filter_content__11JAq .filter_searchFor__u5qPN .filter_option__1zv4P {
  cursor: pointer;
  border-radius: 8px;
}
.filter_cardFilter__17je9 .filter_content__11JAq .filter_searchFor__u5qPN .filter_option__1zv4P::before {
  border-radius: 8px;
}
.filter_cardFilter__17je9 .filter_content__11JAq .filter_searchFor__u5qPN .filter_option__1zv4P.filter_active__3GfVI {
  background: linear-gradient(
    191deg,
    #00b3ec -91.84%,
    #cbec00 -91.82%,
    #700101 25.42%,
    #d0a024 126.74%
  );
  color: #fff;
}
.filter_cardFilter__17je9 .filter_content__11JAq .filter_submit__2fEGw {
  margin-top: 10px;
  width: 190px;
}
.filter_cardFilter__17je9 .filter_content__11JAq .filter_submit__2fEGw .filter_button__DsyPR {
  border-radius: 8px;
  cursor: pointer;
}
.filter_cardFilter__17je9 .filter_content__11JAq .filter_submit__2fEGw .filter_button__DsyPR::before {
  border-radius: 8px;
}
.filter_cardFilter__17je9 .filter_content__11JAq .filter_submit__2fEGw .filter_button__DsyPR:hover {
  background: linear-gradient(
    191deg,
    #00b3ec -91.84%,
    #cbec00 -91.82%,
    #700101 25.42%,
    #d0a024 126.74%
  );
  color: #fff;
}
.filter_cardFilter__17je9 .filter_content__11JAq .filter_clear__3AZ_n {
  margin-top: 10px;
  width: 190px;
}
.filter_cardFilter__17je9 .filter_content__11JAq .filter_clear__3AZ_n .filter_button__DsyPR {
  border-radius: 8px;
  cursor: pointer;
}
.filter_cardFilter__17je9 .filter_content__11JAq .filter_clear__3AZ_n .filter_button__DsyPR::before {
  border-radius: 8px;
}
.filter_cardFilter__17je9 .filter_content__11JAq .filter_clear__3AZ_n .filter_button__DsyPR:hover {
  background: linear-gradient(
    191deg,
    #00b3ec -91.84%,
    #cbec00 -91.82%,
    #700101 25.42%,
    #d0a024 126.74%
  );
  color: #fff;
}
.filter_cardFilter__17je9 .filter_content__11JAq .filter_inner__1lDSL {
  margin-top: 25px;
  width: 200px;
  height: 280px;
  overflow-y: auto;
}
.filter_cardFilter__17je9 .filter_content__11JAq .filter_inner__1lDSL .filter_checkboxGroup__1r6rl .filter_checkboxGroupHeader__2hQ_J {
  position: relative;
  color: #1a1a1a;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  margin-top: 10px;
  margin-bottom: 10px;
  cursor: pointer;
  width: 100%;
}
.filter_cardFilter__17je9 .filter_content__11JAq .filter_inner__1lDSL .filter_checkboxGroup__1r6rl .filter_checkboxGroupHeader__2hQ_J::after {
  position: absolute;
  content: "˅";
  top: -2px;
  right: 0;
  font-size: 25px;
  font-weight: 250;
}
.filter_cardFilter__17je9 .filter_content__11JAq .filter_inner__1lDSL .filter_checkboxGroup__1r6rl.filter_active__3GfVI .filter_checkboxGroupHeader__2hQ_J::after {
  top: 0px;
  font-size: 28px;
  content: "^";
}
.filter_cardFilter__17je9 .filter_content__11JAq .filter_inner__1lDSL .filter_checkboxGroup__1r6rl .filter_option__1zv4P {
  display: none;
  margin-right: 5px;
  margin-left: 5px;
  margin-bottom: 8px;
}
.filter_cardFilter__17je9 .filter_content__11JAq .filter_inner__1lDSL .filter_checkboxGroup__1r6rl.filter_active__3GfVI .filter_option__1zv4P {
  display: flex;
  grid-gap: 5px;
  gap: 5px;
  align-items: center;
}
.filter_cardFilter__17je9 .filter_content__11JAq .filter_inner__1lDSL .filter_checkboxGroup__1r6rl .filter_option__1zv4P .filter_checkboxLabel__2Vrng {
  padding-top: 2px;
  color: #1a1a1a;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-left: 5px;
  margin-bottom: 0px;
  overflow: hidden;
}
.filter_cardFilter__17je9 .filter_content__11JAq .filter_inner__1lDSL .filter_radioGroup__21xhR .filter_radioGroupHeader__3Jazz {
  position: relative;
  color: #555;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  margin: 5px 0px;
}
.filter_cardFilter__17je9 .filter_content__11JAq .filter_inner__1lDSL .filter_radioGroup__21xhR .filter_radioGroupHeader__3Jazz::after {
  position: absolute;
  content: "˅";
  top: -5px;
  right: 0;
  font-size: 30px;
  font-weight: 200;
}
.filter_cardFilter__17je9 .filter_content__11JAq .filter_inner__1lDSL .filter_radioGroup__21xhR.filter_active__3GfVI .filter_radioGroupHeader__3Jazz::after {
  content: "^";
}
.filter_cardFilter__17je9 .filter_content__11JAq .filter_inner__1lDSL .filter_radioGroup__21xhR .filter_radio__wZsZ0 {
  display: none;
  margin-right: 5px;
}
.filter_cardFilter__17je9 .filter_content__11JAq .filter_inner__1lDSL .filter_radioGroup__21xhR.filter_active__3GfVI .filter_radio__wZsZ0 {
  display: inline-block;
}
.filter_cardFilter__17je9 .filter_content__11JAq .filter_inner__1lDSL .filter_radioGroup__21xhR .filter_radioLabel__1N9sU {
  display: none;
  color: #555;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  vertical-align: end;
}
.filter_cardFilter__17je9 .filter_content__11JAq .filter_inner__1lDSL .filter_radioGroup__21xhR.filter_active__3GfVI .filter_radioLabel__1N9sU {
  display: inline-block;
}
.filter_cardFilter__17je9 .filter_content__11JAq .filter_inner__1lDSL .filter_inputGroup__1tTaR .filter_inputGroupHeader__1hfHA {
  position: relative;
  color: #555;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  margin: 5px 0px;
}
.filter_cardFilter__17je9 .filter_content__11JAq .filter_inner__1lDSL .filter_inputGroup__1tTaR .filter_inputGroupHeader__1hfHA::after {
  position: absolute;
  content: "˅";
  top: -5px;
  right: 0;
  font-size: 30px;
  font-weight: 200;
}
.filter_cardFilter__17je9 .filter_content__11JAq .filter_inner__1lDSL .filter_inputGroup__1tTaR.filter_active__3GfVI .filter_inputGroupHeader__1hfHA::after {
  content: "^";
}
.filter_cardFilter__17je9 .filter_content__11JAq .filter_inner__1lDSL .filter_inputGroup__1tTaR .filter_input__1HWVr {
  display: none;
  max-height: 26px;
  border-radius: 4px;
  border: 1px solid #555;
  font-size: 14px;
}
.filter_cardFilter__17je9 .filter_content__11JAq .filter_inner__1lDSL .filter_inputGroup__1tTaR .filter_input__1HWVr::placeholder {
  color: #b1b1b1;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.filter_cardFilter__17je9 .filter_content__11JAq .filter_inner__1lDSL .filter_inputGroup__1tTaR.filter_active__3GfVI .filter_input__1HWVr {
  display: block;
}
.filter_cardFilter__17je9 .filter_content__11JAq .filter_inner__1lDSL .filter_betweenGroup__1RR-a .filter_betweenGroupHeader__1C5lX {
  position: relative;
  color: #555;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  margin: 5px 0px;
}
.filter_cardFilter__17je9 .filter_content__11JAq .filter_inner__1lDSL .filter_betweenGroup__1RR-a .filter_betweenGroupHeader__1C5lX::after {
  position: absolute;
  content: "˅";
  top: -5px;
  right: 0;
  font-size: 30px;
  font-weight: 200;
}
.filter_cardFilter__17je9 .filter_content__11JAq .filter_inner__1lDSL .filter_betweenGroup__1RR-a.filter_active__3GfVI .filter_betweenGroupHeader__1C5lX::after {
  content: "^";
}
.filter_cardFilter__17je9 .filter_content__11JAq .filter_inner__1lDSL .filter_betweenGroup__1RR-a .filter_betweenInputs__dJYCI {
  display: none;
  grid-gap: 10px;
  gap: 10px;
}
.filter_cardFilter__17je9 .filter_content__11JAq .filter_inner__1lDSL .filter_betweenGroup__1RR-a .filter_betweenInputs__dJYCI .filter_input__1HWVr {
  max-height: 26px;
  border-radius: 4px;
  border: 1px solid #555;
  font-size: 14px;
}
.filter_cardFilter__17je9 .filter_content__11JAq .filter_inner__1lDSL .filter_betweenGroup__1RR-a .filter_betweenInputs__dJYCI .filter_input__1HWVr::placeholder {
  color: #b1b1b1;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.filter_cardFilter__17je9 .filter_content__11JAq .filter_inner__1lDSL .filter_betweenGroup__1RR-a.filter_active__3GfVI .filter_betweenInputs__dJYCI {
  display: flex;
}

@media screen and (max-width: 1200px) {
  .filter_cardFilter__17je9 {
    height: auto;
    margin-bottom: 20px;
    width: auto;
  }
  .filter_cardFilter__17je9 .filter_content__11JAq .filter_inner__1lDSL {
    height: 100px;
    width: 100%;
    overflow-y: auto;
  }
  .filter_cardFilter__17je9 .filter_content__11JAq .filter_inner__1lDSL .filter_checkboxGroup__1r6rl .filter_checkboxGroupHeader__2hQ_J {
    width: 100%;
  }
}

.search_search__1cKOk {
  display: flex;
  justify-content: flex-start;
  grid-gap: 10px;
  gap: 10px;
  width: 100%;
  filter: drop-shadow(1px 1px 8px rgba(0, 0, 0, 0.15));
  background-color: #fff;
  border-radius: 20px;
  padding-left: 22px;
}
.search_search__1cKOk .search_icon__3vGpx {
  margin-top: 10px;
  margin-bottom: 10px;
  min-width: 22px;
}
.search_search__1cKOk .search_input__3i83- {
  width: 100%;
  color: #646464;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  border: none;
  vertical-align: middle;
}
.search_search__1cKOk .search_input__3i83-::placeholder {
  color: #e1e1e1;
  line-height: 18px;
  vertical-align: middle;
}

.search_search__1cKOk .search_input__3i83-:focus-visible {
  outline: none;
}
.search_search__1cKOk .search_button__25qXM {
  position: relative;
  min-width: 65px;
  border-radius: 0 20px 20px 20px;
  border: none;
  background: linear-gradient(
    191deg,
    #00b3ec -91.84%,
    #cbec00 -91.82%,
    #700101 25.42%,
    #d0a024 126.74%
  );
}
.search_search__1cKOk .search_button__25qXM::after {
  position: absolute;
  content: "";
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  visibility: hidden;
  background-color: #fff;
  opacity: 0.3;
  border-radius: 0 20px 20px 20px;
}
.search_search__1cKOk .search_button__25qXM:hover::after {
  visibility: visible;
}

.results_info__1ZMQL .results_totals__3WXnr {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  grid-gap: 5px;
  gap: 5px;
  margin-top: 10px;
  font-size: 15px;
  font-weight: 400;
  line-height: 18px;
  text-align: left;
  color: #696969;
}
.results_info__1ZMQL .results_totals__3WXnr .results_actions__1bHoo {
  cursor: pointer;
  display: flex;
  align-items: center;
  grid-gap: 10px;
  gap: 10px;
}
.results_info__1ZMQL .results_totals__3WXnr .results_actions__1bHoo .results_show__2Ue4g {
  color: #700101;
}
.results_results__1JmLY {
  margin-top: 10px;
  display: flex;
  flex-wrap: wrap;
  grid-gap: 20px;
  gap: 20px;
  width: 100%;
}
.results_results__1JmLY .results_result__WUG7g {
  position: relative;
  display: flex;
  color: #555;
  width: 100%;
}
.results_results__1JmLY .results_result__WUG7g .results_card__oCtD8 {
  cursor: default;
  border-radius: 20px;
  background: #fff;
  filter: drop-shadow(1px 1px 4px rgba(0, 0, 0, 0.15));
  padding: 12px 20px;
  color: #555;
  width: 100%;
}
.results_results__1JmLY .results_result__WUG7g .results_card__oCtD8 .results_city__3LYmD {
  width: 100%;
  font-size: 13px;
  font-weight: 400;
  line-height: 16px;
  text-align: left;
}
.results_results__1JmLY .results_result__WUG7g .results_card__oCtD8 .results_info__1ZMQL {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.results_results__1JmLY .results_result__WUG7g .results_card__oCtD8 .results_info__1ZMQL .results_about__183hQ {
  display: flex;
  align-items: center;
}
.results_results__1JmLY .results_result__WUG7g .results_card__oCtD8 .results_info__1ZMQL .results_about__183hQ .results_select__1PgF9 {
  min-width: 18px;
  min-height: 18px;
  border: 1.5px solid;
  border-radius: 4px;
}
.results_results__1JmLY .results_result__WUG7g .results_card__oCtD8 .results_info__1ZMQL .results_about__183hQ .results_select__1PgF9.results_selected__1SOl8 {
  border: none;
  width: 20px;
  height: 20px;
}
.results_results__1JmLY .results_result__WUG7g .results_card__oCtD8 .results_info__1ZMQL .results_about__183hQ .results_select__1PgF9 img {
  display: none;
  width: 100%;
  height: 100%;
}
.results_results__1JmLY .results_result__WUG7g .results_card__oCtD8 .results_info__1ZMQL .results_about__183hQ .results_select__1PgF9.results_selected__1SOl8 img {
  display: block;
}
.results_results__1JmLY .results_result__WUG7g .results_card__oCtD8 .results_info__1ZMQL .results_about__183hQ img {
  width: 75px;
  height: 48px;
  object-fit: contain;
}
.results_results__1JmLY .results_result__WUG7g .results_card__oCtD8 .results_info__1ZMQL .results_about__183hQ .results_title__1qpqh {
  width: 400px;
  font-size: 16px;
  font-weight: 400;
  line-height: 20px;
  text-align: left;
  color: #1a1a1a;
  -webkit-line-clamp: 2;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
}
.results_results__1JmLY .results_result__WUG7g .results_card__oCtD8 .results_info__1ZMQL .results_toCompany__11gM3 {
  font-size: 11px;
  font-weight: 400;
  line-height: 13.5px;
  text-align: left;
  text-decoration: none;
  color: #1a1a1a;
}
.results_results__1JmLY .results_result__WUG7g .results_card__oCtD8 .results_info__1ZMQL .results_toCompany__11gM3 svg {
  margin-left: 10px;
  margin-bottom: 2px;
  fill: #1a1a1a;
}
.results_results__1JmLY .results_result__WUG7g .results_card__oCtD8 .results_specialities__1fSfB {
  margin-top: 10px;
}
.results_results__1JmLY .results_result__WUG7g .results_card__oCtD8 .results_specialities__1fSfB .results_title__1qpqh {
  display: flex;
  flex-wrap: wrap;
  grid-gap: 5px;
  gap: 5px;
  font-size: 13px;
  font-weight: 400;
  line-height: 16px;
  text-align: left;
  color: #1a1a1a;
  margin-bottom: 10px;
}
.results_results__1JmLY .results_result__WUG7g .results_card__oCtD8 .results_specialities__1fSfB .results_title__1qpqh .results_colored__rJS6J {
  background: linear-gradient(
    191deg,
    #00b3ec -91.84%,
    #cbec00 -91.82%,
    #700101 25.42%,
    #d0a024 126.74%
  );
  background-size: cover;
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.results_speciality__mrZFH + .results_speciality__mrZFH {
  margin-top: 5px;
}
.results_results__1JmLY .results_result__WUG7g .results_card__oCtD8 .results_specialities__1fSfB .results_speciality__mrZFH {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: #f5f5f5;
  border-radius: 5px;
  padding: 5px 15px;
}
.results_results__1JmLY .results_result__WUG7g .results_card__oCtD8 .results_specialities__1fSfB .results_speciality__mrZFH .results_name__35aGJ {
  -webkit-line-clamp: 1;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
  font-size: 13px;
  font-weight: 400;
  line-height: 16px;
  text-align: left;
  color: #1a1a1a;
  max-width: 250px;
}
.results_results__1JmLY .results_result__WUG7g .results_card__oCtD8 .results_specialities__1fSfB .results_speciality__mrZFH .results_props__17mgL {
  display: flex;
  align-items: center;
}
.results_results__1JmLY .results_result__WUG7g .results_card__oCtD8 .results_specialities__1fSfB .results_speciality__mrZFH .results_props__17mgL .results_prop__374S6 {
  position: relative;
  justify-content: space-between;
  background-color: #fff;
  padding: 4px 10px;
  display: flex;
  align-items: center;
  color: #1a1a1a;
  width: 150px;
}
.results_results__1JmLY .results_result__WUG7g .results_card__oCtD8 .results_specialities__1fSfB .results_speciality__mrZFH .results_props__17mgL .results_prop__374S6 .results_name__35aGJ {
  font-size: 10px;
  font-weight: 400;
  line-height: 10px;
  text-align: left;
}
.results_results__1JmLY .results_result__WUG7g .results_card__oCtD8 .results_specialities__1fSfB .results_speciality__mrZFH .results_props__17mgL .results_prop__374S6 .results_value__1lg9k {
  margin-left: 5px;
  font-size: 13px;
  font-weight: 400;
  line-height: 15.73px;
  text-align: left;
}
.results_results__1JmLY .results_result__WUG7g .results_card__oCtD8 .results_specialities__1fSfB .results_speciality__mrZFH .results_props__17mgL .results_prop__374S6 .results_separator__2U9WE {
  position: absolute;
  border: 1px solid #f5f5f5;
  height: 12px;
  right: -1px;
}
.results_results__1JmLY .results_result__WUG7g .results_card__oCtD8 .results_specialities__1fSfB .results_speciality__mrZFH .results_props__17mgL .results_go__2bewh {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 20px;
  border-radius: 25px;
  background: linear-gradient(
    191deg,
    #00b3ec -91.84%,
    #cbec00 -91.82%,
    #700101 25.42%,
    #d0a024 126.74%
  );
  padding: 7px 10px;
}
.results_results__1JmLY .results_result__WUG7g .results_card__oCtD8 .results_specialities__1fSfB .results_speciality__mrZFH .results_props__17mgL .results_go__2bewh svg {
  fill: #fff;
  width: 20px;
  height: 10px;
}

.results_results__1JmLY .results_result__WUG7g .results_title__1qpqh {
  font-size: 13.5px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  -webkit-line-clamp: 3;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
}
.results_more__3nDMF {
  display: flex;
  justify-content: center;
  text-decoration: none;
}
.results_more__3nDMF .results_all__upxVA {
  color: #000;
  font-size: 10px;
  font-weight: 300;
  line-height: 12px;
  text-align: left;
  cursor: pointer;
  position: relative;
  margin-top: 10px;
  padding: 5px 20px;
  box-shadow: 1px 1px 8px 0px rgba(0, 0, 0, 0.15);
  border-radius: 20px;
  text-decoration: none;
}
.results_more__3nDMF .results_all__upxVA::before {
  content: "";
  position: absolute;
  background: linear-gradient(
    191deg,
    #00b3ec -91.84%,
    #cbec00 -91.82%,
    #700101 25.42%,
    #d0a024 126.74%
  );
  width: 6px;
  height: 16px;
  top: calc(50% - 8px);
  left: 100%;
  clip-path: polygon(0 12.5%, 100% 50%, 0 87.5%);
}
.results_full__3yNjb {
  width: 100%;
  display: flex;
  justify-content: center;
}
@media screen and (max-width: 1200px) {
  .results_results__1JmLY .results_result__WUG7g .results_card__oCtD8 .results_info__1ZMQL {
    display: block;
  }
  .results_results__1JmLY .results_result__WUG7g .results_card__oCtD8 .results_info__1ZMQL .results_about__183hQ .results_title__1qpqh {
    width: 100%;
  }
  .results_results__1JmLY .results_result__WUG7g .results_card__oCtD8 .results_specialities__1fSfB .results_speciality__mrZFH {
    display: block;
    padding: 10px;
  }
  .results_results__1JmLY .results_result__WUG7g .results_card__oCtD8 .results_specialities__1fSfB .results_speciality__mrZFH .results_name__35aGJ {
    margin-bottom: 10px;
  }
  .results_results__1JmLY .results_result__WUG7g .results_card__oCtD8 .results_specialities__1fSfB .results_speciality__mrZFH .results_props__17mgL {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
  }
  .results_results__1JmLY .results_result__WUG7g .results_card__oCtD8 .results_specialities__1fSfB .results_speciality__mrZFH .results_props__17mgL .results_prop__374S6 {
    width: 100%;
  }
  .results_results__1JmLY .results_result__WUG7g .results_card__oCtD8 .results_specialities__1fSfB .results_speciality__mrZFH .results_props__17mgL .results_prop__374S6 .results_name__35aGJ {
    margin-bottom: 0px;
  }
  .results_results__1JmLY .results_result__WUG7g .results_card__oCtD8 .results_specialities__1fSfB .results_speciality__mrZFH .results_props__17mgL .results_prop__374S6 .results_separator__2U9WE {
    display: none;
  }
  .results_results__1JmLY .results_result__WUG7g .results_card__oCtD8 .results_specialities__1fSfB .results_speciality__mrZFH .results_props__17mgL .results_go__2bewh {
    margin-left: 0px;
    width: 50px;
    margin-top: 10px;
  }
}

.pagination_prev__3PGwM {
  background: linear-gradient(
    191deg,
    #00b3ec -91.84%,
    #cbec00 -91.82%,
    #700101 25.42%,
    #d0a024 126.74%
  ) !important;
}
.pagination_current__8EWvs {
  color: #700101 !important;
}
.pagination_next__2H7ym {
  background: linear-gradient(
    191deg,
    #00b3ec -91.84%,
    #cbec00 -91.82%,
    #700101 25.42%,
    #d0a024 126.74%
  ) !important;
}



.chooser_container__2iz08 {
  position: absolute;
  top: 50px;
  right: 0;
  border-radius: 10px;
}

.chooser_background-container__1AaOp {
  position: absolute;
  right: 0px;
  top: -125px;
  z-index: -1;
}

.chooser_button__2M897 {
  width: 55px;
  cursor: pointer;
  padding: 15px 15px 15px 15px;
  color: #ffffff;
  writing-mode: vertical-rl;
  text-align: center;
  justify-items: center;
  font-size: 15px;
  font-weight: 400;
}

.chooser_button__2M897.chooser_top__2QVB5 {
  margin-top: -40px;
  padding-top: 40px;
}

.chooser_button__2M897.chooser_bottom__eq2VV {
  margin-bottom: -40px;
  padding-bottom: 40px;
}

.chooser_button__2M897:hover {
  background-color: rgba(255, 255, 255, 0.4);
  color: #014170;
}

.chooser_line__2Pm0u {
  margin-left: 12.5px;
  width: 30px;
  height: 1px;
  background-color: #08FA99;
}

.about_block__37r3M {
  position: relative;
  margin-top: 10px;
}

.about_content__2ri6J {
  display: flex;
}

.about_background__ttMiD {
  position: absolute;
  z-index: -1;
  top: -10px;
  left: -360px;
}

.about_info__3xITW {
  margin-top: 55px;
  width: 625px;
}

.about_info__3xITW .about_header__aX_P4 {
  font-size: 65px;
  font-style: normal;
  font-weight: 900;
  line-height: 100px;
  text-transform: uppercase;
  margin: 0;
}

.about_info__3xITW .about_header__aX_P4 .about_text__2A0Ip {
  background: url(/static/media/background-space.8699e94d.png),
    lightgray 0px -61.53px / 137.633% 239.84% no-repeat;
}

.about_info__3xITW .about_header__aX_P4 .about_filter__2Eu5p {
  background: rgba(154, 70, 16, 0.7);
}

.about_info__3xITW .about_subheader__32t4q {
  margin-top: 0px;
  font-size: 18px;
  font-weight: 400;
  line-height: 22px;
  color: #333333;
}

.about_info__3xITW .about_cards__3AJlT {
  display: flex;
  flex-wrap: wrap;
  grid-gap: 10px;
  gap: 10px;
  margin-top: 25px;
}

.about_info__3xITW .about_cards__3AJlT .about_card__2dK8B {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  width: 300px;
  height: 108px;
  box-shadow: 1px 1px 8px 0px #00000026;
  border-radius: 10px;
  padding: 7px 10px 12px 20px;
}
.about_info__3xITW .about_cards__3AJlT .about_card__2dK8B .about_icon__qOH_Y {
  height: 60px;
  margin-left: -5px;
}
.about_info__3xITW .about_cards__3AJlT .about_card__2dK8B .about_icon__qOH_Y img {
  height: 100%;
}
.about_info__3xITW .about_cards__3AJlT .about_card__2dK8B .about_button__2kBF1 {
  position: relative;
  font-size: 12px;
  font-weight: 300;
  line-height: 14.52px;
  text-align: left;
  box-shadow: 1px 1px 8px 0px #00000026;
  border-radius: 20px;
  padding: 6px 20px;
  margin-right: 20px;
  color: #333333;
  text-decoration: none;
  cursor: pointer;
}
.about_info__3xITW .about_cards__3AJlT .about_card__2dK8B .about_button__2kBF1::after {
  content: "";
  position: absolute;
  background: linear-gradient(
    191deg,
    #cbec00 -91.84%,
    #700101 25.42%,
    #d0a024 126.74%
  );
  width: 6px;
  height: 16px;
  top: calc(50% - 8px);
  right: -10px;
  clip-path: polygon(0 12.5%, 100% 50%, 0 87.5%);
}
.about_info__3xITW .about_cards__3AJlT .about_card__2dK8B .about_title__3bCPV {
  font-size: 16px;
  font-weight: 600;
  line-height: 19.25px;
  text-align: left;
  background: #9a4610;
  background-size: cover;
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.about_info__3xITW .about_subheader__32t4q span {
  position: relative;
  cursor: default;
}

.about_info__3xITW .about_subheader__32t4q span:hover div {
  visibility: visible;
}

.about_info__3xITW .about_description__2csPG {
  margin-top: 17px;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  color: #333333;
}

.about_info__3xITW .about_more__1cZ6x {
  margin-top: 17px;
  cursor: pointer;
  width: 177px;
  height: 47px;
  font-weight: 400;
  font-size: 15px;
  line-height: 18px;
  color: #555555;
}
.about_info__3xITW .about_more__1cZ6x:hover .about_fill__1m466,
.about_info__3xITW .about_more__1cZ6x .about_border__1x0pr,
.about_info__3xITW .about_more__1cZ6x .about_arrow__2qsPi {
  background-image: linear-gradient(
    191.12deg,
    #cbec00 -91.84%,
    #700101 25.42%,
    #d0a024 126.74%
  );
}
.about_card__2dK8B {
  width: 460px;
  height: 450px;
}
.about_card__2dK8B .about_border__1x0pr {
  background-image: linear-gradient(
    191.12deg,
    #cbec00 -91.84%,
    #700101 25.42%,
    #d0a024 126.74%
  );
}
.about_card__2dK8B .about_arrow__2qsPi {
  background-image: linear-gradient(
    201.12deg,
    #cbec00 -91.84%,
    #700101 25.42%,
    #d0a024 186.74%
  );
}
.about_hint__33Nlp {
  visibility: hidden;
  position: absolute;
  z-index: 999;
  top: 0;
  left: 85%;
  min-width: 280px;
  max-width: 280px;
}
.about_hint__33Nlp .about_description__2csPG {
  background: url(/static/media/about-background-hint.bfcbb6bd.svg);
  background-size: 100% auto;
  background-repeat: no-repeat;
  color: #555;
  text-align: center;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  padding: 22px 16px 20px 16px;
  word-wrap: break-word;
}
@media screen and (max-width: 1200px) {
  .about_content__2ri6J {
    display: block;
  }
  .about_info__3xITW {
    width: 100%;
    margin-left: 5px;
    margin-top: 20px;
  }
  .about_info__3xITW .about_header__aX_P4 {
    font-size: 8vw;
    line-height: 10vw;
  }
  .about_info__3xITW .about_subheader__32t4q {
    font-size: 1rem;
    width: calc(100vw - 75px);
  }
  .about_info__3xITW .about_cards__3AJlT {
    margin-top: 180px;
    width: calc(100vw - 10px);
  }
  .about_info__3xITW .about_cards__3AJlT .about_card__2dK8B {
    width: 100%;
  }
  .about_info__3xITW .about_description__2csPG {
    width: calc(100vw - 75px);
  }
  .about_hint__33Nlp {
    display: none;
  }
  .about_helper__2wztL {
    display: none;
  }
  .about_background__ttMiD {
    display: none;
  }
}

.filter_filter__ZxC4Y .filter_searchFor__fCvTS {
  display: flex;
  grid-gap: 10px;
  gap: 10px;
}
.filter_filter__ZxC4Y .filter_searchFor__fCvTS .filter_option__3lg0i {
  cursor: pointer;
  border-radius: 8px;
}
.filter_filter__ZxC4Y .filter_searchFor__fCvTS .filter_option__3lg0i::before {
  border-radius: 8px;
}
.filter_filter__ZxC4Y .filter_searchFor__fCvTS .filter_option__3lg0i.filter_active__201hE {
  background: linear-gradient(
    191deg,
    #cbec00 -91.84%,
    #700101 25.42%,
    #d0a024 126.74%
  );
  color: #fff;
}
.filter_filter__ZxC4Y .filter_submit__1eM-V {
  margin-top: 10px;
  width: 190px;
}
.filter_filter__ZxC4Y .filter_submit__1eM-V .filter_button__1TwIm {
  border-radius: 8px;
  cursor: pointer;
}
.filter_filter__ZxC4Y .filter_submit__1eM-V .filter_button__1TwIm::before {
  border-radius: 8px;
}
.filter_filter__ZxC4Y .filter_submit__1eM-V .filter_button__1TwIm:hover {
  background: linear-gradient(
    191deg,
    #cbec00 -91.84%,
    #700101 25.42%,
    #d0a024 126.74%
  );
  color: #fff;
}
.filter_filter__ZxC4Y .filter_clear__3_suQ {
  margin-top: 10px;
  width: 190px;
}
.filter_filter__ZxC4Y .filter_clear__3_suQ .filter_button__1TwIm {
  border-radius: 8px;
  cursor: pointer;
}
.filter_filter__ZxC4Y .filter_clear__3_suQ .filter_button__1TwIm::before {
  border-radius: 8px;
}
.filter_filter__ZxC4Y .filter_clear__3_suQ .filter_button__1TwIm:hover {
  background: linear-gradient(
    191deg,
    #cbec00 -91.84%,
    #700101 25.42%,
    #d0a024 126.74%
  );
  color: #fff;
}
.filter_filter__ZxC4Y .filter_inner__2X-NI {
  margin-top: 25px;
  width: 280px;
  height: 280px;
  overflow-y: auto;
}
.filter_filter__ZxC4Y .filter_inner__2X-NI .filter_checkboxGroup__WO0OF .filter_checkboxGroupHeader__g0w-v {
  position: relative;
  color: #1a1a1a;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  margin-top: 10px;
  margin-bottom: 10px;
  cursor: pointer;
  width: 270px;
}
.filter_filter__ZxC4Y .filter_inner__2X-NI .filter_checkboxGroup__WO0OF .filter_checkboxGroupHeader__g0w-v::after {
  position: absolute;
  content: "˅";
  top: -2px;
  right: 0;
  font-size: 25px;
  font-weight: 250;
}
.filter_filter__ZxC4Y .filter_inner__2X-NI .filter_checkboxGroup__WO0OF.filter_active__201hE .filter_checkboxGroupHeader__g0w-v::after {
  top: 0px;
  font-size: 28px;
  content: "^";
}
.filter_filter__ZxC4Y .filter_inner__2X-NI .filter_checkboxGroup__WO0OF .filter_option__3lg0i {
  display: none;
  margin-right: 5px;
  margin-left: 5px;
  margin-bottom: 8px;
}
.filter_filter__ZxC4Y .filter_inner__2X-NI .filter_checkboxGroup__WO0OF.filter_active__201hE .filter_option__3lg0i {
  display: flex;
  grid-gap: 5px;
  gap: 5px;
  align-items: center;
}
.filter_filter__ZxC4Y .filter_inner__2X-NI .filter_checkboxGroup__WO0OF .filter_option__3lg0i .filter_checkboxLabel__2ikvD {
  padding-top: 2px;
  color: #1a1a1a;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-left: 5px;
  margin-bottom: 0px;
}

@media screen and (max-width: 1200px) {
  .filter_filter__ZxC4Y .filter_inner__2X-NI {
    height: 100px;
    width: calc(100vw - 60px);
    overflow-y: auto;
  }
  .filter_filter__ZxC4Y .filter_inner__2X-NI .filter_checkboxGroup__WO0OF .filter_checkboxGroupHeader__g0w-v {
    width: calc(100vw - 60px);
  }
}

.search_cardSearch__bn9LF {
  width: 100%;
  height: 820px;
}
.search_cardSearch__bn9LF .search_border__3RKM0 {
  background: linear-gradient(
    191deg,
    #00b3ec -91.84%,
    #cbec00 -91.82%,
    #700101 25.42%,
    #d0a024 126.74%
  );
}
.search_cardSearch__bn9LF .search_arrow__15qvi {
  background: linear-gradient(
    201deg,
    #00b3ec -91.84%,
    #cbec00 -91.82%,
    #700101 25.42%,
    #d0a024 126.74%
  );
}
.search_cardSearch__bn9LF .search_inner__NO33e {
  display: flex;
  grid-gap: 40px;
  gap: 40px;
  padding: 30px 29px 20px 29px;
}
.search_cardSearch__bn9LF .search_content__25Fhd{
  width: 100%;
}
.search_cardSearch__bn9LF .search_content__25Fhd .search_search__3PODU {
  display: flex;
  justify-content: flex-start;
  grid-gap: 10px;
  gap: 10px;
  width: 100%;
  filter: drop-shadow(1px 1px 8px rgba(0, 0, 0, 0.15));
  background-color: rgba(239, 239, 239, 1);
  border-radius: 20px;
  padding-left: 22px;
}
.search_cardSearch__bn9LF .search_content__25Fhd .search_search__3PODU .search_icon__3DdhQ {
  margin-top: 10px;
  margin-bottom: 10px;
  min-width: 22px;
}
.search_cardSearch__bn9LF .search_content__25Fhd .search_search__3PODU .search_input__3ewjo {
  width: 100%;
  color: #646464;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  border: none;
  vertical-align: middle;
  background-color: rgba(239, 239, 239, 1);
}
.search_cardSearch__bn9LF .search_content__25Fhd .search_search__3PODU .search_input__3ewjo::placeholder,
.search_cardSearch__bn9LF .search_content__25Fhd .search_search__3PODU .search_input__3ewjo::-webkit-input-placeholder {
  color: #000;
  line-height: 18px;
  vertical-align: middle;
}

.search_cardSearch__bn9LF .search_content__25Fhd .search_search__3PODU .search_input__3ewjo:focus-visible {
  outline: none;
}
.search_cardSearch__bn9LF .search_content__25Fhd .search_search__3PODU .search_button__2mln6 {
  position: relative;
  min-width: 65px;
  border-radius: 0 20px 20px 20px;
  border: none;
  background: linear-gradient(
    191deg,
    #00b3ec -91.84%,
    #cbec00 -91.82%,
    #700101 25.42%,
    #d0a024 126.74%
  );
}
.search_cardSearch__bn9LF .search_content__25Fhd .search_search__3PODU .search_button__2mln6::after {
  position: absolute;
  content: "";
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  visibility: hidden;
  background-color: #fff;
  opacity: 0.3;
  border-radius: 0 20px 20px 20px;
}
.search_cardSearch__bn9LF .search_content__25Fhd .search_search__3PODU .search_button__2mln6:hover::after {
  visibility: visible;
}
.search_cardSearch__bn9LF .search_content__25Fhd .search_info__2OxAl {
  margin-top: 5px;
  display: flex;
  flex-wrap: wrap;
  align-content: space-between;
  width: 100%;
}
.search_cardSearch__bn9LF .search_content__25Fhd .search_info__2OxAl .search_totals__3haf4 {
  display: flex;
  flex-wrap: wrap;
  grid-gap: 5px;
  gap: 5px;
  margin-top: 10px;
  font-size: 15px;
  font-weight: 400;
  line-height: 18px;
  text-align: left;
  color: #696969;
}
.search_cardSearch__bn9LF .search_content__25Fhd .search_results__3ycUi {
  margin-top: 10px;
  display: flex;
  flex-wrap: wrap;
  grid-gap: 20px;
  gap: 20px;
  width: 100%;
}
.search_cardSearch__bn9LF .search_content__25Fhd .search_results__3ycUi .search_result__3jeA2 {
  position: relative;
  display: flex;
  color: #555;
  width: 100%;
}
.search_cardSearch__bn9LF .search_content__25Fhd .search_results__3ycUi .search_result__3jeA2 .search_card__mdsGa {
  cursor: default;
  border-radius: 20px;
  background: #fff;
  filter: drop-shadow(1px 1px 4px rgba(0, 0, 0, 0.15));
  padding: 12px 20px;
  color: #555;
  width: 100%;
}
.search_cardSearch__bn9LF .search_content__25Fhd .search_results__3ycUi .search_result__3jeA2 .search_card__mdsGa .search_city__3tkxL {
  width: 100%;
  font-size: 13px;
  font-weight: 400;
  line-height: 16px;
  text-align: left;
}
.search_cardSearch__bn9LF .search_content__25Fhd .search_results__3ycUi .search_result__3jeA2 .search_card__mdsGa .search_info__2OxAl {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.search_cardSearch__bn9LF .search_content__25Fhd .search_results__3ycUi .search_result__3jeA2 .search_card__mdsGa .search_info__2OxAl .search_about__1I7dU {
  display: flex;
  align-items: center;
}
.search_cardSearch__bn9LF .search_content__25Fhd .search_results__3ycUi .search_result__3jeA2 .search_card__mdsGa .search_info__2OxAl .search_about__1I7dU img {
  width: 75px;
  height: 48px;
  object-fit: contain;
}
.search_cardSearch__bn9LF .search_content__25Fhd .search_results__3ycUi .search_result__3jeA2 .search_card__mdsGa .search_info__2OxAl .search_about__1I7dU .search_title__20ZXf {
  width: 400px;
  font-size: 16px;
  font-weight: 400;
  line-height: 20px;
  text-align: left;
  color: #1a1a1a;
  -webkit-line-clamp: 2;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
}
.search_cardSearch__bn9LF .search_content__25Fhd .search_results__3ycUi .search_result__3jeA2 .search_card__mdsGa .search_info__2OxAl .search_toCompany__3p2E9 {
  text-decoration: none;
  color: #1a1a1a;
  font-size: 11px;
  font-weight: 400;
  line-height: 13.5px;
  text-align: left;
}
.search_cardSearch__bn9LF .search_content__25Fhd .search_results__3ycUi .search_result__3jeA2 .search_card__mdsGa .search_info__2OxAl .search_toCompany__3p2E9 svg {
  margin-left: 10px;
  margin-bottom: 2px;
  fill: #1a1a1a;
}
.search_cardSearch__bn9LF .search_content__25Fhd .search_results__3ycUi .search_result__3jeA2 .search_card__mdsGa .search_specialities__36BgT {
  margin-top: 10px;
}
.search_cardSearch__bn9LF .search_content__25Fhd .search_results__3ycUi .search_result__3jeA2 .search_card__mdsGa .search_specialities__36BgT .search_title__20ZXf {
  display: flex;
  flex-wrap: wrap;
  grid-gap: 5px;
  gap: 5px;
  font-size: 13px;
  font-weight: 400;
  line-height: 16px;
  text-align: left;
  color: #1a1a1a;
  margin-bottom: 10px;
}
.search_cardSearch__bn9LF .search_content__25Fhd .search_results__3ycUi .search_result__3jeA2 .search_card__mdsGa .search_specialities__36BgT .search_title__20ZXf .search_colored__1ou5n {
  background: linear-gradient(155.19deg, #c12e2e 15.81%, #cd9f29 100.43%);
  background-size: cover;
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.search_speciality__iWas_ + .search_speciality__iWas_ {
  margin-top: 5px;
}
.search_cardSearch__bn9LF .search_content__25Fhd .search_results__3ycUi .search_result__3jeA2 .search_card__mdsGa .search_specialities__36BgT .search_speciality__iWas_ {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: #f5f5f5;
  border-radius: 5px;
  padding: 5px 15px;
}
.search_cardSearch__bn9LF .search_content__25Fhd .search_results__3ycUi .search_result__3jeA2 .search_card__mdsGa .search_specialities__36BgT .search_speciality__iWas_ .search_name__XdY94 {
  -webkit-line-clamp: 1;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
  font-size: 13px;
  font-weight: 400;
  line-height: 16px;
  text-align: left;
  color: #1a1a1a;
  max-width: 250px;
}
.search_cardSearch__bn9LF .search_content__25Fhd .search_results__3ycUi .search_result__3jeA2 .search_card__mdsGa .search_specialities__36BgT .search_speciality__iWas_ .search_props__1DKOm {
  display: flex;
  align-items: center;
}
.search_cardSearch__bn9LF
  .search_content__25Fhd
  .search_results__3ycUi
  .search_result__3jeA2
  .search_card__mdsGa
  .search_specialities__36BgT
  .search_speciality__iWas_
  .search_props__1DKOm
  .search_prop__2S6Ml {
  position: relative;
  background-color: #fff;
  padding: 4px 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: #1a1a1a;
  width: 150px;
}
.search_cardSearch__bn9LF
  .search_content__25Fhd
  .search_results__3ycUi
  .search_result__3jeA2
  .search_card__mdsGa
  .search_specialities__36BgT
  .search_speciality__iWas_
  .search_props__1DKOm
  .search_nowt__1BECW {
  position: relative;
  background-color: #fff;
  padding: 4px 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: #1a1a1a;
  font-size: small;
}
.search_cardSearch__bn9LF
  .search_content__25Fhd
  .search_results__3ycUi
  .search_result__3jeA2
  .search_card__mdsGa
  .search_specialities__36BgT
  .search_speciality__iWas_
  .search_props__1DKOm
  .search_prop__2S6Ml
  .search_name__XdY94 {
  font-size: 10px;
  font-weight: 400;
  line-height: 10px;
  text-align: left;
}
.search_cardSearch__bn9LF
  .search_content__25Fhd
  .search_results__3ycUi
  .search_result__3jeA2
  .search_card__mdsGa
  .search_specialities__36BgT
  .search_speciality__iWas_
  .search_props__1DKOm
  .search_prop__2S6Ml
  .search_value__8YAuZ {
  margin-left: 5px;
  font-size: 13px;
  font-weight: 400;
  line-height: 15.73px;
  text-align: left;
}
.search_cardSearch__bn9LF
  .search_content__25Fhd
  .search_results__3ycUi
  .search_result__3jeA2
  .search_card__mdsGa
  .search_specialities__36BgT
  .search_speciality__iWas_
  .search_props__1DKOm
  .search_prop__2S6Ml
  .search_separator__2A8UW {
  position: absolute;
  border: 1px solid #f5f5f5;
  height: 12px;
  right: -1px;
}
.search_cardSearch__bn9LF
  .search_content__25Fhd
  .search_results__3ycUi
  .search_result__3jeA2
  .search_card__mdsGa
  .search_specialities__36BgT
  .search_speciality__iWas_
  .search_props__1DKOm
  .search_go__2WiwB {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 20px;
  border-radius: 25px;
  background: linear-gradient(
    191.12deg,
    #00b3ec -91.84%,
    #cbec00 -91.82%,
    #700101 25.42%,
    #d0a024 126.74%
  );
  padding: 7px 10px;
}
.search_cardSearch__bn9LF
  .search_content__25Fhd
  .search_results__3ycUi
  .search_result__3jeA2
  .search_card__mdsGa
  .search_specialities__36BgT
  .search_speciality__iWas_
  .search_props__1DKOm
  .search_go__2WiwB
  svg {
  fill: #fff;
  width: 20px;
  height: 10px;
}

.search_cardSearch__bn9LF .search_content__25Fhd .search_results__3ycUi .search_result__3jeA2 .search_title__20ZXf {
  font-size: 13.5px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  -webkit-line-clamp: 3;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
}
.search_more__1neDn {
  text-decoration: none;
  display: flex;
  justify-content: center;
}
.search_more__1neDn .search_all__dyaPh {
  text-decoration: none;
  color: #000;
  font-size: 10px;
  font-weight: 300;
  line-height: 12px;
  text-align: left;
  cursor: pointer;
  position: relative;
  margin-top: 10px;
  padding: 5px 20px;
  box-shadow: 1px 1px 8px 0px rgba(0, 0, 0, 0.15);
  border-radius: 20px;
}
.search_more__1neDn .search_all__dyaPh::before {
  content: "";
  position: absolute;
  background: linear-gradient(
    191deg,
    #cbec00 -91.84%,
    #700101 25.42%,
    #d0a024 126.74%
  );
  width: 6px;
  height: 16px;
  top: calc(50% - 8px);
  left: 100%;
  clip-path: polygon(0 12.5%, 100% 50%, 0 87.5%);
}
.search_full__2rLlv {
  width: 100%;
  display: flex;
  justify-content: center;
}
.search_full__2rLlv .search_more__1neDn .search_all__dyaPh {
  font-size: 12px;
  line-height: 14.5px;
  margin-top: 20px;
}
.search_full__2rLlv .search_more__1neDn .search_all__dyaPh::before {
  width: 8px;
  height: 18px;
  top: calc(50% - 8px);
}
@media screen and (max-width: 1200px) {
  .search_cardSearch__bn9LF {
    height: auto;
  }
  .search_cardSearch__bn9LF .search_inner__NO33e {
    flex-wrap: wrap;
  }
  .search_cardSearch__bn9LF .search_content__25Fhd {
    width: 100%;
  }
  .search_cardSearch__bn9LF .search_content__25Fhd .search_results__3ycUi .search_result__3jeA2 .search_card__mdsGa .search_info__2OxAl {
    display: block;
  }
  .search_cardSearch__bn9LF .search_content__25Fhd .search_results__3ycUi .search_result__3jeA2 .search_card__mdsGa .search_info__2OxAl .search_about__1I7dU .search_title__20ZXf {
    width: 100%;
  }
  .search_cardSearch__bn9LF .search_content__25Fhd .search_results__3ycUi .search_result__3jeA2 .search_card__mdsGa .search_specialities__36BgT .search_speciality__iWas_ {
    display: block;
    padding: 10px;
  }
  .search_cardSearch__bn9LF .search_content__25Fhd .search_results__3ycUi .search_result__3jeA2 .search_card__mdsGa .search_specialities__36BgT .search_speciality__iWas_ .search_name__XdY94 {
    max-width: auto;
    margin-bottom: 10px;
  }
  .search_cardSearch__bn9LF .search_content__25Fhd .search_results__3ycUi .search_result__3jeA2 .search_card__mdsGa .search_specialities__36BgT .search_speciality__iWas_ .search_props__1DKOm {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
  }
  .search_cardSearch__bn9LF
    .search_content__25Fhd
    .search_results__3ycUi
    .search_result__3jeA2
    .search_card__mdsGa
    .search_specialities__36BgT
    .search_speciality__iWas_
    .search_props__1DKOm
    .search_prop__2S6Ml {
    width: 100%;
  }
  .search_cardSearch__bn9LF
    .search_content__25Fhd
    .search_results__3ycUi
    .search_result__3jeA2
    .search_card__mdsGa
    .search_specialities__36BgT
    .search_speciality__iWas_
    .search_props__1DKOm
    .search_prop__2S6Ml
    .search_name__XdY94 {
    margin-bottom: 0px;
  }
  .search_cardSearch__bn9LF
    .search_content__25Fhd
    .search_results__3ycUi
    .search_result__3jeA2
    .search_card__mdsGa
    .search_specialities__36BgT
    .search_speciality__iWas_
    .search_props__1DKOm
    .search_prop__2S6Ml
    .search_separator__2A8UW {
    display: none;
  }
  .search_cardSearch__bn9LF
    .search_content__25Fhd
    .search_results__3ycUi
    .search_result__3jeA2
    .search_card__mdsGa
    .search_specialities__36BgT
    .search_speciality__iWas_
    .search_props__1DKOm
    .search_go__2WiwB {
    margin-left: 0px;
    width: 50px;
    margin-top: 10px;
  }
}

.map_block__1W1E6 {
  display: flex;
  flex-wrap: wrap;
  position: relative;
  margin-top: 140px;
  justify-content: center;
}
.map_header__28Um5 {
  background: linear-gradient(
    180deg,
    #00b3ec -91.84%,
    #cbec00 -91.82%,
    #700101 25.42%,
    #d0a024 126.74%
  );
  background-size: cover;
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-size: 36px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  text-transform: uppercase;
  text-align: left;
  margin-bottom: 40px;
  width: 100%;
}
.map_secondHeader__1FDza {
  background: linear-gradient(
    180deg,
    #00b3ec -91.84%,
    #cbec00 -91.82%,
    #700101 25.42%,
    #d0a024 126.74%
  );
  background-size: cover;
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-size: 32px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  text-align: left;
  margin-top: 40px;
  width: 100%;
}
.map_map__2EcsQ {
  position: relative;
  margin-top: 20px;
  height: 960px;
  width: 800px;
}
.map_map__2EcsQ .map_point__YfNqZ {
  position: absolute;
}
.map_map__2EcsQ .map_point__YfNqZ .map_icon__hyp9W {
  cursor: pointer;
  fill: #fff;
}
.map_map__2EcsQ .map_point__YfNqZ .map_icon__hyp9W:hover {
  fill: url(#hover);
}
.map_map__2EcsQ .map_point__YfNqZ .map_card__3dCuH {
  height: 289px;
  width: 434px;
  position: absolute;
  display: none;
  top: 12px;
  right: 20px;
}
.map_card__3dCuH .map_border__2IM22 {
  background: linear-gradient(
    191deg,
    #00b3ec -91.84%,
    #cbec00 -91.82%,
    #700101 25.42%,
    #d0a024 126.74%
  );
}
.map_card__3dCuH .map_arrow__1yiCA {
  background: linear-gradient(
    201deg,
    #00b3ec -91.84%,
    #cbec00 -91.82%,
    #700101 25.42%,
    #d0a024 126.74%
  );
}
.map_map__2EcsQ .map_point__YfNqZ:hover .map_card__3dCuH {
  display: block;
  z-index: 1;
}
.map_card__3dCuH.map_mobile__1hYFn {
  display: none;
}
.map_card__3dCuH .map_content__8dzDL {
  padding: 16px 20px 27px 22px;
  cursor: default;
}
.map_card__3dCuH .map_content__8dzDL .map_header__28Um5 {
  display: flex;
  color: #555;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  writing-mode: horizontal-tb;
  transform: none;
  background: none;
  background-clip: none;
  -webkit-background-clip: none;
  -webkit-text-fill-color: #555;
  text-transform: none;
  margin-right: 0;
  margin-bottom: 8px;
  align-items: center;
}
.map_card__3dCuH .map_content__8dzDL .map_header__28Um5 .map_icon__hyp9W {
  margin-right: 15px;
  margin-left: -4px;
  width: 75px;
  height: 48px;
  object-fit: contain;
}
.map_card__3dCuH .map_content__8dzDL .map_contacts__1NZMA {
  display: flex;
  grid-gap: 40px;
  gap: 40px;
}
.map_card__3dCuH .map_content__8dzDL .map_contacts__1NZMA .map_site__1xEcm {
  margin-bottom: 10px;
}
.map_card__3dCuH .map_content__8dzDL .map_contacts__1NZMA .map_site__1xEcm .map_title__1YUOl {
  color: #555;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.map_card__3dCuH .map_content__8dzDL .map_contacts__1NZMA .map_site__1xEcm .map_link__3O_XC {
  color: #1194ba;
  font-size: 12px;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
}
.map_card__3dCuH .map_content__8dzDL .map_contacts__1NZMA .map_phones__36p2x .map_title__1YUOl {
  color: #555;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-bottom: 8px;
}
.map_card__3dCuH .map_content__8dzDL .map_contacts__1NZMA .map_phones__36p2x .map_link__3O_XC {
  display: block;
  color: #555;
  font-size: 12px;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
  text-decoration: none;
  text-wrap: nowrap;
}
.map_card__3dCuH .map_content__8dzDL .map_contacts__1NZMA .map_address__yEYdF {
  margin-bottom: 12px;
}
.map_card__3dCuH .map_content__8dzDL .map_contacts__1NZMA .map_address__yEYdF .map_title__1YUOl {
  color: #555;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-bottom: 8px;
}
.map_card__3dCuH .map_content__8dzDL .map_contacts__1NZMA .map_address__yEYdF .map_description__2iusI {
  color: #555;
  font-size: 12px;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
}
.map_card__3dCuH .map_content__8dzDL .map_contacts__1NZMA .map_mail__3eIFp .map_title__1YUOl {
  color: #555;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.map_card__3dCuH .map_content__8dzDL .map_contacts__1NZMA .map_mail__3eIFp .map_link__3O_XC {
  color: #1194ba;
  font-size: 12px;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
}
.map_card__3dCuH .map_content__8dzDL .map_contacts__1NZMA .map_line__3FB-I {
  width: 1px;
  background: #e1e1e1;
}
.map_card__3dCuH .map_content__8dzDL .map_tags__oeClT .map_title__1YUOl {
  margin-top: 12px;
  margin-bottom: 9px;
  color: #555;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.map_card__3dCuH .map_content__8dzDL .map_tags__oeClT .map_description__2iusI {
  display: flex;
  flex-wrap: wrap;
  grid-column-gap: 13px;
  column-gap: 13px;
  grid-row-gap: 6px;
  row-gap: 6px;
}
.map_card__3dCuH .map_content__8dzDL .map_tags__oeClT .map_description__2iusI .map_tag__3Mzx0 {
  width: 109px;
  border-radius: 20px;
  border: 1px solid #e1e1e1;
  background: #fff;
  color: #555;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-align: center;
  padding: 5px 10px;
}
@media screen and (max-width: 1200px) {
  .map_block__1W1E6 {
    flex-wrap: wrap;
    justify-content: center;
  }
  .map_cardSearch__D_jE4 {
    width: calc(100vw - 10px);
    height: 660px;
  }
  .map_cardSearch__D_jE4 .map_content__8dzDL .map_results__1-qF0 .map_result__1xgrD {
    width: 100%;
  }
  .map_map__2EcsQ {
    margin-top: 20px;
    height: auto;
    display: block;
  }
  .map_map__2EcsQ .map_point__YfNqZ:hover .map_card__3dCuH {
    display: none;
    z-index: 10;
  }
  .map_card__3dCuH.map_mobile__1hYFn {
    margin-top: 20px;
    display: block;
    width: calc(100vw - 10px);
  }
}

.categories_block__HqQGS {
  position: relative;
  margin-top: 40px;
}
.categories_tabs__IzucM {
  display: flex;
  align-items: flex-end;
  border-bottom: none !important;
}
.categories_tab__P6bJy {
  cursor: pointer;
  font-size: 26px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  color: #999;
  border: none !important;
  border-radius: 0px !important;
  background: #fff;
  box-shadow: -3px 2px 6px 0px rgba(0, 0, 0, 0.11) inset;
  margin-bottom: 5px !important;
  padding: 5px 27px;
}
.categories_tab__P6bJy:hover {
  color: #999;
  background: linear-gradient(
    191deg,
    #00b3ec -91.84%,
    #cbec00 -91.82%,
    #700101 25.42%,
    #d0a024 126.74%
  );
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.categories_tab__P6bJy.categories_start__3l6nS {
  border-radius: 10px 0px 0px 10px !important;
}
.categories_tab__P6bJy.categories_end__1a9YY {
  border-radius: 0px 10px 10px 0px !important;
}
.categories_tab__P6bJy.categories_active__2APzZ {
  font-size: 36px;
  background: linear-gradient(
    191deg,
    #00b3ec -91.84%,
    #cbec00 -91.82%,
    #700101 25.42%,
    #d0a024 126.74%
  );
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  box-shadow: 1px 1px 8px 0px rgba(0, 0, 0, 0.15);
}
.categories_tabContent__2bcuc {
  margin-top: 45px;
  display: flex !important;
  justify-content: flex-start;
  align-items: flex-start;
  align-content: flex-start;
  grid-column-gap: 24px;
  column-gap: 24px;
  grid-row-gap: 18px;
  row-gap: 18px;
  flex-wrap: wrap;
}
.categories_card__21nf3 {
  width: 350px;
  height: auto;
  box-shadow: 1px 1px 8px 0px #00000026;
  padding: 15px 15px 8px 15px;
  border-radius: 10px;
}
.categories_card__21nf3 .categories_tags__1hyWM {
  display: flex;
  grid-gap: 5px;
  gap: 5px;
}
.categories_card__21nf3 .categories_tags__1hyWM .categories_tag__17j1e {
  font-size: 10px;
  font-weight: 400;
  background: linear-gradient(155.19deg, #c12e2e 15.81%, #cd9f29 100.43%);
  border-radius: 25px;
  padding: 2px 10px;
  color: #fff;
  text-transform: lowercase;
}
.categories_card__21nf3 .categories_type__3njjI {
  margin-top: 6px;
  color: #696969;
  font-size: 12px;
  line-height: 14.5px;
  font-weight: 400;
  height: 30px;
  -webkit-line-clamp: 2;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
}
.categories_card__21nf3 .categories_header__3JZnW {
  color: #1a1a1a;
  font-size: 15px;
  font-weight: 500;
  line-height: 18px;
  height: 36px;
  -webkit-line-clamp: 2;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
}
.categories_card__21nf3 .categories_description__KxyEi {
  margin-top: 6px;
  background: #f6f6f6;
  border-radius: 10px;
  padding: 5px 10px;
  font-size: 12px;
  line-height: 16px;
  font-weight: 400;
  color: #1a1a1a;
  height: 58px;
  -webkit-line-clamp: 3;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
}
.categories_card__21nf3 .categories_footer__224gF {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 6px;
  font-size: 12px;
}
.categories_card__21nf3 .categories_footer__224gF .categories_data__SSkn3 {
  color: #1a1a1a;
  font-size: 11px;
  font-weight: 400;
}
.categories_card__21nf3 .categories_footer__224gF .categories_addition__5PH6F {
  background: linear-gradient(155.19deg, #c12e2e 15.81%, #cd9f29 100.43%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-size: 11px;
  font-weight: 400;
}
.categories_requestButton__iwSsi {
  position: absolute;
  padding: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  top: 10px;
  right: 0;
  color: #555;
  text-align: center;
  font-size: 11.5px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.categories_requestButton__iwSsi:hover .categories_fill__3cv2h,
.categories_requestButton__iwSsi .categories_border__IoHlu,
.categories_requestButton__iwSsi .categories_arrow__3kArR {
  background-image: linear-gradient(
    191deg,
    #00b3ec -91.84%,
    #cbec00 -91.82%,
    #700101 25.42%,
    #d0a024 126.74%
  ) !important;
}
.categories_more__K_ONU {
  display: flex;
  justify-content: center;
  text-decoration: none;
}
.categories_more__K_ONU .categories_all__1Az_4 {
  color: #000;
  font-size: 12px;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
  cursor: pointer;
  position: relative;
  margin-top: 48px;
  padding: 5px 20px;
  box-shadow: 1px 1px 8px 0px rgba(0, 0, 0, 0.15);
  border-radius: 20px;
}
.categories_more__K_ONU .categories_all__1Az_4::before {
  content: "";
  position: absolute;
  background: linear-gradient(
    191deg,
    #00b3ec -91.84%,
    #cbec00 -91.82%,
    #700101 25.42%,
    #d0a024 126.74%
  );
  width: 6px;
  height: 16px;
  top: calc(50% - 8px);
  left: 100%;
  clip-path: polygon(0 12.5%, 100% 50%, 0 87.5%);
}
@media screen and (max-width: 1200px) {
  .categories_block__HqQGS {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }
  .categories_tab__P6bJy {
    font-size: 0.7rem;
  }
  .categories_tab__P6bJy.categories_active__2APzZ {
    font-size: 0.8rem;
  }
  .categories_tab__P6bJy.categories_start__3l6nS {
    border-radius: 5px 0px 0px 5px !important;
  }
  .categories_tab__P6bJy.categories_end__1a9YY {
    border-radius: 0px 5px 5px 0px !important;
  }
  .categories_tabContent__2bcuc {
    justify-content: center;
  }
  .categories_requestButton__iwSsi {
    top: 35px;
  }
}

.calendar_block__3s38d {
  margin-top: 40px;
}
.calendar_block__3s38d .calendar_header__2PGfZ {
  background: linear-gradient(
    191deg,
    #00b3ec -91.84%,
    #cbec00 -91.82%,
    #700101 25.42%,
    #d0a024 126.74%
  );
  background-size: cover;
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-size: 36px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}
.calendar_block__3s38d .calendar_inner__1yzPP {
  display: flex;
  flex-wrap: wrap;
  grid-gap: 40px;
  gap: 40px;
  margin-top: 40px;
}
.calendar_toDay__3E7fb {
  background: linear-gradient(
    0deg,
    #cbec00 -91.82%,
    #d0a024 126.74%
  );
}
.calendar_focusDay__1NcRa {
  border: 1px solid #d0a024 !important;
  color: #d0a024;
}
.calendar_cardSearch__3VkSz {
  width: 392px;
  height: 640px;
}
.calendar_cardSearch__3VkSz .calendar_border__2ZGH9 {
  background-image: linear-gradient(
    191.12deg,
    #cbec00 -91.84%,
    #700101 25.42%,
    #d0a024 126.74%
  );
}
.calendar_cardSearch__3VkSz .calendar_arrow__2FzzW {
  background-image: linear-gradient(
    201.12deg,
    #cbec00 -91.84%,
    #700101 25.42%,
    #d0a024 186.74%
  );
}
.calendar_cardSearch__3VkSz .calendar_content__3Fzti {
  padding: 30px 29px 20px 29px;
}
.calendar_cardSearch__3VkSz .calendar_content__3Fzti .calendar_search__2-5yA {
  display: flex;
  justify-content: flex-start;
  grid-gap: 10px;
  gap: 10px;
  width: 100%;
  filter: drop-shadow(1px 1px 8px rgba(0, 0, 0, 0.15));
  background-color: rgba(239, 239, 239, 1);
  border-radius: 20px;
  padding-left: 22px;
}
.calendar_cardSearch__3VkSz .calendar_content__3Fzti .calendar_search__2-5yA .calendar_icon__3ZZ-F {
  margin-top: 10px;
  margin-bottom: 10px;
  min-width: 22px;
}
.calendar_cardSearch__3VkSz .calendar_content__3Fzti .calendar_search__2-5yA .calendar_input__1CVlZ {
  width: 100%;
  color: rgba(0, 0, 0, 1);
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  border: none;
  vertical-align: middle;
  background-color: rgba(239, 239, 239, 1);
}
.calendar_cardSearch__3VkSz .calendar_content__3Fzti .calendar_search__2-5yA .calendar_input__1CVlZ::placeholder,
.calendar_cardSearch__3VkSz .calendar_content__3Fzti .calendar_search__2-5yA .calendar_input__1CVlZ::-webkit-input-placeholder {
  color: #000;
  line-height: 18px;
  vertical-align: middle;
}

.calendar_cardSearch__3VkSz .calendar_content__3Fzti .calendar_search__2-5yA .calendar_input__1CVlZ:focus-visible {
  outline: none;
}
.calendar_cardSearch__3VkSz .calendar_content__3Fzti .calendar_search__2-5yA .calendar_button__11eWl {
  position: relative;
  min-width: 65px;
  border-radius: 0 20px 20px 20px;
  border: none;
  background-image: linear-gradient(
    191.12deg,
    #cbec00 -91.84%,
    #700101 25.42%,
    #d0a024 126.74%
  );
}
.calendar_cardSearch__3VkSz .calendar_content__3Fzti .calendar_search__2-5yA .calendar_button__11eWl::after {
  position: absolute;
  content: "";
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  visibility: hidden;
  background-color: #fff;
  opacity: 0.3;
  border-radius: 0 20px 20px 20px;
}
.calendar_cardSearch__3VkSz .calendar_content__3Fzti .calendar_search__2-5yA .calendar_button__11eWl:hover::after {
  visibility: visible;
}
.calendar_cardSearch__3VkSz .calendar_content__3Fzti .calendar_searchAdvanced__2K_CX {
  display: flex;
  justify-content: center;
}
.calendar_cardSearch__3VkSz .calendar_content__3Fzti .calendar_searchAdvanced__2K_CX a {
  margin-top: 6px;
  font-size: 0.85rem;
  text-decoration: none;
  color: #d0a024;
}
.calendar_cardSearch__3VkSz .calendar_content__3Fzti .calendar_info__2CxUJ {
  margin-top: 5px;
  display: flex;
  flex-wrap: wrap;
  align-content: space-between;
  justify-content: center;
  height: 540px;
}
.calendar_cardSearch__3VkSz .calendar_content__3Fzti .calendar_results__ZZbPL {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
}
.calendar_cardSearch__3VkSz .calendar_content__3Fzti .calendar_results__ZZbPL .calendar_result__2rmoZ {
  position: relative;
  color: #555;
  width: 100%;
}
.calendar_cardSearch__3VkSz .calendar_content__3Fzti .calendar_results__ZZbPL .calendar_result__2rmoZ .calendar_card__2xxZS {
  cursor: default;
  padding: 12px 0px;
  grid-gap: 15px;
  gap: 15px;
  color: #000;
  width: 100%;
  border-bottom: 1px solid #e1e1e1;
}
.calendar_cardSearch__3VkSz .calendar_content__3Fzti .calendar_results__ZZbPL .calendar_result__2rmoZ .calendar_title__TgZw1 {
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  -webkit-line-clamp: 3;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-align: start;
  width: 100%;
}
.calendar_cardSearch__3VkSz .calendar_content__3Fzti .calendar_results__ZZbPL .calendar_result__2rmoZ .calendar_date__2zE0O {
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  -webkit-line-clamp: 3;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-align: end;
  width: 100%;
}
@media screen and (max-width: 1200px) {
  .calendar_block__3s38d .calendar_inner__1yzPP {
    justify-content: center;
  }
  .calendar_cardSearch__3VkSz {
    width: calc(100vw - 10px);
  }
}

.news_block__BWK_C {
  position: relative;
  margin-top: 120px;
}
.news_header__33QLK {
  font-size: 32px;
  font-weight: 700;
  line-height: 40px;
  text-align: left;
  text-transform: uppercase;
  background: linear-gradient(
    191deg,
    #00b3ec -91.84%,
    #cbec00 -91.82%,
    #700101 25.42%,
    #d0a024 126.74%
  );
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.news_background__24BBY {
  position: absolute;
  top: 40px;
  right: 160px;
}
.news_all-news__1RME4 {
  cursor: pointer;
  position: relative;
  margin-top: 48px;
  margin-bottom: 17px;
  margin-left: -5px;
  padding-right: 8px;
  width: 100%;
  text-align: end;
  color: #555;
  font-size: 11.5px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.news_all-news__1RME4::before {
  content: "";
  position: absolute;
  background: linear-gradient(180deg, #c12e2e 15.81%, #cd9f29 100.43%);
  width: 6px;
  height: 12px;
  top: 1px;
  left: calc(100% - 4px);
  clip-path: polygon(0 12.5%, 100% 50%, 0 87.5%);
}
.news_news__3-F2S {
  --width-card: 264px;
  --height-card: 318px;
}
.news_news__3-F2S .news_slide__2xHM2 {
  width: var(--width-card);
}
.news_news__3-F2S .news_card__HOhNb {
  cursor: default;
  position: relative;
  background-color: #fff;
  border-radius: 19.5px;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  width: var(--width-card);
  height: var(--height-card);
}
.news_news__3-F2S .news_card__HOhNb::before {
  content: "";
  background-image: linear-gradient(180deg, #c12e2e 15.81%, #cd9f29 100.43%);
  top: -1px;
  left: -1px;
  bottom: -1px;
  right: -1px;
  position: absolute;
  z-index: -1;
  border-radius: 20px;
}
.news_news__3-F2S .news_card__HOhNb .news_image__2Q7dM {
  height: calc(var(--height-card) / 2);
  max-width: 100%;
  border-radius: 20px;
}
.news_news__3-F2S .news_card__HOhNb .news_image__2Q7dM img {
  width: 100%;
  height: calc(var(--height-card) / 2);
  border-radius: 20px 20px 0px 0px;
  object-fit: cover;
}
.news_news__3-F2S .news_card__HOhNb .news_info__3_ipp {
  padding: 15px;
  display: flex;
  flex-wrap: wrap;
  align-content: space-between;
  height: calc(var(--height-card) / 2);
}
.news_news__3-F2S .news_card__HOhNb .news_info__3_ipp .news_title__1aq0Z {
  color: #333;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  width: 100%;
}
.news_news__3-F2S .news_card__HOhNb .news_info__3_ipp .news_description__oS7Zl {
  color: #555;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  max-width: var(--width-card);
  max-height: calc(var(--height-card) / 2 - 80px);
  margin-top: 10px;
  -webkit-line-clamp: 5;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
}
.news_news__3-F2S .news_card__HOhNb .news_info__3_ipp .news_addition__35Nvq {
  display: flex;
  justify-content: space-between;
  color: #333;
  font-size: 11px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  width: 100%;
}
.news_news__3-F2S .news_card__HOhNb .news_info__3_ipp .news_addition__35Nvq .news_more__12Dnu {
  cursor: pointer;
  position: relative;
  padding-right: 8px;
  background-image: linear-gradient(180deg, #c12e2e 15.81%, #cd9f29 100.43%);
  color: transparent;
  -webkit-background-clip: text;
  background-clip: text;
}
.news_news__3-F2S .news_card__HOhNb .news_info__3_ipp .news_addition__35Nvq .news_more__12Dnu::before {
  content: "";
  position: absolute;
  background: linear-gradient(180deg, #c12e2e 15.81%, #cd9f29 100.43%);
  width: 6px;
  height: 12px;
  top: 1px;
  left: calc(100% - 4px);
  clip-path: polygon(0 12.5%, 100% 50%, 0 87.5%);
}
.news_news__3-F2S .news_swiper__35755 {
  max-width: 1100px;
  padding: 1px 3px;
  height: calc(var(--height-card) + 33px) !important;
  --background-swiper-scrollbar-horizontal: #f8f8f8;
  --background-swiper-scrollbar-drag: linear-gradient(
    155deg,
    #700101 15.81%,
    #d0a024 100.43%
  );
}

@media screen and (max-width: 1200px) {
  .news_block__BWK_C {
    padding-left: 5px !important;
    padding-right: 5px !important;
  }
  .news_news__3-F2S .news_swiper__35755 {
    max-width: 100vw;
  }
}

.docs_block__kTfkS {
  position: relative;
  margin-top: 100px;
  display: flex;
  --height-card: 280px;
  --width-card: 300px;
}
.docs_block__kTfkS .docs_header__3N7G_ {
  display: flex;
  justify-content: flex-end;
  writing-mode: vertical-lr;
  transform: rotate(180deg);
  font-size: 29px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  background: linear-gradient(
    191deg,
    #00b3ec -91.84%,
    #cbec00 -91.82%,
    #700101 25.42%,
    #d0a024 126.74%
  );
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  margin-right: 34px;
}
.docs_registries__1KlHh .docs_slide__2nXC7 {
  height: var(--height-card);
  width: var(--width-card);
}
.docs_registries__1KlHh .docs_slide__2nXC7 + .docs_slide__2nXC7 {
  border-left: 1px solid #cecece;
  padding-left: 25px;
  padding-right: 25px;
  width: calc(var(--width-card) + 25px);
}
.docs_registries__1KlHh .docs_slide__2nXC7 .docs_card__1ereR {
  display: flex;
  flex-wrap: wrap;
  padding-left: 10px;
}
.docs_registries__1KlHh .docs_slide__2nXC7 .docs_card__1ereR .docs_link__2Nnl9 {
  position: relative;
  display: flex;
  align-items: center;
  margin-bottom: 30px;
  height: 75px;
  width: 100%;
  overflow: hidden;
}
.docs_registries__1KlHh .docs_slide__2nXC7 .docs_link__2Nnl9 .docs_dot__U1cvq {
  position: absolute;
  width: 10px;
  height: 10px;
  border-radius: 20px;
  background: linear-gradient(
    191deg,
    #cbec00 -91.84%,
    #700101 25.42%,
    #d0a024 126.74%
  );
  top: calc(50% - 5px);
}
.docs_registries__1KlHh .docs_slide__2nXC7 .docs_link__2Nnl9 .docs_title__EBOHt {
  padding-left: 20px;
  color: #1194ba;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  -webkit-text-decoration-line: underline;
          text-decoration-line: underline;
  -webkit-line-clamp: 4;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
}
.docs_registries__1KlHh .docs_swiper__3L99g {
  max-width: 1035px;
  padding: 1px 3px;
  height: calc(var(--height-card) + 33px) !important;
  --background-swiper-scrollbar-horizontal: #f8f8f8;
  --background-swiper-scrollbar-drag: linear-gradient(
    155deg,
    #700101 15.81%,
    #d0a024 100.43%
  );
}
.docs_block__kTfkS .docs_hidden__2az39 {
  position: absolute;
  top: 2px;
  right: -6px;
  box-shadow: #fff 0px 5px 12px 12px;
  width: 1px;
  height: var(--height-card);
  z-index: 1;
}
@media screen and (max-width: 1200px) {
  .docs_block__kTfkS {
    display: block;
  }
  .docs_block__kTfkS .docs_header__3N7G_ {
    display: none;
  }
  .docs_block__kTfkS .docs_hidden__2az39 {
    display: none;
  }
  .docs_registries__1KlHh .docs_swiper__3L99g {
    max-width: calc(100vw - 10px);
  }
}
.partners_block__mKg11 {
  position: relative;
  margin-top: 160px;
}
.partners_header__34y8a {
  font-size: 32px;
  font-weight: 700;
  line-height: 40px;
  text-align: left;
  text-transform: uppercase;
  background: linear-gradient(
    191deg,
    #00b3ec -91.84%,
    #cbec00 -91.82%,
    #700101 25.42%,
    #d0a024 126.74%
  );
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.partners_partners__2xFUV {
  margin-top: 77px;
  display: flex;
  flex-wrap: wrap;
  grid-gap: 25px;
  gap: 25px;
}
.partners_partners__2xFUV .partners_partner__3KbPN {
  cursor: default;
  position: relative;
  background-color: #fff;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  width: 350px;
}
.partners_partners__2xFUV .partners_partner__3KbPN .partners_photo__1NCfj {
  height: 120px;
}
.partners_partners__2xFUV .partners_partner__3KbPN .partners_photo__1NCfj img {
  height: 120px;
  width: 100%;
  object-fit: contain;
}
.partners_partners__2xFUV .partners_partner__3KbPN .partners_info__3SCVG .partners_title__276x8 {
  padding: 8px 13px;
}
.partners_partners__2xFUV .partners_partner__3KbPN .partners_info__3SCVG .partners_title__276x8 .partners_name__2L-nN {
  color: #333;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}
@media screen and (max-width: 1200px) {
  .partners_block__mKg11 {
    padding-left: 5px !important;
    padding-right: 5px !important;
  }
  .partners_partners__2xFUV {
    justify-content: center;
  }
  .partners_partners__2xFUV .partners_partner__3KbPN {
    width: 100vw;
  }
}

.about_block__1MoX_ {
  position: relative;
  margin-top: 10px;
}

.about_content__Ui34y {
  margin-top: 100px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
}

.about_content__Ui34y .about_image__2rzgv {
  display: flex;
  width: 474px;
  height: 316px;
}

.about_content__Ui34y .about_image__2rzgv img {
  object-fit: cover;
  border-radius: 20px;
  box-shadow: 1px 1px 8px 0px #00000026;
}

.about_background__1GysD {
  position: absolute;
  z-index: -1;
  top: -60px;
  left: -360px;
}

.about_info__2ux_U {
  width: 570px;
}

.about_info__2ux_U .about_header__11j3W {
  font-size: 26px;
  font-weight: 700;
  line-height: 32px;
  text-align: left;
  text-transform: uppercase;
  background: linear-gradient(155.19deg, #c12e2e 15.81%, #cd9f29 100.43%);
  margin: 0;
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.about_info__2ux_U .about_list__3YLh2 {
  margin-top: 16px;
  padding: 0;
}
.about_info__2ux_U .about_list__3YLh2 li {
  display: flex;
  align-items: center;
  grid-gap: 10px;
  gap: 10px;
  margin-bottom: 10px;
}
.about_info__2ux_U .about_list__3YLh2 li .about_dot__13ch9 {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
}
.about_info__2ux_U .about_list__3YLh2 li .about_dot__13ch9 .about_icon__1520s {
  position: absolute;
}
.about_info__2ux_U .about_list__3YLh2 li .about_text__2R26p {
  font-size: 16px;
  font-weight: 400;
  line-height: 20px;
  text-align: left;
}

@media screen and (max-width: 1200px) {
  .about_content__Ui34y {
    margin-top: 20px;
    padding-left: 5px !important;
  }
  .about_info__2ux_U {
    width: calc(100% - 70px);
  }
  .about_content__Ui34y .about_image__2rzgv {
    display: none;
  }
  .about_background__1GysD {
    display: none;
  }
}

.actions_block__2nP-G {
  margin-top: 100px;
}
.actions_content__FDAnD {
  display: flex;
  flex-direction: column;
  grid-row-gap: 55px;
  row-gap: 55px;
}
.actions_content__FDAnD .actions_header__3W5sq {
  width: 100%;
  font-size: 32px;
  font-weight: 700;
  line-height: 40px;
  text-align: left;
  text-transform: uppercase;
  background: linear-gradient(180deg, #c12e2e 15.81%, #cd9f29 100.43%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.actions_content__FDAnD .actions_cards__3WWpf {
  display: flex;
  justify-content: space-between;
}
.actions_content__FDAnD .actions_cards__3WWpf .actions_card__3EsUL {
  width: 30%;
  height: calc(100% - 20px);
  min-height: 400px;
  box-shadow: 1px 1px 8px 0px #00000026;
  border-radius: 10px;
  padding: 2px 14px 30px 14px;
}
.actions_content__FDAnD .actions_cards__3WWpf .actions_card__3EsUL.actions_big__1KF4I {
  width: 35%;
  height: calc(100% - 20px);
  min-height: 425px;
  padding: 2px 30px 30px 30px;
}
.actions_content__FDAnD .actions_cards__3WWpf .actions_card__3EsUL .actions_title__2bHjh {
  display: flex;
  justify-content: center;
  margin-top: 80px;
  margin-bottom: 22px;
}
.actions_content__FDAnD .actions_cards__3WWpf .actions_card__3EsUL .actions_title__2bHjh .actions_container__32YdK {
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: default;
  position: relative;
  background-color: #fff;
  border-radius: 19.5px;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  width: auto;
  padding-left: 30px;
  padding-right: 30px;
  height: 56px;
}
.actions_content__FDAnD .actions_cards__3WWpf .actions_card__3EsUL.actions_big__1KF4I .actions_title__2bHjh .actions_container__32YdK {
  padding-left: 15px;
  padding-right: 15px;
}
.actions_content__FDAnD .actions_cards__3WWpf .actions_card__3EsUL .actions_title__2bHjh .actions_container__32YdK::before {
  content: "";
  background-image: linear-gradient(180deg, #c12e2e 15.81%, #cd9f29 100.43%);
  top: -1px;
  left: -1px;
  bottom: -1px;
  right: -1px;
  position: absolute;
  z-index: -1;
  border-radius: 20px;
}
.actions_content__FDAnD .actions_cards__3WWpf .actions_card__3EsUL .actions_title__2bHjh .actions_container__32YdK .actions_iconContainer__2Fkcp {
  position: absolute;
}
.actions_content__FDAnD .actions_cards__3WWpf .actions_card__3EsUL .actions_title__2bHjh .actions_container__32YdK .actions_icon__3qBy1 {
  position: relative;
  display: flex;
  justify-content: center;
  width: 100%;
}
.actions_content__FDAnD .actions_cards__3WWpf .actions_card__3EsUL .actions_title__2bHjh .actions_container__32YdK .actions_icon__3qBy1 img {
  position: absolute;
  top: -106px;
}
.actions_content__FDAnD .actions_cards__3WWpf .actions_card__3EsUL .actions_title__2bHjh .actions_container__32YdK .actions_icon__3qBy1 img:nth-child(2) {
  top: calc(50% - 80px);
}
.actions_content__FDAnD .actions_cards__3WWpf .actions_card__3EsUL .actions_title__2bHjh .actions_container__32YdK .actions_text__1bx9C {
  margin-top: 10px;
  font-size: 15px;
  font-weight: 400;
  line-height: 18.15px;
  text-align: center;
}
.actions_content__FDAnD .actions_cards__3WWpf .actions_card__3EsUL .actions_steps__2E_BH {
  display: flex;
  flex-direction: column;
  grid-row-gap: 14px;
  row-gap: 14px;
}
.actions_content__FDAnD .actions_cards__3WWpf .actions_card__3EsUL .actions_steps__2E_BH .actions_step__1y5Yc {
  display: flex;
  align-items: center;
  grid-column-gap: 10px;
  column-gap: 10px;
}
.actions_content__FDAnD .actions_cards__3WWpf .actions_card__3EsUL .actions_steps__2E_BH .actions_step__1y5Yc .actions_icon__3qBy1 {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
}
.actions_content__FDAnD .actions_cards__3WWpf .actions_card__3EsUL .actions_steps__2E_BH .actions_step__1y5Yc .actions_icon__3qBy1 .actions_normal__3btJ7 {
  position: absolute;
}
.actions_content__FDAnD .actions_cards__3WWpf .actions_card__3EsUL .actions_steps__2E_BH .actions_step__1y5Yc .actions_icon__3qBy1 .actions_success__3fDJ8 {
  position: absolute;
  opacity: 0;
}
.actions_content__FDAnD .actions_cards__3WWpf .actions_card__3EsUL .actions_steps__2E_BH .actions_step__1y5Yc.actions_passed__2Qy8U.actions_auth__3cHLd {
  pointer-events: none !important;
}
.actions_content__FDAnD .actions_cards__3WWpf .actions_card__3EsUL .actions_steps__2E_BH .actions_step__1y5Yc.actions_passed__2Qy8U .actions_icon__3qBy1 .actions_normal__3btJ7 {
  opacity: 0.3;
}
.actions_content__FDAnD .actions_cards__3WWpf .actions_card__3EsUL .actions_steps__2E_BH .actions_step__1y5Yc.actions_passed__2Qy8U .actions_icon__3qBy1 .actions_success__3fDJ8 {
  opacity: 1;
}
.actions_content__FDAnD .actions_cards__3WWpf .actions_card__3EsUL .actions_steps__2E_BH .actions_step__1y5Yc .actions_text__1bx9C {
  display: flex;
  flex-wrap: wrap;
  grid-column-gap: 5px;
  column-gap: 5px;
  font-size: 16px;
  font-weight: 400;
  line-height: 20px;
  text-align: left;
}
.actions_content__FDAnD .actions_cards__3WWpf .actions_card__3EsUL .actions_steps__2E_BH .actions_step__1y5Yc .actions_text__1bx9C span {
  cursor: pointer;
  position: relative;
  background: linear-gradient(155.19deg, #c12e2e 15.81%, #cd9f29 100.43%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  text-decoration: underline;
}
.actions_content__FDAnD .actions_cards__3WWpf .actions_card__3EsUL .actions_steps__2E_BH .actions_step__1y5Yc .actions_text__1bx9C span::after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 1px;
  background: linear-gradient(155.19deg, #c12e2e 15.81%, #cd9f29 100.43%);
}
.actions_content__FDAnD .actions_cards__3WWpf .actions_card__3EsUL .actions_steps__2E_BH .actions_step__1y5Yc .actions_text__1bx9C a {
  cursor: pointer;
  position: relative;
  background: linear-gradient(155.19deg, #c12e2e 15.81%, #cd9f29 100.43%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  text-decoration: underline;
}
.actions_content__FDAnD .actions_cards__3WWpf .actions_card__3EsUL .actions_steps__2E_BH .actions_step__1y5Yc .actions_text__1bx9C a::after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 1px;
  background: linear-gradient(155.19deg, #c12e2e 15.81%, #cd9f29 100.43%);
}
@media screen and (max-width: 1200px) {
  .actions_content__FDAnD {
    padding-left: 5px !important;
    padding-right: 5px !important;
  }
  .actions_content__FDAnD .actions_cards__3WWpf {
    flex-wrap: wrap;
    grid-row-gap: 20px;
    row-gap: 20px;
  }
  .actions_content__FDAnD .actions_cards__3WWpf .actions_card__3EsUL {
    width: 100%;
  }
  .actions_content__FDAnD .actions_cards__3WWpf .actions_card__3EsUL.actions_big__1KF4I {
    width: 100%;
  }
}

.news_block__2qFlK {
  position: relative;
  margin-top: 120px;
}
.news_header__3uYEY {
  font-size: 32px;
  font-weight: 700;
  line-height: 40px;
  text-align: left;
  text-transform: uppercase;
  background: linear-gradient(180deg, #c12e2e 15.81%, #cd9f29 100.43%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.news_background__1Fh-- {
  position: absolute;
  top: 40px;
  right: 160px;
}
.news_all-news__Iwrt9 {
  cursor: pointer;
  position: relative;
  margin-top: 48px;
  margin-bottom: 17px;
  margin-left: -5px;
  padding-right: 8px;
  width: 100%;
  text-align: end;
  color: #555;
  font-size: 11.5px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.news_all-news__Iwrt9::before {
  content: "";
  position: absolute;
  background: linear-gradient(180deg, #c12e2e 15.81%, #cd9f29 100.43%);
  width: 6px;
  height: 12px;
  top: 1px;
  left: calc(100% - 4px);
  clip-path: polygon(0 12.5%, 100% 50%, 0 87.5%);
}
.news_news__27EPR {
  --width-card: 264px;
  --height-card: 318px;
}
.news_news__27EPR .news_slide__2PJA4 {
  width: var(--width-card);
}
.news_news__27EPR .news_card__10nvq {
  cursor: default;
  position: relative;
  background-color: #fff;
  border-radius: 19.5px;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  width: var(--width-card);
  height: var(--height-card);
}
.news_news__27EPR .news_card__10nvq::before {
  content: "";
  background-image: linear-gradient(180deg, #c12e2e 15.81%, #cd9f29 100.43%);
  top: -1px;
  left: -1px;
  bottom: -1px;
  right: -1px;
  position: absolute;
  z-index: -1;
  border-radius: 20px;
}
.news_news__27EPR .news_card__10nvq .news_image__29yJA {
  height: calc(var(--height-card) / 2);
  max-width: 100%;
  border-radius: 20px;
}
.news_news__27EPR .news_card__10nvq .news_image__29yJA img {
  width: 100%;
  height: calc(var(--height-card) / 2);
  border-radius: 20px 20px 0px 0px;
  object-fit: cover;
}
.news_news__27EPR .news_card__10nvq .news_info__2v4i6 {
  padding: 15px;
  display: flex;
  flex-wrap: wrap;
  align-content: space-between;
  height: calc(var(--height-card) / 2);
}
.news_news__27EPR .news_card__10nvq .news_info__2v4i6 .news_title__68v5S {
  color: #333;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  width: 100%;
}
.news_news__27EPR .news_card__10nvq .news_info__2v4i6 .news_description__1SlS6 {
  color: #555;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  max-width: var(--width-card);
  max-height: calc(var(--height-card) / 2 - 80px);
  margin-top: 10px;
  -webkit-line-clamp: 5;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
}
.news_news__27EPR .news_card__10nvq .news_info__2v4i6 .news_addition__2zRVZ {
  display: flex;
  justify-content: space-between;
  color: #333;
  font-size: 11px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  width: 100%;
}
.news_news__27EPR .news_card__10nvq .news_info__2v4i6 .news_addition__2zRVZ .news_more__1EXQp {
  cursor: pointer;
  position: relative;
  padding-right: 8px;
  background-image: linear-gradient(180deg, #c12e2e 15.81%, #cd9f29 100.43%);
  color: transparent;
  -webkit-background-clip: text;
  background-clip: text;
}
.news_news__27EPR .news_card__10nvq .news_info__2v4i6 .news_addition__2zRVZ .news_more__1EXQp::before {
  content: "";
  position: absolute;
  background: linear-gradient(180deg, #c12e2e 15.81%, #cd9f29 100.43%);
  width: 6px;
  height: 12px;
  top: 1px;
  left: calc(100% - 4px);
  clip-path: polygon(0 12.5%, 100% 50%, 0 87.5%);
}
.news_news__27EPR .news_swiper__27oSu {
  max-width: 1100px;
  padding: 1px 3px;
  height: calc(var(--height-card) + 33px) !important;
  --background-swiper-scrollbar-horizontal: #f8f8f8;
  --background-swiper-scrollbar-drag: linear-gradient(
    155deg,
    #c12e2e 15.81%,
    #cd9f29 100.43%
  );
}

@media screen and (max-width: 1200px) {
  .news_block__2qFlK {
    padding-left: 5px !important;
    padding-right: 5px !important;
  }
  .news_news__27EPR .news_swiper__27oSu {
    max-width: 100vw;
  }
}

.partners_block__2lFoC {
  position: relative;
  margin-top: 160px;
}
.partners_header__n44XZ {
  font-size: 32px;
  font-weight: 700;
  line-height: 40px;
  text-align: left;
  text-transform: uppercase;
  background: linear-gradient(180deg, #c12e2e 15.81%, #cd9f29 100.43%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.partners_partners__3AxiA {
  margin-top: 77px;
  display: flex;
  flex-wrap: wrap;
  grid-gap: 25px;
  gap: 25px;
}
.partners_partners__3AxiA .partners_partner__2WZxL {
  cursor: default;
  position: relative;
  background-color: #fff;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  width: 350px;
}
.partners_partners__3AxiA .partners_partner__2WZxL .partners_photo__CG_Kr {
  height: 120px;
}
.partners_partners__3AxiA .partners_partner__2WZxL .partners_photo__CG_Kr img {
  height: 120px;
  width: 100%;
  object-fit: contain;
}
.partners_partners__3AxiA .partners_partner__2WZxL .partners_info__1hsPN .partners_title__1fk9- {
  padding: 8px 13px;
}
.partners_partners__3AxiA .partners_partner__2WZxL .partners_info__1hsPN .partners_title__1fk9- .partners_name__17IxJ {
  color: #333;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}
@media screen and (max-width: 1200px) {
  .partners_block__2lFoC {
    padding-left: 5px !important;
    padding-right: 5px !important;
  }
  .partners_partners__3AxiA {
    justify-content: center;
  }
  .partners_partners__3AxiA .partners_partner__2WZxL {
    width: 100vw;
  }
}

.about_block__377ek {
  position: relative;
  margin-top: 10px;
}

.about_content__1D0YD {
  margin-top: 100px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
}

.about_content__1D0YD .about_image__pfDYt {
  display: flex;
  width: 474px;
  height: 316px;
}

.about_content__1D0YD .about_image__pfDYt img {
  object-fit: cover;
  border-radius: 20px;
  box-shadow: 1px 1px 8px 0px #00000026;
}

.about_background__2T5y- {
  position: absolute;
  z-index: -1;
  top: -60px;
  left: -360px;
}

.about_info__3bi-a {
  width: 570px;
}

.about_info__3bi-a .about_header__3u-QS {
  font-size: 26px;
  font-weight: 700;
  line-height: 32px;
  text-align: left;
  text-transform: uppercase;
  background: linear-gradient(155.19deg, #c12e2e 15.81%, #cd9f29 100.43%);
  margin: 0;
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.about_info__3bi-a .about_list__LSJfJ {
  margin-top: 16px;
  padding: 0;
}
.about_info__3bi-a .about_list__LSJfJ li {
  display: flex;
  align-items: center;
  grid-gap: 10px;
  gap: 10px;
  margin-bottom: 10px;
}
.about_info__3bi-a .about_list__LSJfJ li .about_dot__1MHre {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
}
.about_info__3bi-a .about_list__LSJfJ li .about_dot__1MHre .about_icon__2AQWF {
  position: absolute;
}
.about_info__3bi-a .about_list__LSJfJ li .about_text__14osY {
  font-size: 16px;
  font-weight: 400;
  line-height: 20px;
  text-align: left;
}

@media screen and (max-width: 1200px) {
  .about_content__1D0YD {
    margin-top: 20px;
    padding-left: 5px !important;
  }
  .about_info__3bi-a {
    width: calc(100% - 70px);
  }
  .about_content__1D0YD .about_image__pfDYt {
    display: none;
  }
  .about_background__2T5y- {
    display: none;
  }
}

.actions_block__2aY7- {
  margin-top: 100px;
}
.actions_content__3B7Kv {
  display: flex;
  flex-direction: column;
  grid-row-gap: 55px;
  row-gap: 55px;
}
.actions_content__3B7Kv .actions_header__YpjuK {
  width: 100%;
  font-size: 32px;
  font-weight: 700;
  line-height: 40px;
  text-align: left;
  text-transform: uppercase;
  background: linear-gradient(180deg, #c12e2e 15.81%, #cd9f29 100.43%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.actions_content__3B7Kv .actions_cards__2W190 {
  display: flex;
  justify-content: space-between;
}
.actions_content__3B7Kv .actions_cards__2W190 .actions_card__1myq0 {
  width: 30%;
  height: calc(100% - 20px);
  min-height: 400px;
  box-shadow: 1px 1px 8px 0px #00000026;
  border-radius: 10px;
  padding: 2px 14px 30px 14px;
}
.actions_content__3B7Kv .actions_cards__2W190 .actions_card__1myq0.actions_big__2s6dP {
  width: 35%;
  height: calc(100% - 20px);
  min-height: 425px;
  padding: 2px 30px 30px 30px;
}
.actions_content__3B7Kv .actions_cards__2W190 .actions_card__1myq0 .actions_title__2-fY8 {
  display: flex;
  justify-content: center;
  margin-top: 80px;
  margin-bottom: 22px;
}
.actions_content__3B7Kv .actions_cards__2W190 .actions_card__1myq0 .actions_title__2-fY8 .actions_container__3Eb4E {
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: default;
  position: relative;
  background-color: #fff;
  border-radius: 19.5px;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  width: auto;
  padding-left: 30px;
  padding-right: 30px;
  height: 56px;
}
.actions_content__3B7Kv .actions_cards__2W190 .actions_card__1myq0.actions_big__2s6dP .actions_title__2-fY8 .actions_container__3Eb4E {
  padding-left: 15px;
  padding-right: 15px;
}
.actions_content__3B7Kv .actions_cards__2W190 .actions_card__1myq0 .actions_title__2-fY8 .actions_container__3Eb4E::before {
  content: "";
  background-image: linear-gradient(180deg, #c12e2e 15.81%, #cd9f29 100.43%);
  top: -1px;
  left: -1px;
  bottom: -1px;
  right: -1px;
  position: absolute;
  z-index: -1;
  border-radius: 20px;
}
.actions_content__3B7Kv .actions_cards__2W190 .actions_card__1myq0 .actions_title__2-fY8 .actions_container__3Eb4E .actions_iconContainer__1lNNB {
  position: absolute;
}
.actions_content__3B7Kv .actions_cards__2W190 .actions_card__1myq0 .actions_title__2-fY8 .actions_container__3Eb4E .actions_icon__2rlbz {
  position: relative;
  display: flex;
  justify-content: center;
  width: 100%;
}
.actions_content__3B7Kv .actions_cards__2W190 .actions_card__1myq0 .actions_title__2-fY8 .actions_container__3Eb4E .actions_icon__2rlbz img {
  position: absolute;
  top: -106px;
}
.actions_content__3B7Kv .actions_cards__2W190 .actions_card__1myq0 .actions_title__2-fY8 .actions_container__3Eb4E .actions_icon__2rlbz img:nth-child(2) {
  top: calc(50% - 80px);
}
.actions_content__3B7Kv .actions_cards__2W190 .actions_card__1myq0 .actions_title__2-fY8 .actions_container__3Eb4E .actions_text__2LOk3 {
  margin-top: 10px;
  font-size: 15px;
  font-weight: 400;
  line-height: 18.15px;
  text-align: center;
}
.actions_content__3B7Kv .actions_cards__2W190 .actions_card__1myq0 .actions_steps__39AJy {
  display: flex;
  flex-direction: column;
  grid-row-gap: 14px;
  row-gap: 14px;
}
.actions_content__3B7Kv .actions_cards__2W190 .actions_card__1myq0 .actions_steps__39AJy .actions_step__183cP {
  display: flex;
  align-items: center;
  grid-column-gap: 10px;
  column-gap: 10px;
}
.actions_content__3B7Kv .actions_cards__2W190 .actions_card__1myq0 .actions_steps__39AJy .actions_step__183cP .actions_icon__2rlbz {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
}
.actions_content__3B7Kv .actions_cards__2W190 .actions_card__1myq0 .actions_steps__39AJy .actions_step__183cP .actions_icon__2rlbz .actions_normal__2OKs5 {
  position: absolute;
}
.actions_content__3B7Kv .actions_cards__2W190 .actions_card__1myq0 .actions_steps__39AJy .actions_step__183cP .actions_icon__2rlbz .actions_success__3zUaX {
  position: absolute;
  opacity: 0;
}
.actions_content__3B7Kv .actions_cards__2W190 .actions_card__1myq0 .actions_steps__39AJy .actions_step__183cP.actions_passed__3dJKd.actions_auth__OBz_p {
  pointer-events: none !important;
}
.actions_content__3B7Kv .actions_cards__2W190 .actions_card__1myq0 .actions_steps__39AJy .actions_step__183cP.actions_passed__3dJKd .actions_icon__2rlbz .actions_normal__2OKs5 {
  opacity: 0.3;
}
.actions_content__3B7Kv .actions_cards__2W190 .actions_card__1myq0 .actions_steps__39AJy .actions_step__183cP.actions_passed__3dJKd .actions_icon__2rlbz .actions_success__3zUaX {
  opacity: 1;
}
.actions_content__3B7Kv .actions_cards__2W190 .actions_card__1myq0 .actions_steps__39AJy .actions_step__183cP .actions_text__2LOk3 {
  display: flex;
  flex-wrap: wrap;
  grid-column-gap: 5px;
  column-gap: 5px;
  font-size: 16px;
  font-weight: 400;
  line-height: 20px;
  text-align: left;
}
.actions_content__3B7Kv .actions_cards__2W190 .actions_card__1myq0 .actions_steps__39AJy .actions_step__183cP .actions_text__2LOk3 span {
  cursor: pointer;
  position: relative;
  background: linear-gradient(155.19deg, #c12e2e 15.81%, #cd9f29 100.43%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  text-decoration: underline;
}
.actions_content__3B7Kv .actions_cards__2W190 .actions_card__1myq0 .actions_steps__39AJy .actions_step__183cP .actions_text__2LOk3 span::after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 1px;
  background: linear-gradient(155.19deg, #c12e2e 15.81%, #cd9f29 100.43%);
}
.actions_content__3B7Kv .actions_cards__2W190 .actions_card__1myq0 .actions_steps__39AJy .actions_step__183cP .actions_text__2LOk3 a {
  cursor: pointer;
  position: relative;
  background: linear-gradient(155.19deg, #c12e2e 15.81%, #cd9f29 100.43%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  text-decoration: underline;
}
.actions_content__3B7Kv .actions_cards__2W190 .actions_card__1myq0 .actions_steps__39AJy .actions_step__183cP .actions_text__2LOk3 a::after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 1px;
  background: linear-gradient(155.19deg, #c12e2e 15.81%, #cd9f29 100.43%);
}
@media screen and (max-width: 1200px) {
  .actions_content__3B7Kv {
    padding-left: 5px !important;
    padding-right: 5px !important;
  }
  .actions_content__3B7Kv .actions_cards__2W190 {
    flex-wrap: wrap;
    grid-row-gap: 20px;
    row-gap: 20px;
  }
  .actions_content__3B7Kv .actions_cards__2W190 .actions_card__1myq0 {
    width: 100%;
  }
  .actions_content__3B7Kv .actions_cards__2W190 .actions_card__1myq0.actions_big__2s6dP {
    width: 100%;
  }
}

.info_block__1Z70S {
  margin-top: 80px;
}
.info_inner__1G3rH {
  display: flex;
  justify-content: center;
}
.info_card__yBILT {
  width: 100%;
  height: auto;
}
.info_card__yBILT .info_border__2rm2t {
  background-image: linear-gradient(
    191.12deg,
    #cbec00 -91.84%,
    #700101 25.42%,
    #d0a024 126.74%
  );
}
.info_card__yBILT .info_arrow__1oixL {
  background-image: linear-gradient(
    201.12deg,
    #cbec00 -91.84%,
    #700101 25.42%,
    #d0a024 186.74%
  );
}
.info_content__1mfZm {
  min-height: 100px;
  padding: 20px;
}
@media screen and (max-width: 1200px) {
  .info_card__yBILT {
    width: calc(100vw - 10px);
  }
}

.news_block__1q_pd {
  position: relative;
  margin-top: 120px;
}
.news_header__3QDvR {
  font-size: 32px;
  font-weight: 700;
  line-height: 40px;
  text-align: left;
  text-transform: uppercase;
  background: linear-gradient(180deg, #c12e2e 15.81%, #cd9f29 100.43%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.news_background__l-mry {
  position: absolute;
  top: 40px;
  right: 160px;
}
.news_all-news__3ePQL {
  cursor: pointer;
  position: relative;
  margin-top: 48px;
  margin-bottom: 17px;
  margin-left: -5px;
  padding-right: 8px;
  width: 100%;
  text-align: end;
  color: #555;
  font-size: 11.5px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.news_all-news__3ePQL::before {
  content: "";
  position: absolute;
  background: linear-gradient(180deg, #c12e2e 15.81%, #cd9f29 100.43%);
  width: 6px;
  height: 12px;
  top: 1px;
  left: calc(100% - 4px);
  clip-path: polygon(0 12.5%, 100% 50%, 0 87.5%);
}
.news_news__3NrVs {
  --width-card: 264px;
  --height-card: 318px;
}
.news_news__3NrVs .news_slide__-kCuj {
  width: var(--width-card);
}
.news_news__3NrVs .news_card__17IhA {
  cursor: default;
  position: relative;
  background-color: #fff;
  border-radius: 19.5px;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  width: var(--width-card);
  height: var(--height-card);
}
.news_news__3NrVs .news_card__17IhA::before {
  content: "";
  background-image: linear-gradient(180deg, #c12e2e 15.81%, #cd9f29 100.43%);
  top: -1px;
  left: -1px;
  bottom: -1px;
  right: -1px;
  position: absolute;
  z-index: -1;
  border-radius: 20px;
}
.news_news__3NrVs .news_card__17IhA .news_image__60l6s {
  height: calc(var(--height-card) / 2);
  max-width: 100%;
  border-radius: 20px;
}
.news_news__3NrVs .news_card__17IhA .news_image__60l6s img {
  width: 100%;
  height: calc(var(--height-card) / 2);
  border-radius: 20px 20px 0px 0px;
  object-fit: cover;
}
.news_news__3NrVs .news_card__17IhA .news_info__1VNA- {
  padding: 15px;
  display: flex;
  flex-wrap: wrap;
  align-content: space-between;
  height: calc(var(--height-card) / 2);
}
.news_news__3NrVs .news_card__17IhA .news_info__1VNA- .news_title__4Ez1J {
  color: #333;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  width: 100%;
}
.news_news__3NrVs .news_card__17IhA .news_info__1VNA- .news_description__3BcNO {
  color: #555;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  max-width: var(--width-card);
  max-height: calc(var(--height-card) / 2 - 80px);
  margin-top: 10px;
  -webkit-line-clamp: 5;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
}
.news_news__3NrVs .news_card__17IhA .news_info__1VNA- .news_addition__Kj9Tv {
  display: flex;
  justify-content: space-between;
  color: #333;
  font-size: 11px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  width: 100%;
}
.news_news__3NrVs .news_card__17IhA .news_info__1VNA- .news_addition__Kj9Tv .news_more__32Xhy {
  cursor: pointer;
  position: relative;
  padding-right: 8px;
  background-image: linear-gradient(180deg, #c12e2e 15.81%, #cd9f29 100.43%);
  color: transparent;
  -webkit-background-clip: text;
  background-clip: text;
}
.news_news__3NrVs .news_card__17IhA .news_info__1VNA- .news_addition__Kj9Tv .news_more__32Xhy::before {
  content: "";
  position: absolute;
  background: linear-gradient(180deg, #c12e2e 15.81%, #cd9f29 100.43%);
  width: 6px;
  height: 12px;
  top: 1px;
  left: calc(100% - 4px);
  clip-path: polygon(0 12.5%, 100% 50%, 0 87.5%);
}
.news_news__3NrVs .news_swiper__1AjtH {
  max-width: 1100px;
  padding: 1px 3px;
  height: calc(var(--height-card) + 33px) !important;
  --background-swiper-scrollbar-horizontal: #f8f8f8;
  --background-swiper-scrollbar-drag: linear-gradient(
    155deg,
    #c12e2e 15.81%,
    #cd9f29 100.43%
  );
}

@media screen and (max-width: 1200px) {
  .news_block__1q_pd {
    padding-left: 5px !important;
    padding-right: 5px !important;
  }
  .news_news__3NrVs .news_swiper__1AjtH {
    max-width: 100vw;
  }
}

.partners_block__2s3uO {
  position: relative;
  margin-top: 160px;
}
.partners_header__2PuLB {
  font-size: 32px;
  font-weight: 700;
  line-height: 40px;
  text-align: left;
  text-transform: uppercase;
  background: linear-gradient(180deg, #c12e2e 15.81%, #cd9f29 100.43%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.partners_partners__44_yP {
  margin-top: 77px;
  display: flex;
  flex-wrap: wrap;
  grid-gap: 25px;
  gap: 25px;
}
.partners_partners__44_yP .partners_partner__11GQE {
  cursor: default;
  position: relative;
  background-color: #fff;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  width: 350px;
}
.partners_partners__44_yP .partners_partner__11GQE .partners_photo__Z-HOf {
  height: 120px;
}
.partners_partners__44_yP .partners_partner__11GQE .partners_photo__Z-HOf img {
  height: 120px;
  width: 100%;
  object-fit: contain;
}
.partners_partners__44_yP .partners_partner__11GQE .partners_info__3y1Dn .partners_title__3pbZs {
  padding: 8px 13px;
}
.partners_partners__44_yP .partners_partner__11GQE .partners_info__3y1Dn .partners_title__3pbZs .partners_name__2Xg_H {
  color: #333;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}
@media screen and (max-width: 1200px) {
  .partners_block__2s3uO {
    padding-left: 5px !important;
    padding-right: 5px !important;
  }
  .partners_partners__44_yP {
    justify-content: center;
  }
  .partners_partners__44_yP .partners_partner__11GQE {
    width: 100vw;
  }
}

.about_block__19lFs {
  margin-top: 30px;
}
.about_block__19lFs .about_logo__3HDmY {
  width: 480px;
}
.about_block__19lFs .about_logo__3HDmY img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.about_block__19lFs .about_info__NJ_hh {
  margin-top: 20px;
  width: 575px;
}
.about_block__19lFs .about_info__NJ_hh .about_header__2beoL {
  display: flex;
  grid-gap: 26px;
  gap: 26px;
}
.about_block__19lFs .about_info__NJ_hh .about_header__2beoL h1 {
  color: #555;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  margin-bottom: 0px;
  min-width: 77.5%;
}
.about_block__19lFs .about_info__NJ_hh .about_header__2beoL .about_rating__odoM2 {
  width: auto;
}
.about_block__19lFs .about_info__NJ_hh .about_header__2beoL .about_title__19-yW {
  color: #555;
  font-size: 16px;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
}
.about_block__19lFs .about_info__NJ_hh .about_header__2beoL .about_rating__odoM2 .about_stars__gABiI {
  flex-wrap: nowrap;
  display: flex;
}
.about_block__19lFs .about_info__NJ_hh .about_header__2beoL .about_rating__odoM2 .about_stars__gABiI svg {
  margin-left: 3px;
  fill: #d9d9d9;
}
.about_block__19lFs .about_info__NJ_hh .about_header__2beoL .about_rating__odoM2 .about_stars__gABiI svg.about_active__sJdHd {
  fill: #ddc544;
}
.about_block__19lFs .about_info__NJ_hh .about_description__2S_k4 {
  margin-top: 15px;
  color: #555;
  font-size: 16px;
  font-style: normal;
  font-weight: 300;
  line-height: 20px;
}
.about_block__19lFs .about_info__NJ_hh .about_addresses__mEhs2 {
  margin-top: 15px;
}
.about_block__19lFs .about_info__NJ_hh .about_addresses__mEhs2 .about_title__19-yW {
  color: #555;
  font-size: 17px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-bottom: 8px;
}
.about_block__19lFs .about_info__NJ_hh .about_addresses__mEhs2 .about_address__3IO74 {
  color: #555;
  font-size: 16px;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
}
.about_block__19lFs .about_info__NJ_hh .about_contacts__2Tys2 {
  display: flex;
  grid-gap: 48px;
  gap: 48px;
  margin-top: 10px;
}
.about_block__19lFs .about_info__NJ_hh .about_contacts__2Tys2 .about_contact__2Y_Ir .about_title__19-yW {
  color: #555;
  font-size: 17px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-bottom: 8px;
}
.about_block__19lFs .about_info__NJ_hh .about_contacts__2Tys2 .about_contact__2Y_Ir .about_description__2S_k4 {
  margin-top: 0px;
  text-decoration: none;
}
.about_block__19lFs .about_info__NJ_hh .about_contacts__2Tys2 .about_contact__2Y_Ir .about_link__152S- {
  color: #1194ba;
  -webkit-text-decoration-line: underline;
          text-decoration-line: underline;
}
.about_block__19lFs .about_finances__2OXR7 {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-top: 12px;
  width: 480px;
}
.about_block__19lFs .about_finances__2OXR7 .about_card__5iJf9 {
  cursor: default;
  position: relative;
  background-color: #fff;
  border-radius: 19.5px;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  height: 75px;
  text-align: center;
  padding: 18px;
}
.about_block__19lFs .about_finances__2OXR7 .about_card__5iJf9::before {
  content: "";
  background-image: linear-gradient(
    191deg,
    #00b3ec -91.84%,
    #cbec00 -91.82%,
    #700101 25.42%,
    #d0a024 126.74%
  );
  top: -1px;
  left: -1px;
  bottom: -1px;
  right: -1px;
  position: absolute;
  z-index: -1;
  border-radius: 20px;
}
.about_block__19lFs .about_finances__2OXR7 .about_card__5iJf9 .about_count__1mRK4 {
  color: #555;
  text-align: center;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.about_block__19lFs .about_finances__2OXR7 .about_card__5iJf9 .about_count__1mRK4 span {
  margin-left: 5px;
  color: #555;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
}
.about_block__19lFs .about_finances__2OXR7 .about_card__5iJf9 .about_description__2S_k4 {
  color: #555;
  text-align: center;
  font-size: 12px;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
}
.about_block__19lFs .about_finances__2OXR7 .about_info__NJ_hh {
  margin-top: 0px;
  display: flex;
  justify-content: space-between;
}
.about_block__19lFs .about_finances__2OXR7 .about_info__NJ_hh .about_title__19-yW {
  color: #555;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.about_block__19lFs .about_finances__2OXR7 .about_info__NJ_hh .about_description__2S_k4 {
  margin-top: 0px;
  color: #555;
  font-size: 16px;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
}
.about_block__19lFs .about_contact__2Y_Ir {
  margin-top: 20px;
}
.about_block__19lFs .about_contact__2Y_Ir .about_card__5iJf9 {
  width: 576px;
  height: 160px;
}
.about_block__19lFs .about_contact__2Y_Ir .about_card__5iJf9 .about_border__1LRMy {
  background-image: linear-gradient(
    191deg,
    #00b3ec -91.84%,
    #cbec00 -91.82%,
    #700101 25.42%,
    #d0a024 126.74%
  );
}
.about_block__19lFs .about_contact__2Y_Ir .about_card__5iJf9 .about_arrow__1YmE- {
  background-image: linear-gradient(
    201deg,
    #00b3ec -91.84%,
    #cbec00 -91.82%,
    #700101 25.42%,
    #d0a024 126.74%
  );
}
.about_block__19lFs .about_contact__2Y_Ir .about_card__5iJf9 .about_inner__3naSt {
  padding: 20px;
}
.about_block__19lFs .about_contact__2Y_Ir .about_card__5iJf9 .about_inner__3naSt .about_title__19-yW {
  color: #555;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.about_block__19lFs .about_contact__2Y_Ir .about_card__5iJf9 .about_inner__3naSt .about_about__2_Ucl {
  margin-top: 14px;
  display: flex;
  align-items: center;
  grid-gap: 16px;
  gap: 16px;
}
.about_block__19lFs .about_contact__2Y_Ir .about_card__5iJf9 .about_inner__3naSt .about_about__2_Ucl .about_photo__ycySJ {
  width: 72px;
  height: 72px;
  border-radius: 100px;
}
.about_block__19lFs .about_contact__2Y_Ir .about_card__5iJf9 .about_inner__3naSt .about_about__2_Ucl .about_photo__ycySJ {
  object-fit: cover;
  width: 72px;
  height: 72px;
  border-radius: 100px;
}
.about_block__19lFs .about_contact__2Y_Ir .about_card__5iJf9 .about_inner__3naSt .about_about__2_Ucl .about_contacts__2Tys2 {
  margin-top: 5px;
}
.about_block__19lFs .about_contact__2Y_Ir .about_card__5iJf9 .about_inner__3naSt .about_about__2_Ucl .about_contacts__2Tys2 .about_name__2qvF0 {
  color: #555;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  vertical-align: bottom;
}
.about_block__19lFs .about_contact__2Y_Ir .about_card__5iJf9 .about_inner__3naSt .about_about__2_Ucl .about_contacts__2Tys2 .about_positions__PTLrO {
  display: flex;
  grid-gap: 4px;
  gap: 4px;
}
.about_block__19lFs .about_contact__2Y_Ir .about_card__5iJf9 .about_inner__3naSt .about_about__2_Ucl .about_contacts__2Tys2 .about_positions__PTLrO .about_title__19-yW {
  color: #555;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.about_block__19lFs .about_contact__2Y_Ir .about_card__5iJf9 .about_inner__3naSt .about_about__2_Ucl .about_contacts__2Tys2 .about_positions__PTLrO .about_position__1CCyd {
  color: #555;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.about_block__19lFs .about_contact__2Y_Ir .about_card__5iJf9 .about_inner__3naSt .about_about__2_Ucl .about_contacts__2Tys2 .about_links__2k0G8 {
  width: 100%;
  margin-top: 5px;
  display: flex;
  grid-gap: 30px;
  gap: 30px;
}
.about_block__19lFs .about_contact__2Y_Ir .about_card__5iJf9 .about_inner__3naSt .about_about__2_Ucl .about_contacts__2Tys2 .about_phones__1_OvV .about_title__19-yW {
  color: #555;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.about_block__19lFs .about_contact__2Y_Ir .about_card__5iJf9 .about_inner__3naSt .about_about__2_Ucl .about_contacts__2Tys2 .about_phones__1_OvV .about_phone__1nRAR {
  display: inline;
  color: #555;
  font-size: 12px;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
}
.about_block__19lFs .about_contact__2Y_Ir .about_card__5iJf9 .about_inner__3naSt .about_about__2_Ucl .about_contacts__2Tys2 .about_phones__1_OvV .about_phone__1nRAR + .about_phone__1nRAR {
  margin-left: 18px;
}
.about_block__19lFs .about_contact__2Y_Ir .about_card__5iJf9 .about_inner__3naSt .about_about__2_Ucl .about_contacts__2Tys2 .about_emails__243xx .about_title__19-yW {
  color: #555;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.about_block__19lFs .about_contact__2Y_Ir .about_card__5iJf9 .about_inner__3naSt .about_about__2_Ucl .about_contacts__2Tys2 .about_emails__243xx .about_email__3Tlne {
  display: inline;
  color: #1194ba;
  font-size: 12px;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
  -webkit-text-decoration-line: underline;
          text-decoration-line: underline;
}
.about_block__19lFs .about_contact__2Y_Ir .about_typeActivity__KB3YU {
  margin-top: 14px;
  display: flex;
  grid-gap: 25px;
  gap: 25px;
  max-width: 576px;
}
.about_block__19lFs .about_contact__2Y_Ir .about_typeActivity__KB3YU .about_title__19-yW {
  color: #555;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-wrap: nowrap;
}
.about_block__19lFs .about_contact__2Y_Ir .about_typeActivity__KB3YU .about_description__2S_k4 {
  color: #555;
  font-size: 16px;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
}
@media screen and (max-width: 1200px) {
  .about_block__19lFs {
    margin-top: 10px;
  }
  .about_block__19lFs .about_logo__3HDmY {
    display: none;
  }
  .about_block__19lFs .about_info__NJ_hh {
    padding: 0 5px;
  }
  .about_block__19lFs .about_info__NJ_hh .about_header__2beoL {
    flex-wrap: wrap;
  }
  .about_block__19lFs .about_info__NJ_hh .about_contacts__2Tys2 {
    flex-wrap: wrap;
    grid-gap: 12px;
    gap: 12px;
  }
  .about_block__19lFs .about_finances__2OXR7 {
    width: 100vw;
    padding: 0 5px;
    grid-row-gap: 12px;
    row-gap: 12px;
  }
  .about_block__19lFs .about_finances__2OXR7 .about_card__5iJf9 {
    width: 100%;
  }
  .about_block__19lFs .about_contact__2Y_Ir {
    padding: 0 5px;
  }
  .about_block__19lFs .about_contact__2Y_Ir .about_card__5iJf9 {
    width: calc(100vw - 10px);
  }
}

.addition_block__2s5GM {
  position: relative;
  margin-top: 40px;
}
.addition_tabs__2hrSL {
  display: flex;
  align-items: flex-end;
  border-bottom: none !important;
}
.addition_tab__3b0RH {
  cursor: pointer;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  text-transform: uppercase;
  color: #999;
  border: none !important;
  border-radius: 0px !important;
  background: #fff;
  margin-bottom: 5px !important;
}
.addition_tab__3b0RH:hover {
  color: #999;
  background: linear-gradient(
    191deg,
    #00b3ec -91.84%,
    #cbec00 -91.82%,
    #700101 25.42%,
    #d0a024 126.74%
  );
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.addition_tab__3b0RH.addition_active__1ZSZh {
  font-size: 25px;
  background: linear-gradient(
    191deg,
    #00b3ec -91.84%,
    #cbec00 -91.82%,
    #700101 25.42%,
    #d0a024 126.74%
  );
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.addition_tabContent__1DT43 {
  position: relative;
  margin-top: 30px;
  display: flex !important;
  justify-content: space-between;
  align-items: flex-start;
  align-content: flex-start;
  grid-row-gap: 18px;
  row-gap: 18px;
  flex-wrap: wrap;
}
.addition_tabContent__1DT43 .addition_practices__2iCoD {
  display: flex;
  flex-wrap: wrap;
  grid-column-gap: 30px;
  column-gap: 30px;
  grid-row-gap: 25px;
  row-gap: 25px;
}
.addition_tabContent__1DT43 .addition_practices__2iCoD .addition_card__29P2Q {
  width: 535px;
  min-height: 120px;
  height: min-content;
}
.addition_tabContent__1DT43 .addition_practices__2iCoD .addition_card__29P2Q .addition_border__P4FOw {
  background-image: linear-gradient(
    191deg,
    #00b3ec -91.84%,
    #cbec00 -91.82%,
    #700101 25.42%,
    #d0a024 126.74%
  );
}
.addition_tabContent__1DT43 .addition_practices__2iCoD .addition_card__29P2Q .addition_arrow__bdlnM {
  background-image: linear-gradient(
    201deg,
    #00b3ec -91.84%,
    #cbec00 -91.82%,
    #700101 25.42%,
    #d0a024 126.74%
  );
}
.addition_tabContent__1DT43 .addition_practices__2iCoD .addition_card__29P2Q .addition_inner__1bqbA {
  padding: 20px;
}
.addition_tabContent__1DT43 .addition_practices__2iCoD .addition_card__29P2Q .addition_inner__1bqbA .addition_dates__1rOQ5 {
  color: #555;
  font-size: 13px;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0em;
  text-align: left;
  text-align: end;
  width: 100%;
  margin-bottom: 8px;
}
.addition_tabContent__1DT43 .addition_practices__2iCoD .addition_card__29P2Q .addition_inner__1bqbA .addition_title__12YTc {
  color: #555;
  font-size: 16px;
  font-weight: 500;
  line-height: 19px;
  text-align: left;
  text-decoration: none;
  width: 100%;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}
.addition_tabContent__1DT43 .addition_practices__2iCoD .addition_card__29P2Q .addition_inner__1bqbA .addition_subtitle__3--gG {
  color: #555;
  font-size: 14px;
  font-weight: 400;
  line-height: 19px;
  text-align: left;
  text-decoration: none;
  width: 100%;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}
.addition_tabContent__1DT43 .addition_practices__2iCoD .addition_card__29P2Q .addition_inner__1bqbA .addition_description__32CkS {
  margin-top: 8px;
  color: #555;
  font-size: 14px;
  font-weight: 400;
  line-height: 19px;
  text-align: left;
  text-decoration: none;
}
.addition_tabContent__1DT43 .addition_mentorships__1EJWx {
  display: flex;
  flex-wrap: wrap;
  grid-column-gap: 30px;
  column-gap: 30px;
  grid-row-gap: 25px;
  row-gap: 25px;
}
.addition_tabContent__1DT43 .addition_mentorships__1EJWx .addition_card__29P2Q {
  width: 535px;
  min-height: 120px;
  height: min-content;
}
.addition_tabContent__1DT43 .addition_mentorships__1EJWx .addition_card__29P2Q .addition_border__P4FOw {
  background-image: linear-gradient(
    191deg,
    #00b3ec -91.84%,
    #cbec00 -91.82%,
    #700101 25.42%,
    #d0a024 126.74%
  );
}
.addition_tabContent__1DT43 .addition_mentorships__1EJWx .addition_card__29P2Q .addition_arrow__bdlnM {
  background-image: linear-gradient(
    201deg,
    #00b3ec -91.84%,
    #cbec00 -91.82%,
    #700101 25.42%,
    #d0a024 126.74%
  );
}
.addition_tabContent__1DT43 .addition_mentorships__1EJWx .addition_card__29P2Q .addition_inner__1bqbA {
  padding: 20px;
}
.addition_tabContent__1DT43 .addition_mentorships__1EJWx .addition_card__29P2Q .addition_inner__1bqbA .addition_dates__1rOQ5 {
  color: #555;
  font-size: 13px;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0em;
  text-align: left;
  text-align: end;
  width: 100%;
  margin-bottom: 8px;
}
.addition_tabContent__1DT43 .addition_mentorships__1EJWx .addition_card__29P2Q .addition_inner__1bqbA .addition_title__12YTc {
  color: #555;
  font-size: 16px;
  font-weight: 500;
  line-height: 19px;
  text-align: left;
  text-decoration: none;
  width: 100%;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}
.addition_tabContent__1DT43 .addition_mentorships__1EJWx .addition_card__29P2Q .addition_inner__1bqbA .addition_subtitle__3--gG {
  color: #555;
  font-size: 14px;
  font-weight: 400;
  line-height: 19px;
  text-align: left;
  text-decoration: none;
  width: 100%;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}
.addition_tabContent__1DT43 .addition_mentorships__1EJWx .addition_card__29P2Q .addition_inner__1bqbA .addition_description__32CkS {
  margin-top: 14px;
  color: #555;
  font-size: 14px;
  font-weight: 400;
  line-height: 19px;
  text-align: left;
  text-decoration: none;
}
.addition_tabContent__1DT43 .addition_vacancies__x7cKt {
  display: flex;
  flex-wrap: wrap;
  grid-column-gap: 30px;
  column-gap: 30px;
  grid-row-gap: 25px;
  row-gap: 25px;
}
.addition_tabContent__1DT43 .addition_vacancies__x7cKt .addition_card__29P2Q {
  width: 535px;
  min-height: 120px;
  height: min-content;
}
.addition_tabContent__1DT43 .addition_vacancies__x7cKt .addition_card__29P2Q .addition_border__P4FOw {
  background-image: linear-gradient(
    191deg,
    #00b3ec -91.84%,
    #cbec00 -91.82%,
    #700101 25.42%,
    #d0a024 126.74%
  );
}
.addition_tabContent__1DT43 .addition_vacancies__x7cKt .addition_card__29P2Q .addition_arrow__bdlnM {
  background-image: linear-gradient(
    201deg,
    #00b3ec -91.84%,
    #cbec00 -91.82%,
    #700101 25.42%,
    #d0a024 126.74%
  );
}
.addition_tabContent__1DT43 .addition_vacancies__x7cKt .addition_card__29P2Q .addition_inner__1bqbA {
  padding: 20px;
}
.addition_tabContent__1DT43 .addition_vacancies__x7cKt .addition_card__29P2Q .addition_inner__1bqbA .addition_dates__1rOQ5 {
  color: #555;
  font-size: 13px;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0em;
  text-align: left;
  text-align: end;
  width: 100%;
  margin-bottom: 8px;
}
.addition_tabContent__1DT43 .addition_vacancies__x7cKt .addition_card__29P2Q .addition_inner__1bqbA .addition_title__12YTc {
  color: #555;
  font-size: 16px;
  font-weight: 500;
  line-height: 19px;
  text-align: left;
  text-decoration: none;
  width: 100%;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}
.addition_tabContent__1DT43 .addition_vacancies__x7cKt .addition_card__29P2Q .addition_inner__1bqbA .addition_subtitle__3--gG {
  color: #555;
  font-size: 14px;
  font-weight: 400;
  line-height: 19px;
  text-align: left;
  text-decoration: none;
  width: 100%;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}
.addition_tabContent__1DT43 .addition_vacancies__x7cKt .addition_card__29P2Q .addition_inner__1bqbA .addition_description__32CkS {
  margin-top: 8px;
  color: #555;
  font-size: 14px;
  font-weight: 400;
  line-height: 19px;
  text-align: left;
  text-decoration: none;
}
@media screen and (max-width: 1200px) {
  .addition_tabContent__1DT43 .addition_practices__2iCoD {
    padding: 0 5px;
  }
  .addition_tabContent__1DT43 .addition_practices__2iCoD .addition_card__29P2Q {
    width: calc(100vw - 10px);
  }
  .addition_tabContent__1DT43 .addition_mentorships__1EJWx {
    padding: 0 5px;
  }
  .addition_tabContent__1DT43 .addition_mentorships__1EJWx .addition_card__29P2Q {
    width: calc(100vw - 10px);
  }
  .addition_tabContent__1DT43 .addition_vacancies__x7cKt {
    padding: 0 5px;
  }
  .addition_tabContent__1DT43 .addition_vacancies__x7cKt .addition_card__29P2Q {
    width: calc(100vw - 10px);
  }
}

.return_block__1JQ-L {
  margin-top: 20px;
}
.return_block__1JQ-L .return_second__2Pbpj {
  display: flex;
  justify-content: flex-start;
}
.return_block__1JQ-L .return_second__2Pbpj .return_link__2WgJQ {
  display: flex;
  align-items: center;
  text-decoration: none;
  color: #555;
}

.about_block__PSxnf {
  margin-top: 30px;
}
.about_block__PSxnf .about_first__19slS {
  display: flex;
  justify-content: space-between;
}
.about_block__PSxnf .about_second__1iZe- {
  display: flex;
  justify-content: space-between;
}
.about_block__PSxnf .about_mainPhoto__3jAUm {
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
}
.about_block__PSxnf .about_mainPhoto__3jAUm {
  object-fit: contain;
  height: 350px;
  width: 480px;
}
.about_block__PSxnf .about_info__19GkM {
  margin-top: 20px;
  width: 575px;
}
.about_block__PSxnf .about_info__19GkM .about_header__33Xub {
  display: flex;
  grid-gap: 20px;
  gap: 20px;
  align-items: center;
}
.about_block__PSxnf .about_info__19GkM .about_header__33Xub h1 {
  color: #555;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  margin-bottom: 0px;
  min-width: 77.5%;
}
.about_block__PSxnf .about_info__19GkM .about_header__33Xub .about_logo__xkNym {
  object-fit: contain;
  max-width: 100px;
  max-height: 40px;
}
.about_block__PSxnf .about_info__19GkM .about_header__33Xub .about_title__I1oVP {
  color: #555;
  font-size: 16px;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
}
.about_block__PSxnf .about_info__19GkM .about_header__33Xub .about_rating__2ER-3 .about_stars__fuwoy {
  flex-wrap: nowrap;
  display: flex;
}
.about_block__PSxnf .about_info__19GkM .about_header__33Xub .about_rating__2ER-3 .about_stars__fuwoy svg {
  margin-left: 3px;
  fill: #d9d9d9;
}
.about_block__PSxnf .about_info__19GkM .about_header__33Xub .about_rating__2ER-3 .about_stars__fuwoy svg.about_active__3jpeH {
  fill: #ddc544;
}
.about_block__PSxnf .about_info__19GkM .about_description__2YAoe {
  margin-top: 15px;
  color: #555;
  font-size: 16px;
  font-style: normal;
  font-weight: 300;
  line-height: 20px;
}
.about_block__PSxnf .about_info__19GkM .about_addresses__3go79 {
  margin-top: 15px;
}
.about_block__PSxnf .about_info__19GkM .about_addresses__3go79 .about_title__I1oVP {
  color: #555;
  font-size: 17px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-bottom: 8px;
}
.about_block__PSxnf .about_info__19GkM .about_addresses__3go79 .about_address__NTgO2 {
  color: #555;
  font-size: 16px;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
}
.about_block__PSxnf .about_info__19GkM .about_contacts__2Ht7Z {
  display: flex;
  grid-gap: 48px;
  gap: 48px;
  margin-top: 10px;
}
.about_block__PSxnf .about_info__19GkM .about_contacts__2Ht7Z .about_contact__FVGER .about_title__I1oVP {
  color: #555;
  font-size: 17px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-bottom: 8px;
}
.about_block__PSxnf .about_info__19GkM .about_contacts__2Ht7Z .about_contact__FVGER .about_description__2YAoe {
  margin-top: 0px;
  text-decoration: none;
}
.about_block__PSxnf .about_info__19GkM .about_contacts__2Ht7Z .about_contact__FVGER .about_link__3oOlT {
  color: #1194ba;
  -webkit-text-decoration-line: underline;
          text-decoration-line: underline;
}
.about_block__PSxnf .about_tags__skmul {
  margin-top: 12px;
}
.about_block__PSxnf .about_tags__skmul .about_title__I1oVP {
  color: #555;
  font-size: 17px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.about_block__PSxnf .about_tags__skmul .about_options__15JAy {
  margin-top: 16px;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  align-content: flex-start;
  grid-row-gap: 12px;
  row-gap: 12px;
  grid-column-gap: 24px;
  column-gap: 24px;
  align-self: stretch;
  flex-wrap: wrap;
  max-width: 480px;
}
.about_block__PSxnf .about_tags__skmul .about_option__1h74F {
  color: #fff;
  font-size: 14px;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
  border-radius: 20px;
  border: 1px solid;
  background: #d8d8d8;
  padding: 8px 16px;
}
.about_block__PSxnf .about_tags__skmul .about_option__1h74F.about_active__3jpeH {
  background: linear-gradient(
    191deg,
    #00b3ec -91.84%,
    #cbec00 -91.82%,
    #700101 25.42%,
    #d0a024 126.74%
  );
}
.about_block__PSxnf .about_contact__FVGER {
  margin-top: 20px;
}
.about_block__PSxnf .about_contact__FVGER .about_card__2HYdJ {
  width: 575px;
  height: 160px;
}
.about_block__PSxnf .about_contact__FVGER .about_card__2HYdJ .about_border__nw6Ca {
  background-image: linear-gradient(
    191deg,
    #00b3ec -91.84%,
    #cbec00 -91.82%,
    #700101 25.42%,
    #d0a024 126.74%
  );
}
.about_block__PSxnf .about_contact__FVGER .about_card__2HYdJ .about_arrow__1g1aV {
  background-image: linear-gradient(
    201deg,
    #00b3ec -91.84%,
    #cbec00 -91.82%,
    #700101 25.42%,
    #d0a024 126.74%
  );
}
.about_block__PSxnf .about_contact__FVGER .about_card__2HYdJ .about_inner__2j6tq {
  padding: 20px;
}
.about_block__PSxnf .about_contact__FVGER .about_card__2HYdJ .about_inner__2j6tq .about_title__I1oVP {
  color: #555;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.about_block__PSxnf .about_contact__FVGER .about_card__2HYdJ .about_inner__2j6tq .about_about__3-M7d {
  margin-top: 14px;
  display: flex;
  align-items: center;
  grid-gap: 16px;
  gap: 16px;
}
.about_block__PSxnf .about_contact__FVGER .about_card__2HYdJ .about_inner__2j6tq .about_about__3-M7d .about_photo__2sRJh {
  object-fit: cover;
  width: 72px;
  height: 72px;
  border-radius: 100px;
}
.about_block__PSxnf .about_contact__FVGER .about_card__2HYdJ .about_inner__2j6tq .about_about__3-M7d .about_contacts__2Ht7Z {
  margin-top: 5px;
}
.about_block__PSxnf .about_contact__FVGER .about_card__2HYdJ .about_inner__2j6tq .about_about__3-M7d .about_contacts__2Ht7Z .about_name__2Ia3o {
  color: #555;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.about_block__PSxnf .about_contact__FVGER .about_card__2HYdJ .about_inner__2j6tq .about_about__3-M7d .about_contacts__2Ht7Z .about_positions__3PnrZ {
  display: flex;
  grid-gap: 4px;
  gap: 4px;
}
.about_block__PSxnf .about_contact__FVGER .about_card__2HYdJ .about_inner__2j6tq .about_about__3-M7d .about_contacts__2Ht7Z .about_positions__3PnrZ .about_title__I1oVP {
  color: #555;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.about_block__PSxnf .about_contact__FVGER .about_card__2HYdJ .about_inner__2j6tq .about_about__3-M7d .about_contacts__2Ht7Z .about_positions__3PnrZ .about_position__2ze1N {
  color: #555;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.about_block__PSxnf .about_contact__FVGER .about_card__2HYdJ .about_inner__2j6tq .about_about__3-M7d .about_contacts__2Ht7Z .about_links__yDWHP {
  margin-top: 5px;
  display: flex;
  grid-gap: 30px;
  gap: 30px;
}
.about_block__PSxnf .about_contact__FVGER .about_card__2HYdJ .about_inner__2j6tq .about_about__3-M7d .about_contacts__2Ht7Z .about_phones__2T3mA .about_title__I1oVP {
  color: #555;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.about_block__PSxnf .about_contact__FVGER .about_card__2HYdJ .about_inner__2j6tq .about_about__3-M7d .about_contacts__2Ht7Z .about_phones__2T3mA .about_phone__2gqf9 {
  display: inline;
  color: #555;
  font-size: 12px;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
}
.about_block__PSxnf .about_contact__FVGER .about_card__2HYdJ .about_inner__2j6tq .about_about__3-M7d .about_contacts__2Ht7Z .about_phones__2T3mA .about_phone__2gqf9 + .about_phone__2gqf9 {
  margin-left: 18px;
}
.about_block__PSxnf .about_contact__FVGER .about_card__2HYdJ .about_inner__2j6tq .about_about__3-M7d .about_contacts__2Ht7Z .about_emails__L3Sr7 .about_title__I1oVP {
  color: #555;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.about_block__PSxnf .about_contact__FVGER .about_card__2HYdJ .about_inner__2j6tq .about_about__3-M7d .about_contacts__2Ht7Z .about_emails__L3Sr7 .about_email__F4coA {
  display: inline;
  color: #1194ba;
  font-size: 12px;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
  -webkit-text-decoration-line: underline;
          text-decoration-line: underline;
}
@media screen and (max-width: 1200px) {
  .about_block__PSxnf .about_second__1iZe- {
    flex-wrap: wrap;
    justify-content: center;
    padding: 5px;
  }
  .about_block__PSxnf .about_info__19GkM {
    width: calc(100vw - 10px);
  }
  .about_block__PSxnf .about_info__19GkM .about_contacts__2Ht7Z {
    flex-wrap: wrap;
    grid-row-gap: 4px;
    row-gap: 4px;
  }
  .about_block__PSxnf .about_tags__skmul {
    width: calc(100vw - 10px);
  }
  .about_block__PSxnf .about_mainPhoto__3jAUm {
    display: none;
  }
  .about_block__PSxnf .about_info__19GkM .about_header__33Xub .about_logo__xkNym {
    display: none;
  }
  .about_block__PSxnf .about_contact__FVGER .about_card__2HYdJ {
    width: calc(100vw - 10px);
    height: auto;
  }
  .about_block__PSxnf .about_contact__FVGER .about_card__2HYdJ .about_inner__2j6tq .about_about__3-M7d .about_contacts__2Ht7Z .about_links__yDWHP {
    flex-wrap: wrap;
    grid-gap: 5px;
    gap: 5px;
  }
}

.return_block__1k5zm {
  margin-top: 20px;
}
.return_block__1k5zm .return_second__2LjiT {
  display: flex;
  justify-content: flex-start;
}
.return_block__1k5zm .return_second__2LjiT .return_link__PA9aL {
  display: flex;
  align-items: center;
  text-decoration: none;
  color: #555;
}

.about_block__VsgOz {
  margin-top: 30px;
}
.about_block__VsgOz .about_first__s4pVs {
  display: flex;
  justify-content: space-between;
}
.about_block__VsgOz .about_second__1CpSr {
  display: flex;
  justify-content: space-between;
}
.about_block__VsgOz .about_mainPhoto__2774x {
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
}
.about_block__VsgOz .about_mainPhoto__2774x {
  object-fit: contain;
  height: 350px;
  width: 480px;
}
.about_block__VsgOz .about_info__i6e-y {
  margin-top: 20px;
  width: 575px;
}
.about_block__VsgOz .about_info__i6e-y .about_header__mOW9R {
  display: flex;
  flex-wrap: wrap;
  grid-gap: 10px;
  gap: 10px;
  align-items: center;
  margin-bottom: 10px;
}
.about_block__VsgOz .about_info__i6e-y .about_header__mOW9R h1 {
  color: #555;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  margin-bottom: 0px;
  min-width: 77.5%;
}
.about_block__VsgOz .about_info__i6e-y .about_header__mOW9R h3 {
  color: #555;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-bottom: 0px;
  min-width: 77.5%;
}
.about_block__VsgOz .about_info__i6e-y .about_header__mOW9R .about_logo__1XVjz {
  object-fit: contain;
  max-width: 100px;
  max-height: 40px;
}
.about_block__VsgOz .about_info__i6e-y .about_header__mOW9R .about_title__3Cw4x {
  color: #555;
  font-size: 16px;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
}
.about_block__VsgOz .about_info__i6e-y .about_header__mOW9R .about_rating__1Lno5 .about_stars__v-Srl {
  flex-wrap: nowrap;
  display: flex;
}
.about_block__VsgOz .about_info__i6e-y .about_header__mOW9R .about_rating__1Lno5 .about_stars__v-Srl svg {
  margin-left: 3px;
  fill: #d9d9d9;
}
.about_block__VsgOz .about_info__i6e-y .about_header__mOW9R .about_rating__1Lno5 .about_stars__v-Srl svg.about_active__tbM3- {
  fill: #ddc544;
}
.about_block__VsgOz .about_info__i6e-y .about_description__2hpgZ {
  color: #555;
  font-size: 16px;
  font-style: normal;
  font-weight: 300;
  line-height: 20px;
}
.about_block__VsgOz .about_info__i6e-y .about_addresses__-cFqO {
  margin-top: 15px;
}
.about_block__VsgOz .about_info__i6e-y .about_addresses__-cFqO .about_title__3Cw4x {
  color: #555;
  font-size: 17px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-bottom: 8px;
}
.about_block__VsgOz .about_info__i6e-y .about_addresses__-cFqO .about_address__2q5w1 {
  color: #555;
  font-size: 16px;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
}
.about_block__VsgOz .about_info__i6e-y .about_contacts__cTxSm {
  display: flex;
  grid-gap: 48px;
  gap: 48px;
  margin-top: 10px;
}
.about_block__VsgOz .about_info__i6e-y .about_contacts__cTxSm .about_contact__2inUv .about_title__3Cw4x {
  color: #555;
  font-size: 17px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-bottom: 8px;
}
.about_block__VsgOz .about_info__i6e-y .about_contacts__cTxSm .about_contact__2inUv .about_description__2hpgZ {
  margin-top: 0px;
  text-decoration: none;
}
.about_block__VsgOz .about_info__i6e-y .about_contacts__cTxSm .about_contact__2inUv .about_link__3xukH {
  color: #1194ba;
  -webkit-text-decoration-line: underline;
          text-decoration-line: underline;
}
.about_block__VsgOz .about_tags__20M6t {
  margin-top: 12px;
}
.about_block__VsgOz .about_tags__20M6t .about_title__3Cw4x {
  color: #555;
  font-size: 17px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.about_block__VsgOz .about_tags__20M6t .about_options__1NE7h {
  margin-top: 16px;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  align-content: flex-start;
  grid-row-gap: 12px;
  row-gap: 12px;
  grid-column-gap: 24px;
  column-gap: 24px;
  align-self: stretch;
  flex-wrap: wrap;
  max-width: 480px;
}
.about_block__VsgOz .about_tags__20M6t .about_option__135Rj {
  color: #fff;
  font-size: 14px;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
  border-radius: 20px;
  border: 1px solid;
  background: #d8d8d8;
  padding: 8px 16px;
}
.about_block__VsgOz .about_tags__20M6t .about_option__135Rj.about_active__tbM3- {
  background: linear-gradient(
    191deg,
    #00b3ec -91.84%,
    #cbec00 -91.82%,
    #700101 25.42%,
    #d0a024 126.74%
  );
}
.about_block__VsgOz .about_contact__2inUv {
  margin-top: 20px;
}
.about_block__VsgOz .about_contact__2inUv .about_card__1FKix {
  width: 575px;
  height: 160px;
}
.about_block__VsgOz .about_contact__2inUv .about_card__1FKix .about_border__2ZjHR {
  background-image: linear-gradient(
    191deg,
    #00b3ec -91.84%,
    #cbec00 -91.82%,
    #700101 25.42%,
    #d0a024 126.74%
  );
}
.about_block__VsgOz .about_contact__2inUv .about_card__1FKix .about_arrow__1qztP {
  background-image: linear-gradient(
    201deg,
    #00b3ec -91.84%,
    #cbec00 -91.82%,
    #700101 25.42%,
    #d0a024 126.74%
  );
}
.about_block__VsgOz .about_contact__2inUv .about_card__1FKix .about_inner__2bY33 {
  padding: 20px;
}
.about_block__VsgOz .about_contact__2inUv .about_card__1FKix .about_inner__2bY33 .about_title__3Cw4x {
  color: #555;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.about_block__VsgOz .about_contact__2inUv .about_card__1FKix .about_inner__2bY33 .about_about__2woPf {
  margin-top: 14px;
  display: flex;
  align-items: center;
  grid-gap: 16px;
  gap: 16px;
}
.about_block__VsgOz .about_contact__2inUv .about_card__1FKix .about_inner__2bY33 .about_about__2woPf .about_photo__1EpV8 {
  object-fit: cover;
  width: 72px;
  height: 72px;
  border-radius: 100px;
}
.about_block__VsgOz .about_contact__2inUv .about_card__1FKix .about_inner__2bY33 .about_about__2woPf .about_contacts__cTxSm {
  margin-top: 5px;
}
.about_block__VsgOz .about_contact__2inUv .about_card__1FKix .about_inner__2bY33 .about_about__2woPf .about_contacts__cTxSm .about_name__2Mgt3 {
  color: #555;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.about_block__VsgOz .about_contact__2inUv .about_card__1FKix .about_inner__2bY33 .about_about__2woPf .about_contacts__cTxSm .about_positions__2fSL- {
  display: flex;
  grid-gap: 4px;
  gap: 4px;
}
.about_block__VsgOz .about_contact__2inUv .about_card__1FKix .about_inner__2bY33 .about_about__2woPf .about_contacts__cTxSm .about_positions__2fSL- .about_title__3Cw4x {
  color: #555;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.about_block__VsgOz .about_contact__2inUv .about_card__1FKix .about_inner__2bY33 .about_about__2woPf .about_contacts__cTxSm .about_positions__2fSL- .about_position__1L49G {
  color: #555;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.about_block__VsgOz .about_contact__2inUv .about_card__1FKix .about_inner__2bY33 .about_about__2woPf .about_contacts__cTxSm .about_links__WsXAT {
  margin-top: 5px;
  display: flex;
  grid-gap: 30px;
  gap: 30px;
}
.about_block__VsgOz .about_contact__2inUv .about_card__1FKix .about_inner__2bY33 .about_about__2woPf .about_contacts__cTxSm .about_phones__MhbUN .about_title__3Cw4x {
  color: #555;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.about_block__VsgOz .about_contact__2inUv .about_card__1FKix .about_inner__2bY33 .about_about__2woPf .about_contacts__cTxSm .about_phones__MhbUN .about_phone__3KxtQ {
  display: inline;
  color: #555;
  font-size: 12px;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
}
.about_block__VsgOz .about_contact__2inUv .about_card__1FKix .about_inner__2bY33 .about_about__2woPf .about_contacts__cTxSm .about_phones__MhbUN .about_phone__3KxtQ + .about_phone__3KxtQ {
  margin-left: 18px;
}
.about_block__VsgOz .about_contact__2inUv .about_card__1FKix .about_inner__2bY33 .about_about__2woPf .about_contacts__cTxSm .about_emails__3_E9a .about_title__3Cw4x {
  color: #555;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.about_block__VsgOz .about_contact__2inUv .about_card__1FKix .about_inner__2bY33 .about_about__2woPf .about_contacts__cTxSm .about_emails__3_E9a .about_email__19FgN {
  display: inline;
  color: #1194ba;
  font-size: 12px;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
  -webkit-text-decoration-line: underline;
          text-decoration-line: underline;
}
@media screen and (max-width: 1200px) {
  .about_block__VsgOz .about_second__1CpSr {
    flex-wrap: wrap;
    justify-content: center;
    padding: 5px;
  }
  .about_block__VsgOz .about_info__i6e-y {
    width: calc(100vw - 10px);
  }
  .about_block__VsgOz .about_info__i6e-y .about_contacts__cTxSm {
    flex-wrap: wrap;
    grid-row-gap: 4px;
    row-gap: 4px;
  }
  .about_block__VsgOz .about_tags__20M6t {
    width: calc(100vw - 10px);
  }
  .about_block__VsgOz .about_mainPhoto__2774x {
    display: none;
  }
  .about_block__VsgOz .about_info__i6e-y .about_header__mOW9R .about_logo__1XVjz {
    display: none;
  }
  .about_block__VsgOz .about_contact__2inUv .about_card__1FKix {
    width: calc(100vw - 10px);
    height: auto;
  }
  .about_block__VsgOz .about_contact__2inUv .about_card__1FKix .about_inner__2bY33 .about_about__2woPf .about_contacts__cTxSm .about_links__WsXAT {
    flex-wrap: wrap;
    grid-gap: 5px;
    gap: 5px;
  }
}

.filter_cardFilter__2ofpT {
  width: 100%;
  height: 600px;
}
.filter_cardFilter__2ofpT .filter_border__rXVlx {
  background: linear-gradient(
    191deg,
    #00b3ec -91.84%,
    #cbec00 -91.82%,
    #700101 25.42%,
    #d0a024 126.74%
  );
}
.filter_cardFilter__2ofpT .filter_arrow__2A_RD {
  background: linear-gradient(
    201deg,
    #00b3ec -91.84%,
    #cbec00 -91.82%,
    #700101 25.42%,
    #d0a024 126.74%
  );
}
.filter_cardFilter__2ofpT .filter_content__2vht8 {
  padding: 30px 29px 20px 29px;
}
.filter_cardFilter__2ofpT .filter_content__2vht8 .filter_searchFor__1wnEZ {
  display: flex;
  grid-gap: 10px;
  gap: 10px;
}
.filter_cardFilter__2ofpT .filter_content__2vht8 .filter_searchFor__1wnEZ .filter_option__3I74q {
  cursor: pointer;
  border-radius: 8px;
}
.filter_cardFilter__2ofpT .filter_content__2vht8 .filter_searchFor__1wnEZ .filter_option__3I74q::before {
  border-radius: 8px;
}
.filter_cardFilter__2ofpT .filter_content__2vht8 .filter_searchFor__1wnEZ .filter_option__3I74q.filter_active__27kgS {
  background: linear-gradient(
    191deg,
    #00b3ec -91.84%,
    #cbec00 -91.82%,
    #700101 25.42%,
    #d0a024 126.74%
  );
  color: #fff;
}
.filter_cardFilter__2ofpT .filter_content__2vht8 .filter_submit__3sPjX {
  margin-top: 10px;
  width: 190px;
}
.filter_cardFilter__2ofpT .filter_content__2vht8 .filter_submit__3sPjX .filter_button__2XPPP {
  border-radius: 8px;
  cursor: pointer;
}
.filter_cardFilter__2ofpT .filter_content__2vht8 .filter_submit__3sPjX .filter_button__2XPPP::before {
  border-radius: 8px;
}
.filter_cardFilter__2ofpT .filter_content__2vht8 .filter_submit__3sPjX .filter_button__2XPPP:hover {
  background: linear-gradient(
    191deg,
    #00b3ec -91.84%,
    #cbec00 -91.82%,
    #700101 25.42%,
    #d0a024 126.74%
  );
  color: #fff;
}
.filter_cardFilter__2ofpT .filter_content__2vht8 .filter_clear__vAx7j {
  margin-top: 10px;
  width: 190px;
}
.filter_cardFilter__2ofpT .filter_content__2vht8 .filter_clear__vAx7j .filter_button__2XPPP {
  border-radius: 8px;
  cursor: pointer;
}
.filter_cardFilter__2ofpT .filter_content__2vht8 .filter_clear__vAx7j .filter_button__2XPPP::before {
  border-radius: 8px;
}
.filter_cardFilter__2ofpT .filter_content__2vht8 .filter_clear__vAx7j .filter_button__2XPPP:hover {
  background: linear-gradient(
    191deg,
    #00b3ec -91.84%,
    #cbec00 -91.82%,
    #700101 25.42%,
    #d0a024 126.74%
  );
  color: #fff;
}
.filter_cardFilter__2ofpT .filter_content__2vht8 .filter_inner__2F6F5 {
  margin-top: 25px;
  width: 200px;
  height: 280px;
  overflow-y: auto;
}
.filter_cardFilter__2ofpT .filter_content__2vht8 .filter_inner__2F6F5 .filter_checkboxGroup__4U-Q5 .filter_checkboxGroupHeader__3r52M {
  position: relative;
  color: #1a1a1a;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  margin-top: 10px;
  margin-bottom: 10px;
  cursor: pointer;
  width: 100%;
}
.filter_cardFilter__2ofpT .filter_content__2vht8 .filter_inner__2F6F5 .filter_checkboxGroup__4U-Q5 .filter_checkboxGroupHeader__3r52M::after {
  position: absolute;
  content: "˅";
  top: -2px;
  right: 0;
  font-size: 25px;
  font-weight: 250;
}
.filter_cardFilter__2ofpT .filter_content__2vht8 .filter_inner__2F6F5 .filter_checkboxGroup__4U-Q5.filter_active__27kgS .filter_checkboxGroupHeader__3r52M::after {
  top: 0px;
  font-size: 28px;
  content: "^";
}
.filter_cardFilter__2ofpT .filter_content__2vht8 .filter_inner__2F6F5 .filter_checkboxGroup__4U-Q5 .filter_option__3I74q {
  display: none;
  margin-right: 5px;
  margin-left: 5px;
  margin-bottom: 8px;
}
.filter_cardFilter__2ofpT .filter_content__2vht8 .filter_inner__2F6F5 .filter_checkboxGroup__4U-Q5.filter_active__27kgS .filter_option__3I74q {
  display: flex;
  grid-gap: 5px;
  gap: 5px;
  align-items: center;
}
.filter_cardFilter__2ofpT .filter_content__2vht8 .filter_inner__2F6F5 .filter_checkboxGroup__4U-Q5 .filter_option__3I74q .filter_checkboxLabel__2QsF7 {
  padding-top: 2px;
  color: #1a1a1a;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-left: 5px;
  margin-bottom: 0px;
  overflow: hidden;
}
.filter_cardFilter__2ofpT .filter_content__2vht8 .filter_inner__2F6F5 .filter_radioGroup__ZJB9x .filter_radioGroupHeader__1IS_A {
  position: relative;
  color: #555;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  margin: 5px 0px;
}
.filter_cardFilter__2ofpT .filter_content__2vht8 .filter_inner__2F6F5 .filter_radioGroup__ZJB9x .filter_radioGroupHeader__1IS_A::after {
  position: absolute;
  content: "˅";
  top: -5px;
  right: 0;
  font-size: 30px;
  font-weight: 200;
}
.filter_cardFilter__2ofpT .filter_content__2vht8 .filter_inner__2F6F5 .filter_radioGroup__ZJB9x.filter_active__27kgS .filter_radioGroupHeader__1IS_A::after {
  content: "^";
}
.filter_cardFilter__2ofpT .filter_content__2vht8 .filter_inner__2F6F5 .filter_radioGroup__ZJB9x .filter_radio__2s7Oz {
  display: none;
  margin-right: 5px;
}
.filter_cardFilter__2ofpT .filter_content__2vht8 .filter_inner__2F6F5 .filter_radioGroup__ZJB9x.filter_active__27kgS .filter_radio__2s7Oz {
  display: inline-block;
}
.filter_cardFilter__2ofpT .filter_content__2vht8 .filter_inner__2F6F5 .filter_radioGroup__ZJB9x .filter_radioLabel__5ecBX {
  display: none;
  color: #555;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  vertical-align: end;
}
.filter_cardFilter__2ofpT .filter_content__2vht8 .filter_inner__2F6F5 .filter_radioGroup__ZJB9x.filter_active__27kgS .filter_radioLabel__5ecBX {
  display: inline-block;
}
.filter_cardFilter__2ofpT .filter_content__2vht8 .filter_inner__2F6F5 .filter_inputGroup__1d1D5 .filter_inputGroupHeader__1QUDx {
  position: relative;
  color: #555;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  margin: 5px 0px;
}
.filter_cardFilter__2ofpT .filter_content__2vht8 .filter_inner__2F6F5 .filter_inputGroup__1d1D5 .filter_inputGroupHeader__1QUDx::after {
  position: absolute;
  content: "˅";
  top: -5px;
  right: 0;
  font-size: 30px;
  font-weight: 200;
}
.filter_cardFilter__2ofpT .filter_content__2vht8 .filter_inner__2F6F5 .filter_inputGroup__1d1D5.filter_active__27kgS .filter_inputGroupHeader__1QUDx::after {
  content: "^";
}
.filter_cardFilter__2ofpT .filter_content__2vht8 .filter_inner__2F6F5 .filter_inputGroup__1d1D5 .filter_input__zjnxP {
  display: none;
  max-height: 26px;
  border-radius: 4px;
  border: 1px solid #555;
  font-size: 14px;
}
.filter_cardFilter__2ofpT .filter_content__2vht8 .filter_inner__2F6F5 .filter_inputGroup__1d1D5 .filter_input__zjnxP::placeholder {
  color: #b1b1b1;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.filter_cardFilter__2ofpT .filter_content__2vht8 .filter_inner__2F6F5 .filter_inputGroup__1d1D5.filter_active__27kgS .filter_input__zjnxP {
  display: block;
}
.filter_cardFilter__2ofpT .filter_content__2vht8 .filter_inner__2F6F5 .filter_betweenGroup__1meYX .filter_betweenGroupHeader__PJ1GY {
  position: relative;
  color: #555;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  margin: 5px 0px;
}
.filter_cardFilter__2ofpT .filter_content__2vht8 .filter_inner__2F6F5 .filter_betweenGroup__1meYX .filter_betweenGroupHeader__PJ1GY::after {
  position: absolute;
  content: "˅";
  top: -5px;
  right: 0;
  font-size: 30px;
  font-weight: 200;
}
.filter_cardFilter__2ofpT .filter_content__2vht8 .filter_inner__2F6F5 .filter_betweenGroup__1meYX.filter_active__27kgS .filter_betweenGroupHeader__PJ1GY::after {
  content: "^";
}
.filter_cardFilter__2ofpT .filter_content__2vht8 .filter_inner__2F6F5 .filter_betweenGroup__1meYX .filter_betweenInputs__pcaBE {
  display: none;
  grid-gap: 10px;
  gap: 10px;
}
.filter_cardFilter__2ofpT .filter_content__2vht8 .filter_inner__2F6F5 .filter_betweenGroup__1meYX .filter_betweenInputs__pcaBE .filter_input__zjnxP {
  max-height: 26px;
  border-radius: 4px;
  border: 1px solid #555;
  font-size: 14px;
}
.filter_cardFilter__2ofpT .filter_content__2vht8 .filter_inner__2F6F5 .filter_betweenGroup__1meYX .filter_betweenInputs__pcaBE .filter_input__zjnxP::placeholder {
  color: #b1b1b1;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.filter_cardFilter__2ofpT .filter_content__2vht8 .filter_inner__2F6F5 .filter_betweenGroup__1meYX.filter_active__27kgS .filter_betweenInputs__pcaBE {
  display: flex;
}

@media screen and (max-width: 1200px) {
  .filter_cardFilter__2ofpT {
    height: 310px;
    margin-bottom: 20px;
  }
  .filter_cardFilter__2ofpT .filter_content__2vht8 .filter_inner__2F6F5 {
    height: 100px;
    width: calc(100vw - 60px);
    overflow-y: auto;
  }
  .filter_cardFilter__2ofpT .filter_content__2vht8 .filter_inner__2F6F5 .filter_checkboxGroup__4U-Q5 .filter_checkboxGroupHeader__3r52M {
    width: calc(100vw - 60px);
  }
}

.search_search__3Pe2r {
  display: flex;
  justify-content: flex-start;
  grid-gap: 10px;
  gap: 10px;
  width: 100%;
  filter: drop-shadow(1px 1px 8px rgba(0, 0, 0, 0.15));
  background-color: #fff;
  border-radius: 20px;
  padding-left: 22px;
}
.search_search__3Pe2r .search_icon__J1Bgp {
  margin-top: 10px;
  margin-bottom: 10px;
  min-width: 22px;
}
.search_search__3Pe2r .search_input__3jcic {
  width: 100%;
  color: #646464;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  border: none;
  vertical-align: middle;
}
.search_search__3Pe2r .search_input__3jcic::placeholder {
  color: #e1e1e1;
  line-height: 18px;
  vertical-align: middle;
}

.search_search__3Pe2r .search_input__3jcic:focus-visible {
  outline: none;
}
.search_search__3Pe2r .search_button__15yp7 {
  position: relative;
  min-width: 65px;
  border-radius: 0 20px 20px 20px;
  border: none;
  background: linear-gradient(
    191deg,
    #00b3ec -91.84%,
    #cbec00 -91.82%,
    #700101 25.42%,
    #d0a024 126.74%
  );
}
.search_search__3Pe2r .search_button__15yp7::after {
  position: absolute;
  content: "";
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  visibility: hidden;
  background-color: #fff;
  opacity: 0.3;
  border-radius: 0 20px 20px 20px;
}
.search_search__3Pe2r .search_button__15yp7:hover::after {
  visibility: visible;
}

.results_info__1doxn .results_totals__2x2oW {
  display: flex;
  flex-wrap: wrap;
  grid-gap: 5px;
  gap: 5px;
  margin-top: 10px;
  font-size: 15px;
  font-weight: 400;
  line-height: 18px;
  text-align: left;
  color: #696969;
}
.results_results__1DwyO {
  margin-top: 10px;
  display: flex;
  flex-wrap: wrap;
  grid-gap: 20px;
  gap: 20px;
  width: 100%;
}
.results_results__1DwyO .results_result__3Y867 {
  position: relative;
  display: flex;
  color: #555;
  width: 100%;
}
.results_results__1DwyO .results_result__3Y867 .results_card__1N6hS {
  cursor: default;
  border-radius: 20px;
  background: #fff;
  filter: drop-shadow(1px 1px 4px rgba(0, 0, 0, 0.15));
  padding: 12px 20px;
  color: #555;
  width: 100%;
}
.results_results__1DwyO .results_result__3Y867 .results_card__1N6hS .results_city__3urd- {
  width: 100%;
  font-size: 13px;
  font-weight: 400;
  line-height: 16px;
  text-align: left;
}
.results_results__1DwyO .results_result__3Y867 .results_card__1N6hS .results_info__1doxn {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.results_results__1DwyO .results_result__3Y867 .results_card__1N6hS .results_info__1doxn .results_about__1hpPX {
  display: flex;
  align-items: center;
}
.results_results__1DwyO .results_result__3Y867 .results_card__1N6hS .results_info__1doxn .results_about__1hpPX img {
  width: 75px;
  height: 48px;
  object-fit: contain;
}
.results_results__1DwyO .results_result__3Y867 .results_card__1N6hS .results_info__1doxn .results_about__1hpPX .results_title__2MEP0 {
  width: 400px;
  font-size: 16px;
  font-weight: 400;
  line-height: 20px;
  text-align: left;
  color: #1a1a1a;
  -webkit-line-clamp: 2;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
}
.results_results__1DwyO .results_result__3Y867 .results_card__1N6hS .results_info__1doxn .results_toCompany__2hGOo {
  font-size: 11px;
  font-weight: 400;
  line-height: 13.5px;
  text-align: left;
  text-decoration: none;
  color: #1a1a1a;
}
.results_results__1DwyO .results_result__3Y867 .results_card__1N6hS .results_info__1doxn .results_toCompany__2hGOo svg {
  margin-left: 10px;
  margin-bottom: 2px;
  fill: #1a1a1a;
}
.results_results__1DwyO .results_result__3Y867 .results_card__1N6hS .results_specialities__3OE0t {
  margin-top: 10px;
}
.results_results__1DwyO .results_result__3Y867 .results_card__1N6hS .results_specialities__3OE0t .results_title__2MEP0 {
  display: flex;
  flex-wrap: wrap;
  grid-gap: 5px;
  gap: 5px;
  font-size: 13px;
  font-weight: 400;
  line-height: 16px;
  text-align: left;
  color: #1a1a1a;
  margin-bottom: 10px;
}
.results_results__1DwyO .results_result__3Y867 .results_card__1N6hS .results_specialities__3OE0t .results_title__2MEP0 .results_colored__1klNM {
  background: linear-gradient(
    191deg,
    #00b3ec -91.84%,
    #cbec00 -91.82%,
    #700101 25.42%,
    #d0a024 126.74%
  );
  background-size: cover;
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.results_speciality__3bRHA + .results_speciality__3bRHA {
  margin-top: 5px;
}
.results_results__1DwyO .results_result__3Y867 .results_card__1N6hS .results_specialities__3OE0t .results_speciality__3bRHA {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: #f5f5f5;
  border-radius: 5px;
  padding: 5px 15px;
}
.results_results__1DwyO .results_result__3Y867 .results_card__1N6hS .results_specialities__3OE0t .results_speciality__3bRHA .results_name__25Env {
  -webkit-line-clamp: 1;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
  font-size: 13px;
  font-weight: 400;
  line-height: 16px;
  text-align: left;
  color: #1a1a1a;
  max-width: 250px;
}
.results_results__1DwyO .results_result__3Y867 .results_card__1N6hS .results_specialities__3OE0t .results_speciality__3bRHA .results_props__2eRZq {
  display: flex;
  align-items: center;
}
.results_results__1DwyO .results_result__3Y867 .results_card__1N6hS .results_specialities__3OE0t .results_speciality__3bRHA .results_props__2eRZq .results_prop__1-Mnl {
  position: relative;
  justify-content: space-between;
  background-color: #fff;
  padding: 4px 10px;
  display: flex;
  align-items: center;
  color: #1a1a1a;
  width: 150px;
}
.results_results__1DwyO .results_result__3Y867 .results_card__1N6hS .results_specialities__3OE0t .results_speciality__3bRHA .results_props__2eRZq .results_prop__1-Mnl .results_name__25Env {
  font-size: 10px;
  font-weight: 400;
  line-height: 10px;
  text-align: left;
}
.results_results__1DwyO .results_result__3Y867 .results_card__1N6hS .results_specialities__3OE0t .results_speciality__3bRHA .results_props__2eRZq .results_prop__1-Mnl .results_value__2gL0V {
  margin-left: 5px;
  font-size: 13px;
  font-weight: 400;
  line-height: 15.73px;
  text-align: left;
}
.results_results__1DwyO .results_result__3Y867 .results_card__1N6hS .results_specialities__3OE0t .results_speciality__3bRHA .results_props__2eRZq .results_prop__1-Mnl .results_separator__2ABOK {
  position: absolute;
  border: 1px solid #f5f5f5;
  height: 12px;
  right: -1px;
}
.results_results__1DwyO .results_result__3Y867 .results_card__1N6hS .results_specialities__3OE0t .results_speciality__3bRHA .results_props__2eRZq .results_go__1IsZV {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 20px;
  border-radius: 25px;
  background: linear-gradient(
    191deg,
    #00b3ec -91.84%,
    #cbec00 -91.82%,
    #700101 25.42%,
    #d0a024 126.74%
  );
  padding: 7px 10px;
}
.results_results__1DwyO .results_result__3Y867 .results_card__1N6hS .results_specialities__3OE0t .results_speciality__3bRHA .results_props__2eRZq .results_go__1IsZV svg {
  fill: #fff;
  width: 20px;
  height: 10px;
}

.results_results__1DwyO .results_result__3Y867 .results_title__2MEP0 {
  font-size: 13.5px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  -webkit-line-clamp: 3;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
}
.results_more__2d2o6 {
  display: flex;
  justify-content: center;
  text-decoration: none;
}
.results_more__2d2o6 .results_all__3diju {
  color: #000;
  font-size: 10px;
  font-weight: 300;
  line-height: 12px;
  text-align: left;
  cursor: pointer;
  position: relative;
  margin-top: 10px;
  padding: 5px 20px;
  box-shadow: 1px 1px 8px 0px rgba(0, 0, 0, 0.15);
  border-radius: 20px;
  text-decoration: none;
}
.results_more__2d2o6 .results_all__3diju::before {
  content: "";
  position: absolute;
  background: linear-gradient(
    191deg,
    #00b3ec -91.84%,
    #cbec00 -91.82%,
    #700101 25.42%,
    #d0a024 126.74%
  );
  width: 6px;
  height: 16px;
  top: calc(50% - 8px);
  left: 100%;
  clip-path: polygon(0 12.5%, 100% 50%, 0 87.5%);
}
.results_full__1vJH2 {
  width: 100%;
  display: flex;
  justify-content: center;
}
@media screen and (max-width: 1200px) {
  .results_results__1DwyO .results_result__3Y867 .results_card__1N6hS .results_info__1doxn {
    display: block;
  }
  .results_results__1DwyO .results_result__3Y867 .results_card__1N6hS .results_info__1doxn .results_about__1hpPX .results_title__2MEP0 {
    width: 100%;
  }
  .results_results__1DwyO .results_result__3Y867 .results_card__1N6hS .results_specialities__3OE0t .results_speciality__3bRHA {
    display: block;
    padding: 10px;
  }
  .results_results__1DwyO .results_result__3Y867 .results_card__1N6hS .results_specialities__3OE0t .results_speciality__3bRHA .results_name__25Env {
    margin-bottom: 10px;
  }
  .results_results__1DwyO .results_result__3Y867 .results_card__1N6hS .results_specialities__3OE0t .results_speciality__3bRHA .results_props__2eRZq {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
  }
  .results_results__1DwyO .results_result__3Y867 .results_card__1N6hS .results_specialities__3OE0t .results_speciality__3bRHA .results_props__2eRZq .results_prop__1-Mnl {
    width: 100%;
  }
  .results_results__1DwyO .results_result__3Y867 .results_card__1N6hS .results_specialities__3OE0t .results_speciality__3bRHA .results_props__2eRZq .results_prop__1-Mnl .results_name__25Env {
    margin-bottom: 0px;
  }
  .results_results__1DwyO .results_result__3Y867 .results_card__1N6hS .results_specialities__3OE0t .results_speciality__3bRHA .results_props__2eRZq .results_prop__1-Mnl .results_separator__2ABOK {
    display: none;
  }
  .results_results__1DwyO .results_result__3Y867 .results_card__1N6hS .results_specialities__3OE0t .results_speciality__3bRHA .results_props__2eRZq .results_go__1IsZV {
    margin-left: 0px;
    width: 50px;
    margin-top: 10px;
  }
}

.page_block__1CDsf {
  padding: 12px;
}
@media screen and (max-width: 1200px) {
  .page_block__1CDsf {
    padding: 0px;
  }
}

.filter_cardFilter__2N85t {
  width: 100%;
  height: 600px;
}
.filter_cardFilter__2N85t .filter_border__2SSbD {
  background: linear-gradient(
    191deg,
    #00b3ec -91.84%,
    #cbec00 -91.82%,
    #700101 25.42%,
    #d0a024 126.74%
  );
}
.filter_cardFilter__2N85t .filter_arrow__367Gf {
  background: linear-gradient(
    201deg,
    #00b3ec -91.84%,
    #cbec00 -91.82%,
    #700101 25.42%,
    #d0a024 126.74%
  );
}
.filter_cardFilter__2N85t .filter_content__8iTFB {
  padding: 30px 29px 20px 29px;
}
.filter_cardFilter__2N85t .filter_content__8iTFB .filter_header__-y3Oj {
  width: 100%;
  text-align: center;
  font-size: 20px;
  font-weight: 600;
}
.filter_cardFilter__2N85t .filter_content__8iTFB .filter_inner__kIzj4 {
  margin-top: 10px;
  width: 200px;
  height: 280px;
  overflow-y: auto;
}
.filter_cardFilter__2N85t .filter_content__8iTFB .filter_inner__kIzj4 .filter_group__DmabN .filter_groupHeader__36S4V {
  position: relative;
  color: #1a1a1a;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  margin-top: 10px;
  margin-bottom: 10px;
  cursor: pointer;
  width: 100%;
}
.filter_cardFilter__2N85t .filter_content__8iTFB .filter_inner__kIzj4 .filter_group__DmabN .filter_groupHeader__36S4V::after {
  position: absolute;
  content: "˅";
  top: -2px;
  right: 0;
  font-size: 25px;
  font-weight: 250;
}
.filter_cardFilter__2N85t .filter_content__8iTFB .filter_inner__kIzj4 .filter_group__DmabN.filter_active__qylxT .filter_groupHeader__36S4V::after {
  top: 0px;
  font-size: 28px;
  content: "^";
}
.filter_option__1a667 {
  display: flex;
  grid-gap: 5px;
  gap: 5px;
  align-items: center;
  margin-right: 5px;
  margin-left: 5px;
  margin-bottom: 8px;
  cursor: pointer;
}
.filter_cardFilter__2N85t .filter_content__8iTFB .filter_inner__kIzj4 .filter_option__1a667.filter_active__qylxT {
  color: #d0a024;
}

@media screen and (max-width: 1200px) {
  .filter_cardFilter__2N85t {
    height: 310px;
    margin-bottom: 20px;
  }
  .filter_cardFilter__2N85t .filter_content__8iTFB .filter_inner__kIzj4 {
    height: 100px;
    width: calc(100vw - 60px);
    overflow-y: auto;
  }
  .filter_cardFilter__2N85t .filter_content__8iTFB .filter_inner__kIzj4 .filter_group__DmabN .filter_groupHeader__36S4V {
    width: calc(100vw - 60px);
  }
}

.search_order__33m9b{
  display: flex;
  justify-content: flex-end;
  width: 100%;
  margin-bottom: 10px;
}
.search_order__33m9b .search_title__1x-Ql{
  font-size: 14px;
  font-weight: 400;
}
.search_order__33m9b .search_select__2NP-n{
  border: none;
  font-size: 14px;
  font-weight: 400;
}
.search_search__zbCCm {
  display: flex;
  justify-content: flex-start;
  grid-gap: 10px;
  gap: 10px;
  width: 100%;
  filter: drop-shadow(1px 1px 8px rgba(0, 0, 0, 0.15));
  background-color: #fff;
  border-radius: 20px;
  padding-left: 22px;
}
.search_search__zbCCm .search_icon__1qJ9k {
  margin-top: 10px;
  margin-bottom: 10px;
  min-width: 22px;
}
.search_search__zbCCm .search_input__E3OVg {
  width: 100%;
  color: #646464;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  border: none;
  vertical-align: middle;
}
.search_search__zbCCm .search_input__E3OVg::placeholder {
  color: #e1e1e1;
  line-height: 18px;
  vertical-align: middle;
}

.search_search__zbCCm .search_input__E3OVg:focus-visible {
  outline: none;
}
.search_search__zbCCm .search_button__IdX-1 {
  position: relative;
  min-width: 65px;
  border-radius: 0 20px 20px 20px;
  border: none;
  background: linear-gradient(
    191deg,
    #00b3ec -91.84%,
    #cbec00 -91.82%,
    #700101 25.42%,
    #d0a024 126.74%
  );
}
.search_search__zbCCm .search_button__IdX-1::after {
  position: absolute;
  content: "";
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  visibility: hidden;
  background-color: #fff;
  opacity: 0.3;
  border-radius: 0 20px 20px 20px;
}
.search_search__zbCCm .search_button__IdX-1:hover::after {
  visibility: visible;
}

.results_results__2jLD1 {
  margin-top: 10px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  grid-gap: 20px;
  gap: 20px;
  width: 100%;
}

.results_result__1ZMzd .results_card__19HCz {
  width: 350px;
  height: auto;
  box-shadow: 1px 1px 8px 0px #00000026;
  padding: 15px 15px 8px 15px;
  border-radius: 10px;
}
.results_result__1ZMzd .results_card__19HCz .results_tags__2o_6V {
  display: flex;
  grid-gap: 5px;
  gap: 5px;
}
.results_result__1ZMzd .results_card__19HCz .results_tags__2o_6V .results_tag__1DAwx {
  font-size: 10px;
  font-weight: 400;
  background: linear-gradient(155.19deg, #c12e2e 15.81%, #cd9f29 100.43%);
  border-radius: 25px;
  padding: 2px 10px;
  color: #fff;
  text-transform: lowercase;
}
.results_result__1ZMzd .results_card__19HCz .results_type__NsJzF {
  margin-top: 6px;
  color: #696969;
  font-size: 12px;
  line-height: 14.5px;
  font-weight: 400;
  height: 30px;
  -webkit-line-clamp: 2;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
}
.results_result__1ZMzd .results_card__19HCz .results_header__1grCx {
  color: #1a1a1a;
  font-size: 15px;
  font-weight: 500;
  line-height: 18px;
  height: 36px;
  -webkit-line-clamp: 2;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
}
.results_result__1ZMzd .results_card__19HCz .results_description__2qX_z {
  margin-top: 6px;
  background: #f6f6f6;
  border-radius: 10px;
  padding: 5px 10px;
  font-size: 12px;
  line-height: 16px;
  font-weight: 400;
  color: #1a1a1a;
  height: 58px;
  -webkit-line-clamp: 3;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
}
.results_result__1ZMzd .results_card__19HCz .results_footer__9BsWa {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 6px;
  font-size: 12px;
}
.results_result__1ZMzd .results_card__19HCz .results_footer__9BsWa .results_data__2BqXN {
  color: #1a1a1a;
  font-size: 11px;
  font-weight: 400;
}
.results_result__1ZMzd .results_card__19HCz .results_footer__9BsWa .results_addition__207aM {
  background: linear-gradient(155.19deg, #c12e2e 15.81%, #cd9f29 100.43%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-size: 11px;
  font-weight: 400;
}
@media screen and (max-width: 1200px) {
  .results_result__1ZMzd .results_result__1ZMzd .results_card__19HCz {
    width: 100%;
  }
}

.page_block__7Nx0C {
  padding: 12px;
}
@media screen and (max-width: 1200px) {
  .page_block__7Nx0C {
    padding: 0px;
  }
}

.search_container__1RP_C {
  position: absolute;
  top: 100%;
  background-color: #ffffff;
  border: 1px solid #dee2e6;
  padding: 5px;
}

.navbar_logo__2kv_L {
  cursor: pointer;
}
.navbar_search-container__2-jee {
  display: flex;
  align-items: center;
  background: #efefef;
  border-radius: 30px;
}
.navbar_search-container__2-jee .navbar_icon__1fBbl {
  height: 22px;
  width: 22px;
  margin-left: 22.75px;
}
.navbar_search-container__2-jee .navbar_input__1sU8O {
  background: #efefef;
  border-radius: 30px;
  border: none;
  color: #000000;
  padding: 9px 10px 7px 12.75px;
  font-size: 15px;
  font-weight: 400;
  line-height: 18px;
  text-align: left;
  vertical-align: middle;
}

.navbar_search-container__2-jee .navbar_input__1sU8O::placeholder {
  color: #000000;
  line-height: 20px;
  vertical-align: middle;
}

.navbar_search-container__2-jee .navbar_input__1sU8O:focus-visible {
  outline: none;
}

.navbar_login-container__3fqFe {
  display: flex;
  align-items: center;
  height: 100%;
  text-decoration: none;
}

.navbar_login-container__3fqFe .navbar_icon__1fBbl {
  height: 30px;
  width: 30px;
  margin-right: 8px;
}

.navbar_login-container__3fqFe .navbar_label__1oUlG {
  font-size: 15px;
  line-height: 18px;
  font-weight: 400;
  color: #000000;
}
@media screen and (max-width: 1200px) {
  .navbar_logo__2kv_L {
    margin-left: 5px;
  }
  .navbar_search-container__2-jee {
    display: none;
  }
  .navbar_login-container__3fqFe{
    margin-right: 5px;
  }
}

.customModal_modal__3qvdw > * {
  border: 0 !important;
  border-radius: 0.25rem;
}
.customModal_header__16PsA {
  padding: 0.8rem;
  border-top-left-radius: 0.25rem;
  border-top-right-radius: 0.25rem;
}
.customModal_header__16PsA .customModal_title__3Lvjk {
  color: #495057;
  font-weight: 600;
  font-size: 1.2rem !important;
}
.customModal_body__3GQDa .customModal_label__3AzE4 {
  margin-bottom: 0.5rem;
  font-weight: 600;
  font-size: 0.75rem;
}
.customModal_body__3GQDa .customModal_input__J-K70 {
  border-radius: 0.25rem;
  font-size: 0.8rem;
  padding: 0.5rem 0.9rem;
  font-weight: 400;
  color: #212529;
}
.customModal_body__3GQDa .customModal_inputCheckbox__v-8Y9 {
  display: block;
  border-radius: 0.25rem;
  font-size: 2.32rem;
  padding: 0.5rem 0.5rem;
  font-weight: 400;
  color: #212529;
  margin-top: 0;
}
.customModal_body__3GQDa .customModal_invalid__1Qv2y {
  font-size: 0.7em;
  display: block !important;
}
.customModal_body__3GQDa .customModal_checkboxGroup__3ayfT {
  max-height: 100px;
  overflow-y: auto;
  border: 1px solid #dee2e6;
  border-radius: 0.25rem;
  padding: 0.25rem;
}
.customModal_footer__3oD1h {
  border-top: 0;
}
.customModal_footer__3oD1h .customModal_close__1v4x1 {
  border-radius: 0.25rem;
  font-size: 14px;
}
.customModal_footer__3oD1h .customModal_submit__Ow0-z {
  border-radius: 0.25rem;
  border: 0 !important;
  background-color: #0ab39c;
  font-size: 14px;
}
.customModal_footer__3oD1h .customModal_submit__Ow0-z:hover {
  color: #fff;
  background-color: #099885;
  border-color: #088f7d;
}

.footer_block__3iwaV {
  margin-top: 100px;
}
.footer_copyright__B8ep6 {
  margin-bottom: 0;
}
.footer_feedback__1QPgf {
  display: flex;
  grid-column-gap: 5px;
  column-gap: 5px;
  align-items: center;
  cursor: pointer;
  margin-bottom: 0;
}
.footer_block__3iwaV .footer_social__2J0Vl .footer_link__1hl7N {
  color: #000;
  text-decoration: none;
  margin-right: 5px;
  font-size: 16px;
}
.footer_social__2J0Vl + .footer_social__2J0Vl {
  margin-top: 5px;
}
@media screen and (max-width: 1200px) {
  .footer_copyright__B8ep6 {
    margin-left: 5px;
    width: 100%;
  }
  .footer_contacts__3xd1d {
    margin-right: 5px;
  }
}

.chooser_container__3jcXP {
  position: absolute;
  top: 50px;
  right: 0;
  border-radius: 10px;
}

.chooser_background-container__3eOAB {
  position: absolute;
  right: 0px;
  top: -118px;
  z-index: -1;
}

.chooser_button__2yPCg {
  width: 55px;
  cursor: pointer;
  padding: 15px 15px 15px 15px;
  color: #ffffff;
  writing-mode: vertical-rl;
  text-align: center;
  justify-items: center;
  font-size: 15px;
  font-weight: 400;
}

.chooser_button__2yPCg.chooser_top__253Em {
  margin-top: -40px;
  padding-top: 40px;
}

.chooser_button__2yPCg.chooser_bottom__1oiwM {
  margin-bottom: -40px;
  padding-bottom: 40px;
}

.chooser_button__2yPCg:hover {
  background-color: rgba(255, 255, 255, 0.4);
  color: #014170;
}

.chooser_line__3PtZ9 {
  margin-left: 12.5px;
  width: 30px;
  height: 1px;
  background-color: #08fa99;
}

.button_button__2qMiK {
  cursor: pointer;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #fff;
  padding: 11px 30px;
  border-radius: 19.5px;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;

  --active-bg: linear-gradient(
    170deg,
    #00b3ec -91.84%,
    #cbec00 -91.82%,
    #6e9a10 25.42%,
    #d0a024 126.74%
  );
  --active-color: #ffffff;
}
.button_button__2qMiK.button_secondary__3806j {
  color: #000000;
  background-color: #efefef;
}
.button_button__2qMiK::before {
  content: "";
  background-image: linear-gradient(
    170deg,
    #00b3ec -91.84%,
    #cbec00 -91.82%,
    #6e9a10 25.42%,
    #d0a024 126.74%
  );
  top: -1px;
  left: -1px;
  bottom: -1px;
  right: -1px;
  position: absolute;
  z-index: -1;
  border-radius: 20px;
}

.about_block__1k03s {
  position: relative;
  margin-top: 10px;
}

.about_content__WVOEa {
  display: flex;
}

.about_background__2WQN- {
  position: absolute;
  z-index: -1;
  top: -10px;
  left: -360px;
}

.about_info__3vAcE {
  margin-top: 95px;
  width: 575px;
}

.about_info__3vAcE .about_header__1k4gn {
  font-size: 65px;
  font-style: normal;
  font-weight: 900;
  line-height: 100px;
  text-transform: uppercase;
  margin: 0;
}

.about_info__3vAcE .about_header__1k4gn .about_text__3r3_C {
  background: url(/static/media/background-space.8699e94d.png),
    lightgray 0px -61.53px / 137.633% 239.84% no-repeat;
}

.about_info__3vAcE .about_header__1k4gn .about_filter__PnNqe {
  background: rgba(110, 154, 16, 0.7);
}

.about_info__3vAcE .about_header__1k4gn .about_first__2AQEy {
  font-size: 101px;
  font-style: normal;
  font-weight: 900;
  line-height: 100px;
  text-transform: uppercase;
  margin: 0;
}

.about_info__3vAcE .about_header__1k4gn .about_second__1cjsO {
  font-size: 95px;
  font-style: normal;
  font-weight: 900;
  line-height: 100px;
  text-transform: uppercase;
}

.about_info__3vAcE .about_subheader__1enHH {
  margin-top: 17px;
  font-size: 18px;
  font-weight: 400;
  line-height: 22px;
  color: #333333;
}

.about_info__3vAcE .about_subheader__1enHH span {
  position: relative;
  cursor: default;
}

.about_info__3vAcE .about_subheader__1enHH span:hover div {
  visibility: visible;
}

.about_info__3vAcE .about_description__31AFz {
  margin-top: 17px;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  color: #333333;
}

.about_info__3vAcE .about_more__1LOuz {
  margin-top: 17px;
  cursor: pointer;
  width: 177px;
  height: 47px;
  font-weight: 400;
  font-size: 15px;
  line-height: 18px;
  color: #555555;
}
.about_info__3vAcE .about_more__1LOuz:hover .about_fill__2vM_Y,
.about_info__3vAcE .about_more__1LOuz .about_border__340B0,
.about_info__3vAcE .about_more__1LOuz .about_arrow__kX2nl {
  background-image: linear-gradient(
    191.12deg,
    #00b3ec -91.84%,
    #cbec00 -91.82%,
    #6e9a10 25.42%,
    #d0a024 126.74%
  );
}
.about_card__15fRu {
  width: 460px;
  height: 450px;
}
.about_card__15fRu .about_border__340B0 {
  background-image: linear-gradient(
    191.12deg,
    #00b3ec -91.84%,
    #cbec00 -91.82%,
    #6e9a10 25.42%,
    #d0a024 126.74%
  );
}
.about_card__15fRu .about_arrow__kX2nl {
  background-image: linear-gradient(
    201.12deg,
    #00b3ec -91.84%,
    #cbec00 -91.82%,
    #6e9a10 25.42%,
    #d0a024 126.74%
  );
}
.about_message__1EShT {
  position: relative;
  border-radius: 0px 20px 0px 20px;
  padding: 14px 12px;
  color: #000000;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  background: #efefef;
  position: relative;
  width: 100%;
  filter: drop-shadow(1px 1px 8px rgba(0, 0, 0, 0.15));
}
.about_message__1EShT::before {
  content: "";
  position: absolute;
  top: 40%;
  left: calc(100% - 2px);
  clip-path: polygon(100% 0, 0 100%, 0 25%);
  width: 30px;
  height: 30px;
  background: #efefef;
}
.about_message__1EShT p {
  margin-bottom: 6px;
}
.about_hint__Cmver {
  visibility: hidden;
  position: absolute;
  z-index: 999;
  top: 0;
  left: 85%;
  min-width: 280px;
  max-width: 280px;
}
.about_hint__Cmver .about_description__31AFz {
  background: url(/static/media/about-background-hint.bfcbb6bd.svg);
  background-size: 100% auto;
  background-repeat: no-repeat;
  color: #555;
  text-align: center;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  padding: 22px 16px 20px 16px;
  word-wrap: break-word;
}
@media screen and (max-width: 1200px) {
  .about_content__WVOEa{
    display: block;
  }
  .about_info__3vAcE {
    width: 100%;
    padding-left: 5px;
    margin-top: 20px;
  }
  .about_info__3vAcE .about_header__1k4gn {
    font-size: 8vw;
    line-height: 10vw;
  }
  .about_info__3vAcE .about_header__1k4gn .about_first__2AQEy {
    font-size: 12vw;
    line-height: 14vw;
  }
  .about_info__3vAcE .about_header__1k4gn .about_second__1cjsO {
    font-size: 11.3vw;
    line-height: 12vw;
  }
  .about_info__3vAcE .about_subheader__1enHH {
    font-size: 1rem;
    width: calc(100vw - 75px);
  }
  .about_info__3vAcE .about_description__31AFz {
    width: calc(100vw - 75px);
  }
  .about_hint__Cmver {
    display: none;
  }
  .about_background__2WQN- {
    display: none;
  }
}

.filter_filter__3grVd .filter_searchFor__336cO {
  display: flex;
  grid-gap: 10px;
  gap: 10px;
}
.filter_filter__3grVd .filter_searchFor__336cO .filter_option__3PILN {
  cursor: pointer;
  border-radius: 8px;
}
.filter_filter__3grVd .filter_searchFor__336cO .filter_option__3PILN::before {
  border-radius: 8px;
}
.filter_filter__3grVd .filter_searchFor__336cO .filter_option__3PILN.filter_active__1pa9q {
  background: linear-gradient(
    191.12deg,
    #00b3ec -91.84%,
    #cbec00 -91.82%,
    #6e9a10 25.42%,
    #d0a024 126.74%
  );
  color: #fff;
}
.filter_filter__3grVd .filter_submit__2lKVJ {
  margin-top: 10px;
  width: 190px;
}
.filter_filter__3grVd .filter_submit__2lKVJ .filter_button__2DKZJ {
  border-radius: 8px;
  cursor: pointer;
}
.filter_filter__3grVd .filter_submit__2lKVJ .filter_button__2DKZJ::before {
  border-radius: 8px;
}
.filter_filter__3grVd .filter_submit__2lKVJ .filter_button__2DKZJ:hover {
  background: linear-gradient(
    191.12deg,
    #00b3ec -91.84%,
    #cbec00 -91.82%,
    #6e9a10 25.42%,
    #d0a024 126.74%
  );
  color: #fff;
}
.filter_filter__3grVd .filter_clear__2UYNG {
  margin-top: 10px;
  width: 190px;
}
.filter_filter__3grVd .filter_clear__2UYNG .filter_button__2DKZJ {
  border-radius: 8px;
  cursor: pointer;
}
.filter_filter__3grVd .filter_clear__2UYNG .filter_button__2DKZJ::before {
  border-radius: 8px;
}
.filter_filter__3grVd .filter_clear__2UYNG .filter_button__2DKZJ:hover {
  background: linear-gradient(
    191.12deg,
    #00b3ec -91.84%,
    #cbec00 -91.82%,
    #6e9a10 25.42%,
    #d0a024 126.74%
  );
  color: #fff;
}
.filter_filter__3grVd .filter_inner__1SZSo {
  margin-top: 25px;
  width: 280px;
  height: 280px;
  overflow-y: auto;
}
.filter_filter__3grVd .filter_inner__1SZSo .filter_checkboxGroup__38hzh .filter_checkboxGroupHeader__2h7y2 {
  position: relative;
  color: #1a1a1a;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  margin-top: 10px;
  margin-bottom: 10px;
  cursor: pointer;
  width: 270px;
}
.filter_filter__3grVd .filter_inner__1SZSo .filter_checkboxGroup__38hzh .filter_checkboxGroupHeader__2h7y2::after {
  position: absolute;
  content: "˅";
  top: -2px;
  right: 0;
  font-size: 25px;
  font-weight: 250;
}
.filter_filter__3grVd .filter_inner__1SZSo .filter_checkboxGroup__38hzh.filter_active__1pa9q .filter_checkboxGroupHeader__2h7y2::after {
  top: 0px;
  font-size: 28px;
  content: "^";
}
.filter_filter__3grVd .filter_inner__1SZSo .filter_checkboxGroup__38hzh .filter_option__3PILN {
  display: none;
  margin-right: 5px;
  margin-left: 5px;
  margin-bottom: 8px;
}
.filter_filter__3grVd .filter_inner__1SZSo .filter_checkboxGroup__38hzh.filter_active__1pa9q .filter_option__3PILN {
  display: flex;
  grid-gap: 5px;
  gap: 5px;
  align-items: center;
}
.filter_filter__3grVd .filter_inner__1SZSo .filter_checkboxGroup__38hzh .filter_option__3PILN .filter_checkboxLabel__Vs6vc {
  padding-top: 2px;
  color: #1a1a1a;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-left: 5px;
  margin-bottom: 0px;
}
@media screen and (max-width: 1200px) {
  .filter_filter__3grVd .filter_inner__1SZSo {
    height: 100px;
    width: calc(100vw - 60px);
    overflow-y: auto;
  }
  .filter_filter__3grVd .filter_inner__1SZSo .filter_checkboxGroup__38hzh .filter_checkboxGroupHeader__2h7y2 {
    width: calc(100vw - 60px);
  }
}

.search_cardSearch__2ucQI {
  width: 100%;
  height: 820px;
}
.search_cardSearch__2ucQI .search_border__148UK {
  background: linear-gradient(
    191.12deg,
    #00b3ec -91.84%,
    #cbec00 -91.82%,
    #6e9a10 25.42%,
    #d0a024 126.74%
  );
}
.search_cardSearch__2ucQI .search_arrow__2mAic {
  background: linear-gradient(
    201.12deg,
    #00b3ec -91.84%,
    #cbec00 -91.82%,
    #6e9a10 25.42%,
    #d0a024 126.74%
  );
}
.search_cardSearch__2ucQI .search_inner__1QaRr {
  display: flex;
  grid-gap: 40px;
  gap: 40px;
  padding: 30px 29px 20px 29px;
}
.search_cardSearch__2ucQI .search_content__2C2vn{
  width: 100%;
}
.search_cardSearch__2ucQI .search_content__2C2vn .search_search__1EYpM {
  display: flex;
  justify-content: flex-start;
  grid-gap: 10px;
  gap: 10px;
  width: 100%;
  filter: drop-shadow(1px 1px 8px rgba(0, 0, 0, 0.15));
  background-color: rgba(239, 239, 239, 1);
  border-radius: 20px;
  padding-left: 22px;
}
.search_cardSearch__2ucQI .search_content__2C2vn .search_search__1EYpM .search_icon__1nWzl {
  margin-top: 10px;
  margin-bottom: 10px;
  min-width: 22px;
}
.search_cardSearch__2ucQI .search_content__2C2vn .search_search__1EYpM .search_input__In-s9 {
  width: 100%;
  color: #646464;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  border: none;
  vertical-align: middle;
  background-color: rgba(239, 239, 239, 1);
}
.search_cardSearch__2ucQI .search_content__2C2vn .search_search__1EYpM .search_input__In-s9::placeholder,
.search_cardSearch__2ucQI .search_content__2C2vn .search_search__1EYpM .search_input__In-s9::-webkit-input-placeholder {
  color: #000;
  line-height: 18px;
  vertical-align: middle;
}

.search_cardSearch__2ucQI .search_content__2C2vn .search_search__1EYpM .search_input__In-s9:focus-visible {
  outline: none;
}
.search_cardSearch__2ucQI .search_content__2C2vn .search_search__1EYpM .search_button__1EPPH {
  position: relative;
  min-width: 65px;
  border-radius: 0 20px 20px 20px;
  border: none;
  background: linear-gradient(
    191.12deg,
    #00b3ec -91.84%,
    #cbec00 -91.82%,
    #6e9a10 25.42%,
    #d0a024 126.74%
  );
}
.search_cardSearch__2ucQI .search_content__2C2vn .search_search__1EYpM .search_button__1EPPH::after {
  position: absolute;
  content: "";
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  visibility: hidden;
  background-color: #fff;
  opacity: 0.3;
  border-radius: 0 20px 20px 20px;
}
.search_cardSearch__2ucQI .search_content__2C2vn .search_search__1EYpM .search_button__1EPPH:hover::after {
  visibility: visible;
}
.search_cardSearch__2ucQI .search_content__2C2vn .search_info__3USQj {
  margin-top: 5px;
  display: flex;
  flex-wrap: wrap;
  align-content: space-between;
  width: 100%;
}
.search_cardSearch__2ucQI .search_content__2C2vn .search_info__3USQj .search_totals__1tuZZ {
  display: flex;
  flex-wrap: wrap;
  grid-gap: 5px;
  gap: 5px;
  margin-top: 10px;
  font-size: 15px;
  font-weight: 400;
  line-height: 18px;
  text-align: left;
  color: #696969;
}
.search_cardSearch__2ucQI .search_content__2C2vn .search_results__3uM68 {
  margin-top: 10px;
  display: flex;
  flex-wrap: wrap;
  grid-gap: 20px;
  gap: 20px;
  width: 100%;
}
.search_cardSearch__2ucQI .search_content__2C2vn .search_results__3uM68 .search_result__eGdws {
  position: relative;
  display: flex;
  color: #555;
  width: 100%;
}
.search_cardSearch__2ucQI .search_content__2C2vn .search_results__3uM68 .search_result__eGdws .search_card__3twpn {
  cursor: default;
  border-radius: 20px;
  background: #fff;
  filter: drop-shadow(1px 1px 4px rgba(0, 0, 0, 0.15));
  padding: 12px 20px;
  color: #555;
  width: 100%;
}
.search_cardSearch__2ucQI .search_content__2C2vn .search_results__3uM68 .search_result__eGdws .search_card__3twpn .search_city__1_YVi {
  width: 100%;
  font-size: 13px;
  font-weight: 400;
  line-height: 16px;
  text-align: left;
}
.search_cardSearch__2ucQI .search_content__2C2vn .search_results__3uM68 .search_result__eGdws .search_card__3twpn .search_info__3USQj {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.search_cardSearch__2ucQI .search_content__2C2vn .search_results__3uM68 .search_result__eGdws .search_card__3twpn .search_info__3USQj .search_about__68DUg {
  display: flex;
  align-items: center;
}
.search_cardSearch__2ucQI .search_content__2C2vn .search_results__3uM68 .search_result__eGdws .search_card__3twpn .search_info__3USQj .search_about__68DUg img {
  width: 75px;
  height: 48px;
  object-fit: contain;
}
.search_cardSearch__2ucQI .search_content__2C2vn .search_results__3uM68 .search_result__eGdws .search_card__3twpn .search_info__3USQj .search_about__68DUg .search_title__H8yOE {
  width: 400px;
  font-size: 16px;
  font-weight: 400;
  line-height: 20px;
  text-align: left;
  color: #1a1a1a;
  -webkit-line-clamp: 2;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
}
.search_cardSearch__2ucQI .search_content__2C2vn .search_results__3uM68 .search_result__eGdws .search_card__3twpn .search_info__3USQj .search_toCompany__2jS24 {
  text-decoration: none;
  color: #1a1a1a;
  font-size: 11px;
  font-weight: 400;
  line-height: 13.5px;
  text-align: left;
}
.search_cardSearch__2ucQI .search_content__2C2vn .search_results__3uM68 .search_result__eGdws .search_card__3twpn .search_info__3USQj .search_toCompany__2jS24 svg {
  margin-left: 10px;
  margin-bottom: 2px;
  fill: #1a1a1a;
}
.search_cardSearch__2ucQI .search_content__2C2vn .search_results__3uM68 .search_result__eGdws .search_card__3twpn .search_specialities__2h7SJ {
  margin-top: 10px;
}
.search_cardSearch__2ucQI .search_content__2C2vn .search_results__3uM68 .search_result__eGdws .search_card__3twpn .search_specialities__2h7SJ .search_title__H8yOE {
  display: flex;
  flex-wrap: wrap;
  grid-gap: 5px;
  gap: 5px;
  font-size: 13px;
  font-weight: 400;
  line-height: 16px;
  text-align: left;
  color: #1a1a1a;
  margin-bottom: 10px;
}
.search_cardSearch__2ucQI .search_content__2C2vn .search_results__3uM68 .search_result__eGdws .search_card__3twpn .search_specialities__2h7SJ .search_title__H8yOE .search_colored___dmmO {
  background: linear-gradient(
    191.12deg,
    #00b3ec -91.84%,
    #cbec00 -91.82%,
    #6e9a10 25.42%,
    #d0a024 126.74%
  );
  background-size: cover;
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.search_speciality__nRPtK + .search_speciality__nRPtK {
  margin-top: 5px;
}
.search_cardSearch__2ucQI .search_content__2C2vn .search_results__3uM68 .search_result__eGdws .search_card__3twpn .search_specialities__2h7SJ .search_speciality__nRPtK {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: #f5f5f5;
  border-radius: 5px;
  padding: 5px 15px;
}
.search_cardSearch__2ucQI .search_content__2C2vn .search_results__3uM68 .search_result__eGdws .search_card__3twpn .search_specialities__2h7SJ .search_speciality__nRPtK .search_name__2BxHa {
  -webkit-line-clamp: 1;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
  font-size: 13px;
  font-weight: 400;
  line-height: 16px;
  text-align: left;
  color: #1a1a1a;
  max-width: 250px;
}
.search_cardSearch__2ucQI .search_content__2C2vn .search_results__3uM68 .search_result__eGdws .search_card__3twpn .search_specialities__2h7SJ .search_speciality__nRPtK .search_props__247iG {
  display: flex;
  align-items: center;
}
.search_cardSearch__2ucQI
  .search_content__2C2vn
  .search_results__3uM68
  .search_result__eGdws
  .search_card__3twpn
  .search_specialities__2h7SJ
  .search_speciality__nRPtK
  .search_props__247iG
  .search_prop__1_RrE {
  position: relative;
  justify-content: space-between;
  background-color: #fff;
  padding: 4px 10px;
  display: flex;
  align-items: center;
  color: #1a1a1a;
  width: 150px;
}
.search_cardSearch__2ucQI
  .search_content__2C2vn
  .search_results__3uM68
  .search_result__eGdws
  .search_card__3twpn
  .search_specialities__2h7SJ
  .search_speciality__nRPtK
  .search_props__247iG
  .search_prop__1_RrE
  .search_name__2BxHa {
  font-size: 10px;
  font-weight: 400;
  line-height: 10px;
  text-align: left;
}
.search_cardSearch__2ucQI
  .search_content__2C2vn
  .search_results__3uM68
  .search_result__eGdws
  .search_card__3twpn
  .search_specialities__2h7SJ
  .search_speciality__nRPtK
  .search_props__247iG
  .search_prop__1_RrE
  .search_value__oMX0H {
  margin-left: 5px;
  font-size: 13px;
  font-weight: 400;
  line-height: 15.73px;
  text-align: left;
}
.search_cardSearch__2ucQI
  .search_content__2C2vn
  .search_results__3uM68
  .search_result__eGdws
  .search_card__3twpn
  .search_specialities__2h7SJ
  .search_speciality__nRPtK
  .search_props__247iG
  .search_prop__1_RrE
  .search_separator__1VnN_ {
  position: absolute;
  border: 1px solid #f5f5f5;
  height: 12px;
  right: -1px;
}
.search_cardSearch__2ucQI
  .search_content__2C2vn
  .search_results__3uM68
  .search_result__eGdws
  .search_card__3twpn
  .search_specialities__2h7SJ
  .search_speciality__nRPtK
  .search_props__247iG
  .search_go__15snD {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 20px;
  border-radius: 25px;
  background: linear-gradient(
    191.12deg,
    #00b3ec -91.84%,
    #cbec00 -91.82%,
    #6e9a10 25.42%,
    #d0a024 126.74%
  );
  padding: 7px 10px;
}
.search_cardSearch__2ucQI
  .search_content__2C2vn
  .search_results__3uM68
  .search_result__eGdws
  .search_card__3twpn
  .search_specialities__2h7SJ
  .search_speciality__nRPtK
  .search_props__247iG
  .search_go__15snD
  svg {
  fill: #fff;
  width: 20px;
  height: 10px;
}

.search_cardSearch__2ucQI .search_content__2C2vn .search_results__3uM68 .search_result__eGdws .search_title__H8yOE {
  font-size: 13.5px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  -webkit-line-clamp: 3;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
}
.search_more__1PCaF {
  display: flex;
  justify-content: center;
  text-decoration: none;
}
.search_more__1PCaF .search_all__1hUw3 {
  color: #000;
  font-size: 10px;
  font-weight: 300;
  line-height: 12px;
  text-align: left;
  cursor: pointer;
  position: relative;
  margin-top: 10px;
  padding: 5px 20px;
  box-shadow: 1px 1px 8px 0px rgba(0, 0, 0, 0.15);
  border-radius: 20px;
  text-decoration: none;
}
.search_more__1PCaF .search_all__1hUw3::before {
  content: "";
  position: absolute;
  background: linear-gradient(
    191.12deg,
    #00b3ec -91.84%,
    #cbec00 -91.82%,
    #6e9a10 25.42%,
    #d0a024 126.74%
  );
  width: 6px;
  height: 16px;
  top: calc(50% - 8px);
  left: 100%;
  clip-path: polygon(0 12.5%, 100% 50%, 0 87.5%);
}
.search_full__3GD6p {
  width: 100%;
  display: flex;
  justify-content: center;
}
.search_full__3GD6p .search_more__1PCaF .search_all__1hUw3 {
  font-size: 12px;
  line-height: 14.5px;
  margin-top: 20px;
}
.search_full__3GD6p .search_more__1PCaF .search_all__1hUw3::before {
  width: 8px;
  height: 18px;
  top: calc(50% - 8px);
}
@media screen and (max-width: 1200px) {
  .search_cardSearch__2ucQI {
    height: auto;
    margin: 0px 5px;
  }
  .search_cardSearch__2ucQI .search_inner__1QaRr {
    flex-wrap: wrap;
  }
  .search_cardSearch__2ucQI .search_content__2C2vn {
    width: 100%;
  }
  .search_cardSearch__2ucQI .search_content__2C2vn .search_results__3uM68 .search_result__eGdws .search_card__3twpn .search_info__3USQj {
    display: block;
  }
  .search_cardSearch__2ucQI .search_content__2C2vn .search_results__3uM68 .search_result__eGdws .search_card__3twpn .search_info__3USQj .search_about__68DUg .search_title__H8yOE {
    width: 100%;
  }
  .search_cardSearch__2ucQI .search_content__2C2vn .search_results__3uM68 .search_result__eGdws .search_card__3twpn .search_specialities__2h7SJ .search_speciality__nRPtK {
    display: block;
    padding: 10px;
  }
  .search_cardSearch__2ucQI .search_content__2C2vn .search_results__3uM68 .search_result__eGdws .search_card__3twpn .search_specialities__2h7SJ .search_speciality__nRPtK .search_name__2BxHa {
    margin-bottom: 10px;
    max-width: auto;
  }
  .search_cardSearch__2ucQI .search_content__2C2vn .search_results__3uM68 .search_result__eGdws .search_card__3twpn .search_specialities__2h7SJ .search_speciality__nRPtK .search_props__247iG {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
  }
  .search_cardSearch__2ucQI
    .search_content__2C2vn
    .search_results__3uM68
    .search_result__eGdws
    .search_card__3twpn
    .search_specialities__2h7SJ
    .search_speciality__nRPtK
    .search_props__247iG
    .search_prop__1_RrE {
    width: 100%;
  }
  .search_cardSearch__2ucQI
    .search_content__2C2vn
    .search_results__3uM68
    .search_result__eGdws
    .search_card__3twpn
    .search_specialities__2h7SJ
    .search_speciality__nRPtK
    .search_props__247iG
    .search_prop__1_RrE
    .search_name__2BxHa {
    margin-bottom: 0px;
  }
  .search_cardSearch__2ucQI
    .search_content__2C2vn
    .search_results__3uM68
    .search_result__eGdws
    .search_card__3twpn
    .search_specialities__2h7SJ
    .search_speciality__nRPtK
    .search_props__247iG
    .search_prop__1_RrE
    .search_separator__1VnN_ {
    display: none;
  }
  .search_cardSearch__2ucQI
    .search_content__2C2vn
    .search_results__3uM68
    .search_result__eGdws
    .search_card__3twpn
    .search_specialities__2h7SJ
    .search_speciality__nRPtK
    .search_props__247iG
    .search_go__15snD {
    margin-left: 0px;
    width: 50px;
    margin-top: 10px;
  }
}

.map_block__3hlcJ {
  display: flex;
  flex-wrap: wrap;
  position: relative;
  margin-top: 140px;
  justify-content: center;
}
.map_header__pe_mT {
  background: linear-gradient(
    180.12deg,
    #00b3ec -91.84%,
    #cbec00 -91.82%,
    #6e9a10 25.42%,
    #d0a024 126.74%
  );
  background-size: cover;
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-size: 36px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  text-transform: uppercase;
  text-align: left;
  margin-bottom: 40px;
  width: 100%;
}
.map_secondHeader__22KTk {
  background: linear-gradient(
    180.12deg,
    #00b3ec -91.84%,
    #cbec00 -91.82%,
    #6e9a10 25.42%,
    #d0a024 126.74%
  );
  background-size: cover;
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-size: 32px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  text-align: left;
  margin-top: 40px;
  width: 100%;
}
.map_legend__2a2f7{
  display: flex;
  grid-row-gap: 5px;
  row-gap: 5px;
  flex-direction: column;
  width: 100%;
  margin-top: 10px;
}
.map_legend__2a2f7 .map_legendPoint__3GfSb{
  display: flex;
}
.map_legend__2a2f7 .map_legendPoint__3GfSb .map_icon__1XTs8.map_college___cO2y {
  cursor: pointer;
  fill: #fff;
}
.map_legend__2a2f7 .map_legendPoint__3GfSb .map_icon__1XTs8.map_university__U6eoc {
  cursor: pointer;
  fill: #0072ff;
}
.map_map__2Prh0 {
  position: relative;
  margin-top: 20px;
  height: 960px;
  width: 800px;
}
.map_map__2Prh0 .map_point__DV7GY {
  position: absolute;
}
.map_map__2Prh0 .map_point__DV7GY .map_icon__1XTs8.map_college___cO2y {
  cursor: pointer;
  fill: #fff;
}
.map_map__2Prh0 .map_point__DV7GY .map_icon__1XTs8.map_university__U6eoc {
  cursor: pointer;
  fill: #0072ff;
}
.map_map__2Prh0 .map_point__DV7GY .map_icon__1XTs8:hover {
  fill: #646464;
}
.map_map__2Prh0 .map_point__DV7GY .map_card__1qSqZ {
  height: 289px;
  width: 434px;
  position: absolute;
  display: none;
  top: 12px;
  right: 20px;
}
.map_card__1qSqZ .map_border__3U_7f {
  background: linear-gradient(
    191.12deg,
    #00b3ec -91.84%,
    #cbec00 -91.82%,
    #6e9a10 25.42%,
    #d0a024 126.74%
  );
}
.map_card__1qSqZ .map_arrow__38ldM {
  background: linear-gradient(
    201.12deg,
    #00b3ec -91.84%,
    #cbec00 -91.82%,
    #6e9a10 25.42%,
    #d0a024 126.74%
  );
}
.map_map__2Prh0 .map_point__DV7GY:hover .map_card__1qSqZ {
  display: block;
  z-index: 1;
}
.map_card__1qSqZ.map_mobile__320_Q {
  display: none;
}
.map_card__1qSqZ .map_content__ojHRs {
  margin-top: 5px;
  padding: 10px 20px 27px 22px;
  cursor: default;
  height: 280px;
  overflow-y: auto;
}
.map_card__1qSqZ .map_content__ojHRs .map_item__1AzAQ {
  border-bottom: 1px solid #e1e1e1;
  text-decoration: none;
}
.map_item__1AzAQ + .map_item__1AzAQ{
  margin-top: 12px;
}
.map_card__1qSqZ .map_content__ojHRs .map_item__1AzAQ .map_header__pe_mT {
  display: flex;
  color: #555;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  writing-mode: horizontal-tb;
  transform: none;
  background: none;
  background-clip: none;
  -webkit-background-clip: none;
  -webkit-text-fill-color: #555;
  text-transform: none;
  margin-right: 0;
  margin-bottom: 8px;
  align-items: center;
}
.map_card__1qSqZ .map_content__ojHRs .map_item__1AzAQ .map_header__pe_mT .map_icon__1XTs8 {
  margin-right: 15px;
  margin-left: -4px;
  height: 48px;
  object-fit: contain;
}
.map_card__1qSqZ .map_content__ojHRs .map_item__1AzAQ .map_contacts__1hFLH {
  display: flex;
  grid-gap: 40px;
  gap: 40px;
}
.map_card__1qSqZ .map_content__ojHRs .map_item__1AzAQ .map_contacts__1hFLH .map_address__2SdlE {
  margin-bottom: 12px;
}
.map_card__1qSqZ .map_content__ojHRs .map_item__1AzAQ .map_contacts__1hFLH .map_address__2SdlE .map_title__2jDrA {
  color: #555;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-bottom: 8px;
}
.map_card__1qSqZ .map_content__ojHRs .map_item__1AzAQ .map_contacts__1hFLH .map_address__2SdlE .map_description__1eHJL {
  color: #555;
  font-size: 12px;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
}
@media screen and (max-width: 1200px) {
  .map_block__3hlcJ {
    flex-wrap: wrap;
    justify-content: center;
  }
  .map_header__pe_mT {
    margin-left: 5px;
  }
  .map_secondHeader__22KTk {
    margin-left: 5px;
  }
  .map_cardSearch__3pb-o {
    width: calc(100vw - 10px);
    height: 660px;
  }
  .map_cardSearch__3pb-o .map_content__ojHRs .map_results__3i3Z_ .map_result__1EQiC {
    width: 100%;
  }
  .map_map__2Prh0 {
    margin-top: 20px;
    height: auto;
    display: block;
  }
  .map_map__2Prh0 .map_point__DV7GY:hover .map_card__1qSqZ {
    display: none;
    z-index: 10;
  }
  .map_card__1qSqZ.map_mobile__320_Q {
    margin-top: 20px;
    display: block;
    width: calc(100vw - 10px);
  }
}

.categories_block__3ynOF {
  position: relative;
  margin-top: 40px;
}
.categories_tabs__1DY_j {
  display: flex;
  align-items: flex-end;
  border-bottom: none !important;
}
.categories_tab__2esyf {
  cursor: pointer;
  font-size: 26px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  color: #999;
  border: none !important;
  border-radius: 0px !important;
  background: #fff;
  box-shadow: -3px 2px 6px 0px rgba(0, 0, 0, 0.11) inset;
  margin-bottom: 5px !important;
  padding: 5px 27px;
}
.categories_tab__2esyf:hover {
  color: #999;
  background: linear-gradient(
    191.12deg,
    #00b3ec -91.84%,
    #cbec00 -91.82%,
    #6e9a10 25.42%,
    #d0a024 126.74%
  );
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.categories_tab__2esyf.categories_start__h62cn {
  border-radius: 10px 0px 0px 10px !important;
}
.categories_tab__2esyf.categories_end__baK9_ {
  border-radius: 0px 10px 10px 0px !important;
}
.categories_tab__2esyf.categories_active__25EOm {
  font-size: 36px;
  background: linear-gradient(
    191.12deg,
    #00b3ec -91.84%,
    #cbec00 -91.82%,
    #6e9a10 25.42%,
    #d0a024 126.74%
  );
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  box-shadow: 1px 1px 8px 0px rgba(0, 0, 0, 0.15);
}
.categories_tabContent__2XYRx {
  margin-top: 45px;
  display: flex !important;
  justify-content: flex-start;
  align-items: flex-start;
  align-content: flex-start;
  grid-column-gap: 24px;
  column-gap: 24px;
  grid-row-gap: 18px;
  row-gap: 18px;
  flex-wrap: wrap;
}
.categories_card__3BPvS {
  width: 350px;
  height: auto;
  box-shadow: 1px 1px 8px 0px #00000026;
  padding: 15px 15px 8px 15px;
  border-radius: 10px;
}
.categories_card__3BPvS .categories_tags__1a4Kh {
  display: flex;
  grid-gap: 5px;
  gap: 5px;
}
.categories_card__3BPvS .categories_tags__1a4Kh .categories_tag__RqW8S {
  font-size: 10px;
  font-weight: 400;
  background: linear-gradient(155.19deg, #6e9a10 15.81%, #6e9a10 100.43%);
  border-radius: 25px;
  padding: 2px 10px;
  color: #fff;
  text-transform: lowercase;
}
.categories_card__3BPvS .categories_type__JNUII {
  margin-top: 6px;
  color: #696969;
  font-size: 12px;
  line-height: 14.5px;
  font-weight: 400;
  height: 30px;
  -webkit-line-clamp: 2;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
}
.categories_card__3BPvS .categories_header__eRls1 {
  color: #1a1a1a;
  font-size: 15px;
  font-weight: 500;
  line-height: 18px;
  height: 36px;
  -webkit-line-clamp: 2;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
}
.categories_card__3BPvS .categories_description__4rh3- {
  margin-top: 6px;
  background: #f6f6f6;
  border-radius: 10px;
  padding: 5px 10px;
  font-size: 12px;
  line-height: 16px;
  font-weight: 400;
  color: #1a1a1a;
  height: 58px;
  -webkit-line-clamp: 3;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
}
.categories_card__3BPvS .categories_footer__1yeSy {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 6px;
  font-size: 12px;
}
.categories_card__3BPvS .categories_footer__1yeSy .categories_data__29Rcx {
  color: #1a1a1a;
  font-size: 11px;
  font-weight: 400;
}
.categories_card__3BPvS .categories_footer__1yeSy .categories_addition__13zMo {
  background: linear-gradient(155.19deg, #6e9a10 15.81%, #6e9a10 100.43%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-size: 11px;
  font-weight: 400;
}
.categories_requestButton__1nF8K {
  position: absolute;
  padding: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  top: 10px;
  right: 0;
  color: #555;
  text-align: center;
  font-size: 11.5px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.categories_requestButton__1nF8K:hover .categories_fill__MM8tw,
.categories_requestButton__1nF8K .categories_border__BP4ch,
.categories_requestButton__1nF8K .categories_arrow__1XZXC {
  background-image: linear-gradient(
    191.12deg,
    #00b3ec -91.84%,
    #cbec00 -91.82%,
    #6e9a10 25.42%,
    #d0a024 126.74%
  ) !important;
}
.categories_more__2Z6VI {
  display: flex;
  justify-content: center;
  text-decoration: none;
}
.categories_more__2Z6VI .categories_all__203Xq {
  color: #000;
  font-size: 12px;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
  cursor: pointer;
  position: relative;
  margin-top: 48px;
  padding: 5px 20px;
  box-shadow: 1px 1px 8px 0px rgba(0, 0, 0, 0.15);
  border-radius: 20px;
}
.categories_more__2Z6VI .categories_all__203Xq::before {
  content: "";
  position: absolute;
  background: linear-gradient(
    191.12deg,
    #00b3ec -91.84%,
    #cbec00 -91.82%,
    #6e9a10 25.42%,
    #d0a024 126.74%
  );
  width: 6px;
  height: 16px;
  top: calc(50% - 8px);
  left: 100%;
  clip-path: polygon(0 12.5%, 100% 50%, 0 87.5%);
}
@media screen and (max-width: 1200px) {
  .categories_block__3ynOF {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }
  .categories_tab__2esyf {
    font-size: 0.7rem;
  }
  .categories_tab__2esyf.categories_active__25EOm {
    font-size: 0.8rem;
  }
  .categories_tab__2esyf.categories_start__h62cn {
    border-radius: 5px 0px 0px 5px !important;
  }
  .categories_tab__2esyf.categories_end__baK9_ {
    border-radius: 0px 5px 5px 0px !important;
  }
  .categories_tabContent__2XYRx {
    justify-content: center;
  }
  .categories_requestButton__1nF8K {
    top: 35px;
  }
}

.action_block__1qBZ_ {
  display: flex;
  justify-content: space-between;
  position: relative;
  margin-top: 85px;
}
.action_card__2lMxU {
  width: 540px;
  height: auto;
}
.action_card__2lMxU .action_border__3buLH {
  background-image: linear-gradient(
    191.12deg,
    #00b3ec -91.84%,
    #cbec00 -91.82%,
    #6e9a10 25.42%,
    #d0a024 126.74%
  );
}
.action_card__2lMxU .action_arrow__1aUFS {
  background-image: linear-gradient(
    201.12deg,
    #00b3ec -91.84%,
    #cbec00 -91.82%,
    #6e9a10 25.42%,
    #d0a024 126.74%
  );
}
.action_card__2lMxU .action_content__1sMuC {
  padding: 12px 25px;
  color: #555;
}
.action_card__2lMxU .action_content__1sMuC .action_header__194vB {
  margin-top: 40px;
  font-size: 36px;
  font-weight: 700;
  line-height: 44px;
  text-align: center;
  text-transform: uppercase;
  background: linear-gradient(
    180deg,
    #00b3ec -91.84%,
    #cbec00 -91.82%,
    #6e9a10 25.42%,
    #d0a024 126.74%
  );
  background-size: cover;
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.action_list__1xLbP {
  display: flex;
  flex-wrap: wrap;
  margin-top: 35px;
  padding-left: 10px;
}
.action_list__1xLbP .action_link__16855 {
  position: relative;
  margin-bottom: 30px;
  width: 100%;
}
.action_list__1xLbP .action_link__16855 .action_dot__2arhW {
  position: absolute;
  width: 10px;
  height: 10px;
  border-radius: 20px;
  background: linear-gradient(
    191.12deg,
    #00b3ec -91.84%,
    #cbec00 -91.82%,
    #6e9a10 25.42%,
    #d0a024 126.74%
  );
  top: calc(50% - 5px);
}
.action_list__1xLbP .action_link__16855 .action_title__ITJYr {
  padding-left: 20px;
  color: #000000;
  font-size: 19px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  line-height: 22px;
  -webkit-text-decoration-line: underline;
          text-decoration-line: underline;
}
@media screen and (max-width: 1200px) {
  .action_block__1qBZ_ {
    display: flex;
    flex-wrap: wrap;
    grid-row-gap: 40px;
    row-gap: 40px;
    justify-content: center;
  }
  .action_card__2lMxU {
    margin: 0px 5px;
  }
  .action_card__2lMxU .action_content__1sMuC .action_header__194vB {
    font-size: 1.25rem;
  }
}

.news_block__1p-FC {
  position: relative;
  margin-top: 60px;
}
.news_header__QbXmU {
  background: linear-gradient(
    180deg,
    #00b3ec -91.84%,
    #cbec00 -91.82%,
    #6e9a10 25.42%,
    #d0a024 126.74%
  );
  background-size: cover;
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-size: 36px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  text-transform: uppercase;
}
.news_background__v8Woq {
  position: absolute;
  top: -10px;
  right: 160px;
}
.news_all-news__1_6eX {
  cursor: pointer;
  position: relative;
  margin-top: 48px;
  margin-bottom: 17px;
  margin-left: -5px;
  padding-right: 8px;
  width: 100%;
  text-align: end;
  color: #555;
  font-size: 11.5px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.news_all-news__1_6eX::before {
  content: "";
  position: absolute;
  background: linear-gradient(
    191.12deg,
    #00b3ec -91.84%,
    #cbec00 -91.82%,
    #6e9a10 25.42%,
    #d0a024 126.74%
  );
  width: 6px;
  height: 12px;
  top: 1px;
  left: calc(100% - 4px);
  clip-path: polygon(0 12.5%, 100% 50%, 0 87.5%);
}
.news_news__2bX21 {
  --width-card: 264px;
  --height-card: 318px;
}
.news_news__2bX21 .news_slide__3AlYq {
  width: var(--width-card);
}
.news_news__2bX21 .news_card__22Iad {
  cursor: default;
  position: relative;
  background-color: #fff;
  border-radius: 19.5px;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  width: var(--width-card);
  height: var(--height-card);
}
.news_news__2bX21 .news_card__22Iad::before {
  content: "";
  background-image: linear-gradient(
    191.12deg,
    #00b3ec -91.84%,
    #cbec00 -91.82%,
    #6e9a10 25.42%,
    #d0a024 126.74%
  );
  top: -1px;
  left: -1px;
  bottom: -1px;
  right: -1px;
  position: absolute;
  z-index: -1;
  border-radius: 20px;
}
.news_news__2bX21 .news_card__22Iad .news_image__311Kr {
  height: calc(var(--height-card) / 2);
  max-width: 100%;
  border-radius: 20px;
}
.news_news__2bX21 .news_card__22Iad .news_image__311Kr img {
  width: 100%;
  height: calc(var(--height-card) / 2);
  border-radius: 20px 20px 0px 0px;
  object-fit: cover;
}
.news_news__2bX21 .news_card__22Iad .news_info__2WphP {
  padding: 15px;
  display: flex;
  flex-wrap: wrap;
  align-content: space-between;
  height: calc(var(--height-card) / 2);
}
.news_news__2bX21 .news_card__22Iad .news_info__2WphP .news_title__ZLdHu {
  color: #333;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  width: 100%;
}
.news_news__2bX21 .news_card__22Iad .news_info__2WphP .news_description__1wAcJ {
  color: #555;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  max-width: var(--width-card);
  max-height: calc(var(--height-card) / 2 - 80px);
  margin-top: 10px;
  -webkit-line-clamp: 5;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
}
.news_news__2bX21 .news_card__22Iad .news_info__2WphP .news_addition__2FBD_ {
  display: flex;
  justify-content: space-between;
  color: #333;
  font-size: 11px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  width: 100%;
}
.news_news__2bX21 .news_card__22Iad .news_info__2WphP .news_addition__2FBD_ .news_more__1G2ND {
  cursor: pointer;
  position: relative;
  padding-right: 8px;
  background-image: linear-gradient(
    191.12deg,
    #00b3ec -91.84%,
    #cbec00 -91.82%,
    #6e9a10 25.42%,
    #d0a024 126.74%
  );
  color: transparent;
  -webkit-background-clip: text;
  background-clip: text;
}
.news_news__2bX21 .news_card__22Iad .news_info__2WphP .news_addition__2FBD_ .news_more__1G2ND::before {
  content: "";
  position: absolute;
  background: linear-gradient(
    191.12deg,
    #00b3ec -91.84%,
    #cbec00 -91.82%,
    #6e9a10 25.42%,
    #d0a024 126.74%
  );
  width: 6px;
  height: 12px;
  top: 1px;
  left: calc(100% - 4px);
  clip-path: polygon(0 12.5%, 100% 50%, 0 87.5%);
}
.news_news__2bX21 .news_swiper__38OIb {
  max-width: 1100px;
  padding: 1px 3px;
  height: calc(var(--height-card) + 33px) !important;
  --background-swiper-scrollbar-horizontal: #f8f8f8;
  --background-swiper-scrollbar-drag: linear-gradient(
    191.12deg,
    #00b3ec -91.84%,
    #cbec00 -91.82%,
    #6e9a10 25.42%,
    #d0a024 126.74%
  );
}

@media screen and (max-width: 1200px) {
  .news_header__QbXmU{
    margin-left: 5px;
  }
  .news_news__2bX21 .news_swiper__38OIb {
    max-width: calc(100vw - 10px);
  }
}

.calendar_block__1OjsP {
  margin-top: 40px;
}
.calendar_block__1OjsP .calendar_header__1RqOK {
  background: linear-gradient(
    180deg,
    #00b3ec -91.84%,
    #cbec00 -91.82%,
    #6e9a10 25.42%,
    #d0a024 126.74%
  );
  background-size: cover;
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-size: 36px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}
.calendar_block__1OjsP .calendar_inner__uBbjB {
  display: flex;
  flex-wrap: wrap;
  grid-gap: 40px;
  gap: 40px;
  margin-top: 40px;
}
.calendar_cardSearch__X_ZMo {
  width: 392px;
  height: 640px;
}
.calendar_cardSearch__X_ZMo .calendar_border__Crgxv {
  background-image: linear-gradient(
    191.12deg,
    #00b3ec -91.84%,
    #cbec00 -91.82%,
    #6e9a10 25.42%,
    #d0a024 126.74%
  );
}
.calendar_cardSearch__X_ZMo .calendar_arrow__FBqLO {
  background-image: linear-gradient(
    201.12deg,
    #00b3ec -91.84%,
    #cbec00 -91.82%,
    #6e9a10 25.42%,
    #d0a024 126.74%
  );
}
.calendar_cardSearch__X_ZMo .calendar_content__2gKRH {
  padding: 30px 29px 20px 29px;
}
.calendar_cardSearch__X_ZMo .calendar_content__2gKRH .calendar_search__tHMgt {
  display: flex;
  justify-content: flex-start;
  grid-gap: 10px;
  gap: 10px;
  width: 100%;
  filter: drop-shadow(1px 1px 8px rgba(0, 0, 0, 0.15));
  background-color: rgba(239, 239, 239, 1);
  border-radius: 20px;
  padding-left: 22px;
}
.calendar_cardSearch__X_ZMo .calendar_content__2gKRH .calendar_search__tHMgt .calendar_icon__2UpUQ {
  margin-top: 10px;
  margin-bottom: 10px;
  min-width: 22px;
}
.calendar_cardSearch__X_ZMo .calendar_content__2gKRH .calendar_search__tHMgt .calendar_input__1NrEg {
  width: 100%;
  color: rgba(0, 0, 0, 1);
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  border: none;
  vertical-align: middle;
  background-color: rgba(239, 239, 239, 1);
}
.calendar_cardSearch__X_ZMo .calendar_content__2gKRH .calendar_search__tHMgt .calendar_input__1NrEg::placeholder,
.calendar_cardSearch__X_ZMo .calendar_content__2gKRH .calendar_search__tHMgt .calendar_input__1NrEg::-webkit-input-placeholder {
  color: #000;
  line-height: 18px;
  vertical-align: middle;
}

.calendar_cardSearch__X_ZMo .calendar_content__2gKRH .calendar_search__tHMgt .calendar_input__1NrEg:focus-visible {
  outline: none;
}
.calendar_cardSearch__X_ZMo .calendar_content__2gKRH .calendar_search__tHMgt .calendar_button__1T041 {
  position: relative;
  min-width: 65px;
  border-radius: 0 20px 20px 20px;
  border: none;
  background: linear-gradient(
    191.12deg,
    #00b3ec -91.84%,
    #cbec00 -91.82%,
    #6e9a10 25.42%,
    #d0a024 126.74%
  );
}
.calendar_cardSearch__X_ZMo .calendar_content__2gKRH .calendar_search__tHMgt .calendar_button__1T041::after {
  position: absolute;
  content: "";
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  visibility: hidden;
  background-color: #fff;
  opacity: 0.3;
  border-radius: 0 20px 20px 20px;
}
.calendar_cardSearch__X_ZMo .calendar_content__2gKRH .calendar_search__tHMgt .calendar_button__1T041:hover::after {
  visibility: visible;
}
.calendar_cardSearch__X_ZMo .calendar_content__2gKRH .calendar_searchAdvanced__2BK1f {
  display: flex;
  justify-content: center;
}
.calendar_cardSearch__X_ZMo .calendar_content__2gKRH .calendar_searchAdvanced__2BK1f a {
  margin-top: 6px;
  font-size: 0.85rem;
  text-decoration: none;
  color: #6e9a10;
}
.calendar_cardSearch__X_ZMo .calendar_content__2gKRH .calendar_info__3Bv8y {
  margin-top: 5px;
  display: flex;
  flex-wrap: wrap;
  align-content: space-between;
  justify-content: center;
  height: 540px;
}
.calendar_cardSearch__X_ZMo .calendar_content__2gKRH .calendar_results__1logf {
  display: flex;
  flex-wrap: wrap;
  grid-gap: 14px;
  gap: 14px;
  width: 100%;
}
.calendar_cardSearch__X_ZMo .calendar_content__2gKRH .calendar_results__1logf .calendar_result__29Z1t {
  position: relative;
  color: #555;
  width: 100%;
}
.calendar_cardSearch__X_ZMo .calendar_content__2gKRH .calendar_results__1logf .calendar_result__29Z1t .calendar_card__nD8vs {
  cursor: default;
  padding: 12px 0px 6px 0px;
  grid-gap: 15px;
  gap: 15px;
  color: #000;
  width: 100%;
  border-bottom: 1px solid #e1e1e1;
}
.calendar_cardSearch__X_ZMo .calendar_content__2gKRH .calendar_results__1logf .calendar_result__29Z1t .calendar_title__3dHDc {
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  -webkit-line-clamp: 3;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-align: start;
  width: 100%;
}
.calendar_cardSearch__X_ZMo .calendar_content__2gKRH .calendar_results__1logf .calendar_result__29Z1t .calendar_date__1-N8b {
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  -webkit-line-clamp: 3;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-align: end;
  width: 100%;
}
@media screen and (max-width: 1200px) {
  .calendar_block__1OjsP .calendar_header__1RqOK{
    margin-left: 5px;
    width: calc(100vw - 10px);
  }
  .calendar_block__1OjsP .calendar_inner__uBbjB {
    justify-content: center;
  }
  .calendar_block__1OjsP .calendar_inner__uBbjB .calendar_calendar__aNmfp{
    width: calc(100vw - 10px);
  }
  .calendar_cardSearch__X_ZMo {
    width: calc(100vw - 5px);
  }
}

.docs_block__1ByfG {
  position: relative;
  margin-top: 100px;
  display: flex;
  --height-card: 280px;
  --width-card: 300px;
}
.docs_block__1ByfG .docs_header__3-7i1 {
  display: flex;
  justify-content: flex-end;
  writing-mode: vertical-lr;
  transform: rotate(180deg);
  font-size: 29px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  background: linear-gradient(
    191.12deg,
    #00b3ec -91.84%,
    #cbec00 -91.82%,
    #6e9a10 25.42%,
    #d0a024 126.74%
  );
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  margin-right: 34px;
}
.docs_registries__2RkA2 .docs_slide__2av1l {
  height: var(--height-card);
  width: var(--width-card);
}
.docs_registries__2RkA2 .docs_slide__2av1l + .docs_slide__2av1l {
  border-left: 1px solid #cecece;
  padding-left: 25px;
  padding-right: 25px;
  width: calc(var(--width-card) + 25px);
}
.docs_registries__2RkA2 .docs_slide__2av1l .docs_card__1kE8c {
  display: flex;
  flex-wrap: wrap;
  padding-left: 10px;
}
.docs_registries__2RkA2 .docs_slide__2av1l .docs_card__1kE8c .docs_link__4D_rm {
  position: relative;
  display: flex;
  align-items: center;
  margin-bottom: 30px;
  height: 75px;
  width: 100%;
  overflow: hidden;
}
.docs_registries__2RkA2 .docs_slide__2av1l .docs_link__4D_rm .docs_dot__1jHk0 {
  position: absolute;
  width: 10px;
  height: 10px;
  border-radius: 20px;
  background: linear-gradient(
    191.12deg,
    #00b3ec -91.84%,
    #cbec00 -91.82%,
    #6e9a10 25.42%,
    #d0a024 126.74%
  );
  top: calc(50% - 5px);
}
.docs_registries__2RkA2 .docs_slide__2av1l .docs_link__4D_rm .docs_title__2dp-_ {
  padding-left: 20px;
  color: #1194ba;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  -webkit-text-decoration-line: underline;
          text-decoration-line: underline;
  -webkit-line-clamp: 4;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
}
.docs_registries__2RkA2 .docs_swiper__3OOFC {
  max-width: 1035px;
  padding: 1px 3px;
  height: calc(var(--height-card) + 33px) !important;
  --background-swiper-scrollbar-horizontal: #f8f8f8;
  --background-swiper-scrollbar-drag: linear-gradient(
    191.12deg,
    #00b3ec -91.84%,
    #cbec00 -91.82%,
    #6e9a10 25.42%,
    #d0a024 126.74%
  );
}
.docs_block__1ByfG .docs_hidden__gCwXA {
  position: absolute;
  top: 2px;
  right: -6px;
  box-shadow: #fff 0px 5px 12px 12px;
  width: 1px;
  height: var(--height-card);
  z-index: 1;
}
@media screen and (max-width: 1200px) {
  .docs_block__1ByfG {
    display: block;
  }
  .docs_block__1ByfG .docs_header__3-7i1 {
    display: none;
  }
  .docs_block__1ByfG .docs_hidden__gCwXA {
    display: none;
  }
  .docs_registries__2RkA2 .docs_swiper__3OOFC {
    max-width: calc(100vw - 10px);
  }
}

.partners_block__2ROvE {
  position: relative;
  margin-top: 100px;
}
.partners_header__yRoe7 {
  background: linear-gradient(
    180deg,
    #00b3ec -91.84%,
    #cbec00 -91.82%,
    #6e9a10 25.42%,
    #d0a024 126.74%
  );
  text-transform: uppercase;
  background-size: cover;
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-size: 36px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}
.partners_partners__1lgDs {
  margin-top: 77px;
  display: flex;
  flex-wrap: wrap;
  grid-gap: 25px;
  gap: 25px;
}
.partners_partners__1lgDs .partners_partner__bKhma {
  cursor: default;
  position: relative;
  background-color: #fff;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  width: 350px;
}
.partners_partners__1lgDs .partners_partner__bKhma .partners_photo__D9jN2 {
  height: 120px;
}
.partners_partners__1lgDs .partners_partner__bKhma .partners_photo__D9jN2 img {
  height: 120px;
  width: 100%;
  object-fit: contain;
}
.partners_partners__1lgDs .partners_partner__bKhma .partners_info__JMaEJ .partners_title__3aM4u {
  padding: 8px 13px;
}
.partners_partners__1lgDs .partners_partner__bKhma .partners_info__JMaEJ .partners_title__3aM4u .partners_name__1BYFV {
  color: #333;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}
@media screen and (max-width: 1200px) {
  .partners_header__yRoe7 {
    margin-left: 5px;
  }
  .partners_partners__1lgDs {
    justify-content: center;
  }
  .partners_partners__1lgDs .partners_partner__bKhma {
    width: calc(100vw - 10px);
  }
}

.filter_cardFilter__2nFm6 {
  width: 100%;
  height: 600px;
}
.filter_cardFilter__2nFm6 .filter_border__1lYvm {
  background: linear-gradient(
    191.12deg,
    #00b3ec -91.84%,
    #cbec00 -91.82%,
    #6e9a10 25.42%,
    #d0a024 126.74%
  );
}
.filter_cardFilter__2nFm6 .filter_arrow__30dEP {
  background: linear-gradient(
    201.12deg,
    #00b3ec -91.84%,
    #cbec00 -91.82%,
    #6e9a10 25.42%,
    #d0a024 126.74%
  );
}
.filter_cardFilter__2nFm6 .filter_content__14EWn {
  padding: 30px 29px 20px 29px;
}
.filter_cardFilter__2nFm6 .filter_content__14EWn .filter_searchFor__2R-SQ {
  display: flex;
  grid-gap: 10px;
  gap: 10px;
}
.filter_cardFilter__2nFm6 .filter_content__14EWn .filter_searchFor__2R-SQ .filter_option__1eIHn {
  cursor: pointer;
  border-radius: 8px;
}
.filter_cardFilter__2nFm6 .filter_content__14EWn .filter_searchFor__2R-SQ .filter_option__1eIHn::before {
  border-radius: 8px;
}
.filter_cardFilter__2nFm6 .filter_content__14EWn .filter_searchFor__2R-SQ .filter_option__1eIHn.filter_active__3qEBY {
  background: linear-gradient(
    191.12deg,
    #00b3ec -91.84%,
    #cbec00 -91.82%,
    #6e9a10 25.42%,
    #d0a024 126.74%
  );
  color: #fff;
}
.filter_cardFilter__2nFm6 .filter_content__14EWn .filter_submit__3gmuj {
  margin-top: 10px;
  width: 190px;
}
.filter_cardFilter__2nFm6 .filter_content__14EWn .filter_submit__3gmuj .filter_button__2h9sY {
  border-radius: 8px;
  cursor: pointer;
}
.filter_cardFilter__2nFm6 .filter_content__14EWn .filter_submit__3gmuj .filter_button__2h9sY::before {
  border-radius: 8px;
}
.filter_cardFilter__2nFm6 .filter_content__14EWn .filter_submit__3gmuj .filter_button__2h9sY:hover {
  background: linear-gradient(
    191.12deg,
    #00b3ec -91.84%,
    #cbec00 -91.82%,
    #6e9a10 25.42%,
    #d0a024 126.74%
  );
  color: #fff;
}
.filter_cardFilter__2nFm6 .filter_content__14EWn .filter_clear__3VqQe {
  margin-top: 10px;
  width: 190px;
}
.filter_cardFilter__2nFm6 .filter_content__14EWn .filter_clear__3VqQe .filter_button__2h9sY {
  border-radius: 8px;
  cursor: pointer;
}
.filter_cardFilter__2nFm6 .filter_content__14EWn .filter_clear__3VqQe .filter_button__2h9sY::before {
  border-radius: 8px;
}
.filter_cardFilter__2nFm6 .filter_content__14EWn .filter_clear__3VqQe .filter_button__2h9sY:hover {
  background: linear-gradient(
    191.12deg,
    #00b3ec -91.84%,
    #cbec00 -91.82%,
    #6e9a10 25.42%,
    #d0a024 126.74%
  );
  color: #fff;
}
.filter_cardFilter__2nFm6 .filter_content__14EWn .filter_inner__1qYRG {
  margin-top: 25px;
  width: 200px;
  height: 280px;
  overflow-y: auto;
}
.filter_cardFilter__2nFm6 .filter_content__14EWn .filter_inner__1qYRG .filter_checkboxGroup__1tG3u .filter_checkboxGroupHeader__3-OhI {
  position: relative;
  color: #1a1a1a;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  margin-top: 10px;
  margin-bottom: 10px;
  cursor: pointer;
  width: 100%;
}
.filter_cardFilter__2nFm6 .filter_content__14EWn .filter_inner__1qYRG .filter_checkboxGroup__1tG3u .filter_checkboxGroupHeader__3-OhI::after {
  position: absolute;
  content: "˅";
  top: -2px;
  right: 0;
  font-size: 25px;
  font-weight: 250;
}
.filter_cardFilter__2nFm6 .filter_content__14EWn .filter_inner__1qYRG .filter_checkboxGroup__1tG3u.filter_active__3qEBY .filter_checkboxGroupHeader__3-OhI::after {
  top: 0px;
  font-size: 28px;
  content: "^";
}
.filter_cardFilter__2nFm6 .filter_content__14EWn .filter_inner__1qYRG .filter_checkboxGroup__1tG3u .filter_option__1eIHn {
  display: none;
  margin-right: 5px;
  margin-left: 5px;
  margin-bottom: 8px;
}
.filter_cardFilter__2nFm6 .filter_content__14EWn .filter_inner__1qYRG .filter_checkboxGroup__1tG3u.filter_active__3qEBY .filter_option__1eIHn {
  display: flex;
  grid-gap: 5px;
  gap: 5px;
  align-items: center;
}
.filter_cardFilter__2nFm6 .filter_content__14EWn .filter_inner__1qYRG .filter_checkboxGroup__1tG3u .filter_option__1eIHn .filter_checkboxLabel__2grDX {
  padding-top: 2px;
  color: #1a1a1a;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-left: 5px;
  margin-bottom: 0px;
  overflow: hidden;
}
.filter_cardFilter__2nFm6 .filter_content__14EWn .filter_inner__1qYRG .filter_radioGroup__2NHEr .filter_radioGroupHeader__2UU1Q {
  position: relative;
  color: #555;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  margin: 5px 0px;
}
.filter_cardFilter__2nFm6 .filter_content__14EWn .filter_inner__1qYRG .filter_radioGroup__2NHEr .filter_radioGroupHeader__2UU1Q::after {
  position: absolute;
  content: "˅";
  top: -5px;
  right: 0;
  font-size: 30px;
  font-weight: 200;
}
.filter_cardFilter__2nFm6 .filter_content__14EWn .filter_inner__1qYRG .filter_radioGroup__2NHEr.filter_active__3qEBY .filter_radioGroupHeader__2UU1Q::after {
  content: "^";
}
.filter_cardFilter__2nFm6 .filter_content__14EWn .filter_inner__1qYRG .filter_radioGroup__2NHEr .filter_radio__vYp67 {
  display: none;
  margin-right: 5px;
}
.filter_cardFilter__2nFm6 .filter_content__14EWn .filter_inner__1qYRG .filter_radioGroup__2NHEr.filter_active__3qEBY .filter_radio__vYp67 {
  display: inline-block;
}
.filter_cardFilter__2nFm6 .filter_content__14EWn .filter_inner__1qYRG .filter_radioGroup__2NHEr .filter_radioLabel__2tNbB {
  display: none;
  color: #555;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  vertical-align: end;
}
.filter_cardFilter__2nFm6 .filter_content__14EWn .filter_inner__1qYRG .filter_radioGroup__2NHEr.filter_active__3qEBY .filter_radioLabel__2tNbB {
  display: inline-block;
}
.filter_cardFilter__2nFm6 .filter_content__14EWn .filter_inner__1qYRG .filter_inputGroup__2uUDw .filter_inputGroupHeader__2p-yi {
  position: relative;
  color: #555;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  margin: 5px 0px;
}
.filter_cardFilter__2nFm6 .filter_content__14EWn .filter_inner__1qYRG .filter_inputGroup__2uUDw .filter_inputGroupHeader__2p-yi::after {
  position: absolute;
  content: "˅";
  top: -5px;
  right: 0;
  font-size: 30px;
  font-weight: 200;
}
.filter_cardFilter__2nFm6 .filter_content__14EWn .filter_inner__1qYRG .filter_inputGroup__2uUDw.filter_active__3qEBY .filter_inputGroupHeader__2p-yi::after {
  content: "^";
}
.filter_cardFilter__2nFm6 .filter_content__14EWn .filter_inner__1qYRG .filter_inputGroup__2uUDw .filter_input__30wPh {
  display: none;
  max-height: 26px;
  border-radius: 4px;
  border: 1px solid #555;
  font-size: 14px;
}
.filter_cardFilter__2nFm6 .filter_content__14EWn .filter_inner__1qYRG .filter_inputGroup__2uUDw .filter_input__30wPh::placeholder {
  color: #b1b1b1;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.filter_cardFilter__2nFm6 .filter_content__14EWn .filter_inner__1qYRG .filter_inputGroup__2uUDw.filter_active__3qEBY .filter_input__30wPh {
  display: block;
}
.filter_cardFilter__2nFm6 .filter_content__14EWn .filter_inner__1qYRG .filter_betweenGroup__3N-ZX .filter_betweenGroupHeader__AvqkH {
  position: relative;
  color: #555;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  margin: 5px 0px;
}
.filter_cardFilter__2nFm6 .filter_content__14EWn .filter_inner__1qYRG .filter_betweenGroup__3N-ZX .filter_betweenGroupHeader__AvqkH::after {
  position: absolute;
  content: "˅";
  top: -5px;
  right: 0;
  font-size: 30px;
  font-weight: 200;
}
.filter_cardFilter__2nFm6 .filter_content__14EWn .filter_inner__1qYRG .filter_betweenGroup__3N-ZX.filter_active__3qEBY .filter_betweenGroupHeader__AvqkH::after {
  content: "^";
}
.filter_cardFilter__2nFm6 .filter_content__14EWn .filter_inner__1qYRG .filter_betweenGroup__3N-ZX .filter_betweenInputs__3ibF3 {
  display: none;
  grid-gap: 10px;
  gap: 10px;
}
.filter_cardFilter__2nFm6 .filter_content__14EWn .filter_inner__1qYRG .filter_betweenGroup__3N-ZX .filter_betweenInputs__3ibF3 .filter_input__30wPh {
  max-height: 26px;
  border-radius: 4px;
  border: 1px solid #555;
  font-size: 14px;
}
.filter_cardFilter__2nFm6 .filter_content__14EWn .filter_inner__1qYRG .filter_betweenGroup__3N-ZX .filter_betweenInputs__3ibF3 .filter_input__30wPh::placeholder {
  color: #b1b1b1;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.filter_cardFilter__2nFm6 .filter_content__14EWn .filter_inner__1qYRG .filter_betweenGroup__3N-ZX.filter_active__3qEBY .filter_betweenInputs__3ibF3 {
  display: flex;
}

@media screen and (max-width: 1200px) {
  .filter_cardFilter__2nFm6 {
    height: 310px;
    margin-bottom: 20px;
  }
  .filter_cardFilter__2nFm6 .filter_content__14EWn .filter_inner__1qYRG {
    height: 100px;
    width: calc(100vw - 60px);
    overflow-y: auto;
  }
  .filter_cardFilter__2nFm6 .filter_content__14EWn .filter_inner__1qYRG .filter_checkboxGroup__1tG3u .filter_checkboxGroupHeader__3-OhI {
    width: calc(100vw - 60px);
  }
}

.search_search__1vgmW {
  display: flex;
  justify-content: flex-start;
  grid-gap: 10px;
  gap: 10px;
  width: 100%;
  filter: drop-shadow(1px 1px 8px rgba(0, 0, 0, 0.15));
  background-color: #fff;
  border-radius: 20px;
  padding-left: 22px;
}
.search_search__1vgmW .search_icon__qCCrc {
  margin-top: 10px;
  margin-bottom: 10px;
  min-width: 22px;
}
.search_search__1vgmW .search_input__35aO7 {
  width: 100%;
  color: #646464;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  border: none;
  vertical-align: middle;
}
.search_search__1vgmW .search_input__35aO7::placeholder {
  color: #e1e1e1;
  line-height: 18px;
  vertical-align: middle;
}

.search_search__1vgmW .search_input__35aO7:focus-visible {
  outline: none;
}
.search_search__1vgmW .search_button__3pXXY {
  position: relative;
  min-width: 65px;
  border-radius: 0 20px 20px 20px;
  border: none;
  background: linear-gradient(
    191.12deg,
    #00b3ec -91.84%,
    #cbec00 -91.82%,
    #6e9a10 25.42%,
    #d0a024 126.74%
  );
}
.search_search__1vgmW .search_button__3pXXY::after {
  position: absolute;
  content: "";
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  visibility: hidden;
  background-color: #fff;
  opacity: 0.3;
  border-radius: 0 20px 20px 20px;
}
.search_search__1vgmW .search_button__3pXXY:hover::after {
  visibility: visible;
}

.results_info__1yy0B .results_totals__1B-vZ {
  display: flex;
  flex-wrap: wrap;
  grid-gap: 5px;
  gap: 5px;
  margin-top: 10px;
  font-size: 15px;
  font-weight: 400;
  line-height: 18px;
  text-align: left;
  color: #696969;
}
.results_results__2vyuR {
  margin-top: 10px;
  display: flex;
  flex-wrap: wrap;
  grid-gap: 20px;
  gap: 20px;
  width: 100%;
}
.results_results__2vyuR .results_result__HhF5p {
  position: relative;
  display: flex;
  color: #555;
  width: 100%;
}
.results_results__2vyuR .results_result__HhF5p .results_card__67bXZ {
  cursor: default;
  border-radius: 20px;
  background: #fff;
  filter: drop-shadow(1px 1px 4px rgba(0, 0, 0, 0.15));
  padding: 12px 20px;
  color: #555;
  width: 100%;
}
.results_results__2vyuR .results_result__HhF5p .results_card__67bXZ .results_city__-qm5N {
  width: 100%;
  font-size: 13px;
  font-weight: 400;
  line-height: 16px;
  text-align: left;
}
.results_results__2vyuR .results_result__HhF5p .results_card__67bXZ .results_info__1yy0B {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.results_results__2vyuR .results_result__HhF5p .results_card__67bXZ .results_info__1yy0B .results_about__16uhq {
  display: flex;
  align-items: center;
}
.results_results__2vyuR .results_result__HhF5p .results_card__67bXZ .results_info__1yy0B .results_about__16uhq img {
  width: 75px;
  height: 48px;
  object-fit: contain;
}
.results_results__2vyuR .results_result__HhF5p .results_card__67bXZ .results_info__1yy0B .results_about__16uhq .results_title__2cCaA {
  width: 400px;
  font-size: 16px;
  font-weight: 400;
  line-height: 20px;
  text-align: left;
  color: #1a1a1a;
  -webkit-line-clamp: 2;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
}
.results_results__2vyuR .results_result__HhF5p .results_card__67bXZ .results_info__1yy0B .results_toCompany__FlFe5 {
  font-size: 11px;
  font-weight: 400;
  line-height: 13.5px;
  text-align: left;
  text-decoration: none;
  color: #1a1a1a;
}
.results_results__2vyuR .results_result__HhF5p .results_card__67bXZ .results_info__1yy0B .results_toCompany__FlFe5 svg {
  margin-left: 10px;
  margin-bottom: 2px;
  fill: #1a1a1a;
}
.results_results__2vyuR .results_result__HhF5p .results_card__67bXZ .results_specialities__3f4U3 {
  margin-top: 10px;
}
.results_results__2vyuR .results_result__HhF5p .results_card__67bXZ .results_specialities__3f4U3 .results_title__2cCaA {
  display: flex;
  flex-wrap: wrap;
  grid-gap: 5px;
  gap: 5px;
  font-size: 13px;
  font-weight: 400;
  line-height: 16px;
  text-align: left;
  color: #1a1a1a;
  margin-bottom: 10px;
}
.results_results__2vyuR .results_result__HhF5p .results_card__67bXZ .results_specialities__3f4U3 .results_title__2cCaA .results_colored__2Hg6M {
  background: linear-gradient(
    191.12deg,
    #00b3ec -91.84%,
    #cbec00 -91.82%,
    #6e9a10 25.42%,
    #d0a024 126.74%
  );
  background-size: cover;
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.results_speciality__1aLPs + .results_speciality__1aLPs {
  margin-top: 5px;
}
.results_results__2vyuR .results_result__HhF5p .results_card__67bXZ .results_specialities__3f4U3 .results_speciality__1aLPs {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: #f5f5f5;
  border-radius: 5px;
  padding: 5px 15px;
}
.results_results__2vyuR .results_result__HhF5p .results_card__67bXZ .results_specialities__3f4U3 .results_speciality__1aLPs .results_name__1gRuj {
  -webkit-line-clamp: 1;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
  font-size: 13px;
  font-weight: 400;
  line-height: 16px;
  text-align: left;
  color: #1a1a1a;
  max-width: 250px;
}
.results_results__2vyuR .results_result__HhF5p .results_card__67bXZ .results_specialities__3f4U3 .results_speciality__1aLPs .results_props__10_c3 {
  display: flex;
  align-items: center;
}
.results_results__2vyuR .results_result__HhF5p .results_card__67bXZ .results_specialities__3f4U3 .results_speciality__1aLPs .results_props__10_c3 .results_prop__gm3M7 {
  position: relative;
  justify-content: space-between;
  background-color: #fff;
  padding: 4px 10px;
  display: flex;
  align-items: center;
  color: #1a1a1a;
  width: 150px;
}
.results_results__2vyuR .results_result__HhF5p .results_card__67bXZ .results_specialities__3f4U3 .results_speciality__1aLPs .results_props__10_c3 .results_prop__gm3M7 .results_name__1gRuj {
  font-size: 10px;
  font-weight: 400;
  line-height: 10px;
  text-align: left;
}
.results_results__2vyuR .results_result__HhF5p .results_card__67bXZ .results_specialities__3f4U3 .results_speciality__1aLPs .results_props__10_c3 .results_prop__gm3M7 .results_value__1SUDh {
  margin-left: 5px;
  font-size: 13px;
  font-weight: 400;
  line-height: 15.73px;
  text-align: left;
}
.results_results__2vyuR .results_result__HhF5p .results_card__67bXZ .results_specialities__3f4U3 .results_speciality__1aLPs .results_props__10_c3 .results_prop__gm3M7 .results_separator__Z1E3p {
  position: absolute;
  border: 1px solid #f5f5f5;
  height: 12px;
  right: -1px;
}
.results_results__2vyuR .results_result__HhF5p .results_card__67bXZ .results_specialities__3f4U3 .results_speciality__1aLPs .results_props__10_c3 .results_go__1CN0z {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 20px;
  border-radius: 25px;
  background: linear-gradient(
    191.12deg,
    #00b3ec -91.84%,
    #cbec00 -91.82%,
    #6e9a10 25.42%,
    #d0a024 126.74%
  );
  padding: 7px 10px;
}
.results_results__2vyuR .results_result__HhF5p .results_card__67bXZ .results_specialities__3f4U3 .results_speciality__1aLPs .results_props__10_c3 .results_go__1CN0z svg {
  fill: #fff;
  width: 20px;
  height: 10px;
}

.results_results__2vyuR .results_result__HhF5p .results_title__2cCaA {
  font-size: 13.5px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  -webkit-line-clamp: 3;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
}
.results_more__6V7K- {
  display: flex;
  justify-content: center;
  text-decoration: none;
}
.results_more__6V7K- .results_all__qa_tv {
  color: #000;
  font-size: 10px;
  font-weight: 300;
  line-height: 12px;
  text-align: left;
  cursor: pointer;
  position: relative;
  margin-top: 10px;
  padding: 5px 20px;
  box-shadow: 1px 1px 8px 0px rgba(0, 0, 0, 0.15);
  border-radius: 20px;
  text-decoration: none;
}
.results_more__6V7K- .results_all__qa_tv::before {
  content: "";
  position: absolute;
  background: linear-gradient(
    191.12deg,
    #00b3ec -91.84%,
    #cbec00 -91.82%,
    #6e9a10 25.42%,
    #d0a024 126.74%
  );
  width: 6px;
  height: 16px;
  top: calc(50% - 8px);
  left: 100%;
  clip-path: polygon(0 12.5%, 100% 50%, 0 87.5%);
}
.results_full__2NspQ {
  width: 100%;
  display: flex;
  justify-content: center;
}
@media screen and (max-width: 1200px) {
  .results_results__2vyuR .results_result__HhF5p .results_card__67bXZ .results_info__1yy0B {
    display: block;
  }
  .results_results__2vyuR .results_result__HhF5p .results_card__67bXZ .results_info__1yy0B .results_about__16uhq .results_title__2cCaA {
    width: 100%;
  }
  .results_results__2vyuR .results_result__HhF5p .results_card__67bXZ .results_specialities__3f4U3 .results_speciality__1aLPs {
    display: block;
    padding: 10px;
  }
  .results_results__2vyuR .results_result__HhF5p .results_card__67bXZ .results_specialities__3f4U3 .results_speciality__1aLPs .results_name__1gRuj {
    margin-bottom: 10px;
  }
  .results_results__2vyuR .results_result__HhF5p .results_card__67bXZ .results_specialities__3f4U3 .results_speciality__1aLPs .results_props__10_c3 {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
  }
  .results_results__2vyuR .results_result__HhF5p .results_card__67bXZ .results_specialities__3f4U3 .results_speciality__1aLPs .results_props__10_c3 .results_prop__gm3M7 {
    width: 100%;
  }
  .results_results__2vyuR .results_result__HhF5p .results_card__67bXZ .results_specialities__3f4U3 .results_speciality__1aLPs .results_props__10_c3 .results_prop__gm3M7 .results_name__1gRuj {
    margin-bottom: 0px;
  }
  .results_results__2vyuR .results_result__HhF5p .results_card__67bXZ .results_specialities__3f4U3 .results_speciality__1aLPs .results_props__10_c3 .results_prop__gm3M7 .results_separator__Z1E3p {
    display: none;
  }
  .results_results__2vyuR .results_result__HhF5p .results_card__67bXZ .results_specialities__3f4U3 .results_speciality__1aLPs .results_props__10_c3 .results_go__1CN0z {
    margin-left: 0px;
    width: 50px;
    margin-top: 10px;
  }
}

.pagination_prev__3FWdK {
  background: linear-gradient(
    191.12deg,
    #00b3ec -91.84%,
    #cbec00 -91.82%,
    #6e9a10 25.42%,
    #d0a024 126.74%
  ) !important;
}
.pagination_current__2Q5Kt {
  color: #6e9a10 !important;
}
.pagination_next__1vZt6 {
  background: linear-gradient(
    191.12deg,
    #00b3ec -91.84%,
    #cbec00 -91.82%,
    #6e9a10 25.42%,
    #d0a024 126.74%
  ) !important;
}

.page_block__2udgh {
  padding: 12px;
}
@media screen and (max-width: 1200px) {
  .page_block__2udgh {
    padding: 0px;
  }
}

@font-face {
  font-family: "Inter";
  src: url(/static/media/Inter.32204736.ttf) format("truetype");
}

body {
  margin: 0;
  font-family: "Inter", -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto",
    "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow: hidden;
  --ck-z-default: 100;
  --ck-z-modal: calc(var(--ck-z-default) + 999);
}
.f_hidden{
  display: none;
}
.container {
  width: 1100px;
  max-width: 1100px;
  padding: 0;
}

.content {
  height: 100vh;
}

.content-personal {
  padding: 24px;
  height: calc(100vh - 104px);
  overflow: auto;
}
.main_tooltip {
  position: relative;
  display: inline-block;
 
}

.main_tooltip .main_tooltiptext {
  visibility: hidden;
  width: 220px;
  background-color: black;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;

  /* Position the tooltip */
  position: absolute;
  z-index: 1;
}

.main_tooltip:hover .main_tooltiptext {
  visibility: visible;
}
@media screen and (max-width: 1250px) {
  .container {
    width: 100vw;
    max-width: 100vw;
  }
}
@media screen and (max-width: 758px) {
  .main_tooltip .main_tooltiptext {
    visibility: hidden;   
    background-color: black;
    color: #fff;
    text-align: center;
    border-radius: 6px;
    padding: 5px 0;
    width: 100px;
    /* Position the tooltip */
    position: absolute;
    z-index: 1;
  }
}
