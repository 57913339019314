.block {
  margin-top: 20px;
}
.block .second {
  display: flex;
  justify-content: flex-start;
}
.block .second .link {
  display: flex;
  align-items: center;
  text-decoration: none;
  color: #555;
}
