.block {
  margin-top: 40px;
}
.block .header {
  background: linear-gradient(
    180deg,
    #00b3ec -91.84%,
    #cbec00 -91.82%,
    #6e9a10 25.42%,
    #d0a024 126.74%
  );
  background-size: cover;
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-size: 36px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}
.block .inner {
  display: flex;
  flex-wrap: wrap;
  gap: 40px;
  margin-top: 40px;
}
.cardSearch {
  width: 392px;
  height: 640px;
}
.cardSearch .border {
  background-image: linear-gradient(
    191.12deg,
    #00b3ec -91.84%,
    #cbec00 -91.82%,
    #6e9a10 25.42%,
    #d0a024 126.74%
  );
}
.cardSearch .arrow {
  background-image: linear-gradient(
    201.12deg,
    #00b3ec -91.84%,
    #cbec00 -91.82%,
    #6e9a10 25.42%,
    #d0a024 126.74%
  );
}
.cardSearch .content {
  padding: 30px 29px 20px 29px;
}
.cardSearch .content .search {
  display: flex;
  justify-content: flex-start;
  gap: 10px;
  width: 100%;
  filter: drop-shadow(1px 1px 8px rgba(0, 0, 0, 0.15));
  background-color: rgba(239, 239, 239, 1);
  border-radius: 20px;
  padding-left: 22px;
}
.cardSearch .content .search .icon {
  margin-top: 10px;
  margin-bottom: 10px;
  min-width: 22px;
}
.cardSearch .content .search .input {
  width: 100%;
  color: rgba(0, 0, 0, 1);
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  border: none;
  vertical-align: middle;
  background-color: rgba(239, 239, 239, 1);
}
.cardSearch .content .search .input::placeholder,
.cardSearch .content .search .input::-webkit-input-placeholder {
  color: #000;
  line-height: 18px;
  vertical-align: middle;
}

.cardSearch .content .search .input:focus-visible {
  outline: none;
}
.cardSearch .content .search .button {
  position: relative;
  min-width: 65px;
  border-radius: 0 20px 20px 20px;
  border: none;
  background: linear-gradient(
    191.12deg,
    #00b3ec -91.84%,
    #cbec00 -91.82%,
    #6e9a10 25.42%,
    #d0a024 126.74%
  );
}
.cardSearch .content .search .button::after {
  position: absolute;
  content: "";
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  visibility: hidden;
  background-color: #fff;
  opacity: 0.3;
  border-radius: 0 20px 20px 20px;
}
.cardSearch .content .search .button:hover::after {
  visibility: visible;
}
.cardSearch .content .searchAdvanced {
  display: flex;
  justify-content: center;
}
.cardSearch .content .searchAdvanced a {
  margin-top: 6px;
  font-size: 0.85rem;
  text-decoration: none;
  color: #6e9a10;
}
.cardSearch .content .info {
  margin-top: 5px;
  display: flex;
  flex-wrap: wrap;
  align-content: space-between;
  justify-content: center;
  height: 540px;
}
.cardSearch .content .results {
  display: flex;
  flex-wrap: wrap;
  gap: 14px;
  width: 100%;
}
.cardSearch .content .results .result {
  position: relative;
  color: #555;
  width: 100%;
}
.cardSearch .content .results .result .card {
  cursor: default;
  padding: 12px 0px 6px 0px;
  gap: 15px;
  color: #000;
  width: 100%;
  border-bottom: 1px solid #e1e1e1;
}
.cardSearch .content .results .result .title {
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  -webkit-line-clamp: 3;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-align: start;
  width: 100%;
}
.cardSearch .content .results .result .date {
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  -webkit-line-clamp: 3;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-align: end;
  width: 100%;
}
@media screen and (max-width: 1200px) {
  .block .header{
    margin-left: 5px;
    width: calc(100vw - 10px);
  }
  .block .inner {
    justify-content: center;
  }
  .block .inner .calendar{
    width: calc(100vw - 10px);
  }
  .cardSearch {
    width: calc(100vw - 5px);
  }
}
