.container {
  position: absolute;
  top: 50px;
  right: 0;
  border-radius: 10px;
}

.background-container {
  position: absolute;
  right: 0px;
  top: -118px;
  z-index: -1;
}

.button {
  width: 55px;
  cursor: pointer;
  padding: 15px 15px 15px 15px;
  color: #ffffff;
  writing-mode: vertical-rl;
  text-align: center;
  justify-items: center;
  font-size: 15px;
  font-weight: 400;
}

.button.top {
  margin-top: -40px;
  padding-top: 40px;
}

.button.bottom {
  margin-bottom: -40px;
  padding-bottom: 40px;
}

.button:hover {
  background-color: rgba(255, 255, 255, 0.4);
  color: #014170;
}

.line {
  margin-left: 12.5px;
  width: 30px;
  height: 1px;
  background-color: #08fa99;
}
