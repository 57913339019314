.block {
  position: absolute;
  top: 0;
  left: 0;
  background: #405189;
  opacity: 0.8;
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 10;
  border-radius: 0.25rem;
  animation: fadeIn 0.3s ease-in-out;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 0.8;
  }
}
