.button {
  cursor: pointer;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #fff;
  padding: 11px 30px;
  border-radius: 19.5px;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;

  --active-bg: linear-gradient(
    170deg,
    #00b3ec -91.84%,
    #cbec00 -91.82%,
    #6e9a10 25.42%,
    #d0a024 126.74%
  );
  --active-color: #ffffff;
}
.button.secondary {
  color: #000000;
  background-color: #efefef;
}
.button::before {
  content: "";
  background-image: linear-gradient(
    170deg,
    #00b3ec -91.84%,
    #cbec00 -91.82%,
    #6e9a10 25.42%,
    #d0a024 126.74%
  );
  top: -1px;
  left: -1px;
  bottom: -1px;
  right: -1px;
  position: absolute;
  z-index: -1;
  border-radius: 20px;
}
