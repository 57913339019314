/* .swiper{
  overflow: unset !important;
} */
.swiper-scrollbar-horizontal {
  background: var(--background-swiper-scrollbar-horizontal);
}
.swiper-scrollbar-horizontal .swiper-scrollbar-drag {
  background: var(--background-swiper-scrollbar-drag);
}
.swiper-button-prev {
  left: 0 !important;
  top: 40px;
}
.swiper-button-prev::after {
  font-size: 24px;
  color: #00ecb3;
}
.swiper-button-next {
  right: 0 !important;
  top: 40px;
}
.swiper-button-next::after {
  font-size: 24px;
  color: #00ecb3;
}
.swiper-button-prev::after {
  font-size: 24px;
  color: #00ecb3;
}
.calendar-select__control {
  border-radius: 7px !important;
  background-color: rgba(239, 239, 239, 1) !important;
  border: none !important;
}
.calendar-select__control
  .calendar-select__value-container
  .calendar-select__single-value {
  font-size: 16px;
  font-weight: 600;
  line-height: 19px;
  letter-spacing: 0px;
  text-align: left;
  color: rgba(0, 0, 0, 1);
}
.calendar-select__control .calendar-select__indicator-separator {
  display: none;
}
.calendar-select__control .calendar-select__indicator svg {
  fill: rgba(0, 0, 0, 1);
}
.calendar-select__menu {
  background-color: rgba(239, 239, 239, 1) !important;
}
.calendar-select__menu .calendar-select__option--is-selected {
  background-color: rgba(49, 49, 49, 1) !important;
}
.calendar-select__menu .calendar-select__option--is-focused {
  background-color: rgba(200, 200, 200, 1) !important;
}
