.block {
  margin-top: 80px;
}
.inner {
  display: flex;
  justify-content: center;
}
.card {
  width: 100%;
  height: auto;
}
.card .border {
  background-image: linear-gradient(
    191.12deg,
    #cbec00 -91.84%,
    #700101 25.42%,
    #d0a024 126.74%
  );
}
.card .arrow {
  background-image: linear-gradient(
    201.12deg,
    #cbec00 -91.84%,
    #700101 25.42%,
    #d0a024 186.74%
  );
}
.content {
  min-height: 100px;
  padding: 20px;
}
@media screen and (max-width: 1200px) {
  .card {
    width: calc(100vw - 10px);
  }
}
