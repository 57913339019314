.container {
  position: relative;
}
.container .text,
.container .filter {
  background-position: center !important;
  background-size: cover !important;
  background-clip: text !important;
  -webkit-background-clip: text !important;
  -webkit-text-fill-color: transparent !important;
}
.container .filter {
  position: absolute;
  left: 0;
  top: 0;
}
