.helper {
  position: relative;
}
.helper .background {
  z-index: -1;
  position: absolute;
  width: auto;
  height: auto;
  top: 10px;
  left: 100px;
}
.helper .content {
  margin-top: 45px;
  margin-left: 90px;
}
.helper .content .card {
  width: 460px;
  height: 500px;
  padding-top: 5px;
}
.helper .content .card .inner {
  overflow-y: auto;
  margin-top: 20px;
  padding: 20px 30px 30px 30px;
  max-height: calc(500px - 40px);
}
.helper .content .card .inner .hello {
  display: flex;
  justify-content: space-between;
}
.helper .content .card .inner .hello .message {
  position: relative;
  border-radius: 0px 20px 0px 20px;
  padding: 14px 12px;
  color: #555;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  background: #fff;
  position: relative;
  filter: drop-shadow(1px 1px 8px rgba(0, 0, 0, 0.15));
}
.helper .content .hello .message::before {
  content: "";
  position: absolute;
  top: 40%;
  left: calc(100% - 2px);
  clip-path: polygon(100% 0, 0 100%, 0 25%);
  width: 30px;
  height: 30px;
  background: #fff;
}
.helper .content .hello .message p {
  margin-bottom: 6px;
}
.helper .content .hello .icon {
  height: 140px;
  width: auto;
  flex-shrink: 0;
  margin-left: 30px;
}
.helper .content .card .inner .choose {
  color: #555;
}
.helper .content .card .inner .choose .title {
  margin-top: 20px;
  margin-bottom: 16px;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}
.helper .content .card .inner .choose .elements {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  align-content: flex-start;
  gap: 15px;
  flex-wrap: wrap;
}
.helper .content .choose .elements .reload {
  padding: 10px;
}

@media screen and (max-width: 1200px) {
  .helper .background {
    display: none;
  }
  .helper .content{
    margin-left: 5px;
  }
  .helper .content .card{
    width: calc(100% - 5px);
  }
}