.block {
  position: relative;
  margin-top: 10px;
}

.content {
  display: flex;
}

.background {
  position: absolute;
  z-index: -1;
  top: -10px;
  left: -360px;
}

.info {
  margin-top: 95px;
  width: 575px;
}

.info .header {
  font-size: 65px;
  font-style: normal;
  font-weight: 900;
  line-height: 100px;
  text-transform: uppercase;
  margin: 0;
}

.info .header .text {
  background: url("~@content/img/shared/home/png/background-space.png"),
    lightgray 0px -61.53px / 137.633% 239.84% no-repeat;
}

.info .header .filter {
  background: rgba(110, 154, 16, 0.7);
}

.info .header .first {
  font-size: 101px;
  font-style: normal;
  font-weight: 900;
  line-height: 100px;
  text-transform: uppercase;
  margin: 0;
}

.info .header .second {
  font-size: 95px;
  font-style: normal;
  font-weight: 900;
  line-height: 100px;
  text-transform: uppercase;
}

.info .subheader {
  margin-top: 17px;
  font-size: 18px;
  font-weight: 400;
  line-height: 22px;
  color: #333333;
}

.info .subheader span {
  position: relative;
  cursor: default;
}

.info .subheader span:hover div {
  visibility: visible;
}

.info .description {
  margin-top: 17px;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  color: #333333;
}

.info .more {
  margin-top: 17px;
  cursor: pointer;
  width: 177px;
  height: 47px;
  font-weight: 400;
  font-size: 15px;
  line-height: 18px;
  color: #555555;
}
.info .more:hover .fill,
.info .more .border,
.info .more .arrow {
  background-image: linear-gradient(
    191.12deg,
    #00b3ec -91.84%,
    #cbec00 -91.82%,
    #6e9a10 25.42%,
    #d0a024 126.74%
  );
}
.card {
  width: 460px;
  height: 450px;
}
.card .border {
  background-image: linear-gradient(
    191.12deg,
    #00b3ec -91.84%,
    #cbec00 -91.82%,
    #6e9a10 25.42%,
    #d0a024 126.74%
  );
}
.card .arrow {
  background-image: linear-gradient(
    201.12deg,
    #00b3ec -91.84%,
    #cbec00 -91.82%,
    #6e9a10 25.42%,
    #d0a024 126.74%
  );
}
.message {
  position: relative;
  border-radius: 0px 20px 0px 20px;
  padding: 14px 12px;
  color: #000000;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  background: #efefef;
  position: relative;
  width: 100%;
  filter: drop-shadow(1px 1px 8px rgba(0, 0, 0, 0.15));
}
.message::before {
  content: "";
  position: absolute;
  top: 40%;
  left: calc(100% - 2px);
  clip-path: polygon(100% 0, 0 100%, 0 25%);
  width: 30px;
  height: 30px;
  background: #efefef;
}
.message p {
  margin-bottom: 6px;
}
.hint {
  visibility: hidden;
  position: absolute;
  z-index: 999;
  top: 0;
  left: 85%;
  min-width: 280px;
  max-width: 280px;
}
.hint .description {
  background: url("~@content/img/shared/home/svg/about-background-hint.svg");
  background-size: 100% auto;
  background-repeat: no-repeat;
  color: #555;
  text-align: center;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  padding: 22px 16px 20px 16px;
  word-wrap: break-word;
}
@media screen and (max-width: 1200px) {
  .content{
    display: block;
  }
  .info {
    width: 100%;
    padding-left: 5px;
    margin-top: 20px;
  }
  .info .header {
    font-size: 8vw;
    line-height: 10vw;
  }
  .info .header .first {
    font-size: 12vw;
    line-height: 14vw;
  }
  .info .header .second {
    font-size: 11.3vw;
    line-height: 12vw;
  }
  .info .subheader {
    font-size: 1rem;
    width: calc(100vw - 75px);
  }
  .info .description {
    width: calc(100vw - 75px);
  }
  .hint {
    display: none;
  }
  .background {
    display: none;
  }
}
