.info .totals {
  display: flex;
  flex-wrap: wrap;
  gap: 5px;
  margin-top: 10px;
  font-size: 15px;
  font-weight: 400;
  line-height: 18px;
  text-align: left;
  color: #696969;
}
.results {
  margin-top: 10px;
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  width: 100%;
}
.results .result {
  position: relative;
  display: flex;
  color: #555;
  width: 100%;
}
.results .result .card {
  cursor: default;
  border-radius: 20px;
  background: #fff;
  filter: drop-shadow(1px 1px 4px rgba(0, 0, 0, 0.15));
  padding: 12px 20px;
  color: #555;
  width: 100%;
}
.results .result .card .city {
  width: 100%;
  font-size: 13px;
  font-weight: 400;
  line-height: 16px;
  text-align: left;
}
.results .result .card .info {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.results .result .card .info .about {
  display: flex;
  align-items: center;
}
.results .result .card .info .about img {
  width: 75px;
  height: 48px;
  object-fit: contain;
}
.results .result .card .info .about .title {
  width: 400px;
  font-size: 16px;
  font-weight: 400;
  line-height: 20px;
  text-align: left;
  color: #1a1a1a;
  -webkit-line-clamp: 2;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
}
.results .result .card .info .toCompany {
  font-size: 11px;
  font-weight: 400;
  line-height: 13.5px;
  text-align: left;
  text-decoration: none;
  color: #1a1a1a;
}
.results .result .card .info .toCompany svg {
  margin-left: 10px;
  margin-bottom: 2px;
  fill: #1a1a1a;
}
.results .result .card .specialities {
  margin-top: 10px;
}
.results .result .card .specialities .title {
  display: flex;
  flex-wrap: wrap;
  gap: 5px;
  font-size: 13px;
  font-weight: 400;
  line-height: 16px;
  text-align: left;
  color: #1a1a1a;
  margin-bottom: 10px;
}
.results .result .card .specialities .title .colored {
  background: linear-gradient(
    191.12deg,
    #00b3ec -91.84%,
    #cbec00 -91.82%,
    #6e9a10 25.42%,
    #d0a024 126.74%
  );
  background-size: cover;
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.speciality + .speciality {
  margin-top: 5px;
}
.results .result .card .specialities .speciality {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: #f5f5f5;
  border-radius: 5px;
  padding: 5px 15px;
}
.results .result .card .specialities .speciality .name {
  -webkit-line-clamp: 1;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
  font-size: 13px;
  font-weight: 400;
  line-height: 16px;
  text-align: left;
  color: #1a1a1a;
  max-width: 250px;
}
.results .result .card .specialities .speciality .props {
  display: flex;
  align-items: center;
}
.results .result .card .specialities .speciality .props .prop {
  position: relative;
  justify-content: space-between;
  background-color: #fff;
  padding: 4px 10px;
  display: flex;
  align-items: center;
  color: #1a1a1a;
  width: 150px;
}
.results .result .card .specialities .speciality .props .prop .name {
  font-size: 10px;
  font-weight: 400;
  line-height: 10px;
  text-align: left;
}
.results .result .card .specialities .speciality .props .prop .value {
  margin-left: 5px;
  font-size: 13px;
  font-weight: 400;
  line-height: 15.73px;
  text-align: left;
}
.results .result .card .specialities .speciality .props .prop .separator {
  position: absolute;
  border: 1px solid #f5f5f5;
  height: 12px;
  right: -1px;
}
.results .result .card .specialities .speciality .props .go {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 20px;
  border-radius: 25px;
  background: linear-gradient(
    191.12deg,
    #00b3ec -91.84%,
    #cbec00 -91.82%,
    #6e9a10 25.42%,
    #d0a024 126.74%
  );
  padding: 7px 10px;
}
.results .result .card .specialities .speciality .props .go svg {
  fill: #fff;
  width: 20px;
  height: 10px;
}

.results .result .title {
  font-size: 13.5px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  -webkit-line-clamp: 3;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
}
.more {
  display: flex;
  justify-content: center;
  text-decoration: none;
}
.more .all {
  color: #000;
  font-size: 10px;
  font-weight: 300;
  line-height: 12px;
  text-align: left;
  cursor: pointer;
  position: relative;
  margin-top: 10px;
  padding: 5px 20px;
  box-shadow: 1px 1px 8px 0px rgba(0, 0, 0, 0.15);
  border-radius: 20px;
  text-decoration: none;
}
.more .all::before {
  content: "";
  position: absolute;
  background: linear-gradient(
    191.12deg,
    #00b3ec -91.84%,
    #cbec00 -91.82%,
    #6e9a10 25.42%,
    #d0a024 126.74%
  );
  width: 6px;
  height: 16px;
  top: calc(50% - 8px);
  left: 100%;
  clip-path: polygon(0 12.5%, 100% 50%, 0 87.5%);
}
.full {
  width: 100%;
  display: flex;
  justify-content: center;
}
@media screen and (max-width: 1200px) {
  .results .result .card .info {
    display: block;
  }
  .results .result .card .info .about .title {
    width: 100%;
  }
  .results .result .card .specialities .speciality {
    display: block;
    padding: 10px;
  }
  .results .result .card .specialities .speciality .name {
    margin-bottom: 10px;
  }
  .results .result .card .specialities .speciality .props {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
  }
  .results .result .card .specialities .speciality .props .prop {
    width: 100%;
  }
  .results .result .card .specialities .speciality .props .prop .name {
    margin-bottom: 0px;
  }
  .results .result .card .specialities .speciality .props .prop .separator {
    display: none;
  }
  .results .result .card .specialities .speciality .props .go {
    margin-left: 0px;
    width: 50px;
    margin-top: 10px;
  }
}
