.block {
  margin-top: 100px;
}
.copyright {
  margin-bottom: 0;
}
.feedback {
  display: flex;
  column-gap: 5px;
  align-items: center;
  cursor: pointer;
  margin-bottom: 0;
}
.block .social .link {
  color: #000;
  text-decoration: none;
  margin-right: 5px;
  font-size: 16px;
}
.social + .social {
  margin-top: 5px;
}
@media screen and (max-width: 1200px) {
  .copyright {
    margin-left: 5px;
    width: 100%;
  }
  .contacts {
    margin-right: 5px;
  }
}
