.cardFilter {
  width: 100%;
  height: 600px;
}
.cardFilter .border {
  background: linear-gradient(
    191.12deg,
    #00b3ec -91.84%,
    #cbec00 -91.82%,
    #6e9a10 25.42%,
    #d0a024 126.74%
  );
}
.cardFilter .arrow {
  background: linear-gradient(
    201.12deg,
    #00b3ec -91.84%,
    #cbec00 -91.82%,
    #6e9a10 25.42%,
    #d0a024 126.74%
  );
}
.cardFilter .content {
  padding: 30px 29px 20px 29px;
}
.cardFilter .content .searchFor {
  display: flex;
  gap: 10px;
}
.cardFilter .content .searchFor .option {
  cursor: pointer;
  border-radius: 8px;
}
.cardFilter .content .searchFor .option::before {
  border-radius: 8px;
}
.cardFilter .content .searchFor .option.active {
  background: linear-gradient(
    191.12deg,
    #00b3ec -91.84%,
    #cbec00 -91.82%,
    #6e9a10 25.42%,
    #d0a024 126.74%
  );
  color: #fff;
}
.cardFilter .content .submit {
  margin-top: 10px;
  width: 190px;
}
.cardFilter .content .submit .button {
  border-radius: 8px;
  cursor: pointer;
}
.cardFilter .content .submit .button::before {
  border-radius: 8px;
}
.cardFilter .content .submit .button:hover {
  background: linear-gradient(
    191.12deg,
    #00b3ec -91.84%,
    #cbec00 -91.82%,
    #6e9a10 25.42%,
    #d0a024 126.74%
  );
  color: #fff;
}
.cardFilter .content .clear {
  margin-top: 10px;
  width: 190px;
}
.cardFilter .content .clear .button {
  border-radius: 8px;
  cursor: pointer;
}
.cardFilter .content .clear .button::before {
  border-radius: 8px;
}
.cardFilter .content .clear .button:hover {
  background: linear-gradient(
    191.12deg,
    #00b3ec -91.84%,
    #cbec00 -91.82%,
    #6e9a10 25.42%,
    #d0a024 126.74%
  );
  color: #fff;
}
.cardFilter .content .inner {
  margin-top: 25px;
  width: 200px;
  height: 280px;
  overflow-y: auto;
}
.cardFilter .content .inner .checkboxGroup .checkboxGroupHeader {
  position: relative;
  color: #1a1a1a;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  margin-top: 10px;
  margin-bottom: 10px;
  cursor: pointer;
  width: 100%;
}
.cardFilter .content .inner .checkboxGroup .checkboxGroupHeader::after {
  position: absolute;
  content: "˅";
  top: -2px;
  right: 0;
  font-size: 25px;
  font-weight: 250;
}
.cardFilter .content .inner .checkboxGroup.active .checkboxGroupHeader::after {
  top: 0px;
  font-size: 28px;
  content: "^";
}
.cardFilter .content .inner .checkboxGroup .option {
  display: none;
  margin-right: 5px;
  margin-left: 5px;
  margin-bottom: 8px;
}
.cardFilter .content .inner .checkboxGroup.active .option {
  display: flex;
  gap: 5px;
  align-items: center;
}
.cardFilter .content .inner .checkboxGroup .option .checkboxLabel {
  padding-top: 2px;
  color: #1a1a1a;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-left: 5px;
  margin-bottom: 0px;
  overflow: hidden;
}
.cardFilter .content .inner .radioGroup .radioGroupHeader {
  position: relative;
  color: #555;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  margin: 5px 0px;
}
.cardFilter .content .inner .radioGroup .radioGroupHeader::after {
  position: absolute;
  content: "˅";
  top: -5px;
  right: 0;
  font-size: 30px;
  font-weight: 200;
}
.cardFilter .content .inner .radioGroup.active .radioGroupHeader::after {
  content: "^";
}
.cardFilter .content .inner .radioGroup .radio {
  display: none;
  margin-right: 5px;
}
.cardFilter .content .inner .radioGroup.active .radio {
  display: inline-block;
}
.cardFilter .content .inner .radioGroup .radioLabel {
  display: none;
  color: #555;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  vertical-align: end;
}
.cardFilter .content .inner .radioGroup.active .radioLabel {
  display: inline-block;
}
.cardFilter .content .inner .inputGroup .inputGroupHeader {
  position: relative;
  color: #555;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  margin: 5px 0px;
}
.cardFilter .content .inner .inputGroup .inputGroupHeader::after {
  position: absolute;
  content: "˅";
  top: -5px;
  right: 0;
  font-size: 30px;
  font-weight: 200;
}
.cardFilter .content .inner .inputGroup.active .inputGroupHeader::after {
  content: "^";
}
.cardFilter .content .inner .inputGroup .input {
  display: none;
  max-height: 26px;
  border-radius: 4px;
  border: 1px solid #555;
  font-size: 14px;
}
.cardFilter .content .inner .inputGroup .input::placeholder {
  color: #b1b1b1;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.cardFilter .content .inner .inputGroup.active .input {
  display: block;
}
.cardFilter .content .inner .betweenGroup .betweenGroupHeader {
  position: relative;
  color: #555;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  margin: 5px 0px;
}
.cardFilter .content .inner .betweenGroup .betweenGroupHeader::after {
  position: absolute;
  content: "˅";
  top: -5px;
  right: 0;
  font-size: 30px;
  font-weight: 200;
}
.cardFilter .content .inner .betweenGroup.active .betweenGroupHeader::after {
  content: "^";
}
.cardFilter .content .inner .betweenGroup .betweenInputs {
  display: none;
  gap: 10px;
}
.cardFilter .content .inner .betweenGroup .betweenInputs .input {
  max-height: 26px;
  border-radius: 4px;
  border: 1px solid #555;
  font-size: 14px;
}
.cardFilter .content .inner .betweenGroup .betweenInputs .input::placeholder {
  color: #b1b1b1;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.cardFilter .content .inner .betweenGroup.active .betweenInputs {
  display: flex;
}

@media screen and (max-width: 1200px) {
  .cardFilter {
    height: 310px;
    margin-bottom: 20px;
  }
  .cardFilter .content .inner {
    height: 100px;
    width: calc(100vw - 60px);
    overflow-y: auto;
  }
  .cardFilter .content .inner .checkboxGroup .checkboxGroupHeader {
    width: calc(100vw - 60px);
  }
}
