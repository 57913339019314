.block {
  margin-top: 100px;
}
.content {
  display: flex;
  flex-direction: column;
  row-gap: 55px;
}
.content .header {
  width: 100%;
  font-size: 32px;
  font-weight: 700;
  line-height: 40px;
  text-align: left;
  text-transform: uppercase;
  background: linear-gradient(180deg, #c12e2e 15.81%, #cd9f29 100.43%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.content .cards {
  display: flex;
  justify-content: space-between;
}
.content .cards .card {
  width: 30%;
  height: calc(100% - 20px);
  min-height: 400px;
  box-shadow: 1px 1px 8px 0px #00000026;
  border-radius: 10px;
  padding: 2px 14px 30px 14px;
}
.content .cards .card.big {
  width: 35%;
  height: calc(100% - 20px);
  min-height: 425px;
  padding: 2px 30px 30px 30px;
}
.content .cards .card .title {
  display: flex;
  justify-content: center;
  margin-top: 80px;
  margin-bottom: 22px;
}
.content .cards .card .title .container {
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: default;
  position: relative;
  background-color: #fff;
  border-radius: 19.5px;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  width: auto;
  padding-left: 30px;
  padding-right: 30px;
  height: 56px;
}
.content .cards .card.big .title .container {
  padding-left: 15px;
  padding-right: 15px;
}
.content .cards .card .title .container::before {
  content: "";
  background-image: linear-gradient(180deg, #c12e2e 15.81%, #cd9f29 100.43%);
  top: -1px;
  left: -1px;
  bottom: -1px;
  right: -1px;
  position: absolute;
  z-index: -1;
  border-radius: 20px;
}
.content .cards .card .title .container .iconContainer {
  position: absolute;
}
.content .cards .card .title .container .icon {
  position: relative;
  display: flex;
  justify-content: center;
  width: 100%;
}
.content .cards .card .title .container .icon img {
  position: absolute;
  top: -106px;
}
.content .cards .card .title .container .icon img:nth-child(2) {
  top: calc(50% - 80px);
}
.content .cards .card .title .container .text {
  margin-top: 10px;
  font-size: 15px;
  font-weight: 400;
  line-height: 18.15px;
  text-align: center;
}
.content .cards .card .steps {
  display: flex;
  flex-direction: column;
  row-gap: 14px;
}
.content .cards .card .steps .step {
  display: flex;
  align-items: center;
  column-gap: 10px;
}
.content .cards .card .steps .step .icon {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
}
.content .cards .card .steps .step .icon .normal {
  position: absolute;
}
.content .cards .card .steps .step .icon .success {
  position: absolute;
  opacity: 0;
}
.content .cards .card .steps .step.passed.auth {
  pointer-events: none !important;
}
.content .cards .card .steps .step.passed .icon .normal {
  opacity: 0.3;
}
.content .cards .card .steps .step.passed .icon .success {
  opacity: 1;
}
.content .cards .card .steps .step .text {
  display: flex;
  flex-wrap: wrap;
  column-gap: 5px;
  font-size: 16px;
  font-weight: 400;
  line-height: 20px;
  text-align: left;
}
.content .cards .card .steps .step .text span {
  cursor: pointer;
  position: relative;
  background: linear-gradient(155.19deg, #c12e2e 15.81%, #cd9f29 100.43%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  text-decoration: underline;
}
.content .cards .card .steps .step .text span::after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 1px;
  background: linear-gradient(155.19deg, #c12e2e 15.81%, #cd9f29 100.43%);
}
.content .cards .card .steps .step .text a {
  cursor: pointer;
  position: relative;
  background: linear-gradient(155.19deg, #c12e2e 15.81%, #cd9f29 100.43%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  text-decoration: underline;
}
.content .cards .card .steps .step .text a::after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 1px;
  background: linear-gradient(155.19deg, #c12e2e 15.81%, #cd9f29 100.43%);
}
@media screen and (max-width: 1200px) {
  .content {
    padding-left: 5px !important;
    padding-right: 5px !important;
  }
  .content .cards {
    flex-wrap: wrap;
    row-gap: 20px;
  }
  .content .cards .card {
    width: 100%;
  }
  .content .cards .card.big {
    width: 100%;
  }
}
