@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes fadeOut {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

@keyframes bounceIn {
  0% {
    opacity: 0;
    transform: scale(0.5);
  }
  100% {
    opacity: 1;
    transform: scale(1);
  }
}
@keyframes bounceOut {
  0% {
    opacity: 1;
    transform: scale(1);
  }
  100% {
    opacity: 0;
    transform: scale(0.5);
  }
}

@keyframes swipeLeftIn {
  0% {
    margin-left: -100%;
    margin-right: 100%;
    opacity: 0;
  }
  100% {
    margin-left: 0%;
    margin-right: 0%;
    opacity: 1;
  }
}
@keyframes swipeLeftOut {
  0% {
    margin-left: 0%;
    margin-right: 0%;
    opacity: 1;
  }
  100% {
    margin-left: -100%;
    margin-right: 100%;
    opacity: 0;
  }
}

@keyframes swipeRightIn {
  0% {
    margin-left: 100%;
    margin-right: -100%;
    opacity: 0;
  }
  100% {
    margin-left: 0%;
    margin-right: 0%;
    opacity: 1;
  }
}
@keyframes swipeRightOut {
  0% {
    margin-left: 0%;
    margin-right: 0%;
    opacity: 1;
  }
  100% {
    margin-left: 100%;
    margin-right: -100%;
    opacity: 0;
  }
}

@keyframes swipeTopIn {
  0% {
    margin-top: -100%;
    opacity: 0;
  }
  100% {
    margin-top: 0%;
    opacity: 1;
  }
}
@keyframes swipeTopOut {
  0% {
    margin-top: 0%;
    opacity: 1;
  }
  100% {
    margin-top: -100%;
    opacity: 0;
  }
}
