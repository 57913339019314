.block {
  display: flex;
  flex-wrap: wrap;
  position: relative;
  margin-top: 140px;
  justify-content: center;
}
.header {
  background: linear-gradient(
    180.12deg,
    #00b3ec -91.84%,
    #cbec00 -91.82%,
    #6e9a10 25.42%,
    #d0a024 126.74%
  );
  background-size: cover;
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-size: 36px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  text-transform: uppercase;
  text-align: left;
  margin-bottom: 40px;
  width: 100%;
}
.secondHeader {
  background: linear-gradient(
    180.12deg,
    #00b3ec -91.84%,
    #cbec00 -91.82%,
    #6e9a10 25.42%,
    #d0a024 126.74%
  );
  background-size: cover;
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-size: 32px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  text-align: left;
  margin-top: 40px;
  width: 100%;
}
.legend{
  display: flex;
  row-gap: 5px;
  flex-direction: column;
  width: 100%;
  margin-top: 10px;
}
.legend .legendPoint{
  display: flex;
}
.legend .legendPoint .icon.college {
  cursor: pointer;
  fill: #fff;
}
.legend .legendPoint .icon.university {
  cursor: pointer;
  fill: #0072ff;
}
.map {
  position: relative;
  margin-top: 20px;
  height: 960px;
  width: 800px;
}
.map .point {
  position: absolute;
}
.map .point .icon.college {
  cursor: pointer;
  fill: #fff;
}
.map .point .icon.university {
  cursor: pointer;
  fill: #0072ff;
}
.map .point .icon:hover {
  fill: #646464;
}
.map .point .card {
  height: 289px;
  width: 434px;
  position: absolute;
  display: none;
  top: 12px;
  right: 20px;
}
.card .border {
  background: linear-gradient(
    191.12deg,
    #00b3ec -91.84%,
    #cbec00 -91.82%,
    #6e9a10 25.42%,
    #d0a024 126.74%
  );
}
.card .arrow {
  background: linear-gradient(
    201.12deg,
    #00b3ec -91.84%,
    #cbec00 -91.82%,
    #6e9a10 25.42%,
    #d0a024 126.74%
  );
}
.map .point:hover .card {
  display: block;
  z-index: 1;
}
.card.mobile {
  display: none;
}
.card .content {
  margin-top: 5px;
  padding: 10px 20px 27px 22px;
  cursor: default;
  height: 280px;
  overflow-y: auto;
}
.card .content .item {
  border-bottom: 1px solid #e1e1e1;
  text-decoration: none;
}
.item + .item{
  margin-top: 12px;
}
.card .content .item .header {
  display: flex;
  color: #555;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  writing-mode: horizontal-tb;
  transform: none;
  background: none;
  background-clip: none;
  -webkit-background-clip: none;
  -webkit-text-fill-color: #555;
  text-transform: none;
  margin-right: 0;
  margin-bottom: 8px;
  align-items: center;
}
.card .content .item .header .icon {
  margin-right: 15px;
  margin-left: -4px;
  height: 48px;
  object-fit: contain;
}
.card .content .item .contacts {
  display: flex;
  gap: 40px;
}
.card .content .item .contacts .address {
  margin-bottom: 12px;
}
.card .content .item .contacts .address .title {
  color: #555;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-bottom: 8px;
}
.card .content .item .contacts .address .description {
  color: #555;
  font-size: 12px;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
}
@media screen and (max-width: 1200px) {
  .block {
    flex-wrap: wrap;
    justify-content: center;
  }
  .header {
    margin-left: 5px;
  }
  .secondHeader {
    margin-left: 5px;
  }
  .cardSearch {
    width: calc(100vw - 10px);
    height: 660px;
  }
  .cardSearch .content .results .result {
    width: 100%;
  }
  .map {
    margin-top: 20px;
    height: auto;
    display: block;
  }
  .map .point:hover .card {
    display: none;
    z-index: 10;
  }
  .card.mobile {
    margin-top: 20px;
    display: block;
    width: calc(100vw - 10px);
  }
}
