.personal h1,
h2,
h3,
h4,
h5,
h6 {
  color: var(--heading-color) !important;
}
.personal h6 {
  font-size: 0.875rem;
}
.personal .dropdown-menu {
  border: 0 solid #fff;
  color: #212529;
  font-weight: 400;
  font-size: 0.8125rem;
  border-radius: 0.3rem;
  box-shadow: 0 5px 10px rgba(30, 32, 37, 0.12);
}
.personal .dropdown-divider {
  border-top: 1px solid #e9ebec;
}
.personal .card {
  margin-bottom: 1.5rem;
  box-shadow: 0 1px 2px rgba(56, 65, 74, 0.15);
  border: 0 solid #fff;
}
.personal .card-header {
  background-color: #fff;
  padding: 16px;
  border-bottom: 1px solid #e9ebec;
}
.personal .card-title {
  font-size: 16px;
  margin: 0 0 7px 0;
}
.personal .card-body {
  border-radius: 0.2rem;
}
.personal table th,
td {
  font-size: 14px;
}
.personal table th {
  font-weight: 600;
}
.personal .text-primary {
  color: rgba(var(--text-primary-rgb), var(--text-opacity)) !important;
}
.personal p {
  font-size: 0.875rem;
  font-weight: 500;
  color: #878a99;
}
.personal .profile-nav.nav-pills .nav-link {
  color: rgba(255, 255, 255, 0.8);
  font-weight: 500;
}
.personal .profile-nav.nav-pills .nav-link::before {
  background-color: rgba(255, 255, 255, 0.1);
}
.personal .animation-nav li a.active,
.animation-nav li a:hover {
  color: #fff;
  background-color: transparent !important;
}
.personal .animation-nav li a {
  color: var(--vz-body-color);
  border-radius: 4px;
  position: relative;
  overflow: hidden;
  -webkit-transition: all 0.4s;
  transition: all 0.4s;
  z-index: 1;
}
.personal .animation-nav li a::before {
  content: "";
  position: absolute;
  top: 0;
  width: 0;
  right: 0;
  height: 100%;
  -webkit-transition: width 0.4s cubic-bezier(0.51, 0.18, 0, 0.88) 0.1s;
  transition: width 0.4s cubic-bezier(0.51, 0.18, 0, 0.88) 0.1s;
  background-color: #405189;
  z-index: -1;
}
.personal .animation-nav li a.active::before,
.animation-nav li a:hover::before {
  width: 100%;
  left: 0;
}
.rdt_TableBody {
  min-height: 200px;
}

.personal .ck-editor__main .ck-editor__editable {
  min-height: calc(100vh - 400px);
}
.company-select-mobile__control {
  border: none !important;
  border-radius: 0 !important;
  height: 35px !important;
}
.company-select__control {
  border: none !important;
  border-radius: 0 !important;
  height: 44px !important;
}
