.block {
  margin-bottom: 50px;
}
.block .foreground {
  position: relative;
}
.block .foreground .background {
  margin: -1.5rem;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  height: 320px;
}
.block .foreground .background::before {
  content: "";
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  opacity: 0.9;
  background: #405189;
  background: linear-gradient(to top, #171e32, #405189);
}
.block .info {
  position: relative;
  display: flex;
  gap: 1.5rem;
}
.block .info .icon {
  height: 6rem;
  width: 6rem;
}
.block .info .icon svg {
  background-color: #f3f3f9;
  border-radius: 50%;
  width: 100%;
  height: auto;
}
.block .info .description .fio {
  color: #fff !important;
}
.block .info .profession {
  color: rgba(255, 255, 255, 0.75) !important;
}
