.search {
  display: flex;
  justify-content: flex-start;
  gap: 10px;
  width: 100%;
  filter: drop-shadow(1px 1px 8px rgba(0, 0, 0, 0.15));
  background-color: #fff;
  border-radius: 20px;
  padding-left: 22px;
}
.search .icon {
  margin-top: 10px;
  margin-bottom: 10px;
  min-width: 22px;
}
.search .input {
  width: 100%;
  color: #646464;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  border: none;
  vertical-align: middle;
}
.search .input::placeholder {
  color: #e1e1e1;
  line-height: 18px;
  vertical-align: middle;
}

.search .input:focus-visible {
  outline: none;
}
.search .button {
  position: relative;
  min-width: 65px;
  border-radius: 0 20px 20px 20px;
  border: none;
  background: linear-gradient(
    191.12deg,
    #00b3ec -91.84%,
    #cbec00 -91.82%,
    #6e9a10 25.42%,
    #d0a024 126.74%
  );
}
.search .button::after {
  position: absolute;
  content: "";
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  visibility: hidden;
  background-color: #fff;
  opacity: 0.3;
  border-radius: 0 20px 20px 20px;
}
.search .button:hover::after {
  visibility: visible;
}
