.block {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  min-height: calc(100vh - 320px);
}
.block .card {
  width: 300px;
  height: 300px;
}
.block .card .inner {
  height: 100%;
  padding: 20px;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-content: space-between;
  gap: 15px;
}
.block .card .inner .title {
  color: #555;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}
.block .card .inner .formGroup {
  position: relative;
}
.block .card .inner .formGroup .input {
  width: 100%;
  border-radius: 20px;
  border: 1px solid #e1e1e1;
  padding: 10px 20px;
}
.block .card .inner .formGroup .input:focus-visible {
  outline: none;
}
.block .card .inner .formGroup .inputError {
  width: 100%;
  border-radius: 20px;
  border: 1px solid #ec4141;
  padding: 10px 20px;
}
.block .card .inner .formGroup .inputError:focus-visible {
  outline: none;
}
.block .card .inner .formGroup .errorMessage {
  position: absolute;
  font-size: 12px;
  color: #ec4141;
}
.block .card .inner .submit {
  position: relative;
  display: flex;
  width: 100%;
  height: 40px;
  justify-content: center;
}
.block .card .inner .submit .btn {
  cursor: pointer;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #fff;
  padding: 11px 30px;
  border-radius: 19.5px;
  border: none;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  max-width: 125px;
}
.block .card .inner .submit .btn::before {
  content: "";
  background-image: linear-gradient(170deg, #0072ff 15.81%, #29cd8f 100.43%);
  top: -1px;
  left: -1px;
  bottom: -1px;
  right: -1px;
  position: absolute;
  z-index: -1;
  border-radius: 20px;
}
.block .card .inner .submit .btn:hover {
  background: linear-gradient(
    191deg,
    #00b3ec -91.84%,
    #014170 25.42%,
    #8424d0 126.74%
  );
  color: #ffffff;
}
.block .card .inner .submit .submitError {
  font-size: 12px;
  line-height: 16px;
  color: #ec4141;
  position: absolute;
  bottom: -20px;
  overflow: hidden;
  height: 16px;
  text-align: center;
}
.block .card .inner .submit .submitSuccess {
  font-size: 12px;
  line-height: 16px;
  color: #41ec58;
  position: absolute;
  bottom: -20px;
  overflow: hidden;
  height: 16px;
  text-align: center;
}
.block .card .inner .addition {
  width: 100%;
  display: flex;
  justify-content: center;
  color: #555;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.block .card .inner .link {
  color: #555;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
