.block {
  position: relative;
  margin-top: 100px;
}
.header {
  background: linear-gradient(
    180deg,
    #00b3ec -91.84%,
    #cbec00 -91.82%,
    #6e9a10 25.42%,
    #d0a024 126.74%
  );
  text-transform: uppercase;
  background-size: cover;
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-size: 36px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}
.partners {
  margin-top: 77px;
  display: flex;
  flex-wrap: wrap;
  gap: 25px;
}
.partners .partner {
  cursor: default;
  position: relative;
  background-color: #fff;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  width: 350px;
}
.partners .partner .photo {
  height: 120px;
}
.partners .partner .photo img {
  height: 120px;
  width: 100%;
  object-fit: contain;
}
.partners .partner .info .title {
  padding: 8px 13px;
}
.partners .partner .info .title .name {
  color: #333;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}
@media screen and (max-width: 1200px) {
  .header {
    margin-left: 5px;
  }
  .partners {
    justify-content: center;
  }
  .partners .partner {
    width: calc(100vw - 10px);
  }
}
