.link {
  color: #405189;
  text-decoration: none;
}
.btnAddFiles {
  display: flex;
  padding: 0.3rem;
}
.btnAddFiles svg {
  margin-right: 0;
}
