.logo {
  cursor: pointer;
}
.search-container {
  display: flex;
  align-items: center;
  background: #efefef;
  border-radius: 30px;
}
.search-container .icon {
  height: 22px;
  width: 22px;
  margin-left: 22.75px;
}
.search-container .input {
  background: #efefef;
  border-radius: 30px;
  border: none;
  color: #000000;
  padding: 9px 10px 7px 12.75px;
  font-size: 15px;
  font-weight: 400;
  line-height: 18px;
  text-align: left;
  vertical-align: middle;
}

.search-container .input::placeholder {
  color: #000000;
  line-height: 20px;
  vertical-align: middle;
}

.search-container .input:focus-visible {
  outline: none;
}

.login-container {
  display: flex;
  align-items: center;
  height: 100%;
  text-decoration: none;
}

.login-container .icon {
  height: 30px;
  width: 30px;
  margin-right: 8px;
}

.login-container .label {
  font-size: 15px;
  line-height: 18px;
  font-weight: 400;
  color: #000000;
}
@media screen and (max-width: 1200px) {
  .logo {
    margin-left: 5px;
  }
  .search-container {
    display: none;
  }
  .login-container{
    margin-right: 5px;
  }
}
