.cell {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  width: 95px;
  height: 100px;
  padding: 5px;
  --border-color: rgba(239, 239, 239, 1);
}
.first {
  border-bottom: 1px solid var(--border-color);
  border-right: 1px solid var(--border-color);
}
.last {
  border-right: none !important;
  border-bottom: 1px solid var(--border-color);
}
.top {
  border-right: 1px solid var(--border-color);
  border-bottom: 1px solid var(--border-color);
}
.bottom {
  border-bottom: none !important;
  border-right: 1px solid var(--border-color);
}
.center {
  border-right: 1px solid var(--border-color);
  border-bottom: 1px solid var(--border-color);
}
.active {
  background: linear-gradient(
    191.12deg,
    #00b3ec -91.84%,
    #cbec00 -91.82%,
    #6e9a10 25.42%,
    #d0a024 126.74%
  );
}
.focus {
  border: 1px solid #d0a024 !important;
  color: #d0a024;
}
.active.focus {
  background: none;
}
@media screen and (max-width: 1200px) {
  .cell {
    width: calc(100vw / 7);
    height: calc(100vw / 7 - 2px);
  }
}
