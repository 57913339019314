.icon {
  width: 20px;
  height: 14px;
  position: relative;
  cursor: pointer;
  display: inline-block;
}
.icon span {
  background-color: #878a99;
  position: absolute;
  border-radius: 2px;
  -webkit-transition: 0.3s cubic-bezier(0.8, 0.5, 0.2, 1.4);
  transition: 0.3s cubic-bezier(0.8, 0.5, 0.2, 1.4);
  width: 100%;
  height: 2px;
  display: block;
  left: 0;
}
.icon span:nth-child(1) {
  top: 0;
  width: 80%;
}
.icon span:nth-child(2) {
  top: 6px;
}
.icon span:nth-child(3) {
  bottom: 0;
  width: 60%;
}
.icon.open {
  -webkit-transform: rotate(-90deg);
  transform: rotate(-90deg);
}
.icon.open span:nth-child(1) {
  left: 1px;
  top: 5px;
  width: 20px;
  -webkit-transform: rotate(90deg);
  transform: rotate(90deg);
  -webkit-transition-delay: 150ms;
  transition-delay: 150ms;
}
.icon.open span:nth-child(2) {
  left: 3px;
  top: 13px;
  width: 10px;
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
  -webkit-transition-delay: 50ms;
  transition-delay: 50ms;
}
.icon.open span:nth-child(3) {
  left: 9px;
  top: 13px;
  width: 10px;
  -webkit-transform: rotate(-45deg);
  transform: rotate(-45deg);
  -webkit-transition-delay: 0.1s;
  transition-delay: 0.1s;
}
