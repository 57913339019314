.search {
  display: flex;
  column-gap: 5px;
  padding: 0px 15px;
  margin-bottom: 14px;
}
.search input {
  font-size: 14px;
  width: calc(100% - 50px);
  border-radius: 5px;
  border: 1px solid #e1e1e1;
  padding: 0px 15px;
}
.search .btn {
  width: 50px;
  padding: 5px 10px;
}
.table {
  width: 100%;
  font-size: 14px;
}
.table .header .main {
  padding-left: 15px;
  width: 85%;
}
.table .header .info {
  text-align: center;
  width: 10%;
}
.table .header .opened {
  width: 5%;
  padding-right: 15px;
}
.table tr {
  border-bottom: 1px solid #e1e1e1;
}
.table .row .main {
  display: flex !important;
  align-items: center;
  width: auto;
  min-height: 45px;
  padding-left: 15px;
}
.table .row .info {
  position: relative;
  width: 10%;
  text-align: center;
}
.table .row .info::before {
  position: absolute;
  top: calc(50% - 10px);
  left: 0;
  width: 1px;
  height: 20px;
  content: "";
  background-color: #e1e1e1;
}
.table .row .opened {
  position: relative;
  width: 5%;
  font-size: 20px;
  font-weight: 200;
  text-align: center;
  padding-left: 10px;
  padding-right: 15px;
}
.table .row .opened::before {
  position: absolute;
  top: calc(50% - 10px);
  left: 0;
  width: 1px;
  height: 20px;
  content: "";
  background-color: #e1e1e1;
}
.table .row.choosed {
  color: #fff;
}
.table .row.choosed td {
  background-color: #405189;
}
.table .row.choosed .info::before {
  background-color: #fff;
}
.table .row.choosed .opened::before {
  background-color: #fff;
}
