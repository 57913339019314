.custom {
  vertical-align: middle;
  background-color: #0ab39c;
  border-color: #0ab39c;
  font-size: 0.8rem;
  padding: 0.5rem 0.9rem;
  border-radius: 0.25rem;
}
.custom svg {
  margin-right: 5px;
}
.custom:hover {
  background-color: #099885;
  border-color: #088f7d;
}
.custom:active {
  background-color: #088f7d !important;
  border-color: #088675;
  box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
}
