.block {
  position: relative;
  margin-top: 40px;
}
.tabs {
  display: flex;
  align-items: flex-end;
  border-bottom: none !important;
}
.tab {
  cursor: pointer;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  text-transform: uppercase;
  color: #999;
  border: none !important;
  border-radius: 0px !important;
  background: #fff;
  margin-bottom: 5px !important;
}
.tab:hover {
  color: #999;
  background: linear-gradient(
    191deg,
    #00b3ec -91.84%,
    #cbec00 -91.82%,
    #700101 25.42%,
    #d0a024 126.74%
  );
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.tab.active {
  font-size: 25px;
  background: linear-gradient(
    191deg,
    #00b3ec -91.84%,
    #cbec00 -91.82%,
    #700101 25.42%,
    #d0a024 126.74%
  );
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.tabContent {
  position: relative;
  margin-top: 30px;
  display: flex !important;
  justify-content: space-between;
  align-items: flex-start;
  align-content: flex-start;
  row-gap: 18px;
  flex-wrap: wrap;
}
.tabContent .practices {
  display: flex;
  flex-wrap: wrap;
  column-gap: 30px;
  row-gap: 25px;
}
.tabContent .practices .card {
  width: 535px;
  min-height: 120px;
  height: min-content;
}
.tabContent .practices .card .border {
  background-image: linear-gradient(
    191deg,
    #00b3ec -91.84%,
    #cbec00 -91.82%,
    #700101 25.42%,
    #d0a024 126.74%
  );
}
.tabContent .practices .card .arrow {
  background-image: linear-gradient(
    201deg,
    #00b3ec -91.84%,
    #cbec00 -91.82%,
    #700101 25.42%,
    #d0a024 126.74%
  );
}
.tabContent .practices .card .inner {
  padding: 20px;
}
.tabContent .practices .card .inner .dates {
  color: #555;
  font-size: 13px;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0em;
  text-align: left;
  text-align: end;
  width: 100%;
  margin-bottom: 8px;
}
.tabContent .practices .card .inner .title {
  color: #555;
  font-size: 16px;
  font-weight: 500;
  line-height: 19px;
  text-align: left;
  text-decoration: none;
  width: 100%;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}
.tabContent .practices .card .inner .subtitle {
  color: #555;
  font-size: 14px;
  font-weight: 400;
  line-height: 19px;
  text-align: left;
  text-decoration: none;
  width: 100%;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}
.tabContent .practices .card .inner .description {
  margin-top: 8px;
  color: #555;
  font-size: 14px;
  font-weight: 400;
  line-height: 19px;
  text-align: left;
  text-decoration: none;
}
.tabContent .mentorships {
  display: flex;
  flex-wrap: wrap;
  column-gap: 30px;
  row-gap: 25px;
}
.tabContent .mentorships .card {
  width: 535px;
  min-height: 120px;
  height: min-content;
}
.tabContent .mentorships .card .border {
  background-image: linear-gradient(
    191deg,
    #00b3ec -91.84%,
    #cbec00 -91.82%,
    #700101 25.42%,
    #d0a024 126.74%
  );
}
.tabContent .mentorships .card .arrow {
  background-image: linear-gradient(
    201deg,
    #00b3ec -91.84%,
    #cbec00 -91.82%,
    #700101 25.42%,
    #d0a024 126.74%
  );
}
.tabContent .mentorships .card .inner {
  padding: 20px;
}
.tabContent .mentorships .card .inner .dates {
  color: #555;
  font-size: 13px;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0em;
  text-align: left;
  text-align: end;
  width: 100%;
  margin-bottom: 8px;
}
.tabContent .mentorships .card .inner .title {
  color: #555;
  font-size: 16px;
  font-weight: 500;
  line-height: 19px;
  text-align: left;
  text-decoration: none;
  width: 100%;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}
.tabContent .mentorships .card .inner .subtitle {
  color: #555;
  font-size: 14px;
  font-weight: 400;
  line-height: 19px;
  text-align: left;
  text-decoration: none;
  width: 100%;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}
.tabContent .mentorships .card .inner .description {
  margin-top: 14px;
  color: #555;
  font-size: 14px;
  font-weight: 400;
  line-height: 19px;
  text-align: left;
  text-decoration: none;
}
.tabContent .vacancies {
  display: flex;
  flex-wrap: wrap;
  column-gap: 30px;
  row-gap: 25px;
}
.tabContent .vacancies .card {
  width: 535px;
  min-height: 120px;
  height: min-content;
}
.tabContent .vacancies .card .border {
  background-image: linear-gradient(
    191deg,
    #00b3ec -91.84%,
    #cbec00 -91.82%,
    #700101 25.42%,
    #d0a024 126.74%
  );
}
.tabContent .vacancies .card .arrow {
  background-image: linear-gradient(
    201deg,
    #00b3ec -91.84%,
    #cbec00 -91.82%,
    #700101 25.42%,
    #d0a024 126.74%
  );
}
.tabContent .vacancies .card .inner {
  padding: 20px;
}
.tabContent .vacancies .card .inner .dates {
  color: #555;
  font-size: 13px;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0em;
  text-align: left;
  text-align: end;
  width: 100%;
  margin-bottom: 8px;
}
.tabContent .vacancies .card .inner .title {
  color: #555;
  font-size: 16px;
  font-weight: 500;
  line-height: 19px;
  text-align: left;
  text-decoration: none;
  width: 100%;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}
.tabContent .vacancies .card .inner .subtitle {
  color: #555;
  font-size: 14px;
  font-weight: 400;
  line-height: 19px;
  text-align: left;
  text-decoration: none;
  width: 100%;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}
.tabContent .vacancies .card .inner .description {
  margin-top: 8px;
  color: #555;
  font-size: 14px;
  font-weight: 400;
  line-height: 19px;
  text-align: left;
  text-decoration: none;
}
@media screen and (max-width: 1200px) {
  .tabContent .practices {
    padding: 0 5px;
  }
  .tabContent .practices .card {
    width: calc(100vw - 10px);
  }
  .tabContent .mentorships {
    padding: 0 5px;
  }
  .tabContent .mentorships .card {
    width: calc(100vw - 10px);
  }
  .tabContent .vacancies {
    padding: 0 5px;
  }
  .tabContent .vacancies .card {
    width: calc(100vw - 10px);
  }
}
