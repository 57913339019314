.card {
  position: relative;
  width: 100%;
  height: 100%;
}
.card.rightTop .background {
  transform: scale(1, -1);
}
.card.leftTop .background {
  transform: scale(-1, -1);
}
.card.rightBottom .background {
  transform: none;
}
.card.leftBottom .background {
  transform: scale(-1, 1);
}
.background {
  position: absolute;
  z-index: -1;
  width: 100%;
  height: 100%;
}
.background .fill {
  position: absolute;
  width: calc(100% - 2px);
  height: calc(100% - 2px);
  background: #ffffff;
  -webkit-clip-path: polygon(
    0 0,
    100% 0,
    100% calc(73.3%),
    calc(93.3%) 100%,
    0 100%
  );
  clip-path: polygon(
    0 0,
    100% 0,
    100% calc(100% - var(--arrow-height) - 1px),
    calc(100% - var(--arrow-width) - 1px) 100%,
    0 100%
  );
  border: none;
  border-radius: 19.5px 19.5px 0px 19.5px;
}
.background .border {
  position: absolute;
  -webkit-clip-path: polygon(
    0 0,
    100% 0,
    100% calc(73%),
    calc(93%) 100%,
    0 100%
  );
  clip-path: polygon(
    0 0,
    100% 0,
    100% calc(100% - var(--arrow-height) - 1.5px),
    calc(100% - var(--arrow-width) - 1.5px) 100%,
    0 100%
  );
  background-image: linear-gradient(
    191.12deg,
    #00b3ec -91.84%,
    #014170 25.42%,
    #8424d0 126.74%
  );
  top: -1px;
  left: -1px;
  bottom: -1px;
  right: -1px;
  z-index: -1;
  border-radius: 20px 20px 0px 20px;
  width: 100%;
  height: 100%;
}
.background .arrow {
  position: absolute;
  bottom: 1px;
  right: 1px;
  width: var(--arrow-width);
  height: var(--arrow-height);
  clip-path: polygon(100% 0, 0 100%, 100% 100%);
  background-image: linear-gradient(
    201.12deg,
    #00b3ec -91.84%,
    #014170 25.42%,
    #8424d0 186.74%
  );
}
.content {
  z-index: 1;
  height: 100%;
  width: 100%;
}
